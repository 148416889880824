import React, { useEffect } from 'react'
import { useTX } from '@transifex/react'

import { TCoreIoUserLanguageChoices } from '../api/consts'
import { useMe } from '../hooks'

const transifexLangMap: Record<TCoreIoUserLanguageChoices, string> = {
  DA: 'da',
  EN: 'en',
  NO: 'no_NO',
  FI: 'fi',
  SV: 'sv',
}

/**
 * Set transifex language based on user setting
 */
const Transifex = () => {
  const me = useMe()
  const tx = useTX()

  const userLanguage = me?.language as TCoreIoUserLanguageChoices

  useEffect(() => {
    if (userLanguage) {
      const isLangSupported = transifexLangMap[userLanguage]
      if (isLangSupported) {
        tx.setCurrentLocale(isLangSupported)
      } else {
        // eslint-disable-next-line no-console
        console.log(
          new Error(`
        User language: ${userLanguage} is not supported by transifex.
        Setting language to en as fallback.
        `)
        )
      }
    }
  }, [userLanguage, tx])

  return <></>
}

export default Transifex
