import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { CoreCompanyEmailAccountSyncStateChoices } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const coreCompanyEmailAccountSyncStateChoicesLabelMap: Record<
  CoreCompanyEmailAccountSyncStateChoices,
  ReactNode
> = {
  DOWNLOADING: <T _str="Downloading" />,
  EXCEPTION: <T _str="Exception" />,
  NEED_INITIALIZATION: <T _str="Need initialization" />,
  INITIALIZING: <T _str="Initializing" />,
  INVALID: <T _str="Invalid" />,
  INVALID_CREDENTIALS: <T _str="InvalidCredentials" />,
  PARTIAL: <T _str="Partial" />,
  RUNNING: <T _str="Running" />,
  STOPPED: <T _str="Stopped" />,
  SYNC_ERROR: <T _str="SyncError" />,
  VALID: <T _str="Valid" />,
}

export const coreCompanyEmailAccountSyncStateChoicesTagColorMap: Record<
  CoreCompanyEmailAccountSyncStateChoices,
  TagProps['color']
> = {
  DOWNLOADING: 'gray',
  EXCEPTION: 'gray',
  NEED_INITIALIZATION: 'gray',
  INITIALIZING: 'gray',
  INVALID: 'gray',
  INVALID_CREDENTIALS: 'gray',
  PARTIAL: 'gray',
  RUNNING: 'green',
  STOPPED: 'red',
  SYNC_ERROR: 'gray',
  VALID: 'gray',
}

export const coreCompanyEmailAccountSyncStateChoicesOptions =
  createOptionsFromLabelMap(coreCompanyEmailAccountSyncStateChoicesLabelMap)
