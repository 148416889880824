export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  DriverBreakDurationInput: { input: any; output: any; }
  FromGlobalIDValuesList: { input: any; output: any; }
  GenericScalar: { input: any; output: any; }
  GeoJSON: { input: any; output: any; }
  JSONString: { input: any; output: any; }
  RangeDateTimeScalar: { input: any; output: any; }
  TicketEventTypeValuesList: { input: any; output: any; }
  Time: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type ActivePriceDef = Node & {
  __typename: 'ActivePriceDef';
  amount: Scalars['Int']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  endDate: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  product: ProductDef;
  recurringInterval: CrmActivePriceViewRecurringIntervalChoices;
  startDate: Scalars['Date']['output'];
  weightAmount: Scalars['Int']['output'];
};

export type ActivePriceDefConnection = {
  __typename: 'ActivePriceDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActivePriceDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ActivePriceDef` and its cursor. */
export type ActivePriceDefEdge = {
  __typename: 'ActivePriceDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ActivePriceDef>;
};

export type AddAssociatedTicketsToCompanyEmailAccountMessage = {
  __typename: 'AddAssociatedTicketsToCompanyEmailAccountMessage';
  companyEmailAccountMessage?: Maybe<CompanyEmailAccountMessageDef>;
};

export type AddCard = {
  __typename: 'AddCard';
  billingInformation?: Maybe<BillingInformationType>;
};

export type AddContactToProperties = {
  __typename: 'AddContactToProperties';
  contact?: Maybe<ContactDef>;
};

export type AddContactToPropertyInput = {
  debtorNumber?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  role: ContactRole;
};

export type AgreementDef = Node & {
  __typename: 'AgreementDef';
  availableDates?: Maybe<Array<Maybe<Scalars['Date']['output']>>>;
  billingPrice?: Maybe<Scalars['Int']['output']>;
  company: CompanyType;
  container?: Maybe<ContainerType>;
  /** @deprecated use `product { productType, activePrice { ... } }` instead */
  containerPrice?: Maybe<AgreementDeprecatedPriceDef>;
  createdAt: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  endedByTicket?: Maybe<TicketDef>;
  eventLog?: Maybe<AgreementEventLogEventWrapper>;
  excludeFromBilling: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  invoice?: Maybe<InvoiceDef>;
  invoiceItems: InvoiceItemDefConnection;
  isActive: Scalars['Boolean']['output'];
  isEnded?: Maybe<Scalars['Boolean']['output']>;
  isFuture: Scalars['Boolean']['output'];
  isSplit: Scalars['Boolean']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  myShare?: Maybe<Scalars['Float']['output']>;
  nextTicket?: Maybe<TicketDef>;
  prices?: Maybe<AgreementPricesDef>;
  product: ProductDef;
  property: PropertyDef;
  quantity: Scalars['Int']['output'];
  /** @deprecated use `product { productType, activePrice { ... } }` instead */
  recurringPrice?: Maybe<AgreementDeprecatedPriceDef>;
  shares?: Maybe<AgreementShareDefConnection>;
  startDate: Scalars['Date']['output'];
  startedByTicket?: Maybe<TicketDef>;
  uniqueExternalId: Scalars['String']['output'];
};


export type AgreementDefAvailableDatesArgs = {
  periodEnd?: InputMaybe<Scalars['Date']['input']>;
  periodStart?: InputMaybe<Scalars['Date']['input']>;
};


export type AgreementDefEventLogArgs = {
  addAgreementEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addContainerEvents?: InputMaybe<Scalars['Boolean']['input']>;
  agreementEventTypes?: InputMaybe<Array<InputMaybe<AgreementEventTypesGraphene>>>;
  containerEventTypes?: InputMaybe<Array<InputMaybe<DeviceContainerEventTypesGraphene>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type AgreementDefInvoiceItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type AgreementDefSharesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type AgreementDefConnection = {
  __typename: 'AgreementDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AgreementDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AgreementDef` and its cursor. */
export type AgreementDefEdge = {
  __typename: 'AgreementDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AgreementDef>;
};

export type AgreementDeprecatedPriceDef = {
  __typename: 'AgreementDeprecatedPriceDef';
  amount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  recurringInterval?: Maybe<Scalars['Int']['output']>;
};

export type AgreementEventDef = Node & {
  __typename: 'AgreementEventDef';
  agreement?: Maybe<AgreementDef>;
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<AgreementEventTypesGraphene>>>;
  fromEndDate?: Maybe<Scalars['Date']['output']>;
  fromExcludeFromBilling?: Maybe<Scalars['Boolean']['output']>;
  fromQuantity?: Maybe<Scalars['Int']['output']>;
  fromStartDate?: Maybe<Scalars['Date']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toEndDate?: Maybe<Scalars['Date']['output']>;
  toExcludeFromBilling?: Maybe<Scalars['Boolean']['output']>;
  toQuantity?: Maybe<Scalars['Int']['output']>;
  toStartDate?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<UserType>;
};

export type AgreementEventLogEvent = AgreementEventDef | ContainerEventDef;

export type AgreementEventLogEventWrapper = {
  __typename: 'AgreementEventLogEventWrapper';
  edges?: Maybe<Array<Maybe<AgreementEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum AgreementEventTypesGraphene {
  END_DATE_CHANGE = 'END_DATE_CHANGE',
  EXCLUDE_FROM_BILLING_CHANGE = 'EXCLUDE_FROM_BILLING_CHANGE',
  QUANTITY_CHANGE = 'QUANTITY_CHANGE',
  START_DATE_CHANGE = 'START_DATE_CHANGE'
}

export type AgreementExportDef = Node & {
  __typename: 'AgreementExportDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  exportFile?: Maybe<Scalars['String']['output']>;
  fromDate: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  mode: CrmAgreementExportModeChoices;
  modeChangeIncludeNew: Scalars['Boolean']['output'];
  modeChangeIncludeRemoved: Scalars['Boolean']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  toDate: Scalars['DateTime']['output'];
  totalObjects: Scalars['Int']['output'];
  totalObjectsCompleted: Scalars['Int']['output'];
};

export type AgreementExportDefConnection = {
  __typename: 'AgreementExportDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AgreementExportDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AgreementExportDef` and its cursor. */
export type AgreementExportDefEdge = {
  __typename: 'AgreementExportDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AgreementExportDef>;
};

export type AgreementPricesChangeContainerTypeDef = {
  __typename: 'AgreementPricesChangeContainerTypeDef';
  containerType: ContainerTypeType;
  price: PriceDef;
};

export type AgreementPricesChangePickupSettingDef = {
  __typename: 'AgreementPricesChangePickupSettingDef';
  pickupSetting: PickupSettingsType;
  price: PriceDef;
};

export type AgreementPricesDef = {
  __typename: 'AgreementPricesDef';
  changeContainerType: Array<Maybe<AgreementPricesChangeContainerTypeDef>>;
  changePickupSetting: Array<Maybe<AgreementPricesChangePickupSettingDef>>;
};

export type AgreementShareDef = Node & {
  __typename: 'AgreementShareDef';
  agreement: AgreementDef;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  property: PropertyDef;
  share: Scalars['Decimal']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type AgreementShareDefConnection = {
  __typename: 'AgreementShareDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AgreementShareDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AgreementShareDef` and its cursor. */
export type AgreementShareDefEdge = {
  __typename: 'AgreementShareDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AgreementShareDef>;
};

export type AgreementShareInput = {
  propertyId: Scalars['ID']['input'];
  share: Scalars['Decimal']['input'];
};

export type AgreementTemplateDef = Node & {
  __typename: 'AgreementTemplateDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  products: AgreementTemplateProductDefConnection;
  propertyType: PropertyTypeDef;
};


export type AgreementTemplateDefProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AgreementTemplateDefConnection = {
  __typename: 'AgreementTemplateDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AgreementTemplateDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AgreementTemplateDef` and its cursor. */
export type AgreementTemplateDefEdge = {
  __typename: 'AgreementTemplateDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AgreementTemplateDef>;
};

export type AgreementTemplateProductDef = Node & {
  __typename: 'AgreementTemplateProductDef';
  agreementtemplateSet: AgreementTemplateDefConnection;
  company: CompanyType;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  product: ProductDef;
  quantity: Scalars['Int']['output'];
};


export type AgreementTemplateProductDefAgreementtemplateSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AgreementTemplateProductDefConnection = {
  __typename: 'AgreementTemplateProductDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AgreementTemplateProductDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AgreementTemplateProductDef` and its cursor. */
export type AgreementTemplateProductDefEdge = {
  __typename: 'AgreementTemplateProductDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AgreementTemplateProductDef>;
};

export type AgreementTemplateProductMultipleChoiceFieldInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  product: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type AlertDef = Node & {
  __typename: 'AlertDef';
  alertRule: AlertRuleDef;
  alerteventSet: AlertEventDefConnection;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  closedBy?: Maybe<UserType>;
  company: CompanyType;
  container: ContainerType;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};


export type AlertDefAlerteventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AlertDefConnection = {
  __typename: 'AlertDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AlertDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AlertDef` and its cursor. */
export type AlertDefEdge = {
  __typename: 'AlertDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AlertDef>;
};

export type AlertEventDef = Node & {
  __typename: 'AlertEventDef';
  alert: AlertDef;
  alertRecipient: AlertRecipientDef;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  type: AlertsAlertEventTypeChoices;
};

export type AlertEventDefConnection = {
  __typename: 'AlertEventDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AlertEventDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AlertEventDef` and its cursor. */
export type AlertEventDefEdge = {
  __typename: 'AlertEventDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AlertEventDef>;
};

/** An enumeration. */
export enum AlertEventTypeChoices {
  CLOSED = 'CLOSED',
  NEW = 'NEW',
  OPEN = 'OPEN'
}

export type AlertRecipientDef = Node & {
  __typename: 'AlertRecipientDef';
  alertEventType?: Maybe<Array<AlertEventTypeChoices>>;
  alerteventSet: AlertEventDefConnection;
  alertruleSet: AlertRuleDefConnection;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  team?: Maybe<TeamType>;
  user?: Maybe<UserType>;
};


export type AlertRecipientDefAlerteventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type AlertRecipientDefAlertruleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AlertRecipientDefConnection = {
  __typename: 'AlertRecipientDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AlertRecipientDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AlertRecipientDef` and its cursor. */
export type AlertRecipientDefEdge = {
  __typename: 'AlertRecipientDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AlertRecipientDef>;
};

export type AlertRuleAlertRecipientMultipleChoiceFieldInput = {
  alertEventType: Array<AlertEventTypeChoices>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type AlertRuleDef = Node & {
  __typename: 'AlertRuleDef';
  alertRecipients?: Maybe<Array<Maybe<AlertRecipientDef>>>;
  alertSet: AlertDefConnection;
  company?: Maybe<CompanyType>;
  containerTypes?: Maybe<Array<Maybe<ContainerTypeType>>>;
  containers?: Maybe<Array<Maybe<ContainerType>>>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  intervalCount: Scalars['Int']['output'];
  intervalType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  percentageThreshold?: Maybe<Scalars['String']['output']>;
  wasteFractions?: Maybe<Array<Maybe<WasteFractionType>>>;
};


export type AlertRuleDefAlertSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AlertRuleDefConnection = {
  __typename: 'AlertRuleDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AlertRuleDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AlertRuleDef` and its cursor. */
export type AlertRuleDefEdge = {
  __typename: 'AlertRuleDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AlertRuleDef>;
};

/** An enumeration. */
export enum AlertsAlertEventTypeChoices {
  /** Closed */
  CLOSED = 'CLOSED',
  /** New */
  NEW = 'NEW',
  /** Open */
  OPEN = 'OPEN'
}

/**
 *
 *     Types that can be used in ExportTemplate data dict
 *     Database value should represent model name
 *
 */
export enum AllExportTypes {
  ACTIVE_PRICE = 'ACTIVE_PRICE',
  AGREEMENT = 'AGREEMENT',
  AREA = 'AREA',
  COLLECTION_CALENDAR = 'COLLECTION_CALENDAR',
  COLLECTION_CALENDAR_DATE = 'COLLECTION_CALENDAR_DATE',
  COMPANY = 'COMPANY',
  CONTAINER = 'CONTAINER',
  CONTAINER_GROUP = 'CONTAINER_GROUP',
  CONTAINER_TYPE = 'CONTAINER_TYPE',
  DEPOT = 'DEPOT',
  DRIVER = 'DRIVER',
  HAULER_PRICE = 'HAULER_PRICE',
  IOUSER = 'IOUSER',
  LOCATION = 'LOCATION',
  PICKUP_ORDER = 'PICKUP_ORDER',
  PICKUP_SETTINGS = 'PICKUP_SETTINGS',
  PRODUCT = 'PRODUCT',
  PROJECT = 'PROJECT',
  PROPERTY = 'PROPERTY',
  PROPERTY_CONTACT = 'PROPERTY_CONTACT',
  PROPERTY_GROUP = 'PROPERTY_GROUP',
  PROPERTY_TYPE = 'PROPERTY_TYPE',
  ROUTE = 'ROUTE',
  ROUTEVEHICLE = 'ROUTEVEHICLE',
  ROUTE_SCHEME = 'ROUTE_SCHEME',
  SERVICE = 'SERVICE',
  TICKET = 'TICKET',
  TICKET_TYPE = 'TICKET_TYPE',
  VEHICLE = 'VEHICLE',
  VEHICLE_SIZE = 'VEHICLE_SIZE',
  VEHICLE_TYPE = 'VEHICLE_TYPE',
  WASTE_FRACTION = 'WASTE_FRACTION',
  WASTE_STATION = 'WASTE_STATION',
  WEEK = 'WEEK'
}

/** An enumeration. */
export enum AnalyticsComparisonSettingsAmountOfCollectionPeriodChoices {
  A = 'A_',
  /** day */
  DAY = 'DAY',
  /** month */
  MONTH = 'MONTH',
  /** week */
  WEEK = 'WEEK',
  /** year */
  YEAR = 'YEAR'
}

/** An enumeration. */
export enum AnalyticsCostSettingsAmountOfCollectionPeriodChoices {
  A = 'A_',
  /** day */
  DAY = 'DAY',
  /** month */
  MONTH = 'MONTH',
  /** week */
  WEEK = 'WEEK',
  /** year */
  YEAR = 'YEAR'
}

export type AnalyticsCount = {
  __typename: 'AnalyticsCount';
  count?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AnalyticsType = {
  __typename: 'AnalyticsType';
  containerLogs_FillPercentage?: Maybe<ContainerLogAnalyticsType>;
  containers_BatteryPercentage?: Maybe<ContainerAnalyticsType>;
  containers_Efficient?: Maybe<Scalars['Float']['output']>;
  containers_FillPercentage?: Maybe<ContainerAnalyticsType>;
  containers_FillingFrequency?: Maybe<ContainerAnalyticsType>;
  containers_Status?: Maybe<ContainerAnalyticsType>;
  containers_TotalVolume?: Maybe<Scalars['Float']['output']>;
  containers_TotalWeight?: Maybe<Scalars['Float']['output']>;
  heatMapWeight?: Maybe<Array<Maybe<ObjectAnalyticsHeatMapType>>>;
  routes_Status?: Maybe<RouteAnalyticsType>;
  timeSeriesWeight?: Maybe<Array<Maybe<ObjectAnalyticsTimeSeriesType>>>;
  totalDistance?: Maybe<Scalars['Float']['output']>;
  vehicles_Status?: Maybe<VehicleAnalyticsType>;
};


export type AnalyticsTypeContainerLogs_FillPercentageArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
};


export type AnalyticsTypeContainers_BatteryPercentageArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
};


export type AnalyticsTypeContainers_FillPercentageArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};


export type AnalyticsTypeContainers_FillingFrequencyArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
};


export type AnalyticsTypeContainers_StatusArgs = {
  status: Scalars['String']['input'];
};


export type AnalyticsTypeContainers_TotalVolumeArgs = {
  From: Scalars['DateTime']['input'];
  To: Scalars['DateTime']['input'];
};


export type AnalyticsTypeContainers_TotalWeightArgs = {
  From: Scalars['DateTime']['input'];
  To: Scalars['DateTime']['input'];
};


export type AnalyticsTypeHeatMapWeightArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
};


export type AnalyticsTypeRoutes_StatusArgs = {
  From: Scalars['DateTime']['input'];
  To: Scalars['DateTime']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};


export type AnalyticsTypeTimeSeriesWeightArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
};


export type AnalyticsTypeVehicles_StatusArgs = {
  status: Scalars['String']['input'];
};

export type ArchiveBillingRun = {
  __typename: 'ArchiveBillingRun';
  billingRun?: Maybe<BillingRunDef>;
};

export type ArchiveProduct = {
  __typename: 'ArchiveProduct';
  product?: Maybe<ProductDef>;
};

export type AreaDef = Node & {
  __typename: 'AreaDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  haulerpriceSet: HaulerPriceDefConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pickuporderSet: PickUpOrderTypeConnection;
  propertySet: PropertyDefConnection;
  scheduleonrouteactionSet: ScheduleOnRouteActionDefConnection;
};


export type AreaDefHaulerpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type AreaDefPickuporderSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type AreaDefPropertySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type AreaDefScheduleonrouteactionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AreaDefConnection = {
  __typename: 'AreaDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AreaDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AreaDef` and its cursor. */
export type AreaDefEdge = {
  __typename: 'AreaDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AreaDef>;
};

export type AssignTicket = {
  __typename: 'AssignTicket';
  ticket?: Maybe<TicketDef>;
};

export type AssignTicketToTeam = {
  __typename: 'AssignTicketToTeam';
  ticket?: Maybe<TicketDef>;
};

export type AssignToTeamActionDef = Node & {
  __typename: 'AssignToTeamActionDef';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  rule: TicketRuleDef;
  team: TeamType;
};

export type AssignToTeamActionDefConnection = {
  __typename: 'AssignToTeamActionDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssignToTeamActionDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AssignToTeamActionDef` and its cursor. */
export type AssignToTeamActionDefEdge = {
  __typename: 'AssignToTeamActionDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AssignToTeamActionDef>;
};

export type AssignToTeamActionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  team: Scalars['ID']['input'];
};

export type AssignToUserActionDef = Node & {
  __typename: 'AssignToUserActionDef';
  assignee: UserType;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  rule: TicketRuleDef;
};

export type AssignToUserActionDefConnection = {
  __typename: 'AssignToUserActionDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssignToUserActionDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AssignToUserActionDef` and its cursor. */
export type AssignToUserActionDefEdge = {
  __typename: 'AssignToUserActionDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AssignToUserActionDef>;
};

export type AssignToUserActionMutationInput = {
  assignee: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AssignUserCompanyRole = {
  __typename: 'AssignUserCompanyRole';
  user?: Maybe<UserType>;
};

export type AttachDeviceToContainer = {
  __typename: 'AttachDeviceToContainer';
  fillLevelDevice?: Maybe<BaseDeviceInterface>;
};

export type BBoxInput = {
  maxLat: Scalars['Float']['input'];
  maxLon: Scalars['Float']['input'];
  minLat: Scalars['Float']['input'];
  minLon: Scalars['Float']['input'];
};

export type BarcodeGenerationInput = {
  containerId?: InputMaybe<Scalars['String']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
};

export type BaseDeviceInterface = {
  company?: Maybe<CompanyType>;
  connectionType?: Maybe<Scalars['String']['output']>;
  containerDevice?: Maybe<DeviceToContainerTypeConnection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  devId?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<DeviceTypeType>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isContainer?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type BaseDeviceInterfaceContainerDeviceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type BillingFeatureProductPriceType = Node & {
  __typename: 'BillingFeatureProductPriceType';
  billingScheme: CoreBillingFeatureProductPriceBillingSchemeChoices;
  currency: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  maxAmount?: Maybe<Scalars['Int']['output']>;
  minAmount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  tiersUnitAmount?: Maybe<Scalars['Int']['output']>;
  tiersUpTo?: Maybe<Scalars['Int']['output']>;
  unitAmount?: Maybe<Scalars['Int']['output']>;
};

export type BillingFeatureProductType = Node & {
  __typename: 'BillingFeatureProductType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  isSeat: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  prices?: Maybe<Array<Maybe<BillingFeatureProductPriceType>>>;
  recurring: Scalars['Boolean']['output'];
  shippable: Scalars['Boolean']['output'];
  shippableStock: Scalars['Boolean']['output'];
  unitLabel?: Maybe<Scalars['String']['output']>;
};


export type BillingFeatureProductTypePricesArgs = {
  recurringInterval: Scalars['String']['input'];
  recurringIntervalCount: Scalars['Int']['input'];
};

export type BillingFeatureType = Node & {
  __typename: 'BillingFeatureType';
  feature: CoreBillingFeatureFeatureChoices;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  products?: Maybe<Array<Maybe<BillingFeatureProductType>>>;
};

export type BillingFeatureTypeConnection = {
  __typename: 'BillingFeatureTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillingFeatureTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `BillingFeatureType` and its cursor. */
export type BillingFeatureTypeEdge = {
  __typename: 'BillingFeatureTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BillingFeatureType>;
};

export type BillingInformationCardType = {
  __typename: 'BillingInformationCardType';
  brand?: Maybe<Scalars['String']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  expMonth?: Maybe<Scalars['Int']['output']>;
  expYear?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BillingInformationType = Node & {
  __typename: 'BillingInformationType';
  address: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  availableMpFeatures?: Maybe<Array<Maybe<BillingFeatureType>>>;
  availableWhFeatures?: Maybe<Array<Maybe<BillingFeatureType>>>;
  cards?: Maybe<Array<Maybe<BillingInformationCardType>>>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  defaultCollectionMethod: CoreBillingInformationDefaultCollectionMethodChoices;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  invoiceEmail: Scalars['String']['output'];
  invoicePhone: Scalars['String']['output'];
  invoices?: Maybe<Array<Maybe<BillingInvoice>>>;
  mpSubscription?: Maybe<BillingSubscription>;
  name: Scalars['String']['output'];
  shippingAddress: Scalars['String']['output'];
  shippingAddress2: Scalars['String']['output'];
  shippingCity: Scalars['String']['output'];
  shippingCountry: Scalars['String']['output'];
  shippingName: Scalars['String']['output'];
  shippingZipCode: Scalars['String']['output'];
  stripeId?: Maybe<Scalars['String']['output']>;
  vatNumber: Scalars['String']['output'];
  whSubscription?: Maybe<BillingSubscription>;
  zipCode: Scalars['String']['output'];
};

export type BillingInvoice = {
  __typename: 'BillingInvoice';
  amountDue?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  invoicePdf?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Boolean']['output']>;
  paymentDue?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type BillingRunDef = Node & {
  __typename: 'BillingRunDef';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  billingCutoffDateInclusive: Scalars['Date']['output'];
  canExport: Scalars['Boolean']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  error: Scalars['String']['output'];
  exports: BillingRunExportDefConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  invoiceSet: InvoiceDefConnection;
  latestExport?: Maybe<BillingRunExportDef>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  numberOfInvoiceItems?: Maybe<Scalars['Int']['output']>;
  numberOfPropertiesBilled?: Maybe<Scalars['Int']['output']>;
  propertiesToBill: PropertyDefConnection;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: CrmBillingRunStatusChoices;
  totalAmount: Scalars['Decimal']['output'];
};


export type BillingRunDefExportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type BillingRunDefInvoiceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type BillingRunDefPropertiesToBillArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type BillingRunDefConnection = {
  __typename: 'BillingRunDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillingRunDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `BillingRunDef` and its cursor. */
export type BillingRunDefEdge = {
  __typename: 'BillingRunDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BillingRunDef>;
};

export type BillingRunExportDef = Node & {
  __typename: 'BillingRunExportDef';
  billingRun: BillingRunDef;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  error: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: CrmBillingRunExportStatusChoices;
};

export type BillingRunExportDefConnection = {
  __typename: 'BillingRunExportDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillingRunExportDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `BillingRunExportDef` and its cursor. */
export type BillingRunExportDefEdge = {
  __typename: 'BillingRunExportDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BillingRunExportDef>;
};

export type BillingSubscription = {
  __typename: 'BillingSubscription';
  currency?: Maybe<Scalars['String']['output']>;
  currentPeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  currentPeriodStart?: Maybe<Scalars['DateTime']['output']>;
  features?: Maybe<Array<Maybe<BillingSubscriptionFeature>>>;
  recurringInterval?: Maybe<Scalars['String']['output']>;
  recurringIntervalCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type BillingSubscriptionFeature = {
  __typename: 'BillingSubscriptionFeature';
  currency?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  maxQuantity?: Maybe<Scalars['Int']['output']>;
  minQuantity?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<BillingFeatureProductPriceType>;
  quantity?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type BillingSubscriptionQuote = {
  __typename: 'BillingSubscriptionQuote';
  currentPeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  currentPeriodStart?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lineItems?: Maybe<Array<Maybe<BillingSubscriptionQuoteFeature>>>;
  recurringInterval?: Maybe<Scalars['String']['output']>;
  recurringIntervalCount?: Maybe<Scalars['Int']['output']>;
  recurringSubtotal?: Maybe<Scalars['Int']['output']>;
  recurringTotal?: Maybe<Scalars['Int']['output']>;
  upfrontLineItems?: Maybe<Array<Maybe<BillingSubscriptionQuoteFeature>>>;
  upfrontSubtotal?: Maybe<Scalars['Int']['output']>;
  upfrontTotal?: Maybe<Scalars['Int']['output']>;
};

export type BillingSubscriptionQuoteFeature = {
  __typename: 'BillingSubscriptionQuoteFeature';
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  featurePrice?: Maybe<BillingFeatureProductPriceType>;
  quantity?: Maybe<Scalars['Int']['output']>;
  subtotal?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type BillingSubscriptionQuoteFeatureInput = {
  featureProductPriceId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export enum BorderRadiusEnum {
  LARGE = 'large',
  MEDIUM = 'medium',
  NONE = 'none',
  SMALL = 'small'
}

export type BulkActionProgressDef = Node & {
  __typename: 'BulkActionProgressDef';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  note: Scalars['String']['output'];
  progress: Scalars['Int']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<BulkActionProgressStatus>;
  total: Scalars['Int']['output'];
  user: UserType;
};

export type BulkActionProgressDefConnection = {
  __typename: 'BulkActionProgressDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BulkActionProgressDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `BulkActionProgressDef` and its cursor. */
export type BulkActionProgressDefEdge = {
  __typename: 'BulkActionProgressDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BulkActionProgressDef>;
};

/** An enumeration. */
export enum BulkActionProgressStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW'
}

export type BulkAgreementUpdateInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  excludeFromBilling?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
};

export type BulkCreateBulkWastePickupTicket = {
  __typename: 'BulkCreateBulkWastePickupTicket';
  results?: Maybe<Array<CreateBulkWastePickupTicketPayload>>;
};

export type BulkCreateManageContainerTicket = {
  __typename: 'BulkCreateManageContainerTicket';
  results?: Maybe<Array<CreateManageContainerTicketPayload>>;
};

export type BulkCreateTicket = {
  __typename: 'BulkCreateTicket';
  results?: Maybe<Array<CreateTicketPayload>>;
};

export type BulkSetTicketRoute = {
  __typename: 'BulkSetTicketRoute';
  tickets?: Maybe<Array<Maybe<TicketDef>>>;
};

export type BulkSetTicketRouteInput = {
  routeId: Scalars['ID']['input'];
  ticketId: Scalars['ID']['input'];
};

/**
 * This mutation is preferred over UpdateAgreement when updating multiple agreements
 * at once to update common fields for all agreements (recurring and non-recurring).
 * This mutation will update the container status if needed.
 */
export type BulkUpdateAgreement = {
  __typename: 'BulkUpdateAgreement';
  agreements?: Maybe<Array<Maybe<AgreementDef>>>;
};

export type BulkUpdateCompanyEmailAccountMessage = {
  __typename: 'BulkUpdateCompanyEmailAccountMessage';
  companyEmailAccountMessages: Array<CompanyEmailAccountMessageDef>;
};

export type BulkUpdateCompanyEmailAccountMessageInput = {
  assignedTeam?: InputMaybe<Scalars['ID']['input']>;
  assignee?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<CompanyEmailAccountMessageStatus>;
};

export type BulkUpdateContainer = {
  __typename: 'BulkUpdateContainer';
  containers?: Maybe<Array<Maybe<ContainerType>>>;
};

export type BulkUpdateContainerCollectionCalendarInput = {
  collectionCalendarId: Scalars['ID']['input'];
  containerId: Scalars['ID']['input'];
};

export type BulkUpdateContainerInput = {
  updateCollectionCalendar?: InputMaybe<Array<BulkUpdateContainerCollectionCalendarInput>>;
  updateRouteScheme?: InputMaybe<Array<BulkUpdateContainerRouteSchemeInput>>;
};

export type BulkUpdateContainerRouteSchemeInput = {
  containerId: Scalars['ID']['input'];
  routeSchemeId: Scalars['ID']['input'];
};

export type BulkUpdateTicket = {
  __typename: 'BulkUpdateTicket';
  createTicketComment: Array<CreateTicketCommentPayload>;
  createTicketMessage: Array<CreateTicketMessagePayload>;
  tickets: Array<TicketDef>;
  updateAssignee: Array<TicketDef>;
  updateBulkWastePickupTicket: Array<UpdateBulkWastePickupTicketPayload>;
  updateManageContainerTicket: Array<UpdateManageContainerTicketPayload>;
  updatePriority: Array<TicketDef>;
  updateStatus: Array<TicketDef>;
  updateTicket: Array<UpdateTicketPayload>;
};

export type BulkUpdateTicketInput = {
  createTicketComment?: InputMaybe<Array<CreateTicketCommentInput>>;
  createTicketMessage?: InputMaybe<Array<CreateTicketMessageInput>>;
  updateAssignee?: InputMaybe<Array<UpdateTicketAssigneeInput>>;
  updateBulkWastePickupTicket?: InputMaybe<Array<UpdateBulkWastePickupTicketInput>>;
  updateManageContainerTicket?: InputMaybe<Array<UpdateManageContainerTicketInput>>;
  updatePriority?: InputMaybe<Array<UpdateTicketPriorityInput>>;
  updateStatus?: InputMaybe<Array<UpdateTicketStatusInput>>;
  updateTicket?: InputMaybe<Array<UpdateTicketInput>>;
};

export type BulkWastePickupTicketDef = Node & {
  __typename: 'BulkWastePickupTicketDef';
  assignedTeam?: Maybe<TeamType>;
  assignee?: Maybe<UserType>;
  company: CompanyType;
  container?: Maybe<ContainerType>;
  containerProduct?: Maybe<ProductDef>;
  createdAt: Scalars['DateTime']['output'];
  customFields: Scalars['JSONString']['output'];
  deadline?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  ignoreServiceFee: Scalars['Boolean']['output'];
  items: BulkWastePickupTicketItemDefConnection;
  lastView?: Maybe<TicketViewEventDef>;
  location?: Maybe<LocationWithContainerType>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  possibleContainerId: Scalars['String']['output'];
  preferredDate?: Maybe<Scalars['Date']['output']>;
  priority: TicketTicketPriorityChoices;
  property?: Maybe<PropertyDef>;
  reporter?: Maybe<UserType>;
  reporterDriver?: Maybe<DriverType>;
  requestedBy?: Maybe<PropertyUserDef>;
  requestedByOther?: Maybe<Scalars['String']['output']>;
  rfid: Scalars['String']['output'];
  serviceFeeQuantity: Scalars['Int']['output'];
  status: TicketTicketStatusChoices;
  /** The user who changed the status */
  statusChangedBy?: Maybe<UserType>;
  /** The reason for the status change */
  statusChangedReason?: Maybe<TicketTicketStatusChangedReasonChoices>;
  /** The platform where the status was changed */
  statusChangedVia?: Maybe<TicketTicketStatusChangedViaChoices>;
  statusCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  statusInProgressAt?: Maybe<Scalars['DateTime']['output']>;
  statusOnHoldAt?: Maybe<Scalars['DateTime']['output']>;
  statusOpenAt?: Maybe<Scalars['DateTime']['output']>;
  statusPendingAt?: Maybe<Scalars['DateTime']['output']>;
  statusRejectedAt?: Maybe<Scalars['DateTime']['output']>;
  ticketPtr: TicketDef;
  ticketType: TicketTypeDef;
  /** in minutes */
  timeSpent?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  viaPortal: Scalars['Boolean']['output'];
  wasteVolumeEstimate?: Maybe<Scalars['Float']['output']>;
  wasteWeightEstimate?: Maybe<Scalars['Float']['output']>;
};


export type BulkWastePickupTicketDefItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type BulkWastePickupTicketItemDef = Node & {
  __typename: 'BulkWastePickupTicketItemDef';
  estimatedVolume?: Maybe<Scalars['Int']['output']>;
  estimatedWeight?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  ticket: BulkWastePickupTicketDef;
  wasteFraction: WasteFractionType;
};

export type BulkWastePickupTicketItemDefConnection = {
  __typename: 'BulkWastePickupTicketItemDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BulkWastePickupTicketItemDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `BulkWastePickupTicketItemDef` and its cursor. */
export type BulkWastePickupTicketItemDefEdge = {
  __typename: 'BulkWastePickupTicketItemDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BulkWastePickupTicketItemDef>;
};

export type BulkWastePickupTicketItemInput = {
  estimatedVolume?: InputMaybe<Scalars['Int']['input']>;
  estimatedWeight?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  wasteFraction: Scalars['ID']['input'];
};

export type BusinessAnalyticsType = {
  __typename: 'BusinessAnalyticsType';
  co2Reduction?: Maybe<ComparisonType>;
  comparisonCostOverview?: Maybe<Array<Maybe<CostOverviewType>>>;
  comparisonDistance?: Maybe<ComparisonType>;
  comparisonSaving?: Maybe<ComparisonType>;
  costOverview?: Maybe<Array<Maybe<CostOverviewType>>>;
  costPerEmptyingAvg?: Maybe<Scalars['Int']['output']>;
  decreasedCollections?: Maybe<Scalars['Float']['output']>;
  decreasedOverflowingBins?: Maybe<Scalars['Float']['output']>;
  estimatedSavings?: Maybe<Scalars['Float']['output']>;
  fillLevelEfficiency?: Maybe<ComparisonType>;
  reduceOverflows?: Maybe<ComparisonType>;
  reducedCollections?: Maybe<ComparisonType>;
  stats?: Maybe<StatsType>;
};


export type BusinessAnalyticsTypeComparisonCostOverviewArgs = {
  comparisonFrom: Scalars['DateTime']['input'];
  comparisonTo: Scalars['DateTime']['input'];
  periodType: Scalars['String']['input'];
};


export type BusinessAnalyticsTypeCostOverviewArgs = {
  From: Scalars['DateTime']['input'];
  To: Scalars['DateTime']['input'];
};


export type BusinessAnalyticsTypeEstimatedSavingsArgs = {
  From: Scalars['DateTime']['input'];
  To: Scalars['DateTime']['input'];
  comparisonFrom: Scalars['DateTime']['input'];
  comparisonTo: Scalars['DateTime']['input'];
};


export type BusinessAnalyticsTypeFillLevelEfficiencyArgs = {
  From: Scalars['DateTime']['input'];
  To: Scalars['DateTime']['input'];
  comparisonFrom: Scalars['DateTime']['input'];
  comparisonTo: Scalars['DateTime']['input'];
  periodType: Scalars['String']['input'];
};


export type BusinessAnalyticsTypeStatsArgs = {
  From: Scalars['DateTime']['input'];
  To: Scalars['DateTime']['input'];
};

/** An enumeration. */
export enum CardConfigOptions {
  CONTAINER_LEVEL_STOP = 'container_level_stop',
  DEPOT_STOP = 'depot_stop',
  EMPTYING_STOP = 'emptying_stop',
  PROPERTY_LEVEL_STOP = 'property_level_stop',
  WASTE_STATION_STOP = 'waste_station_stop'
}

export type CardPaymentMethodDef = Node & {
  __typename: 'CardPaymentMethodDef';
  brand: Scalars['String']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  last4: Scalars['String']['output'];
  paymentmethodPtr: PaymentMethodDef;
};

export type ChangePriorityActionDef = Node & {
  __typename: 'ChangePriorityActionDef';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  priority: TicketChangePriorityActionPriorityChoices;
  rule: TicketRuleDef;
};

export type ChangePriorityActionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  priority: Scalars['String']['input'];
};

export type ChangeStatusActionDef = Node & {
  __typename: 'ChangeStatusActionDef';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  rule: TicketRuleDef;
  status: TicketChangeStatusActionStatusChoices;
};

export type ChangeStatusActionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
};

export type CheckDeviceId = {
  __typename: 'CheckDeviceId';
  status?: Maybe<Scalars['String']['output']>;
};

export type CheckDriverEditableType = {
  __typename: 'CheckDriverEditableType';
  canEdit?: Maybe<Scalars['Boolean']['output']>;
};

export type ClusteredContainerMapDef = {
  __typename: 'ClusteredContainerMapDef';
  clusters?: Maybe<Array<ContainerClusterDef>>;
  collectionCalendars?: Maybe<Array<CollectionCalendarDef>>;
  points?: Maybe<Array<ContainerMapPointDef>>;
  routeSchemes?: Maybe<Array<RouteSchemeType>>;
};

export type ClusteredPropertyMapDef = {
  __typename: 'ClusteredPropertyMapDef';
  clusters?: Maybe<Array<PropertyClusterDef>>;
  points?: Maybe<Array<PropertyPointMapDef>>;
  propertyTypes?: Maybe<Array<PropertyTypeDef>>;
};

export type ClusteredTicketMapDef = {
  __typename: 'ClusteredTicketMapDef';
  clusters?: Maybe<Array<TicketClusterDef>>;
  points?: Maybe<Array<TicketMapPointDef>>;
  statuses?: Maybe<Array<TicketStatusDef>>;
  ticketTypes?: Maybe<Array<TicketTypeDef>>;
};

export type CollectionCalendarDateDef = Node & {
  __typename: 'CollectionCalendarDateDef';
  collectionCalendar: CollectionCalendarDef;
  collectionDate: Scalars['Date']['output'];
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CollectionCalendarDateDefConnection = {
  __typename: 'CollectionCalendarDateDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionCalendarDateDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CollectionCalendarDateDef` and its cursor. */
export type CollectionCalendarDateDefEdge = {
  __typename: 'CollectionCalendarDateDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CollectionCalendarDateDef>;
};

export type CollectionCalendarDateEventDef = Node & {
  __typename: 'CollectionCalendarDateEventDef';
  collectionCalendarDate?: Maybe<CollectionCalendarDateDef>;
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<CollectionCalendarDateEventTypesGraphene>>>;
  fromCollectionCalendar?: Maybe<CollectionCalendarDef>;
  fromCollectionDate?: Maybe<Scalars['Date']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toCollectionCalendar?: Maybe<CollectionCalendarDef>;
  toCollectionDate?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<UserType>;
};

export enum CollectionCalendarDateEventTypesGraphene {
  COLLECTION_CALENDAR_CHANGE = 'COLLECTION_CALENDAR_CHANGE',
  COLLECTION_DATE_CHANGE = 'COLLECTION_DATE_CHANGE',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  SOFT_DELETED = 'SOFT_DELETED'
}

export type CollectionCalendarDef = Node & {
  __typename: 'CollectionCalendarDef';
  /** It determines whether the collection calendar can only function as part of the combined collection calendars and not as a standalone collection calendar. */
  allowUsageInCombinedCalendarsOnly: Scalars['Boolean']['output'];
  collectioncalendardateSet: CollectionCalendarDateDefConnection;
  collectioncalendardeviationSet: CollectionCalendarDeviationDefConnection;
  combinedCollectionCalendars?: Maybe<Array<Maybe<CollectionCalendarPeriodDef>>>;
  containerSet: ContainerTypeConnection;
  containersCount?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  determinePeriodBy: RoutesCollectionCalendarDeterminePeriodByChoices;
  fromDate: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isCombined?: Maybe<Scalars['Boolean']['output']>;
  latestCollectionCalendarDate?: Maybe<Scalars['Date']['output']>;
  managecontainerticketSet: ManageContainerTicketDefConnection;
  name: Scalars['String']['output'];
  pickupSettings: PickupSettingsType;
  routeschemeSet: RouteSchemeTypeConnection;
  week: CollectionCalendarWeekDef;
  /** Weekdays when the pickup is happening, applicable only if the calendar is not combined. */
  weekdays: Array<Scalars['String']['output']>;
};


export type CollectionCalendarDefCollectioncalendardateSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectionCalendarDefCollectioncalendardeviationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectionCalendarDefContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectionCalendarDefManagecontainerticketSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectionCalendarDefRouteschemeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionCalendarDefConnection = {
  __typename: 'CollectionCalendarDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionCalendarDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CollectionCalendarDef` and its cursor. */
export type CollectionCalendarDefEdge = {
  __typename: 'CollectionCalendarDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CollectionCalendarDef>;
};

export type CollectionCalendarDeviationDef = Node & {
  __typename: 'CollectionCalendarDeviationDef';
  collectionCalendars: CollectionCalendarDefConnection;
  collectioncalendardeviationtoperiodSet: CollectionCalendarDeviationToPeriodDefConnection;
  company: CompanyType;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  periods?: Maybe<Array<Maybe<CollectionCalendarDeviationToPeriodDef>>>;
  startDate: Scalars['Date']['output'];
};


export type CollectionCalendarDeviationDefCollectionCalendarsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectionCalendarDeviationDefCollectioncalendardeviationtoperiodSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionCalendarDeviationDefConnection = {
  __typename: 'CollectionCalendarDeviationDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionCalendarDeviationDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CollectionCalendarDeviationDef` and its cursor. */
export type CollectionCalendarDeviationDefEdge = {
  __typename: 'CollectionCalendarDeviationDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CollectionCalendarDeviationDef>;
};

export type CollectionCalendarDeviationToPeriodDef = Node & {
  __typename: 'CollectionCalendarDeviationToPeriodDef';
  collectionCalendarDeviation: CollectionCalendarDeviationDef;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  routeSchemes: RouteSchemeTypeConnection;
  toEndDate: Scalars['Date']['output'];
  toStartDate: Scalars['Date']['output'];
};


export type CollectionCalendarDeviationToPeriodDefRouteSchemesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionCalendarDeviationToPeriodDefConnection = {
  __typename: 'CollectionCalendarDeviationToPeriodDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionCalendarDeviationToPeriodDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CollectionCalendarDeviationToPeriodDef` and its cursor. */
export type CollectionCalendarDeviationToPeriodDefEdge = {
  __typename: 'CollectionCalendarDeviationToPeriodDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CollectionCalendarDeviationToPeriodDef>;
};

export type CollectionCalendarDeviationToPeriodInput = {
  routeSchemes: Array<InputMaybe<Scalars['ID']['input']>>;
  toEndDate: Scalars['Date']['input'];
  toStartDate: Scalars['Date']['input'];
};

export type CollectionCalendarEventDef = Node & {
  __typename: 'CollectionCalendarEventDef';
  collectionCalendar?: Maybe<CollectionCalendarDef>;
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<CollectionCalendarEventTypesGraphene>>>;
  fromAllowUsageInCombinedCalendarsOnly?: Maybe<Scalars['Boolean']['output']>;
  fromDeterminePeriodBy?: Maybe<Scalars['String']['output']>;
  fromFromDate?: Maybe<Scalars['Date']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  fromPickupsSetting?: Maybe<PickupSettingsType>;
  fromWeek?: Maybe<CollectionCalendarWeekDef>;
  fromWeekdays?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toAllowUsageInCombinedCalendarsOnly?: Maybe<Scalars['Boolean']['output']>;
  toDeterminePeriodBy?: Maybe<Scalars['String']['output']>;
  toFromDate?: Maybe<Scalars['Date']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  toPickupsSetting?: Maybe<PickupSettingsType>;
  toWeek?: Maybe<CollectionCalendarWeekDef>;
  toWeekdays?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  user?: Maybe<UserType>;
};

export enum CollectionCalendarEventTypesGraphene {
  ALLOW_USAGE_IN_COMBINED_CALENDARS_ONLY_CHANGE = 'ALLOW_USAGE_IN_COMBINED_CALENDARS_ONLY_CHANGE',
  DETERMINE_PERIOD_BY_CHANGE = 'DETERMINE_PERIOD_BY_CHANGE',
  FROM_DATE_CHANGE = 'FROM_DATE_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE',
  WEEKDAYS_CHANGE = 'WEEKDAYS_CHANGE',
  WEEK_CHANGE = 'WEEK_CHANGE'
}

export type CollectionCalendarPeriodDef = Node & {
  __typename: 'CollectionCalendarPeriodDef';
  /** Calendar that is used as a base for this combined calendar. */
  basicCalendar: CollectionCalendarDef;
  /** Regular calendar that is combined into this calendar. */
  combinedCalendar: CollectionCalendarDef;
  endDate?: Maybe<Scalars['Date']['output']>;
  endWeekNumber?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
  startWeekNumber?: Maybe<Scalars['Int']['output']>;
};

export type CollectionCalendarPeriodInput = {
  combinedCalendar?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  endWeekNumber?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  startWeekNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionCalendarWeekDef = Node & {
  __typename: 'CollectionCalendarWeekDef';
  collectioncalendarSet: CollectionCalendarDefConnection;
  company: CompanyType;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startWeekNumber?: Maybe<Scalars['Int']['output']>;
};


export type CollectionCalendarWeekDefCollectioncalendarSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionCalendarWeekDefConnection = {
  __typename: 'CollectionCalendarWeekDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionCalendarWeekDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CollectionCalendarWeekDef` and its cursor. */
export type CollectionCalendarWeekDefEdge = {
  __typename: 'CollectionCalendarWeekDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CollectionCalendarWeekDef>;
};

export type CollectionInquiryType = Node & {
  __typename: 'CollectionInquiryType';
  collectionMethod?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<CommentType>;
  contactAddress?: Maybe<LocationWithContainerType>;
  container?: Maybe<ContainerType>;
  createdAt: Scalars['DateTime']['output'];
  deadline?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<Maybe<FileObjectType>>>;
  fromPickUpOrders: PickUpOrderTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  inquiryId: Scalars['UUID']['output'];
  inquiryTyping?: Maybe<CollectionInquiryTypingType>;
  issueType: RoutesCollectionInquiryIssueTypeChoices;
  location?: Maybe<LocationWithContainerType>;
  locationNotes?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  project?: Maybe<ProjectType>;
  reporter?: Maybe<UserType>;
  status?: Maybe<Scalars['String']['output']>;
  /** in minutes */
  timeSpent?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  voiceRecordFile: Scalars['String']['output'];
  wasteFraction?: Maybe<WasteFractionType>;
  /** Approximate of waste size, m³ */
  wasteVolume?: Maybe<Scalars['Float']['output']>;
  wasteWeightEstimate?: Maybe<Scalars['Float']['output']>;
};


export type CollectionInquiryTypeFromPickUpOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionInquiryTypeConnection = {
  __typename: 'CollectionInquiryTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionInquiryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CollectionInquiryType` and its cursor. */
export type CollectionInquiryTypeEdge = {
  __typename: 'CollectionInquiryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CollectionInquiryType>;
};

export type CollectionInquiryTypingType = Node & {
  __typename: 'CollectionInquiryTypingType';
  addTimeSpent: Scalars['Boolean']['output'];
  automaticallyAddToRoutes: Scalars['Boolean']['output'];
  collectioninquirySet: CollectionInquiryTypeConnection;
  defaultForUnhandledContainers: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  project: ProjectType;
  status: RoutesCollectionInquiryTypingStatusChoices;
};


export type CollectionInquiryTypingTypeCollectioninquirySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionInquiryTypingTypeConnection = {
  __typename: 'CollectionInquiryTypingTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionInquiryTypingTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CollectionInquiryTypingType` and its cursor. */
export type CollectionInquiryTypingTypeEdge = {
  __typename: 'CollectionInquiryTypingTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CollectionInquiryTypingType>;
};

export type CommentType = Node & {
  __typename: 'CommentType';
  author?: Maybe<UserType>;
  comments?: Maybe<CollectionInquiryType>;
  /** Relation to the Comment */
  containers: ContainerTypeConnection;
  created: Scalars['DateTime']['output'];
  /** Relation to the Comment */
  depots: DepotTypeConnection;
  /** Relation to the Comment */
  drivers: DriverTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  route?: Maybe<RouteType>;
  terms?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  /** Relation to the Comment */
  vehicles: VehicleTypeConnection;
};


export type CommentTypeContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CommentTypeDepotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CommentTypeDriversArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CommentTypeVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CommentTypeConnection = {
  __typename: 'CommentTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CommentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CommentType` and its cursor. */
export type CommentTypeEdge = {
  __typename: 'CommentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CommentType>;
};

export type CommonUserNotificationUnionDef = UserNotificationType;

export type CommonUserNotificationsWrapper = {
  __typename: 'CommonUserNotificationsWrapper';
  edges?: Maybe<Array<Maybe<CommonUserNotificationUnionDef>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CompanyContainerMetaTypeType = Node & {
  __typename: 'CompanyContainerMetaTypeType';
  company: CompanyType;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type CompanyContainerMetaTypeTypeConnection = {
  __typename: 'CompanyContainerMetaTypeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyContainerMetaTypeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CompanyContainerMetaTypeType` and its cursor. */
export type CompanyContainerMetaTypeTypeEdge = {
  __typename: 'CompanyContainerMetaTypeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyContainerMetaTypeType>;
};

export type CompanyEmailAccountDef = Node & {
  __typename: 'CompanyEmailAccountDef';
  accessToken?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  attachmentsThreshold: Scalars['Int']['output'];
  authUrl?: Maybe<Scalars['String']['output']>;
  billingState: CoreCompanyEmailAccountBillingStateChoices;
  company: CompanyType;
  companyemailaccountmessageSet: CompanyEmailAccountMessageDefConnection;
  companyemailaccountruleSet: CompanyEmailAccountRuleDefConnection;
  companysettingsSet: CompanySettingsTypeConnection;
  email: Scalars['String']['output'];
  grantDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Grant represents the concept of 'connected accounts' in Nylas v3 */
  grantId?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  needsReauth?: Maybe<Scalars['Boolean']['output']>;
  /** Account ID from Nylas v2 */
  providerId?: Maybe<Scalars['String']['output']>;
  sendState: CoreCompanyEmailAccountSendStateChoices;
  syncState: CoreCompanyEmailAccountSyncStateChoices;
};


export type CompanyEmailAccountDefCompanyemailaccountmessageSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyEmailAccountDefCompanyemailaccountruleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyEmailAccountDefCompanysettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyEmailAccountDefConnection = {
  __typename: 'CompanyEmailAccountDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEmailAccountDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CompanyEmailAccountDef` and its cursor. */
export type CompanyEmailAccountDefEdge = {
  __typename: 'CompanyEmailAccountDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyEmailAccountDef>;
};

export type CompanyEmailAccountMessageAttachmentDef = Node & {
  __typename: 'CompanyEmailAccountMessageAttachmentDef';
  companyEmailAccountMessage: CompanyEmailAccountMessageDef;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  file: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
};

export type CompanyEmailAccountMessageAttachmentDefConnection = {
  __typename: 'CompanyEmailAccountMessageAttachmentDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEmailAccountMessageAttachmentDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CompanyEmailAccountMessageAttachmentDef` and its cursor. */
export type CompanyEmailAccountMessageAttachmentDefEdge = {
  __typename: 'CompanyEmailAccountMessageAttachmentDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyEmailAccountMessageAttachmentDef>;
};

export type CompanyEmailAccountMessageChangedSubscription = {
  __typename: 'CompanyEmailAccountMessageChangedSubscription';
  companyEmailAccountMessage?: Maybe<CompanyEmailAccountMessageDef>;
};

export type CompanyEmailAccountMessageDef = Node & {
  __typename: 'CompanyEmailAccountMessageDef';
  assignedTeam?: Maybe<TeamType>;
  assignee?: Maybe<UserType>;
  /** Tickets associated with the message */
  associatedTickets: TicketDefConnection;
  associatedTicketsCount?: Maybe<Scalars['Int']['output']>;
  attachments?: Maybe<Array<Maybe<CompanyEmailAccountMessageAttachmentDef>>>;
  body: Scalars['String']['output'];
  companyEmailAccount: CompanyEmailAccountDef;
  companyemailaccountmessageeventSet: CompanyEmailAccountMessageEventDefConnection;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  errorAt?: Maybe<Scalars['DateTime']['output']>;
  eventLog?: Maybe<CompanyEmailAccountMessageEventWrapper>;
  fromEmail: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isInbound?: Maybe<Scalars['Boolean']['output']>;
  isThreadStarter?: Maybe<Scalars['Boolean']['output']>;
  latestNotificationCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  notificationsLog?: Maybe<CommonUserNotificationsWrapper>;
  providerId?: Maybe<Scalars['String']['output']>;
  sendAt?: Maybe<Scalars['DateTime']['output']>;
  snippet: Scalars['String']['output'];
  status?: Maybe<CompanyEmailAccountMessageStatus>;
  subject?: Maybe<Scalars['String']['output']>;
  threadId?: Maybe<Scalars['String']['output']>;
  threadMessages: CompanyEmailAccountMessageDefConnection;
  threadStarter?: Maybe<CompanyEmailAccountMessageDef>;
  ticket?: Maybe<TicketDef>;
  ticketMessage?: Maybe<TicketMessageDef>;
  ticketdraftSet: TicketDraftDefConnection;
  toEmail?: Maybe<Scalars['String']['output']>;
  toEmailName?: Maybe<Scalars['String']['output']>;
  userNotifications: UserNotificationTypeConnection;
};


export type CompanyEmailAccountMessageDefAssociatedTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyEmailAccountMessageDefCompanyemailaccountmessageeventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyEmailAccountMessageDefEventLogArgs = {
  addCompanyAccountEmailMessageEvents?: InputMaybe<Scalars['Boolean']['input']>;
  companyAccountEmailMessageEventTypes?: InputMaybe<Array<InputMaybe<CompanyEmailAccountMessageEventType>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  isMessageInbound?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type CompanyEmailAccountMessageDefNotificationsLogArgs = {
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  directOnly?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
  unseen?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyEmailAccountMessageDefThreadMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyEmailAccountMessageDefTicketdraftSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyEmailAccountMessageDefUserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyEmailAccountMessageDefConnection = {
  __typename: 'CompanyEmailAccountMessageDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEmailAccountMessageDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CompanyEmailAccountMessageDef` and its cursor. */
export type CompanyEmailAccountMessageDefEdge = {
  __typename: 'CompanyEmailAccountMessageDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyEmailAccountMessageDef>;
};

export type CompanyEmailAccountMessageEventDef = Node & {
  __typename: 'CompanyEmailAccountMessageEventDef';
  company: CompanyType;
  companyEmailAccountMessage: CompanyEmailAccountMessageDef;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  createdByAction: Scalars['Boolean']['output'];
  eventType?: Maybe<CompanyEmailAccountMessageEventType>;
  fromAssignedTeam?: Maybe<TeamType>;
  fromAssignee?: Maybe<UserType>;
  fromAssociatedTickets: TicketDefConnection;
  fromStatus?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  toAssignedTeam?: Maybe<TeamType>;
  toAssignee?: Maybe<UserType>;
  toAssociatedTickets: TicketDefConnection;
  toStatus?: Maybe<Scalars['String']['output']>;
};


export type CompanyEmailAccountMessageEventDefFromAssociatedTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyEmailAccountMessageEventDefToAssociatedTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyEmailAccountMessageEventDefConnection = {
  __typename: 'CompanyEmailAccountMessageEventDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEmailAccountMessageEventDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CompanyEmailAccountMessageEventDef` and its cursor. */
export type CompanyEmailAccountMessageEventDefEdge = {
  __typename: 'CompanyEmailAccountMessageEventDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyEmailAccountMessageEventDef>;
};

export type CompanyEmailAccountMessageEventLogEvent = CompanyEmailAccountMessageEventDef;

/** An enumeration. */
export enum CompanyEmailAccountMessageEventType {
  ASSIGNED_TEAM_CHANGED = 'ASSIGNED_TEAM_CHANGED',
  ASSIGNEE_CHANGED = 'ASSIGNEE_CHANGED',
  ASSOCIATED_TICKET_CREATED_AND_LINKED = 'ASSOCIATED_TICKET_CREATED_AND_LINKED',
  ASSOCIATED_TICKET_LINKED_EXISTING = 'ASSOCIATED_TICKET_LINKED_EXISTING',
  ASSOCIATED_TICKET_UNLINKED = 'ASSOCIATED_TICKET_UNLINKED',
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',
  MESSAGE_SENT = 'MESSAGE_SENT',
  STATUS_CHANGED = 'STATUS_CHANGED'
}

export type CompanyEmailAccountMessageEventWrapper = {
  __typename: 'CompanyEmailAccountMessageEventWrapper';
  edges?: Maybe<Array<Maybe<CompanyEmailAccountMessageEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum CompanyEmailAccountMessageStatus {
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  OPEN = 'OPEN',
  REJECTED = 'REJECTED'
}

export type CompanyEmailAccountRuleDef = Node & {
  __typename: 'CompanyEmailAccountRuleDef';
  companyEmailAccount: CompanyEmailAccountDef;
  createdAt: Scalars['DateTime']['output'];
  defaultAssignee?: Maybe<UserType>;
  defaultProject?: Maybe<ProjectType>;
  defaultTicketType?: Maybe<TicketTypeDef>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  emailFilter: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type CompanyEmailAccountRuleDefConnection = {
  __typename: 'CompanyEmailAccountRuleDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEmailAccountRuleDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CompanyEmailAccountRuleDef` and its cursor. */
export type CompanyEmailAccountRuleDefEdge = {
  __typename: 'CompanyEmailAccountRuleDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyEmailAccountRuleDef>;
};

export type CompanyEventDef = Node & {
  __typename: 'CompanyEventDef';
  company?: Maybe<CompanyType>;
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<CompanyEventTypesGraphene>>>;
  fromDashboardLocation?: Maybe<LocationType>;
  fromDescription?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toDashboardLocation?: Maybe<LocationType>;
  toDescription?: Maybe<Scalars['String']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};

export enum CompanyEventTypesGraphene {
  DASHBOARD_LOCATION_CHANGE = 'DASHBOARD_LOCATION_CHANGE',
  DESCRIPTION_CHANGE = 'DESCRIPTION_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE'
}

export type CompanyEventsEventLogWrapper = {
  __typename: 'CompanyEventsEventLogWrapper';
  edges?: Maybe<Array<Maybe<CompanyEventsWrapper>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CompanyEventsWrapper = CollectionCalendarDateEventDef | CollectionCalendarEventDef | CompanyEventDef | CompanyMetaTypeEventDef | CompanySettingsEventDef | ContainerTypeEventDef | PickupSettingsEventDef | PriceEventDef | ProductEventDef | TicketTypeEventDef | WasteFractionEventDef;

export type CompanyMetaTypeDef = Node & {
  __typename: 'CompanyMetaTypeDef';
  company: CompanyType;
  copyFrom?: Maybe<CoreCompanyMetaTypeCopyFromChoices>;
  createdAt: Scalars['DateTime']['output'];
  displayOn: Array<MetaTypeDisplayOnType>;
  entity: CoreCompanyMetaTypeEntityChoices;
  helpText: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  inputType: CoreCompanyMetaTypeInputTypeChoices;
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
  rolesCanEdit: CompanyRoleDefConnection;
  rolesCanView: CompanyRoleDefConnection;
  scheduledexportmetatypeSet: ScheduledExportMetaTypeDefConnection;
  userCanEdit?: Maybe<Scalars['Boolean']['output']>;
};


export type CompanyMetaTypeDefRolesCanEditArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyMetaTypeDefRolesCanViewArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyMetaTypeDefScheduledexportmetatypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyMetaTypeDefConnection = {
  __typename: 'CompanyMetaTypeDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyMetaTypeDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CompanyMetaTypeDef` and its cursor. */
export type CompanyMetaTypeDefEdge = {
  __typename: 'CompanyMetaTypeDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyMetaTypeDef>;
};

export type CompanyMetaTypeEventDef = Node & {
  __typename: 'CompanyMetaTypeEventDef';
  companyMetaType?: Maybe<CompanyMetaTypeDef>;
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<CompanyMetaTypeEventTypesGraphene>>>;
  fromCopyFrom?: Maybe<Scalars['Int']['output']>;
  fromDisplayOn?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fromHelpText?: Maybe<Scalars['String']['output']>;
  fromInputType?: Maybe<Scalars['String']['output']>;
  fromLabel?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  fromOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toCopyFrom?: Maybe<Scalars['Int']['output']>;
  toDisplayOn?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  toHelpText?: Maybe<Scalars['String']['output']>;
  toInputType?: Maybe<Scalars['String']['output']>;
  toLabel?: Maybe<Scalars['String']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  toOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  user?: Maybe<UserType>;
};

export enum CompanyMetaTypeEventTypesGraphene {
  COPY_FROM_CHANGE = 'COPY_FROM_CHANGE',
  DISPLAY_ON_CHANGE = 'DISPLAY_ON_CHANGE',
  HELP_TEXT_CHANGE = 'HELP_TEXT_CHANGE',
  INPUT_TYPE_CHANGE = 'INPUT_TYPE_CHANGE',
  LABEL_CHANGE = 'LABEL_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE',
  OPTIONS_CHANGE = 'OPTIONS_CHANGE'
}

export type CompanyRoleConfigDef = {
  __typename: 'CompanyRoleConfigDef';
  description?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<CompanyRolesConfigItemDef>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CompanyRoleDef = Node & {
  __typename: 'CompanyRoleDef';
  company: CompanyType;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  metaTypesCanEdit?: Maybe<Array<CompanyMetaTypeDef>>;
  metaTypesCanView?: Maybe<Array<CompanyMetaTypeDef>>;
  name: Scalars['String']['output'];
  permissions: Array<PermissionChoices>;
};

export type CompanyRoleDefConnection = {
  __typename: 'CompanyRoleDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyRoleDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CompanyRoleDef` and its cursor. */
export type CompanyRoleDefEdge = {
  __typename: 'CompanyRoleDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyRoleDef>;
};

export type CompanyRolePermissionDef = Node & {
  __typename: 'CompanyRolePermissionDef';
  company: CompanyType;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: CoreCompanyRolePermissionNameChoices;
};

export type CompanyRolePermissionDefConnection = {
  __typename: 'CompanyRolePermissionDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyRolePermissionDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CompanyRolePermissionDef` and its cursor. */
export type CompanyRolePermissionDefEdge = {
  __typename: 'CompanyRolePermissionDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyRolePermissionDef>;
};

export type CompanyRolesConfigItemDef = {
  __typename: 'CompanyRolesConfigItemDef';
  createPermission?: Maybe<Scalars['String']['output']>;
  deletePermission?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  editPermission?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  viewPermission?: Maybe<Scalars['String']['output']>;
};

export type CompanySettingsEventDef = Node & {
  __typename: 'CompanySettingsEventDef';
  companySettings?: Maybe<CompanySettingsType>;
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<CompanySettingsEventTypesGraphene>>>;
  fromCompanyName?: Maybe<Scalars['String']['output']>;
  fromContactsMaskIndividualTaxId?: Maybe<Scalars['Boolean']['output']>;
  fromContainerNotificationProviders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fromCountry?: Maybe<Scalars['String']['output']>;
  fromCustomTextHome?: Maybe<Scalars['String']['output']>;
  fromCustomTextHomeTitle?: Maybe<Scalars['String']['output']>;
  fromCustomTextLogin?: Maybe<Scalars['String']['output']>;
  fromCustomTextLoginTitle?: Maybe<Scalars['String']['output']>;
  fromDefaultLanguage?: Maybe<Scalars['String']['output']>;
  fromDkdpEnabled?: Maybe<Scalars['Boolean']['output']>;
  fromHostname?: Maybe<Scalars['String']['output']>;
  fromLoginMethod?: Maybe<Scalars['String']['output']>;
  fromMetabaseEnabled?: Maybe<Scalars['Boolean']['output']>;
  fromPdfFooter?: Maybe<Scalars['String']['output']>;
  fromPhoneNumber?: Maybe<Scalars['String']['output']>;
  fromSmsSender?: Maybe<Scalars['String']['output']>;
  fromSmsSenderSignature?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toCompanyName?: Maybe<Scalars['String']['output']>;
  toContactsMaskIndividualTaxId?: Maybe<Scalars['Boolean']['output']>;
  toContainerNotificationProviders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  toCountry?: Maybe<Scalars['String']['output']>;
  toCustomTextHome?: Maybe<Scalars['String']['output']>;
  toCustomTextHomeTitle?: Maybe<Scalars['String']['output']>;
  toCustomTextLogin?: Maybe<Scalars['String']['output']>;
  toCustomTextLoginTitle?: Maybe<Scalars['String']['output']>;
  toDefaultLanguage?: Maybe<Scalars['String']['output']>;
  toDkdpEnabled?: Maybe<Scalars['Boolean']['output']>;
  toHostname?: Maybe<Scalars['String']['output']>;
  toLoginMethod?: Maybe<Scalars['String']['output']>;
  toMetabaseEnabled?: Maybe<Scalars['Boolean']['output']>;
  toPdfFooter?: Maybe<Scalars['String']['output']>;
  toPhoneNumber?: Maybe<Scalars['String']['output']>;
  toSmsSender?: Maybe<Scalars['String']['output']>;
  toSmsSenderSignature?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};

export enum CompanySettingsEventTypesGraphene {
  COMPANY_NAME_CHANGE = 'COMPANY_NAME_CHANGE',
  CONTACTS_MASK_INDIVIDUAL_TAX_ID_CHANGE = 'CONTACTS_MASK_INDIVIDUAL_TAX_ID_CHANGE',
  CONTAINER_NOTIFICATION_PROVIDERS_CHANGE = 'CONTAINER_NOTIFICATION_PROVIDERS_CHANGE',
  COUNTRY_CHANGE = 'COUNTRY_CHANGE',
  CUSTOM_TEXT_HOME_CHANGE = 'CUSTOM_TEXT_HOME_CHANGE',
  CUSTOM_TEXT_LOGIN_CHANGE = 'CUSTOM_TEXT_LOGIN_CHANGE',
  DEFAULT_LANGUAGE_CHANGE = 'DEFAULT_LANGUAGE_CHANGE',
  DKDP_ENABLED_CHANGE = 'DKDP_ENABLED_CHANGE',
  HOSTNAME_CHANGE = 'HOSTNAME_CHANGE',
  LOGIN_METHOD_CHANGE = 'LOGIN_METHOD_CHANGE',
  METABASE_ENABLED_CHANGE = 'METABASE_ENABLED_CHANGE',
  PDF_FOOTER_CHANGE = 'PDF_FOOTER_CHANGE',
  PHONE_NUMBER_CHANGE = 'PHONE_NUMBER_CHANGE',
  SMS_SENDER_CHANGE = 'SMS_SENDER_CHANGE',
  SMS_SENDER_SIGNATURE_CHANGE = 'SMS_SENDER_SIGNATURE_CHANGE'
}

export type CompanySettingsType = Node & {
  __typename: 'CompanySettingsType';
  backgroundImageForHomePage: Scalars['String']['output'];
  backgroundImageForLoginPage: Scalars['String']['output'];
  barcodeTemplate: Scalars['String']['output'];
  billingExportSystem?: Maybe<CoreCompanySettingsBillingExportSystemChoices>;
  billingExportSystemParams: Scalars['JSONString']['output'];
  borderRadius?: Maybe<BorderRadiusEnum>;
  canExportDebtors: Scalars['Boolean']['output'];
  canExportInvoiceLines: Scalars['Boolean']['output'];
  color1: Scalars['String']['output'];
  color2: Scalars['String']['output'];
  color3: Scalars['String']['output'];
  color4: Scalars['String']['output'];
  color5: Scalars['String']['output'];
  colorClosestStop: Scalars['String']['output'];
  colorEmptiedStop: Scalars['String']['output'];
  colorEmptiedWithTicketStop: Scalars['String']['output'];
  colorNextStop: Scalars['String']['output'];
  colorNotEmptiedWithTicketStop: Scalars['String']['output'];
  colorPendingStop: Scalars['String']['output'];
  colorSkippedStop: Scalars['String']['output'];
  colorWillReturnStop: Scalars['String']['output'];
  company: CompanyType;
  companyLogo?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  crmCompanyName?: Maybe<Scalars['String']['output']>;
  crmContactsAssignUniqueId?: Maybe<CoreCompanySettingsCrmContactsAssignUniqueIdChoices>;
  crmContactsMaskIndividualTaxId: Scalars['Boolean']['output'];
  crmCountry?: Maybe<Scalars['String']['output']>;
  crmCustomTextHome?: Maybe<Scalars['String']['output']>;
  crmCustomTextHomeTitle?: Maybe<Scalars['String']['output']>;
  crmCustomTextLogin?: Maybe<Scalars['String']['output']>;
  crmCustomTextLoginTitle?: Maybe<Scalars['String']['output']>;
  crmDefaultLanguage?: Maybe<Scalars['String']['output']>;
  crmEmailSender?: Maybe<CompanyEmailAccountDef>;
  crmEnableContainerNotificationProviders: Array<Scalars['String']['output']>;
  crmHostname?: Maybe<Scalars['String']['output']>;
  crmLoginLogo?: Maybe<Scalars['String']['output']>;
  crmLoginMethod: CoreCompanySettingsCrmLoginMethodChoices;
  crmLoginMethodUser: CoreCompanySettingsCrmLoginMethodUserChoices;
  crmLoginMethodUserIsAdministrator: Scalars['Boolean']['output'];
  crmLoginMethodUserIsCoOwner: Scalars['Boolean']['output'];
  crmLoginMethodUserIsContact: Scalars['Boolean']['output'];
  crmLoginMethodUserIsOwner: Scalars['Boolean']['output'];
  crmLoginMethodUserIsPayer: Scalars['Boolean']['output'];
  crmLogo?: Maybe<Scalars['String']['output']>;
  crmMasterDataSyncRemovePayerOnOwnerOrAdminChanges: Scalars['Boolean']['output'];
  crmMasterDataSyncSyncEanNumbers: Scalars['Boolean']['output'];
  crmPdfFooter: Scalars['String']['output'];
  crmPhoneNumber?: Maybe<Scalars['String']['output']>;
  crmPrimaryColor?: Maybe<Scalars['String']['output']>;
  crmSecondaryColor?: Maybe<Scalars['String']['output']>;
  crmSmsSender?: Maybe<Scalars['String']['output']>;
  crmSmsSenderSignature?: Maybe<Scalars['String']['output']>;
  crmSyncPropertiesFromMasterData: Scalars['Boolean']['output'];
  crmSyncPropertyContactsFromMasterData: Scalars['Boolean']['output'];
  crmUseExternalContactIds: Scalars['Boolean']['output'];
  datetimeFormatCountry: Scalars['String']['output'];
  dkdpEnabled: Scalars['Boolean']['output'];
  dns: Scalars['String']['output'];
  fileTemplateAgreementExport: Scalars['String']['output'];
  fileTemplateCoreScheduledExport: Scalars['String']['output'];
  fileTemplateWasteheroRouteExport: Scalars['String']['output'];
  fontFamily: CoreCompanySettingsFontFamilyChoices;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isActiveDns: Scalars['Boolean']['output'];
  labelConfigDpi: Scalars['Int']['output'];
  labelConfigHeight: Scalars['Int']['output'];
  labelConfigHeightHtml: Scalars['Int']['output'];
  labelConfigMargin: Scalars['Int']['output'];
  labelConfigWidth: Scalars['Int']['output'];
  labelConfigWidthHtml: Scalars['Int']['output'];
  loginImages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  mainPortalFont: Scalars['String']['output'];
  mainPortalFontFamily: Scalars['String']['output'];
  mapCountry: Scalars['String']['output'];
  masterDataProvider?: Maybe<CoreCompanySettingsMasterDataProviderChoices>;
  metabaseEnabled: Scalars['Boolean']['output'];
  metabasePrivateCollectionId?: Maybe<Scalars['String']['output']>;
  metabaseUserId?: Maybe<Scalars['String']['output']>;
  portalColorSettings: Scalars['String']['output'];
  portalPageSettings?: Maybe<Array<Maybe<PortalPageDescriptionSettingType>>>;
  portalpagedescriptionsettingSet: PortalPageDescriptionSettingTypeConnection;
  /** Request rate limit per minute */
  requestRateLimit: Scalars['Int']['output'];
  secondaryPortalFont: Scalars['String']['output'];
  secondaryPortalFontFamily: Scalars['String']['output'];
  stopListContainerLevel?: Maybe<StopListCardConfigType>;
  stopListDepotStop?: Maybe<StopListCardConfigType>;
  stopListEmptyingStop?: Maybe<StopListCardConfigType>;
  stopListPropertyLevel?: Maybe<StopListCardConfigType>;
  stopListWasteStation?: Maybe<StopListCardConfigType>;
  stoplistcustomisationconfigSet: StopListCardConfigTypeConnection;
  supportedLanguages: Array<Scalars['String']['output']>;
  ticketTypeCategoriseBy?: Maybe<TicketTypeOrderCategoriseBy>;
  ticketTypeNavAppOrderType?: Maybe<OrderTypeChoices>;
  ticketTypePlatformOrderType?: Maybe<OrderTypeChoices>;
  ticketTypePortalOrderType?: Maybe<OrderTypeChoices>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CompanySettingsTypePortalpagedescriptionsettingSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanySettingsTypeStoplistcustomisationconfigSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanySettingsTypeConnection = {
  __typename: 'CompanySettingsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanySettingsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CompanySettingsType` and its cursor. */
export type CompanySettingsTypeEdge = {
  __typename: 'CompanySettingsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanySettingsType>;
};

export type CompanyType = Node & {
  __typename: 'CompanyType';
  activepriceviewSet: ActivePriceDefConnection;
  agreementSet: AgreementDefConnection;
  agreementexportSet: AgreementExportDefConnection;
  agreementtemplateSet: AgreementTemplateDefConnection;
  agreementtemplateproductSet: AgreementTemplateProductDefConnection;
  alertSet: AlertDefConnection;
  alertruleSet: AlertRuleDefConnection;
  areaSet: AreaDefConnection;
  billinginformation?: Maybe<BillingInformationType>;
  billingrunSet: BillingRunDefConnection;
  collectioncalendardeviationSet: CollectionCalendarDeviationDefConnection;
  collectioncalendarweekSet: CollectionCalendarWeekDefConnection;
  /** Hex color code */
  color?: Maybe<Scalars['String']['output']>;
  companySettings?: Maybe<CompanySettingsType>;
  companycontainermetatypeSet: CompanyContainerMetaTypeTypeConnection;
  companyemailaccountSet: CompanyEmailAccountDefConnection;
  companymetatypeSet: CompanyMetaTypeDefConnection;
  companyroleSet: CompanyRoleDefConnection;
  companyrolepermissionSet: CompanyRolePermissionDefConnection;
  contactSet: ContactDefConnection;
  containerIdGenerator?: Maybe<ContainerIdGeneratorSettingsDef>;
  containercustomeventSet: CustomContainerEventDefConnection;
  containernotificationSet: ContainerNotificationDefConnection;
  containerpriceSet: ContainerPriceDefConnection;
  createdAt: Scalars['DateTime']['output'];
  criiptoapplicationSet: CriiptoApplicationDefConnection;
  dashboardLocation: LocationWithContainerType;
  dashboardSet: DashboardDefConnection;
  debtorexportSet: DebtorExportDefConnection;
  /** Company of this Depot */
  depots: DepotTypeConnection;
  description?: Maybe<Scalars['String']['output']>;
  dkdpCreds?: Maybe<DkdpCompanyCredentialsDef>;
  eventLog?: Maybe<CompanyEventsEventLogWrapper>;
  externalCrmDealId?: Maybe<Scalars['String']['output']>;
  externalCrmId?: Maybe<Scalars['String']['output']>;
  haulerSet: HaulerDefConnection;
  haulerpriceSet: HaulerPriceDefConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  integrationgpsSet: IntegrationGpsTypeConnection;
  invoiceSet: InvoiceDefConnection;
  invoiceitemSet: InvoiceItemDefConnection;
  iouserSet: UserTypeConnection;
  isActive: Scalars['Boolean']['output'];
  isDemo: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  lorawandeviceSet: LorawanDeviceTypeConnection;
  managecontainerticketSet: ManageContainerTicketDefConnection;
  masterUsers?: Maybe<Array<Maybe<UserType>>>;
  masterdatasyncSet: MasterDataSyncDefConnection;
  metabaseJwt?: Maybe<Scalars['String']['output']>;
  mobiledeviceSet: MobileDeviceTypeConnection;
  name: Scalars['String']['output'];
  notificationmethodSet: NotificationMethodDefConnection;
  paymentmethodSet: PaymentMethodDefConnection;
  pickuporderapprovalSet: PickUpOrderApprovalDefConnection;
  pickuporderapprovalcollectionSet: PickUpOrderApprovalCollectionDefConnection;
  priceSet: PriceDefConnection;
  productSet: ProductDefConnection;
  projectSet?: Maybe<ProjectTypeConnection>;
  propertySet: PropertyDefConnection;
  propertycontactSet: PropertyContactDefConnection;
  propertygroupSet: PropertyGroupDefConnection;
  propertytypeSet: PropertyTypeDefConnection;
  propertyuserSet: PropertyUserDefConnection;
  propertyvieweventSet: PropertyViewEventDefConnection;
  recurringpriceSet: RecurringPriceDefConnection;
  salesofferSet: SalesOfferTypeConnection;
  scheduledexportSet: ScheduledExportDefConnection;
  scheduledexportrunSet: ScheduledExportRunDefConnection;
  serviceSet: ServiceDefConnection;
  servicepriceSet: ServicePriceDefConnection;
  settings?: Maybe<SettingsType>;
  sigfoxdeviceSet: SigFoxDeviceTypeConnection;
  /** Company, which skill related to */
  skillSet: SkillTypeConnection;
  teamSet: TeamTypeConnection;
  ticketSet: TicketDefConnection;
  ticketcategorySet: TicketCategoryDefConnection;
  ticketcommentSet: TicketCommentDefConnection;
  ticketcustomfieldSet: TicketCustomFieldDefConnection;
  ticketdraftSet: TicketDraftDefConnection;
  ticketeventSet: TicketEventDefConnection;
  ticketmessageSet: TicketMessageDefConnection;
  ticketmessagetemplateSet: TicketMessageTemplateDefConnection;
  tickettypeSet: TicketTypeDefConnection;
  ticketvieweventSet: TicketViewEventDefConnection;
  updatedAt: Scalars['DateTime']['output'];
  urbaserCreds?: Maybe<UrbaserCredentialsDef>;
  vehicletrailerSet: VehicleTrailerTypeConnection;
  vehicletrailertickettypeholdSet: VehicleTrailerTicketTypeHoldTypeConnection;
  vehicletrailerwasteholdSet: VehicleTrailerWasteHoldTypeConnection;
  vehicletypeSet: VehicleTypeTypeConnection;
};


export type CompanyTypeActivepriceviewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeAgreementSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeAgreementexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeAgreementtemplateSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeAgreementtemplateproductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeAlertSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeAlertruleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeAreaSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeBillingrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeCollectioncalendardeviationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeCollectioncalendarweekSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeCompanycontainermetatypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeCompanyemailaccountSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeCompanymetatypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeCompanyroleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeCompanyrolepermissionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeContactSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeContainercustomeventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeContainernotificationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeContainerpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeCriiptoapplicationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeDashboardSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeDebtorexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeDepotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeEventLogArgs = {
  addCollectionCalendarDateEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addCollectionCalendarEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addCompanyEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addCompanyMetaTypeEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addCompanySettingsEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addContainerTypeEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addPickupSettingsEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addPriceEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addProductEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addTicketTypeEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addWasteFractionEvents?: InputMaybe<Scalars['Boolean']['input']>;
  collectionCalendarEventTypes?: InputMaybe<Array<InputMaybe<CollectionCalendarEventTypesGraphene>>>;
  companyEventTypes?: InputMaybe<Array<InputMaybe<CompanyEventTypesGraphene>>>;
  companyMetaTypeEventTypes?: InputMaybe<Array<InputMaybe<CompanyMetaTypeEventTypesGraphene>>>;
  companySettingsEventTypes?: InputMaybe<Array<InputMaybe<CompanySettingsEventTypesGraphene>>>;
  containerTypeEventTypes?: InputMaybe<Array<InputMaybe<ContainerTypeEventTypesGraphene>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pickupSettingsEventTypes?: InputMaybe<Array<InputMaybe<PickupSettingsEventTypesGraphene>>>;
  priceEventTypes?: InputMaybe<Array<InputMaybe<PriceEventTypesGraphene>>>;
  productEventTypes?: InputMaybe<Array<InputMaybe<ProductEventTypesGraphene>>>;
  ticketTypeEventTypes?: InputMaybe<Array<InputMaybe<TicketTypeEventTypesShortGraphene>>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  wasteFractionEventTypes?: InputMaybe<Array<InputMaybe<WasteFractionEventTypesGraphene>>>;
};


export type CompanyTypeHaulerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeHaulerpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeIntegrationgpsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeInvoiceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeInvoiceitemSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeIouserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeLorawandeviceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  devId_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeManagecontainerticketSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeMasterdatasyncSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeMobiledeviceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  devId_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeNotificationmethodSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePaymentmethodSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePickuporderapprovalSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePickuporderapprovalcollectionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeProductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeProjectSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePropertySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePropertycontactSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePropertygroupSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePropertytypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePropertyuserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePropertyvieweventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeRecurringpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeSalesofferSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeScheduledexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeScheduledexportrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeServiceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeServicepriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeSigfoxdeviceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  devId_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeSkillSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTeamSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTicketSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTicketcategorySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTicketcommentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTicketcustomfieldSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTicketdraftSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTicketeventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTicketmessageSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTicketmessagetemplateSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTickettypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTicketvieweventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeVehicletrailerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeVehicletrailertickettypeholdSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeVehicletrailerwasteholdSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeVehicletypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyTypeConnection = {
  __typename: 'CompanyTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CompanyType` and its cursor. */
export type CompanyTypeEdge = {
  __typename: 'CompanyTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyType>;
};

export type ComparisonSettingsType = Node & {
  __typename: 'ComparisonSettingsType';
  amountOfCollection?: Maybe<Scalars['Int']['output']>;
  amountOfCollectionPeriod?: Maybe<AnalyticsComparisonSettingsAmountOfCollectionPeriodChoices>;
  averageFillLevel?: Maybe<Scalars['Decimal']['output']>;
  chanceOfOverflowing?: Maybe<Scalars['Decimal']['output']>;
  /** Cost per collection */
  collectionCost: Scalars['Float']['output'];
  /** Cost per tonne */
  costPerTonne?: Maybe<Scalars['Float']['output']>;
  costSettings: CostSettingsType;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Income per collection */
  incomePerTonne?: Maybe<Scalars['Float']['output']>;
  /** Cost per overflowing collection */
  overflowingCost?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** kg for m^3 */
  weightFactor: Scalars['Float']['output'];
};

export type ComparisonSettingsTypeConnection = {
  __typename: 'ComparisonSettingsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ComparisonSettingsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ComparisonSettingsType` and its cursor. */
export type ComparisonSettingsTypeEdge = {
  __typename: 'ComparisonSettingsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ComparisonSettingsType>;
};

export type ComparisonType = {
  __typename: 'ComparisonType';
  comparison?: Maybe<Scalars['Float']['output']>;
  selected?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CompleteActionsRequirements = {
  __typename: 'CompleteActionsRequirements';
  requireContainerIdBarcodeScanning?: Maybe<Scalars['Boolean']['output']>;
  requireContainerLabelPrinted?: Maybe<Scalars['Boolean']['output']>;
  requireRfidPairing?: Maybe<Scalars['Boolean']['output']>;
};

export type CompleteAlert = {
  __typename: 'CompleteAlert';
  alert?: Maybe<AlertDef>;
};

export type CompleteTicketDraft = {
  __typename: 'CompleteTicketDraft';
  ticketDraft?: Maybe<TicketDraftDef>;
};

export type ConfirmDriverPushNotification = {
  __typename: 'ConfirmDriverPushNotification';
  driverPushNotification?: Maybe<DriverPushNotificationType>;
};

export type ContactDef = Node & {
  __typename: 'ContactDef';
  activeContainerCount: Scalars['Int']['output'];
  activeRecurringFeeCount: Scalars['Int']['output'];
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  coName: Scalars['String']['output'];
  company: CompanyType;
  companyType: Scalars['String']['output'];
  contactNumber: Scalars['String']['output'];
  contactProperties?: Maybe<ContactPropertyDefConnection>;
  contactType: CrmContactContactTypeChoices;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  ean: Scalars['String']['output'];
  email: Scalars['String']['output'];
  fullAddress: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  masterDataUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note: Scalars['String']['output'];
  otherPhoneNumbers: Array<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  postalCodeName: Scalars['String']['output'];
  propertyAddressCount: Scalars['Int']['output'];
  propertyAddresses: Array<Scalars['String']['output']>;
  propertyCount: Scalars['Int']['output'];
  propertyUsers?: Maybe<Array<Maybe<PropertyUserDef>>>;
  taxId: Scalars['String']['output'];
  taxSubId: Scalars['String']['output'];
  totalActiveAgreementAmount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type ContactDefContactPropertiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchContacts?: InputMaybe<Scalars['String']['input']>;
  searchProperties?: InputMaybe<Scalars['String']['input']>;
};


export type ContactDefPropertyAddressesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactDefConnection = {
  __typename: 'ContactDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContactDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ContactDef` and its cursor. */
export type ContactDefEdge = {
  __typename: 'ContactDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContactDef>;
};

export type ContactPersonInputObject = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPersonType = Node & {
  __typename: 'ContactPersonType';
  /** Contact in this Depot */
  depots: DepotTypeConnection;
  /** Company email */
  email: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** First name / last name of contact */
  name: Scalars['String']['output'];
  /** Collector's, phone number */
  phone?: Maybe<Scalars['String']['output']>;
};


export type ContactPersonTypeDepotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactPropertyDef = Node & {
  __typename: 'ContactPropertyDef';
  contact: ContactDef;
  debtorNumber: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  endedBySync?: Maybe<MasterDataSyncDef>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  property: PropertyDef;
  role: CrmContactPropertyRoleChoices;
  startDate: Scalars['DateTime']['output'];
  startedBySync?: Maybe<MasterDataSyncDef>;
  totalActiveAgreementAmount: Scalars['Int']['output'];
};

export type ContactPropertyDefConnection = {
  __typename: 'ContactPropertyDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContactPropertyDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ContactPropertyDef` and its cursor. */
export type ContactPropertyDefEdge = {
  __typename: 'ContactPropertyDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContactPropertyDef>;
};

/** An enumeration. */
export enum ContactRole {
  ADMINISTRATOR = 'ADMINISTRATOR',
  CONTACT = 'CONTACT',
  CO_OWNER = 'CO_OWNER',
  OWNER = 'OWNER',
  PAYER = 'PAYER',
  TENANT = 'TENANT'
}

export type ContainerActionDef = Node & {
  __typename: 'ContainerActionDef';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  requireContainerIdBarcodeScanning: Scalars['Boolean']['output'];
  requireContainerLabelPrinted: Scalars['Boolean']['output'];
  requireRfidPairing: Scalars['Boolean']['output'];
  rule: TicketRuleDef;
};

export type ContainerActionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  requireContainerIdBarcodeScanning?: InputMaybe<Scalars['Boolean']['input']>;
  requireContainerLabelPrinted?: InputMaybe<Scalars['Boolean']['input']>;
  requireRfidPairing?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContainerAnalyticsType = {
  __typename: 'ContainerAnalyticsType';
  objects?: Maybe<ContainerTypeConnection>;
  objects_Count?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};


export type ContainerAnalyticsTypeObjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Barcode generation history */
export type ContainerBarcodeGenerationHistoryDef = Node & {
  __typename: 'ContainerBarcodeGenerationHistoryDef';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  progress: Scalars['Int']['output'];
  status: DeviceContainerBarcodeGenerationHistoryStatusChoices;
  total: Scalars['Int']['output'];
};

export type ContainerBarcodeGenerationHistoryDefConnection = {
  __typename: 'ContainerBarcodeGenerationHistoryDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerBarcodeGenerationHistoryDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ContainerBarcodeGenerationHistoryDef` and its cursor. */
export type ContainerBarcodeGenerationHistoryDefEdge = {
  __typename: 'ContainerBarcodeGenerationHistoryDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContainerBarcodeGenerationHistoryDef>;
};

export type ContainerClusterDef = {
  __typename: 'ContainerClusterDef';
  collectionCalendars?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  duplicatedPoints?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  pointsOnTheSameLocation: Scalars['Boolean']['output'];
  routeSchemes?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  totalCount: Scalars['Int']['output'];
};

export type ContainerEventDef = Node & {
  __typename: 'ContainerEventDef';
  container?: Maybe<ContainerType>;
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<DeviceContainerEventTypesGraphene>>>;
  fromCollectionCalendar?: Maybe<CollectionCalendarDef>;
  fromContainerId?: Maybe<Scalars['String']['output']>;
  fromContainerType?: Maybe<ContainerTypeType>;
  fromDescription?: Maybe<Scalars['String']['output']>;
  fromLocation?: Maybe<LocationType>;
  fromPickupMethod?: Maybe<Scalars['String']['output']>;
  fromPickupSetting?: Maybe<PickupSettingsType>;
  fromRouteScheme?: Maybe<RouteSchemeType>;
  fromSensorFillLevel?: Maybe<Scalars['Int']['output']>;
  fromStatus?: Maybe<Scalars['String']['output']>;
  fromWasteFraction?: Maybe<WasteFractionType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toCollectionCalendar?: Maybe<CollectionCalendarDef>;
  toContainerId?: Maybe<Scalars['String']['output']>;
  toContainerType?: Maybe<ContainerTypeType>;
  toDescription?: Maybe<Scalars['String']['output']>;
  toLocation?: Maybe<LocationType>;
  toPickupMethod?: Maybe<Scalars['String']['output']>;
  toPickupSetting?: Maybe<PickupSettingsType>;
  toRouteScheme?: Maybe<RouteSchemeType>;
  toSensorFillLevel?: Maybe<Scalars['Int']['output']>;
  toStatus?: Maybe<Scalars['String']['output']>;
  toWasteFraction?: Maybe<WasteFractionType>;
  user?: Maybe<UserType>;
};

export type ContainerEventLogEvent = ContainerEventDef | CustomContainerEventDef | PickUpOrderEventDef;

export type ContainerEventLogEventWrapper = {
  __typename: 'ContainerEventLogEventWrapper';
  edges?: Maybe<Array<Maybe<ContainerEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ContainerGroupDef = Node & {
  __typename: 'ContainerGroupDef';
  containerTypes?: Maybe<Array<Maybe<ContainerTypeType>>>;
  containers: ContainerTypeConnection;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  eventLog?: Maybe<ContainerGroupEventLogEventWrapper>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  location: LocationWithContainerType;
  locationCurb: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pickupSettings?: Maybe<Array<Maybe<PickupSettingsType>>>;
  project: ProjectType;
  totalContainers?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  wasteFractions?: Maybe<Array<Maybe<WasteFractionType>>>;
};


export type ContainerGroupDefContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerGroupDefEventLogArgs = {
  addContainerEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addContainerGroupEvents?: InputMaybe<Scalars['Boolean']['input']>;
  containerEventTypes?: InputMaybe<Array<InputMaybe<DeviceContainerEventTypesGraphene>>>;
  containerGroupEventTypes?: InputMaybe<Array<InputMaybe<ContainerGroupEventTypesGraphene>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ContainerGroupDefConnection = {
  __typename: 'ContainerGroupDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerGroupDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ContainerGroupDef` and its cursor. */
export type ContainerGroupDefEdge = {
  __typename: 'ContainerGroupDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContainerGroupDef>;
};

export type ContainerGroupEventDef = Node & {
  __typename: 'ContainerGroupEventDef';
  containerGroup?: Maybe<ContainerGroupDef>;
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<ContainerGroupEventTypesGraphene>>>;
  fromLocation?: Maybe<LocationType>;
  fromLocationCurb?: Maybe<Scalars['Boolean']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toLocation?: Maybe<LocationType>;
  toLocationCurb?: Maybe<Scalars['Boolean']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};

export type ContainerGroupEventLogEvent = ContainerEventDef | ContainerGroupEventDef;

export type ContainerGroupEventLogEventWrapper = {
  __typename: 'ContainerGroupEventLogEventWrapper';
  edges?: Maybe<Array<Maybe<ContainerGroupEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum ContainerGroupEventTypesGraphene {
  LOCATION_CHANGE = 'LOCATION_CHANGE',
  LOCATION_CURB_CHANGE = 'LOCATION_CURB_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE'
}

export type ContainerIdGeneratorSettingsDef = Node & {
  __typename: 'ContainerIDGeneratorSettingsDef';
  company: CompanyType;
  companyDisplayOption: DeviceContainerIdGeneratorSettingsCompanyDisplayOptionChoices;
  componentsOrder: Array<Scalars['String']['output']>;
  containerTypeDisplayOption: DeviceContainerIdGeneratorSettingsContainerTypeDisplayOptionChoices;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  incrementFrom: Scalars['Int']['output'];
  lastNumber: Scalars['Int']['output'];
  pickupSettingsDisplayOption: DeviceContainerIdGeneratorSettingsPickupSettingsDisplayOptionChoices;
  prefix: Scalars['String']['output'];
  preview?: Maybe<Scalars['String']['output']>;
  separator: Scalars['String']['output'];
  wasteFractionDisplayOption: DeviceContainerIdGeneratorSettingsWasteFractionDisplayOptionChoices;
};

export type ContainerImportType = Node & {
  __typename: 'ContainerImportType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  delimiter: Scalars['String']['output'];
  error: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  importFile: Scalars['String']['output'];
  project: ProjectType;
  shouldCreate: Scalars['Boolean']['output'];
  shouldUpdate: Scalars['Boolean']['output'];
  shouldUpdateGeocodeLocation: Scalars['Boolean']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type ContainerImportTypeConnection = {
  __typename: 'ContainerImportTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerImportTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ContainerImportType` and its cursor. */
export type ContainerImportTypeEdge = {
  __typename: 'ContainerImportTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContainerImportType>;
};

export type ContainerLogAnalyticsType = {
  __typename: 'ContainerLogAnalyticsType';
  objects?: Maybe<ContainerLogTypeConnection>;
  objects_Count?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};


export type ContainerLogAnalyticsTypeObjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ContainerLogDaysType = {
  __typename: 'ContainerLogDaysType';
  day?: Maybe<Scalars['DateTime']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type ContainerLogType = Node & {
  __typename: 'ContainerLogType';
  container: ContainerType;
  createdAt: Scalars['DateTime']['output'];
  durationToPreviousLog?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isOverflowed: Scalars['Boolean']['output'];
  newFillLevel: Scalars['Float']['output'];
  newFillPercentage: Scalars['Float']['output'];
  oldFillLevel: Scalars['Float']['output'];
  oldFillPercentage: Scalars['Float']['output'];
};

export type ContainerLogTypeConnection = {
  __typename: 'ContainerLogTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerLogTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ContainerLogType` and its cursor. */
export type ContainerLogTypeEdge = {
  __typename: 'ContainerLogTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContainerLogType>;
};

export type ContainerMapPointDef = {
  __typename: 'ContainerMapPointDef';
  collectionCalendarId?: Maybe<Scalars['ID']['output']>;
  collectionCalendarName?: Maybe<Scalars['String']['output']>;
  fillLevel?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  routeSchemeId?: Maybe<Scalars['ID']['output']>;
  routeSchemeName?: Maybe<Scalars['String']['output']>;
};

export type ContainerMetaValueInputObject = {
  companyContainerMetaTypeId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type ContainerNotificationDef = Node & {
  __typename: 'ContainerNotificationDef';
  company: CompanyType;
  container: ContainerType;
  createdAt: Scalars['DateTime']['output'];
  daysBefore: Scalars['Int']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  lastSent?: Maybe<Scalars['DateTime']['output']>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  notificationMethod: NotificationMethodDef;
  property: PropertyDef;
};

export type ContainerNotificationDefConnection = {
  __typename: 'ContainerNotificationDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerNotificationDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ContainerNotificationDef` and its cursor. */
export type ContainerNotificationDefEdge = {
  __typename: 'ContainerNotificationDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContainerNotificationDef>;
};

export type ContainerPriceDef = Node & {
  __typename: 'ContainerPriceDef';
  amount: Scalars['Int']['output'];
  company: CompanyType;
  containerType: ContainerTypeType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  invoiceName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pickupSetting: PickupSettingsType;
  propertyType: PropertyTypeDef;
  recurringInterval: Scalars['Int']['output'];
  startDate: Scalars['Date']['output'];
  wasteFraction: WasteFractionType;
};

export type ContainerPriceDefConnection = {
  __typename: 'ContainerPriceDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerPriceDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ContainerPriceDef` and its cursor. */
export type ContainerPriceDefEdge = {
  __typename: 'ContainerPriceDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContainerPriceDef>;
};

export type ContainerPriceOptionDef = {
  __typename: 'ContainerPriceOptionDef';
  amount: Scalars['Int']['output'];
  containerType: ContainerTypeType;
  id: Scalars['ID']['output'];
  pickupSetting: PickupSettingsType;
  product: ProductDef;
  recurringInterval: Scalars['Int']['output'];
  wasteFraction: WasteFractionType;
};

export type ContainerStorageLocationDef = Node & {
  __typename: 'ContainerStorageLocationDef';
  depot?: Maybe<DepotType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type ContainerStorageLocationInput = {
  depot?: InputMaybe<Scalars['ID']['input']>;
};

export type ContainerType = Node & {
  __typename: 'ContainerType';
  activeAgreement?: Maybe<AgreementDef>;
  agreements: AgreementDefConnection;
  alertSet: AlertDefConnection;
  alertruleSet: AlertRuleDefConnection;
  allowedHoursEnd?: Maybe<Scalars['Time']['output']>;
  allowedHoursStart?: Maybe<Scalars['Time']['output']>;
  availableDates?: Maybe<Array<Maybe<Scalars['Date']['output']>>>;
  availableMeasurementFilters?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  availableVehiclesCount?: Maybe<Scalars['Int']['output']>;
  barcode?: Maybe<Scalars['String']['output']>;
  collectionCalendar?: Maybe<CollectionCalendarDef>;
  /** Relation to the Comment */
  comments: CommentTypeConnection;
  containerGroup?: Maybe<ContainerGroupDef>;
  containerId: Scalars['String']['output'];
  containerLog?: Maybe<ContainerLogType>;
  containerType: ContainerTypeType;
  containercustomeventSet: CustomContainerEventDefConnection;
  containernotificationSet: ContainerNotificationDefConnection;
  createdAt: Scalars['DateTime']['output'];
  currentEffective?: Maybe<Scalars['Float']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  deviceToContainerSet: DeviceToContainerTypeConnection;
  efficient?: Maybe<Scalars['Float']['output']>;
  estimatedSavings?: Maybe<Scalars['Float']['output']>;
  eventLog?: Maybe<ContainerEventLogEventWrapper>;
  fillingFrequency?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  inquiries: CollectionInquiryTypeConnection;
  latestPickUp?: Maybe<Scalars['DateTime']['output']>;
  location: LocationWithContainerType;
  locationCurb: Scalars['Boolean']['output'];
  logs: ContainerLogTypeConnection;
  measurement?: Maybe<FillLevelMeasurementType>;
  metaData?: Maybe<Scalars['JSONString']['output']>;
  nextPickUp?: Maybe<Scalars['Date']['output']>;
  notificationsettingsSet: NotificationSettingsTypeConnection;
  overflowingSettings?: Maybe<OverflowingSettingsType>;
  photo?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  pickUpOrders?: Maybe<PickUpOrderTypeConnection>;
  pickupDays: PickupDayTypeConnection;
  pickupDaysFirstCollection?: Maybe<Scalars['Date']['output']>;
  pickupMethod: DeviceContainerPickupMethodChoices;
  pickupSetting?: Maybe<PickupSettingsType>;
  pickupSettings: PickupSettingsTypeConnection;
  prioritizeInStartOfRoute: Scalars['Boolean']['output'];
  project: ProjectType;
  rfid: Scalars['String']['output'];
  routeDescription: Scalars['String']['output'];
  routeScheme?: Maybe<RouteSchemeType>;
  scheduledPickup?: Maybe<Scalars['DateTime']['output']>;
  sensorFillLevel?: Maybe<Scalars['Int']['output']>;
  sensorFillLevelPeriodHours?: Maybe<Scalars['Int']['output']>;
  showOnRoute: Scalars['Boolean']['output'];
  status: DeviceContainerStatusChoices;
  storedAtDepot?: Maybe<DepotType>;
  ticketdraftSet: TicketDraftDefConnection;
  tickets: TicketDefConnection;
  totalCollections?: Maybe<Scalars['Int']['output']>;
  totalCost?: Maybe<Scalars['Float']['output']>;
  totalOverflowings?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vehicle?: Maybe<VehicleType>;
  wasteCollector?: Maybe<WasteCollectorType>;
  wasteFraction: WasteFractionType;
};


export type ContainerTypeAgreementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeAlertSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeAlertruleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeAvailableDatesArgs = {
  periodEnd?: InputMaybe<Scalars['Date']['input']>;
  periodStart?: InputMaybe<Scalars['Date']['input']>;
};


export type ContainerTypeCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeContainercustomeventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeContainernotificationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeCurrentEffectiveArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ContainerTypeDeviceToContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeEventLogArgs = {
  addPickupOrdersEvents?: InputMaybe<Scalars['Boolean']['input']>;
  containerCustomEventTypes?: InputMaybe<Array<InputMaybe<DeviceContainerCustomEventTypesGraphene>>>;
  containerEventTypes?: InputMaybe<Array<InputMaybe<DeviceContainerEventTypesGraphene>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pickupOrderEventTypes?: InputMaybe<Array<InputMaybe<PickUpOrderEventTypesGraphene>>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type ContainerTypeInquiriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeNotificationsettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypePickUpOrdersArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  approvalCollection?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  collectionCalendarIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  completeApproval?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  completedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  completedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container?: InputMaybe<Scalars['ID']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerPickupSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  container_PickupMethod?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdInquiry?: InputMaybe<Scalars['ID']['input']>;
  createdInquiry_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  createdInquiry_Status?: InputMaybe<Scalars['String']['input']>;
  createdTicketsTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  driverIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fillLevel_Gte?: InputMaybe<Scalars['Float']['input']>;
  fillLevel_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  fillLevel_Lte?: InputMaybe<Scalars['Float']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noApproval?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderType_Icontains?: InputMaybe<Scalars['String']['input']>;
  pendingApproval?: InputMaybe<Scalars['Boolean']['input']>;
  propertyGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  route?: InputMaybe<Scalars['ID']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routeScheduledDayGte?: InputMaybe<Scalars['Date']['input']>;
  routeScheduledDayLte?: InputMaybe<Scalars['Date']['input']>;
  route_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stopStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketAssigneeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketAssigneeTeams?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketCreatedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketOrContainerIsnull?: InputMaybe<Scalars['Boolean']['input']>;
  ticketPriority?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketReportedByPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  ticketReporterByDriver?: InputMaybe<Scalars['Boolean']['input']>;
  ticketRequestedByPortal?: InputMaybe<Scalars['Boolean']['input']>;
  ticketStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticket_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFractionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type ContainerTypePickupDaysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypePickupSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeTicketdraftSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ContainerTypeConnection = {
  __typename: 'ContainerTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ContainerType` and its cursor. */
export type ContainerTypeEdge = {
  __typename: 'ContainerTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContainerType>;
};

export type ContainerTypeEventDef = Node & {
  __typename: 'ContainerTypeEventDef';
  containerType?: Maybe<ContainerTypeType>;
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<ContainerTypeEventTypesGraphene>>>;
  fromContainerAccess?: Maybe<Scalars['String']['output']>;
  fromDiameter?: Maybe<Scalars['Float']['output']>;
  fromEmplacement?: Maybe<Scalars['String']['output']>;
  fromEmptyingTime?: Maybe<Scalars['Int']['output']>;
  fromHeight?: Maybe<Scalars['Float']['output']>;
  fromIsCylindrical?: Maybe<Scalars['Boolean']['output']>;
  fromLength?: Maybe<Scalars['Float']['output']>;
  fromMobility?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  fromOverflowingSettings?: Maybe<OverflowingSettingsType>;
  fromPickupSetting?: Maybe<PickupSettingsType>;
  fromVehicleCoupling?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fromVolume?: Maybe<Scalars['Float']['output']>;
  fromWidth?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toContainerAccess?: Maybe<Scalars['String']['output']>;
  toDiameter?: Maybe<Scalars['Float']['output']>;
  toEmplacement?: Maybe<Scalars['String']['output']>;
  toEmptyingTime?: Maybe<Scalars['Int']['output']>;
  toHeight?: Maybe<Scalars['Float']['output']>;
  toIsCylindrical?: Maybe<Scalars['Boolean']['output']>;
  toLength?: Maybe<Scalars['Float']['output']>;
  toMobility?: Maybe<Scalars['String']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  toOverflowingSettings?: Maybe<OverflowingSettingsType>;
  toPickupSetting?: Maybe<PickupSettingsType>;
  toVehicleCoupling?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  toVolume?: Maybe<Scalars['Float']['output']>;
  toWidth?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<UserType>;
};

export enum ContainerTypeEventTypesGraphene {
  DIAMETER_CHANGE = 'DIAMETER_CHANGE',
  EMPLACEMENT_CHANGE = 'EMPLACEMENT_CHANGE',
  EMPTYING_TIME_CHANGE = 'EMPTYING_TIME_CHANGE',
  HEIGHT_CHANGE = 'HEIGHT_CHANGE',
  IS_CYLINDRICAL_CHANGE = 'IS_CYLINDRICAL_CHANGE',
  LENGTH_CHANGE = 'LENGTH_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE',
  VEHICLE_COUPLING_CHANGE = 'VEHICLE_COUPLING_CHANGE',
  VOLUME_CHANGE = 'VOLUME_CHANGE',
  WIDTH_CHANGE = 'WIDTH_CHANGE'
}

export type ContainerTypeType = Node & {
  __typename: 'ContainerTypeType';
  alertruleSet: AlertRuleDefConnection;
  containerAccess?: Maybe<Scalars['String']['output']>;
  containerSet: ContainerTypeConnection;
  costsettingsSet: CostSettingsTypeConnection;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** m */
  diameter?: Maybe<Scalars['Float']['output']>;
  emplacement?: Maybe<Scalars['String']['output']>;
  emptyingTime: Scalars['Int']['output'];
  /** m */
  height: Scalars['Float']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isCylindrical: Scalars['Boolean']['output'];
  /** m */
  length?: Maybe<Scalars['Float']['output']>;
  mobility?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  overflowingSettings?: Maybe<OverflowingSettingsType>;
  photo?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  pickupSetting?: Maybe<PickupSettingsType>;
  project?: Maybe<ProjectType>;
  projectsVisible: ProjectTypeConnection;
  routeSettings: RouteSettingsTypeConnection;
  scheduledexportSet: ScheduledExportDefConnection;
  scheduledexportrunSet: ScheduledExportRunDefConnection;
  vehicleCoupling?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicletrailerSet: VehicleTrailerTypeConnection;
  /** m³ */
  volume: Scalars['Float']['output'];
  /** m */
  width?: Maybe<Scalars['Float']['output']>;
};


export type ContainerTypeTypeAlertruleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeTypeContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeTypeCostsettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeTypeProjectsVisibleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeTypeRouteSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeTypeScheduledexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeTypeScheduledexportrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContainerTypeTypeVehicletrailerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ContainerTypeTypeConnection = {
  __typename: 'ContainerTypeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerTypeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ContainerTypeType` and its cursor. */
export type ContainerTypeTypeEdge = {
  __typename: 'ContainerTypeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContainerTypeType>;
};

export type ContainerWeightImportType = Node & {
  __typename: 'ContainerWeightImportType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  delimiter: Scalars['String']['output'];
  error: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  importFile: Scalars['String']['output'];
  project: ProjectType;
  status?: Maybe<Scalars['String']['output']>;
};

export type ContainerWeightImportTypeConnection = {
  __typename: 'ContainerWeightImportTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerWeightImportTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ContainerWeightImportType` and its cursor. */
export type ContainerWeightImportTypeEdge = {
  __typename: 'ContainerWeightImportTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ContainerWeightImportType>;
};

/** An enumeration. */
export enum CoreBillingFeatureFeatureChoices {
  /** mp_rfid_kit_1 */
  MP_RFID_KIT_1 = 'MP_RFID_KIT_1',
  /** mp_rfid_long_range_antenna_1 */
  MP_RFID_LONG_RANGE_ANTENNA_1 = 'MP_RFID_LONG_RANGE_ANTENNA_1',
  /** mp_rfid_long_range_antenna_2 */
  MP_RFID_LONG_RANGE_ANTENNA_2 = 'MP_RFID_LONG_RANGE_ANTENNA_2',
  /** mp_rfid_manual_reader_1 */
  MP_RFID_MANUAL_READER_1 = 'MP_RFID_MANUAL_READER_1',
  /** mp_rfid_mobile_reader_1 */
  MP_RFID_MOBILE_READER_1 = 'MP_RFID_MOBILE_READER_1',
  /** mp_rfid_tag_1 */
  MP_RFID_TAG_1 = 'MP_RFID_TAG_1',
  /** mp_rfid_tag_2 */
  MP_RFID_TAG_2 = 'MP_RFID_TAG_2',
  /** mp_rfid_tag_3 */
  MP_RFID_TAG_3 = 'MP_RFID_TAG_3',
  /** mp_rfid_universal_antenna_1 */
  MP_RFID_UNIVERSAL_ANTENNA_1 = 'MP_RFID_UNIVERSAL_ANTENNA_1',
  /** mp_sensor_1 */
  MP_SENSOR_1 = 'MP_SENSOR_1',
  /** mp_sensor_2 */
  MP_SENSOR_2 = 'MP_SENSOR_2',
  /** wh_alerts */
  WH_ALERTS = 'WH_ALERTS',
  /** wh_analytics */
  WH_ANALYTICS = 'WH_ANALYTICS',
  /** wh_asset_management */
  WH_ASSET_MANAGEMENT = 'WH_ASSET_MANAGEMENT',
  /** wh_customer_management */
  WH_CUSTOMER_MANAGEMENT = 'WH_CUSTOMER_MANAGEMENT',
  /** wh_customer_management_portal */
  WH_CUSTOMER_MANAGEMENT_PORTAL = 'WH_CUSTOMER_MANAGEMENT_PORTAL',
  /** wh_fleet_management */
  WH_FLEET_MANAGEMENT = 'WH_FLEET_MANAGEMENT',
  /** wh_navigation */
  WH_NAVIGATION = 'WH_NAVIGATION',
  /** wh_operation_management */
  WH_OPERATION_MANAGEMENT = 'WH_OPERATION_MANAGEMENT',
  /** wh_user_management */
  WH_USER_MANAGEMENT = 'WH_USER_MANAGEMENT'
}

/** An enumeration. */
export enum CoreBillingFeatureProductPriceBillingSchemeChoices {
  /** per_unit */
  PER_UNIT = 'PER_UNIT',
  /** tiered */
  TIERED = 'TIERED'
}

/** An enumeration. */
export enum CoreBillingInformationDefaultCollectionMethodChoices {
  /** Stripe */
  A_1 = 'A_1',
  /** Wise */
  A_2 = 'A_2'
}

/** An enumeration. */
export enum CoreCompanyEmailAccountBillingStateChoices {
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Deleted */
  DELETED = 'DELETED',
  /** Need Initialization */
  NEED_INITIALIZATION = 'NEED_INITIALIZATION',
  /** Paid */
  PAID = 'PAID'
}

/** An enumeration. */
export enum CoreCompanyEmailAccountMessageStatusChoices {
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** New */
  NEW = 'NEW',
  /** Open */
  OPEN = 'OPEN',
  /** Rejected */
  REJECTED = 'REJECTED'
}

/** An enumeration. */
export enum CoreCompanyEmailAccountSendStateChoices {
  /** Exception */
  EXCEPTION = 'EXCEPTION',
  /** Initializing */
  INITIALIZING = 'INITIALIZING',
  /** Running */
  RUNNING = 'RUNNING'
}

/** An enumeration. */
export enum CoreCompanyEmailAccountSyncStateChoices {
  /** Downloading */
  DOWNLOADING = 'DOWNLOADING',
  /** Exception */
  EXCEPTION = 'EXCEPTION',
  /** Initializing */
  INITIALIZING = 'INITIALIZING',
  /** Invalid */
  INVALID = 'INVALID',
  /** Invalid Credentials */
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  /** Need Initialization */
  NEED_INITIALIZATION = 'NEED_INITIALIZATION',
  /** Partial */
  PARTIAL = 'PARTIAL',
  /** Running */
  RUNNING = 'RUNNING',
  /** Stopped */
  STOPPED = 'STOPPED',
  /** Sync Error */
  SYNC_ERROR = 'SYNC_ERROR',
  /** Valid */
  VALID = 'VALID'
}

/** An enumeration. */
export enum CoreCompanyMetaTypeCopyFromChoices {
  /** Property */
  A_1 = 'A_1',
  /** Container */
  A_2 = 'A_2'
}

/** An enumeration. */
export enum CoreCompanyMetaTypeEntityChoices {
  /** Container */
  CONTAINER = 'CONTAINER',
  /** Property */
  PROPERTY = 'PROPERTY',
  /** Property Group */
  PROPERTY_GROUP = 'PROPERTY_GROUP'
}

/** An enumeration. */
export enum CoreCompanyMetaTypeInputTypeChoices {
  /** Multi Select */
  MULTI_SELECT = 'MULTI_SELECT',
  /** Number */
  NUMBER = 'NUMBER',
  /** Select */
  SELECT = 'SELECT',
  /** Signature */
  SIGNATURE = 'SIGNATURE',
  /** Text Area */
  TEXT_AREA = 'TEXT_AREA'
}

/** An enumeration. */
export enum CoreCompanyRolePermissionNameChoices {
  /** Agreement Template  Create */
  AGREEMENT_TEMPLATE_CREATE = 'AGREEMENT_TEMPLATE__CREATE',
  /** Agreement Template  Delete */
  AGREEMENT_TEMPLATE_DELETE = 'AGREEMENT_TEMPLATE__DELETE',
  /** Agreement Template  Edit */
  AGREEMENT_TEMPLATE_EDIT = 'AGREEMENT_TEMPLATE__EDIT',
  /** Alert Rule  Create */
  ALERT_RULE_CREATE = 'ALERT_RULE__CREATE',
  /** Alert Rule  Delete */
  ALERT_RULE_DELETE = 'ALERT_RULE__DELETE',
  /** Alert Rule  Edit */
  ALERT_RULE_EDIT = 'ALERT_RULE__EDIT',
  /** Alert  Create */
  ALERT_CREATE = 'ALERT__CREATE',
  /** Alert  Delete */
  ALERT_DELETE = 'ALERT__DELETE',
  /** Alert  Edit */
  ALERT_EDIT = 'ALERT__EDIT',
  /** Analytics Dashboard  Create */
  ANALYTICS_DASHBOARD_CREATE = 'ANALYTICS_DASHBOARD__CREATE',
  /** Analytics Dashboard  Delete */
  ANALYTICS_DASHBOARD_DELETE = 'ANALYTICS_DASHBOARD__DELETE',
  /** Analytics Dashboard  Edit */
  ANALYTICS_DASHBOARD_EDIT = 'ANALYTICS_DASHBOARD__EDIT',
  /** Approval Collection  Create */
  APPROVAL_COLLECTION_CREATE = 'APPROVAL_COLLECTION__CREATE',
  /** Approval Collection  Delete */
  APPROVAL_COLLECTION_DELETE = 'APPROVAL_COLLECTION__DELETE',
  /** Approval Collection  Edit */
  APPROVAL_COLLECTION_EDIT = 'APPROVAL_COLLECTION__EDIT',
  /** Billing Information  Create */
  BILLING_INFORMATION_CREATE = 'BILLING_INFORMATION__CREATE',
  /** Billing Information  Edit */
  BILLING_INFORMATION_EDIT = 'BILLING_INFORMATION__EDIT',
  /** Billing Run Export  Create */
  BILLING_RUN_EXPORT_CREATE = 'BILLING_RUN_EXPORT__CREATE',
  /** Billing Run  Create */
  BILLING_RUN_CREATE = 'BILLING_RUN__CREATE',
  /** Billing Run  Delete */
  BILLING_RUN_DELETE = 'BILLING_RUN__DELETE',
  /** Collection Calendar Date  Create */
  COLLECTION_CALENDAR_DATE_CREATE = 'COLLECTION_CALENDAR_DATE__CREATE',
  /** Collection Calendar Date  Delete */
  COLLECTION_CALENDAR_DATE_DELETE = 'COLLECTION_CALENDAR_DATE__DELETE',
  /** Collection Calendar Date  Edit */
  COLLECTION_CALENDAR_DATE_EDIT = 'COLLECTION_CALENDAR_DATE__EDIT',
  /** Collection Calendar Deviation  Create */
  COLLECTION_CALENDAR_DEVIATION_CREATE = 'COLLECTION_CALENDAR_DEVIATION__CREATE',
  /** Collection Calendar Deviation  Delete */
  COLLECTION_CALENDAR_DEVIATION_DELETE = 'COLLECTION_CALENDAR_DEVIATION__DELETE',
  /** Collection Calendar Deviation  Edit */
  COLLECTION_CALENDAR_DEVIATION_EDIT = 'COLLECTION_CALENDAR_DEVIATION__EDIT',
  /** Collection Calendar  Create */
  COLLECTION_CALENDAR_CREATE = 'COLLECTION_CALENDAR__CREATE',
  /** Collection Calendar  Delete */
  COLLECTION_CALENDAR_DELETE = 'COLLECTION_CALENDAR__DELETE',
  /** Collection Calendar  Edit */
  COLLECTION_CALENDAR_EDIT = 'COLLECTION_CALENDAR__EDIT',
  /** Collection Week  Create */
  COLLECTION_WEEK_CREATE = 'COLLECTION_WEEK__CREATE',
  /** Collection Week  Delete */
  COLLECTION_WEEK_DELETE = 'COLLECTION_WEEK__DELETE',
  /** Collection Week  Edit */
  COLLECTION_WEEK_EDIT = 'COLLECTION_WEEK__EDIT',
  /** Company Email Account Message  Delete */
  COMPANY_EMAIL_ACCOUNT_MESSAGE_DELETE = 'COMPANY_EMAIL_ACCOUNT_MESSAGE__DELETE',
  /** Company Email Account Message  Edit */
  COMPANY_EMAIL_ACCOUNT_MESSAGE_EDIT = 'COMPANY_EMAIL_ACCOUNT_MESSAGE__EDIT',
  /** Company Email Account  Create */
  COMPANY_EMAIL_ACCOUNT_CREATE = 'COMPANY_EMAIL_ACCOUNT__CREATE',
  /** Company Email Account  Delete */
  COMPANY_EMAIL_ACCOUNT_DELETE = 'COMPANY_EMAIL_ACCOUNT__DELETE',
  /** Company Email Account  Edit */
  COMPANY_EMAIL_ACCOUNT_EDIT = 'COMPANY_EMAIL_ACCOUNT__EDIT',
  /** Company Settings  Edit */
  COMPANY_SETTINGS_EDIT = 'COMPANY_SETTINGS__EDIT',
  /** Company  Edit */
  COMPANY_EDIT = 'COMPANY__EDIT',
  /** Contact Property  Create */
  CONTACT_PROPERTY_CREATE = 'CONTACT_PROPERTY__CREATE',
  /** Contact Property  Delete */
  CONTACT_PROPERTY_DELETE = 'CONTACT_PROPERTY__DELETE',
  /** Contact  Create */
  CONTACT_CREATE = 'CONTACT__CREATE',
  /** Contact  Edit */
  CONTACT_EDIT = 'CONTACT__EDIT',
  /** Container Group  Create */
  CONTAINER_GROUP_CREATE = 'CONTAINER_GROUP__CREATE',
  /** Container Group  Delete */
  CONTAINER_GROUP_DELETE = 'CONTAINER_GROUP__DELETE',
  /** Container Group  Edit */
  CONTAINER_GROUP_EDIT = 'CONTAINER_GROUP__EDIT',
  /** Container Type  Create */
  CONTAINER_TYPE_CREATE = 'CONTAINER_TYPE__CREATE',
  /** Container Type  Delete */
  CONTAINER_TYPE_DELETE = 'CONTAINER_TYPE__DELETE',
  /** Container Type  Edit */
  CONTAINER_TYPE_EDIT = 'CONTAINER_TYPE__EDIT',
  /** Container  Create */
  CONTAINER_CREATE = 'CONTAINER__CREATE',
  /** Container  Delete */
  CONTAINER_DELETE = 'CONTAINER__DELETE',
  /** Container  Edit */
  CONTAINER_EDIT = 'CONTAINER__EDIT',
  /** Criipto Application  Create */
  CRIIPTO_APPLICATION_CREATE = 'CRIIPTO_APPLICATION__CREATE',
  /** Criipto Application  Delete */
  CRIIPTO_APPLICATION_DELETE = 'CRIIPTO_APPLICATION__DELETE',
  /** Criipto Application  Edit */
  CRIIPTO_APPLICATION_EDIT = 'CRIIPTO_APPLICATION__EDIT',
  /** Crm Pricing  Create */
  CRM_PRICING_CREATE = 'CRM_PRICING__CREATE',
  /** Crm Pricing  Delete */
  CRM_PRICING_DELETE = 'CRM_PRICING__DELETE',
  /** Crm Pricing  Edit */
  CRM_PRICING_EDIT = 'CRM_PRICING__EDIT',
  /** Crm Property Group  Create */
  CRM_PROPERTY_GROUP_CREATE = 'CRM_PROPERTY_GROUP__CREATE',
  /** Crm Property Group  Delete */
  CRM_PROPERTY_GROUP_DELETE = 'CRM_PROPERTY_GROUP__DELETE',
  /** Crm Property Group  Edit */
  CRM_PROPERTY_GROUP_EDIT = 'CRM_PROPERTY_GROUP__EDIT',
  /** Crm Property Type  Create */
  CRM_PROPERTY_TYPE_CREATE = 'CRM_PROPERTY_TYPE__CREATE',
  /** Crm Property Type  Delete */
  CRM_PROPERTY_TYPE_DELETE = 'CRM_PROPERTY_TYPE__DELETE',
  /** Crm Property Type  Edit */
  CRM_PROPERTY_TYPE_EDIT = 'CRM_PROPERTY_TYPE__EDIT',
  /** Crm Property  Create */
  CRM_PROPERTY_CREATE = 'CRM_PROPERTY__CREATE',
  /** Crm Property  Delete */
  CRM_PROPERTY_DELETE = 'CRM_PROPERTY__DELETE',
  /** Crm Property  Edit */
  CRM_PROPERTY_EDIT = 'CRM_PROPERTY__EDIT',
  /** Crm Service  Create */
  CRM_SERVICE_CREATE = 'CRM_SERVICE__CREATE',
  /** Crm Service  Delete */
  CRM_SERVICE_DELETE = 'CRM_SERVICE__DELETE',
  /** Crm Service  Edit */
  CRM_SERVICE_EDIT = 'CRM_SERVICE__EDIT',
  /** Debtor Export  Create */
  DEBTOR_EXPORT_CREATE = 'DEBTOR_EXPORT__CREATE',
  /** Depot  Create */
  DEPOT_CREATE = 'DEPOT__CREATE',
  /** Depot  Delete */
  DEPOT_DELETE = 'DEPOT__DELETE',
  /** Depot  Edit */
  DEPOT_EDIT = 'DEPOT__EDIT',
  /** Dkdp Company Credentials  Create */
  DKDP_COMPANY_CREDENTIALS_CREATE = 'DKDP_COMPANY_CREDENTIALS__CREATE',
  /** Dkdp Company Credentials  Delete */
  DKDP_COMPANY_CREDENTIALS_DELETE = 'DKDP_COMPANY_CREDENTIALS__DELETE',
  /** Dkdp Company Credentials  Edit */
  DKDP_COMPANY_CREDENTIALS_EDIT = 'DKDP_COMPANY_CREDENTIALS__EDIT',
  /** Document  Create */
  DOCUMENT_CREATE = 'DOCUMENT__CREATE',
  /** Document  Delete */
  DOCUMENT_DELETE = 'DOCUMENT__DELETE',
  /** Document  Edit */
  DOCUMENT_EDIT = 'DOCUMENT__EDIT',
  /** Driver  Create */
  DRIVER_CREATE = 'DRIVER__CREATE',
  /** Driver  Delete */
  DRIVER_DELETE = 'DRIVER__DELETE',
  /** Driver  Edit */
  DRIVER_EDIT = 'DRIVER__EDIT',
  /** Export Template  Create */
  EXPORT_TEMPLATE_CREATE = 'EXPORT_TEMPLATE__CREATE',
  /** Export Template  Delete */
  EXPORT_TEMPLATE_DELETE = 'EXPORT_TEMPLATE__DELETE',
  /** Export Template  Edit */
  EXPORT_TEMPLATE_EDIT = 'EXPORT_TEMPLATE__EDIT',
  /** Import History  Create */
  IMPORT_HISTORY_CREATE = 'IMPORT_HISTORY__CREATE',
  /** Import History  Delete */
  IMPORT_HISTORY_DELETE = 'IMPORT_HISTORY__DELETE',
  /** Import History  Edit */
  IMPORT_HISTORY_EDIT = 'IMPORT_HISTORY__EDIT',
  /** Measurement Settings  Create */
  MEASUREMENT_SETTINGS_CREATE = 'MEASUREMENT_SETTINGS__CREATE',
  /** Measurement Settings  Delete */
  MEASUREMENT_SETTINGS_DELETE = 'MEASUREMENT_SETTINGS__DELETE',
  /** Measurement Settings  Edit */
  MEASUREMENT_SETTINGS_EDIT = 'MEASUREMENT_SETTINGS__EDIT',
  /** Pickup Order  Create */
  PICKUP_ORDER_CREATE = 'PICKUP_ORDER__CREATE',
  /** Pickup Order  Delete */
  PICKUP_ORDER_DELETE = 'PICKUP_ORDER__DELETE',
  /** Pickup Order  Edit */
  PICKUP_ORDER_EDIT = 'PICKUP_ORDER__EDIT',
  /** Pickup Settings  Create */
  PICKUP_SETTINGS_CREATE = 'PICKUP_SETTINGS__CREATE',
  /** Pickup Settings  Delete */
  PICKUP_SETTINGS_DELETE = 'PICKUP_SETTINGS__DELETE',
  /** Pickup Settings  Edit */
  PICKUP_SETTINGS_EDIT = 'PICKUP_SETTINGS__EDIT',
  /** Project  Create */
  PROJECT_CREATE = 'PROJECT__CREATE',
  /** Project  Delete */
  PROJECT_DELETE = 'PROJECT__DELETE',
  /** Project  Edit */
  PROJECT_EDIT = 'PROJECT__EDIT',
  /** Role  Create */
  ROLE_CREATE = 'ROLE__CREATE',
  /** Role  Delete */
  ROLE_DELETE = 'ROLE__DELETE',
  /** Role  Edit */
  ROLE_EDIT = 'ROLE__EDIT',
  /** Route Scheme  Create */
  ROUTE_SCHEME_CREATE = 'ROUTE_SCHEME__CREATE',
  /** Route Scheme  Delete */
  ROUTE_SCHEME_DELETE = 'ROUTE_SCHEME__DELETE',
  /** Route Scheme  Edit */
  ROUTE_SCHEME_EDIT = 'ROUTE_SCHEME__EDIT',
  /** Route Vehicle  Edit */
  ROUTE_VEHICLE_EDIT = 'ROUTE_VEHICLE__EDIT',
  /** Route  Create */
  ROUTE_CREATE = 'ROUTE__CREATE',
  /** Route  Delete */
  ROUTE_DELETE = 'ROUTE__DELETE',
  /** Route  Edit */
  ROUTE_EDIT = 'ROUTE__EDIT',
  /** Stop List Customisation Config  Edit */
  STOP_LIST_CUSTOMISATION_CONFIG_EDIT = 'STOP_LIST_CUSTOMISATION_CONFIG__EDIT',
  /** Team  Create */
  TEAM_CREATE = 'TEAM__CREATE',
  /** Team  Delete */
  TEAM_DELETE = 'TEAM__DELETE',
  /** Team  Edit */
  TEAM_EDIT = 'TEAM__EDIT',
  /** Ticket Category  Create */
  TICKET_CATEGORY_CREATE = 'TICKET_CATEGORY__CREATE',
  /** Ticket Category  Delete */
  TICKET_CATEGORY_DELETE = 'TICKET_CATEGORY__DELETE',
  /** Ticket Category  Edit */
  TICKET_CATEGORY_EDIT = 'TICKET_CATEGORY__EDIT',
  /** Ticket Custom Field  Create */
  TICKET_CUSTOM_FIELD_CREATE = 'TICKET_CUSTOM_FIELD__CREATE',
  /** Ticket Custom Field  Delete */
  TICKET_CUSTOM_FIELD_DELETE = 'TICKET_CUSTOM_FIELD__DELETE',
  /** Ticket Custom Field  Edit */
  TICKET_CUSTOM_FIELD_EDIT = 'TICKET_CUSTOM_FIELD__EDIT',
  /** Ticket Custom Field  View */
  TICKET_CUSTOM_FIELD_VIEW = 'TICKET_CUSTOM_FIELD__VIEW',
  /** Ticket Message Template  Create */
  TICKET_MESSAGE_TEMPLATE_CREATE = 'TICKET_MESSAGE_TEMPLATE__CREATE',
  /** Ticket Message Template  Delete */
  TICKET_MESSAGE_TEMPLATE_DELETE = 'TICKET_MESSAGE_TEMPLATE__DELETE',
  /** Ticket Message Template  Edit */
  TICKET_MESSAGE_TEMPLATE_EDIT = 'TICKET_MESSAGE_TEMPLATE__EDIT',
  /** Ticket Type  Create */
  TICKET_TYPE_CREATE = 'TICKET_TYPE__CREATE',
  /** Ticket Type  Delete */
  TICKET_TYPE_DELETE = 'TICKET_TYPE__DELETE',
  /** Ticket Type  Edit */
  TICKET_TYPE_EDIT = 'TICKET_TYPE__EDIT',
  /** User Absence  Create */
  USER_ABSENCE_CREATE = 'USER_ABSENCE__CREATE',
  /** User Absence  Delete */
  USER_ABSENCE_DELETE = 'USER_ABSENCE__DELETE',
  /** User Absence  Edit */
  USER_ABSENCE_EDIT = 'USER_ABSENCE__EDIT',
  /** User Project  Create */
  USER_PROJECT_CREATE = 'USER_PROJECT__CREATE',
  /** User Project  Delete */
  USER_PROJECT_DELETE = 'USER_PROJECT__DELETE',
  /** User Project  Edit */
  USER_PROJECT_EDIT = 'USER_PROJECT__EDIT',
  /** User  Create */
  USER_CREATE = 'USER__CREATE',
  /** User  Delete */
  USER_DELETE = 'USER__DELETE',
  /** User  Edit */
  USER_EDIT = 'USER__EDIT',
  /** Vehicle Absence  Create */
  VEHICLE_ABSENCE_CREATE = 'VEHICLE_ABSENCE__CREATE',
  /** Vehicle Absence  Delete */
  VEHICLE_ABSENCE_DELETE = 'VEHICLE_ABSENCE__DELETE',
  /** Vehicle Absence  Edit */
  VEHICLE_ABSENCE_EDIT = 'VEHICLE_ABSENCE__EDIT',
  /** Rfid Vehicle Connection  Create */
  VEHICLE_CONNECTION_CREATE = 'VEHICLE_CONNECTION__CREATE',
  /** Rfid Vehicle Connection  Delete */
  VEHICLE_CONNECTION_DELETE = 'VEHICLE_CONNECTION__DELETE',
  /** Rfid Vehicle Connection  Edit */
  VEHICLE_CONNECTION_EDIT = 'VEHICLE_CONNECTION__EDIT',
  /** Vehicle Type  Create */
  VEHICLE_TYPE_CREATE = 'VEHICLE_TYPE__CREATE',
  /** Vehicle Type  Delete */
  VEHICLE_TYPE_DELETE = 'VEHICLE_TYPE__DELETE',
  /** Vehicle Type  Edit */
  VEHICLE_TYPE_EDIT = 'VEHICLE_TYPE__EDIT',
  /** Vehicle  Create */
  VEHICLE_CREATE = 'VEHICLE__CREATE',
  /** Vehicle  Delete */
  VEHICLE_DELETE = 'VEHICLE__DELETE',
  /** Vehicle  Edit */
  VEHICLE_EDIT = 'VEHICLE__EDIT',
  /** Waste Fraction  Create */
  WASTE_FRACTION_CREATE = 'WASTE_FRACTION__CREATE',
  /** Waste Fraction  Delete */
  WASTE_FRACTION_DELETE = 'WASTE_FRACTION__DELETE',
  /** Waste Fraction  Edit */
  WASTE_FRACTION_EDIT = 'WASTE_FRACTION__EDIT'
}

/** An enumeration. */
export enum CoreCompanySettingsBillingExportSystemChoices {
  /** Navision */
  NAVISION = 'NAVISION'
}

/** An enumeration. */
export enum CoreCompanySettingsCrmContactsAssignUniqueIdChoices {
  /** Tax Id */
  TAX_ID = 'TAX_ID'
}

/** An enumeration. */
export enum CoreCompanySettingsCrmLoginMethodChoices {
  /** none */
  NONE = 'NONE',
  /** user */
  USER = 'USER'
}

/** An enumeration. */
export enum CoreCompanySettingsCrmLoginMethodUserChoices {
  /** Post auth search */
  POST_AUTH_SEARCH = 'POST_AUTH_SEARCH',
  /** Pre auth search */
  PRE_AUTH_SEARCH = 'PRE_AUTH_SEARCH'
}

/** An enumeration. */
export enum CoreCompanySettingsFontFamilyChoices {
  /** Helvetica Neue */
  HELVETICA = 'HELVETICA',
  /** Roboto */
  ROBOTO = 'ROBOTO'
}

/** An enumeration. */
export enum CoreCompanySettingsMasterDataProviderChoices {
  /** KMD Cognito */
  COGNITO = 'COGNITO',
  /** LIFA OIS */
  LIFAOIS = 'LIFAOIS'
}

/** An enumeration. */
export enum CoreExportExportTypeChoices {
  /** Agreements */
  AGREEMENTS = 'AGREEMENTS',
  /** Agreement Available Dates */
  AGREEMENT_AVAILABLE_DATES = 'AGREEMENT_AVAILABLE_DATES',
  /** Containers */
  CONTAINERS = 'CONTAINERS',
  /** Container Available Dates */
  CONTAINER_AVAILABLE_DATES = 'CONTAINER_AVAILABLE_DATES',
  /** Fill Level Measurements */
  FILL_LEVEL_MEASUREMENTS = 'FILL_LEVEL_MEASUREMENTS',
  /** Hauler Prices */
  HAULER_PRICES = 'HAULER_PRICES',
  /** Pick Up Orders */
  PICK_UP_ORDERS = 'PICK_UP_ORDERS',
  /** Products */
  PRODUCTS = 'PRODUCTS',
  /** Properties */
  PROPERTIES = 'PROPERTIES',
  /** Routes */
  ROUTES = 'ROUTES',
  /** Static Routes */
  STATIC_ROUTES = 'STATIC_ROUTES',
  /** Tickets */
  TICKETS = 'TICKETS'
}

/** An enumeration. */
export enum CoreExportStatusChoices {
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Failed */
  FAILED = 'FAILED',
  /** In Progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** New */
  NEW = 'NEW'
}

/** An enumeration. */
export enum CoreIoUserDatetimeFormatLocaleChoices {
  /** Arabic */
  AR = 'AR',
  /** Danish */
  DA = 'DA',
  /** English (us) */
  EN = 'EN',
  /** English (uk) */
  EN_GB = 'EN_GB',
  /** Finnish */
  FI = 'FI',
  /** Norwegian */
  NB = 'NB',
  /** Swedish */
  SV = 'SV'
}

/** An enumeration. */
export enum CoreIoUserLanguageChoices {
  /** Danish */
  DA = 'DA',
  /** Greek */
  EL = 'EL',
  /** English */
  EN = 'EN',
  /** Finnish */
  FI = 'FI',
  /** Norwegian */
  NO = 'NO',
  /** Swedish */
  SV = 'SV'
}

/** An enumeration. */
export enum CoreUserAbsenceAbsenceTypeChoices {
  /** Holiday */
  HOLIDAY = 'HOLIDAY',
  /** Maternity */
  MATERNITY = 'MATERNITY',
  /** Other */
  OTHER = 'OTHER',
  /** Sick */
  SICK = 'SICK'
}

/** An enumeration. */
export enum CoreUserLayoutConfigAnalyticsCalculationTypeChoices {
  /** Custom period */
  CUSTOM_PERIOD = 'CUSTOM_PERIOD',
  /** Historical data */
  HISTORICAL_DATA = 'HISTORICAL_DATA',
  /** Previous period */
  PREVIOUS_PERIOD = 'PREVIOUS_PERIOD'
}

/** An enumeration. */
export enum CoreUserLayoutConfigAnalyticsHistoricalPeriodChoices {
  /** day */
  DAY = 'DAY',
  /** month */
  MONTH = 'MONTH',
  /** quarter */
  QUARTER = 'QUARTER',
  /** week */
  WEEK = 'WEEK',
  /** year */
  YEAR = 'YEAR'
}

/** An enumeration. */
export enum CoreUserLayoutConfigAnalyticsPreviousPeriodChoices {
  /** day */
  DAY = 'DAY',
  /** month */
  MONTH = 'MONTH',
  /** quarter */
  QUARTER = 'QUARTER',
  /** week */
  WEEK = 'WEEK',
  /** year */
  YEAR = 'YEAR'
}

export type CostOverviewType = {
  __typename: 'CostOverviewType';
  avgCollection?: Maybe<Scalars['Float']['output']>;
  avgDisposal?: Maybe<Scalars['Float']['output']>;
  avgIncome?: Maybe<Scalars['Float']['output']>;
  avgOverflowing?: Maybe<Scalars['Float']['output']>;
  containerType?: Maybe<ContainerTypeType>;
  totalCollection?: Maybe<Scalars['Float']['output']>;
  totalDisposal?: Maybe<Scalars['Float']['output']>;
  totalIncome?: Maybe<Scalars['Float']['output']>;
  totalOverflowing?: Maybe<Scalars['Float']['output']>;
  wasteFraction?: Maybe<WasteFractionType>;
};

export type CostSettingsType = Node & {
  __typename: 'CostSettingsType';
  amountOfCollection?: Maybe<Scalars['Int']['output']>;
  amountOfCollectionPeriod?: Maybe<AnalyticsCostSettingsAmountOfCollectionPeriodChoices>;
  averageFillLevel?: Maybe<Scalars['Decimal']['output']>;
  chanceOfOverflowing?: Maybe<Scalars['Decimal']['output']>;
  /** Cost per collection */
  collectionCost: Scalars['Float']['output'];
  comparisonsettings?: Maybe<ComparisonSettingsType>;
  containerLogs?: Maybe<ContainerLogTypeConnection>;
  containerType?: Maybe<ContainerTypeType>;
  containers?: Maybe<ContainerTypeConnection>;
  /** Cost per tonne */
  costPerTonne?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Income per collection */
  incomePerTonne?: Maybe<Scalars['Float']['output']>;
  /** Cost per overflowing collection */
  overflowingCost?: Maybe<Scalars['Float']['output']>;
  ownValues: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
  wasteFraction?: Maybe<WasteFractionType>;
  /** kg for m^3 */
  weightFactor: Scalars['Float']['output'];
};


export type CostSettingsTypeContainerLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CostSettingsTypeContainersArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  allowedForRoute?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  collectionCalendar_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  collectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerId?: InputMaybe<Scalars['String']['input']>;
  containerType_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  containerType_Name_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  containerTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  device_IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  excludeCollectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeRouteSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  measurement_FillPercentage?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noProperty?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyNumber?: InputMaybe<Scalars['String']['input']>;
  propertyTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  q?: InputMaybe<Scalars['String']['input']>;
  routeIdsNotCompletedStops?: InputMaybe<Scalars['String']['input']>;
  routeScheme_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  routeSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchContact?: InputMaybe<Scalars['String']['input']>;
  sensor_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  storedAtDepot?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFractionIds?: InputMaybe<Scalars['String']['input']>;
  wasteFraction_WasteTypes_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  wasteFraction_WasteTypes_Name_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wasteTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CostSettingsTypeConnection = {
  __typename: 'CostSettingsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CostSettingsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CostSettingsType` and its cursor. */
export type CostSettingsTypeEdge = {
  __typename: 'CostSettingsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CostSettingsType>;
};

export type CreateAgreementInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  product: Scalars['ID']['input'];
  property: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type CreateAgreementPayload = {
  __typename: 'CreateAgreementPayload';
  agreement?: Maybe<AgreementDef>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateBillingRun = {
  __typename: 'CreateBillingRun';
  billingRun?: Maybe<BillingRunDef>;
};

export type CreateBulkWastePickupTicketInput = {
  /** If true, the user creating the ticket will be added to the followers */
  addToFollowers?: InputMaybe<Scalars['Boolean']['input']>;
  assignedTeam?: InputMaybe<Scalars['ID']['input']>;
  assignee?: InputMaybe<Scalars['ID']['input']>;
  attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  container?: InputMaybe<Scalars['ID']['input']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<TicketCustomFieldValueInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ignoreServiceFee?: InputMaybe<Scalars['Boolean']['input']>;
  items: Array<InputMaybe<BulkWastePickupTicketItemInput>>;
  location?: InputMaybe<Scalars['ID']['input']>;
  preferredDate?: InputMaybe<Scalars['Date']['input']>;
  /** If true, do not create the ticket, but preview what it would look like if created - e.g. to get the prices. */
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  priority: Scalars['String']['input'];
  property?: InputMaybe<Scalars['ID']['input']>;
  reporter?: InputMaybe<Scalars['ID']['input']>;
  reporterDriver?: InputMaybe<Scalars['ID']['input']>;
  requestedBy?: InputMaybe<Scalars['ID']['input']>;
  requestedByOther?: InputMaybe<Scalars['String']['input']>;
  sendSummary?: InputMaybe<SendTicketSummaryInput>;
  serviceProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketType: Scalars['ID']['input'];
};

export type CreateBulkWastePickupTicketPayload = {
  __typename: 'CreateBulkWastePickupTicketPayload';
  bulkWastePickupTicket?: Maybe<TicketDef>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateCompanyEmailAccountMessageInput = {
  addAttachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  body: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyEmailAccount: Scalars['ID']['input'];
  threadId?: InputMaybe<Scalars['String']['input']>;
  threadStarter?: InputMaybe<Scalars['ID']['input']>;
  toEmail?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyEmailAccountMessagePayload = {
  __typename: 'CreateCompanyEmailAccountMessagePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyEmailAccountMessage?: Maybe<CompanyEmailAccountMessageDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateComparisonSettingsMutationInput = {
  amountOfCollection?: InputMaybe<Scalars['Int']['input']>;
  amountOfCollectionPeriod?: InputMaybe<Scalars['String']['input']>;
  averageFillLevel?: InputMaybe<Scalars['Float']['input']>;
  chanceOfOverflowing?: InputMaybe<Scalars['Float']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Cost per collection */
  collectionCost: Scalars['Float']['input'];
  /** Cost per tonne */
  costPerTonne?: InputMaybe<Scalars['Float']['input']>;
  costSettings: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Income per collection */
  incomePerTonne?: InputMaybe<Scalars['Float']['input']>;
  /** Cost per overflowing collection */
  overflowingCost?: InputMaybe<Scalars['Float']['input']>;
  /** kg for m^3 */
  weightFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateComparisonSettingsMutationPayload = {
  __typename: 'CreateComparisonSettingsMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comparisonSettings?: Maybe<ComparisonSettingsType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateContainer = {
  __typename: 'CreateContainer';
  container?: Maybe<ContainerType>;
};

export type CreateContainerImport = {
  __typename: 'CreateContainerImport';
  containerImport?: Maybe<ContainerImportType>;
};

export type CreateContainerMobile = {
  __typename: 'CreateContainerMobile';
  container?: Maybe<ContainerType>;
};

export type CreateContainerPriceInput = {
  amount: Scalars['Int']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  containerType: Scalars['ID']['input'];
  endDate: Scalars['Date']['input'];
  invoiceName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pickupSetting: Scalars['ID']['input'];
  propertyType: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
  wasteFraction: Scalars['ID']['input'];
};

export type CreateContainerPricePayload = {
  __typename: 'CreateContainerPricePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  containerPrice?: Maybe<ContainerPriceDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateContainerType = {
  __typename: 'CreateContainerType';
  containerType?: Maybe<ContainerTypeType>;
};

export type CreateContainerWeightImport = {
  __typename: 'CreateContainerWeightImport';
  containerWeightImport?: Maybe<ContainerWeightImportType>;
};

export type CreateCostSettingsMutationInput = {
  amountOfCollection?: InputMaybe<Scalars['Int']['input']>;
  amountOfCollectionPeriod?: InputMaybe<Scalars['String']['input']>;
  averageFillLevel?: InputMaybe<Scalars['Float']['input']>;
  chanceOfOverflowing?: InputMaybe<Scalars['Float']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Cost per collection */
  collectionCost: Scalars['Float']['input'];
  containerType: Scalars['ID']['input'];
  /** Cost per tonne */
  costPerTonne?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Income per collection */
  incomePerTonne?: InputMaybe<Scalars['Float']['input']>;
  /** Cost per overflowing collection */
  overflowingCost?: InputMaybe<Scalars['Float']['input']>;
  ownValues?: InputMaybe<Scalars['Boolean']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
  wasteFraction: Scalars['ID']['input'];
  /** kg for m^3 */
  weightFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateCostSettingsMutationPayload = {
  __typename: 'CreateCostSettingsMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  costSettings?: Maybe<CostSettingsType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateDriverPushNotificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  driverIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isSilent?: InputMaybe<Scalars['Boolean']['input']>;
  message: Scalars['String']['input'];
  notificationType: PushNotificationTypes;
  route: Scalars['ID']['input'];
  stops: Array<InputMaybe<Scalars['ID']['input']>>;
  title: Scalars['String']['input'];
};

export type CreateDriverPushNotificationPayload = {
  __typename: 'CreateDriverPushNotificationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  driverPushNotification?: Maybe<DriverPushNotificationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateExport = {
  __typename: 'CreateExport';
  export?: Maybe<ExportType>;
};

export type CreateFillLevelDeviceMobile = {
  __typename: 'CreateFillLevelDeviceMobile';
  fillLevelDevice?: Maybe<BaseDeviceInterface>;
};

export type CreateManageContainerTicketInput = {
  /** If true, the user creating the ticket will be added to the followers */
  addToFollowers?: InputMaybe<Scalars['Boolean']['input']>;
  assignedTeam?: InputMaybe<Scalars['ID']['input']>;
  assignee?: InputMaybe<Scalars['ID']['input']>;
  attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  collectionCalendar?: InputMaybe<Scalars['ID']['input']>;
  company: Scalars['ID']['input'];
  container?: InputMaybe<Scalars['ID']['input']>;
  containerDestination?: InputMaybe<ContainerStorageLocationInput>;
  containerProduct?: InputMaybe<Scalars['ID']['input']>;
  containerSource?: InputMaybe<ContainerStorageLocationInput>;
  containerToAdd?: InputMaybe<Scalars['ID']['input']>;
  containerType?: InputMaybe<Scalars['ID']['input']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<TicketCustomFieldValueInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ignoreServiceFee?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['ID']['input']>;
  newContainerId?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Scalars['ID']['input']>;
  preferredDate?: InputMaybe<Scalars['Date']['input']>;
  /** If true, do not create the ticket, but preview what it would look like if created - e.g. to get the prices. */
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  priority: Scalars['String']['input'];
  property: Scalars['ID']['input'];
  reporter?: InputMaybe<Scalars['ID']['input']>;
  reporterDriver?: InputMaybe<Scalars['ID']['input']>;
  requestedBy?: InputMaybe<Scalars['ID']['input']>;
  requestedByOther?: InputMaybe<Scalars['String']['input']>;
  routeScheme?: InputMaybe<Scalars['ID']['input']>;
  sendSummary?: InputMaybe<SendTicketSummaryInput>;
  serviceProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketType: Scalars['ID']['input'];
  wasteFraction?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateManageContainerTicketPayload = {
  __typename: 'CreateManageContainerTicketPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  manageContainerTicket?: Maybe<TicketDef>;
};

export type CreateMeasurementSettings = {
  __typename: 'CreateMeasurementSettings';
  measurementSettings?: Maybe<MeasurementSettingsType>;
};

export type CreateMobileDevice = {
  __typename: 'CreateMobileDevice';
  device?: Maybe<MobileDeviceType>;
};

export type CreateNotificationSetting = {
  __typename: 'CreateNotificationSetting';
  notificationSetting?: Maybe<NotificationSettingsType>;
};

export type CreateOrUpdateExportTemplate = {
  __typename: 'CreateOrUpdateExportTemplate';
  exportTemplate?: Maybe<ExportTemplateDef>;
};

export type CreateOrUpdateStopListCardConfig = {
  __typename: 'CreateOrUpdateStopListCardConfig';
  stopListCardConfig?: Maybe<StopListCardConfigType>;
};

export type CreatePriceInput = {
  amount: Scalars['Int']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  product: Scalars['ID']['input'];
  recurringInterval: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
  weightAmount: Scalars['Int']['input'];
};

export type CreatePricePayload = {
  __typename: 'CreatePricePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  price?: Maybe<PriceDef>;
};

export type CreatePropertyActionDef = Node & {
  __typename: 'CreatePropertyActionDef';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  rule: TicketRuleDef;
};

export type CreatePropertyActionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRecurringPriceInput = {
  amount: Scalars['Int']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  endDate: Scalars['Date']['input'];
  invoiceName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  propertyType?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['Date']['input'];
};

export type CreateRecurringPricePayload = {
  __typename: 'CreateRecurringPricePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  recurringPrice?: Maybe<RecurringPriceDef>;
};

export type CreateRouteMutation = {
  __typename: 'CreateRouteMutation';
  route?: Maybe<RouteType>;
};

export type CreateScheduledExportRun = {
  __typename: 'CreateScheduledExportRun';
  scheduledExportRun?: Maybe<ScheduledExportRunDef>;
};

export type CreateSensor = {
  __typename: 'CreateSensor';
  fillLevelDevice?: Maybe<BaseDeviceInterface>;
};

export type CreateServicePriceInput = {
  amount: Scalars['Int']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  containerType?: InputMaybe<Scalars['ID']['input']>;
  endDate: Scalars['Date']['input'];
  invoiceName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  propertyType?: InputMaybe<Scalars['ID']['input']>;
  service: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
  wasteFraction?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateServicePricePayload = {
  __typename: 'CreateServicePricePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  servicePrice?: Maybe<ServicePriceDef>;
};

export type CreateTicketActionAttachmentDef = Node & {
  __typename: 'CreateTicketActionAttachmentDef';
  action: CreateTicketActionDef;
  file: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
};

export type CreateTicketActionAttachmentDefConnection = {
  __typename: 'CreateTicketActionAttachmentDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CreateTicketActionAttachmentDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CreateTicketActionAttachmentDef` and its cursor. */
export type CreateTicketActionAttachmentDefEdge = {
  __typename: 'CreateTicketActionAttachmentDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CreateTicketActionAttachmentDef>;
};

export type CreateTicketActionDef = Node & {
  __typename: 'CreateTicketActionDef';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  newTicketAttachments: CreateTicketActionAttachmentDefConnection;
  newTicketDescription: Scalars['String']['output'];
  newTicketPriority: TicketCreateTicketActionNewTicketPriorityChoices;
  newTicketTicketType: TicketTypeDef;
  order: Scalars['Int']['output'];
  rule: TicketRuleDef;
};


export type CreateTicketActionDefNewTicketAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTicketActionDefConnection = {
  __typename: 'CreateTicketActionDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CreateTicketActionDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CreateTicketActionDef` and its cursor. */
export type CreateTicketActionDefEdge = {
  __typename: 'CreateTicketActionDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CreateTicketActionDef>;
};

export type CreateTicketActionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newTicketAttachments?: InputMaybe<Array<Scalars['String']['input']>>;
  newTicketDescription?: InputMaybe<Scalars['String']['input']>;
  newTicketPriority: Scalars['String']['input'];
  newTicketTicketType: Scalars['ID']['input'];
};

export type CreateTicketCommentInput = {
  addAttachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  body: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ticket: Scalars['ID']['input'];
};

export type CreateTicketCommentPayload = {
  __typename: 'CreateTicketCommentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ticketComment?: Maybe<TicketCommentDef>;
};

export type CreateTicketCustomFieldMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayHelpText?: InputMaybe<Scalars['Boolean']['input']>;
  displayOn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayPrefilledValue?: InputMaybe<Scalars['Boolean']['input']>;
  displayUnit?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inputType?: InputMaybe<Scalars['String']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<CustomFieldOptionSelectInput>>>;
  order?: InputMaybe<Scalars['Int']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  prefilledValue?: InputMaybe<Scalars['Int']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTicketCustomFieldMutationPayload = {
  __typename: 'CreateTicketCustomFieldMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ticketCustomField?: Maybe<TicketCustomFieldDef>;
};

export type CreateTicketDraftEmailInput = {
  body: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyEmailAccount: Scalars['ID']['input'];
  threadStarter?: InputMaybe<Scalars['ID']['input']>;
  ticketDraft: Scalars['ID']['input'];
};

export type CreateTicketDraftEmailPayload = {
  __typename: 'CreateTicketDraftEmailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyEmailAccountMessage?: Maybe<CompanyEmailAccountMessageDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateTicketInput = {
  /** If true, the user creating the ticket will be added to the followers */
  addToFollowers?: InputMaybe<Scalars['Boolean']['input']>;
  assignedTeam?: InputMaybe<Scalars['ID']['input']>;
  assignee?: InputMaybe<Scalars['ID']['input']>;
  attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  container?: InputMaybe<Scalars['ID']['input']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<TicketCustomFieldValueInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ignoreServiceFee?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['ID']['input']>;
  preferredDate?: InputMaybe<Scalars['Date']['input']>;
  /** If true, do not create the ticket, but preview what it would look like if created - e.g. to get the prices. */
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  priority: Scalars['String']['input'];
  property?: InputMaybe<Scalars['ID']['input']>;
  reporter?: InputMaybe<Scalars['ID']['input']>;
  reporterDriver?: InputMaybe<Scalars['ID']['input']>;
  requestedBy?: InputMaybe<Scalars['ID']['input']>;
  requestedByOther?: InputMaybe<Scalars['String']['input']>;
  sendSummary?: InputMaybe<SendTicketSummaryInput>;
  serviceProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketType: Scalars['ID']['input'];
};

export type CreateTicketMessageInput = {
  addAttachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  body: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyEmailAccount?: InputMaybe<Scalars['ID']['input']>;
  receiverEmail?: InputMaybe<Scalars['String']['input']>;
  receiverPropertyContact?: InputMaybe<Scalars['ID']['input']>;
  receiverPropertyUser?: InputMaybe<Scalars['ID']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  ticket: Scalars['ID']['input'];
  via?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTicketMessagePayload = {
  __typename: 'CreateTicketMessagePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ticketMessage?: Maybe<TicketMessageDef>;
};

export type CreateTicketPayload = {
  __typename: 'CreateTicketPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ticket?: Maybe<TicketDef>;
};

export type CreateUnconfirmedUser = {
  __typename: 'CreateUnconfirmedUser';
  flow?: Maybe<CreateUnconfirmedUserFlow>;
  unconfirmedUser?: Maybe<UnconfirmedUserType>;
};

export type CreateUnconfirmedUserFlow = {
  __typename: 'CreateUnconfirmedUserFlow';
  enable?: Maybe<Scalars['Boolean']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type CreateUser = {
  __typename: 'CreateUser';
  user?: Maybe<UserType>;
};

export type CreateWasteCollector = {
  __typename: 'CreateWasteCollector';
  newWasteCollector?: Maybe<WasteCollectorType>;
};

export type CreateWasteFraction = {
  __typename: 'CreateWasteFraction';
  wasteFraction?: Maybe<WasteFractionType>;
};

export type CriiptoApplicationDef = Node & {
  __typename: 'CriiptoApplicationDef';
  allowBusiness: Scalars['Boolean']['output'];
  allowedAcrValues: Array<Scalars['String']['output']>;
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  hostname: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type CriiptoApplicationDefConnection = {
  __typename: 'CriiptoApplicationDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CriiptoApplicationDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CriiptoApplicationDef` and its cursor. */
export type CriiptoApplicationDefEdge = {
  __typename: 'CriiptoApplicationDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CriiptoApplicationDef>;
};

/** An enumeration. */
export enum CrmActivePriceViewRecurringIntervalChoices {
  /** Once */
  ONCE = 'ONCE',
  /** Yearly */
  YEARLY = 'YEARLY'
}

/** An enumeration. */
export enum CrmAgreementExportModeChoices {
  /** Change */
  CHANGE = 'CHANGE',
  /** Total */
  TOTAL = 'TOTAL'
}

/** An enumeration. */
export enum CrmBillingRunExportStatusChoices {
  /** Exported */
  EXPORTED = 'EXPORTED',
  /** Exporting */
  EXPORTING = 'EXPORTING',
  /** Failed */
  FAILED = 'FAILED'
}

/** An enumeration. */
export enum CrmBillingRunStatusChoices {
  /** Created */
  CREATED = 'CREATED',
  /** Creating */
  CREATING = 'CREATING',
  /** Failed */
  FAILED = 'FAILED'
}

/** An enumeration. */
export enum CrmContactContactTypeChoices {
  /** Company */
  COMPANY = 'COMPANY',
  /** Individual */
  INDIVIDUAL = 'INDIVIDUAL'
}

/** An enumeration. */
export enum CrmContactPropertyRoleChoices {
  /** Administrator */
  ADMINISTRATOR = 'ADMINISTRATOR',
  /** Contact */
  CONTACT = 'CONTACT',
  /** Co Owner */
  CO_OWNER = 'CO_OWNER',
  /** Owner */
  OWNER = 'OWNER',
  /** Payer */
  PAYER = 'PAYER',
  /** Tenant */
  TENANT = 'TENANT'
}

/** An enumeration. */
export enum CrmDebtorExportStatusChoices {
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Exporting */
  EXPORTING = 'EXPORTING',
  /** Failed */
  FAILED = 'FAILED'
}

/** An enumeration. */
export enum CrmInvoiceStatusChoices {
  /** Draft */
  A_1 = 'A_1',
  /** Open */
  A_2 = 'A_2',
  /** Paid */
  A_3 = 'A_3',
  /** Void */
  A_4 = 'A_4',
  /** Transferred to External System */
  A_5 = 'A_5'
}

/** An enumeration. */
export enum CrmMasterDataSyncStatusChoices {
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Failed */
  FAILED = 'FAILED',
  /** Syncing */
  SYNCING = 'SYNCING'
}

/** An enumeration. */
export enum CrmMasterDataSyncTypeChoices {
  /** Contacts */
  CONTACTS = 'CONTACTS',
  /** Properties */
  PROPERTIES = 'PROPERTIES'
}

/** An enumeration. */
export enum CrmPriceRecurringIntervalChoices {
  /** Once */
  ONCE = 'ONCE',
  /** Yearly */
  YEARLY = 'YEARLY'
}

/** An enumeration. */
export enum CrmProductProductTypeChoices {
  /** Container */
  CONTAINER = 'CONTAINER',
  /** Recurring */
  RECURRING = 'RECURRING',
  /** Service */
  SERVICE = 'SERVICE'
}

/** An enumeration. */
export enum CrmPropertyContactContactTypeChoices {
  /** Company */
  COMPANY = 'COMPANY',
  /** Individual */
  INDIVIDUAL = 'INDIVIDUAL'
}

/** An enumeration. */
export enum CrmPropertyGroupGroupTypeChoices {
  /** Single */
  A_1 = 'A_1',
  /** Split */
  A_2 = 'A_2'
}

/** An enumeration. */
export enum CrmPropertyUserLanguageChoices {
  /** Danish */
  DA = 'DA',
  /** Greek */
  EL = 'EL',
  /** English */
  EN = 'EN',
  /** Finnish */
  FI = 'FI',
  /** Norwegian */
  NO = 'NO',
  /** Swedish */
  SV = 'SV'
}

/** An enumeration. */
export enum CrmScheduledExportFilterTicketDateChoices {
  /** Completed */
  COMPLETED_AT = 'COMPLETED_AT',
  /** Created */
  CREATED_AT = 'CREATED_AT',
  /** In progress */
  IN_PROGRESS_AT = 'IN_PROGRESS_AT',
  /** Open */
  OPEN_AT = 'OPEN_AT',
  /** Pending */
  PENDING_AT = 'PENDING_AT',
  /** Preferred Date */
  PREFERRED_DATE = 'PREFERRED_DATE'
}

/** An enumeration. */
export enum CrmScheduledExportIntervalTypeChoices {
  /** Day */
  DAY = 'DAY',
  /** Hour */
  HOUR = 'HOUR',
  /** Month */
  MONTH = 'MONTH',
  /** Week */
  WEEK = 'WEEK'
}

/** An enumeration. */
export enum CrmScheduledExportLanguageChoices {
  /** Danish */
  DA = 'DA',
  /** Greek */
  EL = 'EL',
  /** English */
  EN = 'EN',
  /** Finnish */
  FI = 'FI',
  /** Norwegian */
  NO = 'NO',
  /** Swedish */
  SV = 'SV'
}

/** An enumeration. */
export enum CrmScheduledExportRunFilterTicketDateChoices {
  /** Completed */
  COMPLETED_AT = 'COMPLETED_AT',
  /** Created */
  CREATED_AT = 'CREATED_AT',
  /** In progress */
  IN_PROGRESS_AT = 'IN_PROGRESS_AT',
  /** Open */
  OPEN_AT = 'OPEN_AT',
  /** Pending */
  PENDING_AT = 'PENDING_AT',
  /** Preferred Date */
  PREFERRED_DATE = 'PREFERRED_DATE'
}

/** An enumeration. */
export enum CrmScheduledExportRunLanguageChoices {
  /** Danish */
  DA = 'DA',
  /** Greek */
  EL = 'EL',
  /** English */
  EN = 'EN',
  /** Finnish */
  FI = 'FI',
  /** Norwegian */
  NO = 'NO',
  /** Swedish */
  SV = 'SV'
}

export type CurrentDriverLocationSubscription = {
  __typename: 'CurrentDriverLocationSubscription';
  drivers?: Maybe<Array<Maybe<DriverType>>>;
};

export type CustomContainerEventDef = Node & {
  __typename: 'CustomContainerEventDef';
  barcodeFileUrl?: Maybe<Scalars['String']['output']>;
  container?: Maybe<ContainerType>;
  createdAt: Scalars['DateTime']['output'];
  customEventType?: Maybe<Array<Maybe<DeviceContainerCustomEventTypesGraphene>>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  user?: Maybe<UserType>;
};

export type CustomContainerEventDefConnection = {
  __typename: 'CustomContainerEventDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomContainerEventDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CustomContainerEventDef` and its cursor. */
export type CustomContainerEventDefEdge = {
  __typename: 'CustomContainerEventDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CustomContainerEventDef>;
};

export type CustomFieldOptionSelectDef = Node & {
  __typename: 'CustomFieldOptionSelectDef';
  customMultiSelectValues: CustomTicketValuesDefConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  metaValue: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  preSelected: Scalars['Boolean']['output'];
  ticketcustomfieldCustomFields: TicketCustomFieldDefConnection;
  ticketcustomvalueSet: CustomTicketValuesDefConnection;
};


export type CustomFieldOptionSelectDefCustomMultiSelectValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomFieldOptionSelectDefTicketcustomfieldCustomFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomFieldOptionSelectDefTicketcustomvalueSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomFieldOptionSelectInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  metaValue: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  preSelected: Scalars['Boolean']['input'];
};

export type CustomFieldTicketTypeInput = {
  connection?: InputMaybe<Scalars['ID']['input']>;
  customFieldId: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomTicketValuesDef = Node & {
  __typename: 'CustomTicketValuesDef';
  customFieldConnection?: Maybe<TicketTypeCustomFieldsConnection>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  multiSelectValue?: Maybe<Array<Maybe<CustomFieldOptionSelectDef>>>;
  numberValue?: Maybe<Scalars['Float']['output']>;
  selectValue?: Maybe<CustomFieldOptionSelectDef>;
  textValue?: Maybe<Scalars['String']['output']>;
  ticket: TicketDef;
};

export type CustomTicketValuesDefConnection = {
  __typename: 'CustomTicketValuesDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomTicketValuesDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomTicketValuesDef` and its cursor. */
export type CustomTicketValuesDefEdge = {
  __typename: 'CustomTicketValuesDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CustomTicketValuesDef>;
};

export type DkdpCompanyCredentialsDef = Node & {
  __typename: 'DKDPCompanyCredentialsDef';
  /** API token for DKDP system */
  apiToken: Scalars['String']['output'];
  certFile: Scalars['String']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isTestEnv: Scalars['Boolean']['output'];
  keyFile: Scalars['String']['output'];
  /** CVR for sender */
  senderCvr: Scalars['String']['output'];
};

export type DkdpCompanyCredentialsMutationInput = {
  /** API token for DKDP system */
  apiToken: Scalars['String']['input'];
  certFile?: InputMaybe<Scalars['Upload']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dkdpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyFile?: InputMaybe<Scalars['Upload']['input']>;
  /** CVR for sender */
  senderCvr: Scalars['String']['input'];
};

export type DkdpCompanyCredentialsMutationPayload = {
  __typename: 'DKDPCompanyCredentialsMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dKDPCompanyCredentials?: Maybe<DkdpCompanyCredentialsDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type DashboardConfig = {
  __typename: 'DashboardConfig';
  place?: Maybe<DashboardConfigPlace>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum DashboardConfigPlace {
  HOME = 'home'
}

export type DashboardDef = Node & {
  __typename: 'DashboardDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  editUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  metabaseId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rolesCanView: CompanyRoleDefConnection;
  updatedAt: Scalars['DateTime']['output'];
  viewUrl?: Maybe<Scalars['String']['output']>;
};


export type DashboardDefRolesCanViewArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DashboardDefConnection = {
  __typename: 'DashboardDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DashboardDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DashboardDef` and its cursor. */
export type DashboardDefEdge = {
  __typename: 'DashboardDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DashboardDef>;
};

export type DateRangeField = {
  __typename: 'DateRangeField';
  lower?: Maybe<Scalars['Date']['output']>;
  upper?: Maybe<Scalars['Date']['output']>;
};

export type DebtorExportDef = Node & {
  __typename: 'DebtorExportDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  error: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: CrmDebtorExportStatusChoices;
};

export type DebtorExportDefConnection = {
  __typename: 'DebtorExportDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DebtorExportDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DebtorExportDef` and its cursor. */
export type DebtorExportDefEdge = {
  __typename: 'DebtorExportDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DebtorExportDef>;
};

export type DeleteAgreementTemplate = {
  __typename: 'DeleteAgreementTemplate';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteAlertRule = {
  __typename: 'DeleteAlertRule';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteArea = {
  __typename: 'DeleteArea';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCard = {
  __typename: 'DeleteCard';
  billingInformation?: Maybe<BillingInformationType>;
};

export type DeleteCollectionCalendar = {
  __typename: 'DeleteCollectionCalendar';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCollectionCalendarDate = {
  __typename: 'DeleteCollectionCalendarDate';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCollectionCalendarDeviation = {
  __typename: 'DeleteCollectionCalendarDeviation';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCollectionCalendarWeek = {
  __typename: 'DeleteCollectionCalendarWeek';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCompanyContainerMetaType = {
  __typename: 'DeleteCompanyContainerMetaType';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteCompanyEmailAccount = {
  __typename: 'DeleteCompanyEmailAccount';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteCompanyEmailAccountMessage = {
  __typename: 'DeleteCompanyEmailAccountMessage';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteCompanyEmailAccountRule = {
  __typename: 'DeleteCompanyEmailAccountRule';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteCompanyMetaType = {
  __typename: 'DeleteCompanyMetaType';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteCompanyRole = {
  __typename: 'DeleteCompanyRole';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteComparisonSettingsMutation = {
  __typename: 'DeleteComparisonSettingsMutation';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteContactProperties = {
  __typename: 'DeleteContactProperties';
  contact?: Maybe<ContactDef>;
  numDeleted: Scalars['Int']['output'];
};

export type DeleteContainer = {
  __typename: 'DeleteContainer';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteContainerGroup = {
  __typename: 'DeleteContainerGroup';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteContainerNotification = {
  __typename: 'DeleteContainerNotification';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteContainerType = {
  __typename: 'DeleteContainerType';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteCostSettingsMutation = {
  __typename: 'DeleteCostSettingsMutation';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteDashboard = {
  __typename: 'DeleteDashboard';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteDepot = {
  __typename: 'DeleteDepot';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteDocument = {
  __typename: 'DeleteDocument';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteDriver = {
  __typename: 'DeleteDriver';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteExportTemplate = {
  __typename: 'DeleteExportTemplate';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteFcmDevice = {
  __typename: 'DeleteFCMDevice';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteHauler = {
  __typename: 'DeleteHauler';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteHaulerPrice = {
  __typename: 'DeleteHaulerPrice';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteImportHistory = {
  __typename: 'DeleteImportHistory';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteIntegrationGpsVehicle = {
  __typename: 'DeleteIntegrationGpsVehicle';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteMeasurementSettings = {
  __typename: 'DeleteMeasurementSettings';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteNotificationSetting = {
  __typename: 'DeleteNotificationSetting';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeletePickupSettingsMutation = {
  __typename: 'DeletePickupSettingsMutation';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteProject = {
  __typename: 'DeleteProject';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteProperty = {
  __typename: 'DeleteProperty';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeletePropertyContact = {
  __typename: 'DeletePropertyContact';
  propertyContact?: Maybe<PropertyContactDef>;
};

export type DeletePropertyGroup = {
  __typename: 'DeletePropertyGroup';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeletePropertyType = {
  __typename: 'DeletePropertyType';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteScheduledExport = {
  __typename: 'DeleteScheduledExport';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteService = {
  __typename: 'DeleteService';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteTeam = {
  __typename: 'DeleteTeam';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteTicketCategory = {
  __typename: 'DeleteTicketCategory';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteTicketCustomFieldMutation = {
  __typename: 'DeleteTicketCustomFieldMutation';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteTicketMessageTemplate = {
  __typename: 'DeleteTicketMessageTemplate';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteTicketType = {
  __typename: 'DeleteTicketType';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteUser = {
  __typename: 'DeleteUser';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteUserAbsence = {
  __typename: 'DeleteUserAbsence';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteUserProject = {
  __typename: 'DeleteUserProject';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteVehicle = {
  __typename: 'DeleteVehicle';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteVehicleAbsence = {
  __typename: 'DeleteVehicleAbsence';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteVehicleType = {
  __typename: 'DeleteVehicleType';
  vehicleType?: Maybe<VehicleTypeType>;
};

export type DeleteWasteCollector = {
  __typename: 'DeleteWasteCollector';
  wasteCollector?: Maybe<WasteCollectorType>;
};

export type DeleteWasteFraction = {
  __typename: 'DeleteWasteFraction';
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteWasteType = {
  __typename: 'DeleteWasteType';
  status?: Maybe<Scalars['String']['output']>;
};

export type DemoInvitation = {
  __typename: 'DemoInvitation';
  status?: Maybe<Scalars['String']['output']>;
};

/** Describes the Depot, has relation to :model:`core.Company` and to :model:`core.Location` */
export type DepotType = Node & {
  __typename: 'DepotType';
  /** Current stopping place */
  activeVehicles: VehicleTypeConnection;
  amountOfTrucks?: Maybe<Scalars['Int']['output']>;
  capacityOfTrucks?: Maybe<Scalars['Int']['output']>;
  /** End of working period */
  closingTime?: Maybe<Scalars['Time']['output']>;
  /** Hex color code */
  color?: Maybe<Scalars['String']['output']>;
  /** Relation to the Comment */
  comments: CommentTypeConnection;
  /** Company of this Depot */
  company: CompanyType;
  /** Contact in this Depot */
  contactPerson?: Maybe<ContactPersonType>;
  containerSet: ContainerTypeConnection;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  depotType?: Maybe<Scalars['String']['output']>;
  depots: PickUpOrderTypeConnection;
  /** Description of the depot */
  description?: Maybe<Scalars['String']['output']>;
  /** Depot as a end location */
  endLocation: DriverTypeConnection;
  /** Radius of geofence in meters */
  geofenceRadius?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Location where Depot is situated */
  location: LocationWithContainerType;
  logo?: Maybe<Scalars['String']['output']>;
  /** Depot's naming */
  name: Scalars['String']['output'];
  /** Start of working period */
  openingTime?: Maybe<Scalars['Time']['output']>;
  routeSettings: RouteSettingsTypeConnection;
  /** Depot as a start location */
  startLocation: DriverTypeConnection;
  /**
   *
   *             Indicates if the depot is open or closed. Not enough data is signified by the 'unknown' status.
   *             Can hold one of the following string values: 'open', 'closed', 'unknown'
   *
   */
  status?: Maybe<Scalars['String']['output']>;
  /** Time to leave the facility */
  timeToLeaveTheFacility?: Maybe<Scalars['Time']['output']>;
  /** Time to unload truck */
  timeToUnloadTruck?: Maybe<Scalars['Time']['output']>;
  /** Stopping places */
  vehicles: VehicleTypeConnection;
  /** Relation to the WasteFraction */
  wasteFractions: WasteFractionTypeConnection;
  workshopServiceDescription?: Maybe<Scalars['String']['output']>;
  workshopType?: Maybe<Scalars['String']['output']>;
};


/** Describes the Depot, has relation to :model:`core.Company` and to :model:`core.Location` */
export type DepotTypeActiveVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes the Depot, has relation to :model:`core.Company` and to :model:`core.Location` */
export type DepotTypeCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes the Depot, has relation to :model:`core.Company` and to :model:`core.Location` */
export type DepotTypeContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes the Depot, has relation to :model:`core.Company` and to :model:`core.Location` */
export type DepotTypeDepotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes the Depot, has relation to :model:`core.Company` and to :model:`core.Location` */
export type DepotTypeEndLocationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes the Depot, has relation to :model:`core.Company` and to :model:`core.Location` */
export type DepotTypeRouteSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes the Depot, has relation to :model:`core.Company` and to :model:`core.Location` */
export type DepotTypeStartLocationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes the Depot, has relation to :model:`core.Company` and to :model:`core.Location` */
export type DepotTypeVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes the Depot, has relation to :model:`core.Company` and to :model:`core.Location` */
export type DepotTypeWasteFractionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DepotTypeConnection = {
  __typename: 'DepotTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DepotTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DepotType` and its cursor. */
export type DepotTypeEdge = {
  __typename: 'DepotTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DepotType>;
};

export enum DescriptionActionEnum {
  APPEND = 'APPEND',
  REPLACE = 'REPLACE'
}

export type DetachSensorMutation = {
  __typename: 'DetachSensorMutation';
  status?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum DeviceContainerBarcodeGenerationHistoryStatusChoices {
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Failed */
  FAILED = 'FAILED',
  /** In Progress */
  IN_PROGRESS = 'IN_PROGRESS'
}

export enum DeviceContainerCustomEventTypesGraphene {
  PRINT_LABEL = 'PRINT_LABEL'
}

export enum DeviceContainerEventTypesGraphene {
  COLLECTION_CALENDAR_CHANGE = 'COLLECTION_CALENDAR_CHANGE',
  CONTAINER_ID_CHANGE = 'CONTAINER_ID_CHANGE',
  CONTAINER_TYPE_CHANGE = 'CONTAINER_TYPE_CHANGE',
  DESCRIPTION_CHANGE = 'DESCRIPTION_CHANGE',
  LOCATION_CHANGE = 'LOCATION_CHANGE',
  PICKUP_METHOD_CHANGE = 'PICKUP_METHOD_CHANGE',
  PICKUP_SETTING_CHANGE = 'PICKUP_SETTING_CHANGE',
  ROUTE_SCHEME_CHANGE = 'ROUTE_SCHEME_CHANGE',
  SENSOR_FILL_LEVEL_CHANGE = 'SENSOR_FILL_LEVEL_CHANGE',
  STATUS_CHANGE = 'STATUS_CHANGE',
  WASTE_FRACTION_CHANGE = 'WASTE_FRACTION_CHANGE'
}

/** An enumeration. */
export enum DeviceContainerIdGeneratorSettingsCompanyDisplayOptionChoices {
  /** First 2 Letters */
  FIRST_TWO = 'FIRST_TWO',
  /** Full */
  FULL = 'FULL',
  /** Slug */
  SLUG = 'SLUG'
}

/** An enumeration. */
export enum DeviceContainerIdGeneratorSettingsContainerTypeDisplayOptionChoices {
  /** First 2 Letters */
  FIRST_TWO = 'FIRST_TWO',
  /** Full */
  FULL = 'FULL',
  /** Slug */
  SLUG = 'SLUG'
}

/** An enumeration. */
export enum DeviceContainerIdGeneratorSettingsPickupSettingsDisplayOptionChoices {
  /** First 2 Letters */
  FIRST_TWO = 'FIRST_TWO',
  /** Full */
  FULL = 'FULL',
  /** Slug */
  SLUG = 'SLUG'
}

/** An enumeration. */
export enum DeviceContainerIdGeneratorSettingsWasteFractionDisplayOptionChoices {
  /** First 2 Letters */
  FIRST_TWO = 'FIRST_TWO',
  /** Full */
  FULL = 'FULL',
  /** Slug */
  SLUG = 'SLUG'
}

/** An enumeration. */
export enum DeviceContainerPickupMethodChoices {
  /** Dynamic */
  DYNAMIC = 'DYNAMIC',
  /** Static */
  STATIC = 'STATIC'
}

/** An enumeration. */
export enum DeviceContainerStatusChoices {
  /** Available */
  AVAILABLE = 'AVAILABLE',
  /** Defect */
  DEFECT = 'DEFECT',
  /** Ended */
  ENDED = 'ENDED',
  /** Future */
  FUTURE = 'FUTURE',
  /** In storage */
  IN_STORAGE = 'IN_STORAGE',
  /** In transit */
  IN_TRANSIT = 'IN_TRANSIT',
  /** On hold */
  ON_HOLD = 'ON_HOLD'
}

/** An enumeration. */
export enum DeviceLorawanDeviceAntennaChoices {
  /** external */
  EXTERNAL = 'EXTERNAL',
  /** internal */
  INTERNAL = 'INTERNAL',
  /** undefined */
  UNDEFINED = 'UNDEFINED'
}

/** An enumeration. */
export enum DeviceMobileDeviceAntennaChoices {
  /** external */
  EXTERNAL = 'EXTERNAL',
  /** internal */
  INTERNAL = 'INTERNAL',
  /** undefined */
  UNDEFINED = 'UNDEFINED'
}

/** An enumeration. */
export enum DeviceSigFoxDeviceAntennaChoices {
  /** external */
  EXTERNAL = 'EXTERNAL',
  /** internal */
  INTERNAL = 'INTERNAL',
  /** undefined */
  UNDEFINED = 'UNDEFINED'
}

export type DeviceToContainerInputObject = {
  customMeasurement?: InputMaybe<Scalars['Boolean']['input']>;
  deviceAngle?: InputMaybe<Scalars['Float']['input']>;
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  internalHeight?: InputMaybe<Scalars['Float']['input']>;
  lid?: InputMaybe<Scalars['String']['input']>;
  measurementId?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type DeviceToContainerType = Node & {
  __typename: 'DeviceToContainerType';
  container: ContainerType;
  device?: Maybe<BaseDeviceInterface>;
  deviceAngle?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  filllevelmeasurementSet?: Maybe<FillLevelMeasurementTypeConnection>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  internalHeight: Scalars['Float']['output'];
  measurementSettings?: Maybe<MeasurementSettingsType>;
  notificationSet: NotificationsTypeConnection;
  objectId: Scalars['Int']['output'];
  offset: Scalars['Float']['output'];
  sideLid: Scalars['Boolean']['output'];
  startDate: Scalars['DateTime']['output'];
  topLid: Scalars['Boolean']['output'];
};


export type DeviceToContainerTypeFilllevelmeasurementSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  fillLevelFromLastestMonth?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type DeviceToContainerTypeNotificationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status_Iexact?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceToContainerTypeConnection = {
  __typename: 'DeviceToContainerTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DeviceToContainerTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DeviceToContainerType` and its cursor. */
export type DeviceToContainerTypeEdge = {
  __typename: 'DeviceToContainerTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DeviceToContainerType>;
};

export type DeviceTypeType = Node & {
  __typename: 'DeviceTypeType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  lorawandeviceSet: LorawanDeviceTypeConnection;
  maxFillLevel: Scalars['Float']['output'];
  minFillLevel: Scalars['Float']['output'];
  mobiledeviceSet: MobileDeviceTypeConnection;
  name: Scalars['String']['output'];
  sigfoxdeviceSet: SigFoxDeviceTypeConnection;
};


export type DeviceTypeTypeLorawandeviceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  devId_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type DeviceTypeTypeMobiledeviceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  devId_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type DeviceTypeTypeSigfoxdeviceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  devId_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DeviceTypeTypeConnection = {
  __typename: 'DeviceTypeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DeviceTypeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DeviceTypeType` and its cursor. */
export type DeviceTypeTypeEdge = {
  __typename: 'DeviceTypeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DeviceTypeType>;
};

export type DirectFileUploadInput = {
  /** The name of the file with an extension. */
  fileName: Scalars['String']['input'];
  /** The size of the file in bytes. */
  fileSize: Scalars['Int']['input'];
  /** The MIME type of the file. */
  mimeType: Scalars['String']['input'];
};

export type DirectFileUploadStartInput = {
  /** The list of files to upload. */
  attachments: Array<InputMaybe<DirectFileUploadInput>>;
  /** The type of the object to which the file will be attached. */
  objectToAttachType: DirectUploadObjectTypes;
};

export type DirectFileUploadUrlDef = {
  __typename: 'DirectFileUploadUrlDef';
  contentType: Scalars['String']['output'];
  fileId: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type DirectImportUploadStartInput = {
  /** The list of files to upload. */
  attachments: DirectFileUploadInput;
};

export enum DirectUploadObjectTypes {
  COMPANY_EMAIL_ACCOUNT_MESSAGE_ATTACHMENT = 'COMPANY_EMAIL_ACCOUNT_MESSAGE_ATTACHMENT',
  PORTAL_FONT_ATTACHMENT = 'PORTAL_FONT_ATTACHMENT',
  PORTAL_LOGO_ATTACHMENT = 'PORTAL_LOGO_ATTACHMENT',
  PORTAL_SECTION_BACKGROUND_ATTACHMENT = 'PORTAL_SECTION_BACKGROUND_ATTACHMENT',
  TICKET_ATTACHMENT = 'TICKET_ATTACHMENT',
  TICKET_COMMENT_ATTACHMENT = 'TICKET_COMMENT_ATTACHMENT',
  TICKET_MESSAGE_ATTACHMENT = 'TICKET_MESSAGE_ATTACHMENT'
}

export type DirectionMatrixType = {
  __typename: 'DirectionMatrixType';
  destination: LocationWithContainerType;
  /** Real distance in meters */
  distance: Scalars['Int']['output'];
  /** Real duration in seconds */
  duration: Scalars['Float']['output'];
  /** Math generated duration in seconds */
  hardDuration: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  origin: LocationWithContainerType;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename: 'DjangoDebug';
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String']['output'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float']['output'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']['output']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean']['output'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean']['output'];
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']['output']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String']['output'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String']['output'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']['output']>;
  /** Start time of this database query. */
  startTime: Scalars['Float']['output'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float']['output'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']['output']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']['output']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String']['output'];
};

export type DocumentDef = Node & {
  __typename: 'DocumentDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DocumentDefConnection = {
  __typename: 'DocumentDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DocumentDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DocumentDef` and its cursor. */
export type DocumentDefEdge = {
  __typename: 'DocumentDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DocumentDef>;
};

export type DriverCostInput = {
  costPerHour?: InputMaybe<Scalars['Decimal']['input']>;
  costPerHourForOvertime?: InputMaybe<Scalars['Decimal']['input']>;
  costPerInterval?: InputMaybe<Scalars['Decimal']['input']>;
  isFixedCost: Scalars['Boolean']['input'];
  paymentInterval?: InputMaybe<Scalars['String']['input']>;
};

/** Describes DriverCost model */
export type DriverCostType = Node & {
  __typename: 'DriverCostType';
  /** Driver cost per hour */
  costPerHour?: Maybe<Scalars['Decimal']['output']>;
  /** Driver cost per hour for overtime */
  costPerHourForOvertime?: Maybe<Scalars['Decimal']['output']>;
  /** Driver cost per time interval */
  costPerInterval?: Maybe<Scalars['Decimal']['output']>;
  driver?: Maybe<DriverType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** If False - driver has payment per hour, if True - fixed cost per month */
  isFixedCost: Scalars['Boolean']['output'];
  paymentInterval?: Maybe<Scalars['String']['output']>;
};

export type DriverGeoJsonType = {
  __typename: 'DriverGeoJSONType';
  driver?: Maybe<DriverType>;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  geoJson?: Maybe<GeoJsonType>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<VehicleType>;
};

export type DriverPushNotificationStatusType = Node & {
  __typename: 'DriverPushNotificationStatusType';
  confirmedAt?: Maybe<Scalars['DateTime']['output']>;
  driver: DriverType;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  isReceived: Scalars['Boolean']['output'];
  notification: DriverPushNotificationType;
  /** Vehicle that the driver was in when the notification was sent */
  vehicle?: Maybe<VehicleType>;
};

export type DriverPushNotificationStatusTypeConnection = {
  __typename: 'DriverPushNotificationStatusTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverPushNotificationStatusTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DriverPushNotificationStatusType` and its cursor. */
export type DriverPushNotificationStatusTypeEdge = {
  __typename: 'DriverPushNotificationStatusTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriverPushNotificationStatusType>;
};

export type DriverPushNotificationType = Node & {
  __typename: 'DriverPushNotificationType';
  confirmedByMe?: Maybe<Scalars['Boolean']['output']>;
  confirmedCount?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  errorAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  notificationStatuses?: Maybe<Array<Maybe<DriverPushNotificationStatusType>>>;
  notificationType: NotificationsDriverPushNotificationNotificationTypeChoices;
  payload?: Maybe<Scalars['JSONString']['output']>;
  pendingCount?: Maybe<Scalars['Int']['output']>;
  receiver: DriverTypeConnection;
  route?: Maybe<RouteType>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  sentBy?: Maybe<UserType>;
  sentStatus?: Maybe<Scalars['Boolean']['output']>;
  stops?: Maybe<Array<Maybe<PickUpOrderType>>>;
  title: Scalars['String']['output'];
};


export type DriverPushNotificationTypeReceiverArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DriverPushNotificationTypeConnection = {
  __typename: 'DriverPushNotificationTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverPushNotificationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DriverPushNotificationType` and its cursor. */
export type DriverPushNotificationTypeEdge = {
  __typename: 'DriverPushNotificationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriverPushNotificationType>;
};

/** Stores information about GeoArea */
export type DriverServiceType = Node & {
  __typename: 'DriverServiceType';
  /** Relation to Service */
  drivers: DriverTypeConnection;
  geofenceArea: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};


/** Stores information about GeoArea */
export type DriverServiceTypeDriversArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Stores relations between :model:`Driver` and :model:`Skill` */
export type DriverSkillType = Node & {
  __typename: 'DriverSkillType';
  /** Relation to Driver model */
  driver: DriverType;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Relation to Skill model */
  skill: SkillType;
  /** Valid from date */
  validFrom: Scalars['Date']['output'];
  /** Valid to date */
  validTo: Scalars['Date']['output'];
};

export type DriverSkillTypeConnection = {
  __typename: 'DriverSkillTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverSkillTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DriverSkillType` and its cursor. */
export type DriverSkillTypeEdge = {
  __typename: 'DriverSkillTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriverSkillType>;
};

export type DriverSummaryCardType = {
  __typename: 'DriverSummaryCardType';
  drivenDistanceMtd?: Maybe<Scalars['Int']['output']>;
  drivenTimeMtd?: Maybe<Scalars['Int']['output']>;
  routesMtd?: Maybe<Scalars['Int']['output']>;
  ticketsCreated?: Maybe<Scalars['Int']['output']>;
  ticketsCreatedLast30Days?: Maybe<Scalars['Int']['output']>;
  ticketsCreatedToday?: Maybe<Scalars['Int']['output']>;
};

/** Stores information about Driver */
export type DriverType = Node & {
  __typename: 'DriverType';
  /** Shows, how many hours Driver can work per day */
  allowedMaximumDailyHours?: Maybe<Scalars['Int']['output']>;
  /** Shows, how many hours Driver can work per week */
  allowedMaximumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  /** Allowed maximum working days a month */
  allowedMaximumWorkingDaysAMonth?: Maybe<Scalars['Int']['output']>;
  allowedOvertime?: Maybe<Scalars['String']['output']>;
  breakDuration?: Maybe<Scalars['String']['output']>;
  /** Time, when driver have scheduled break */
  breakTimeEnd?: Maybe<Scalars['Time']['output']>;
  /** Time, when driver have scheduled break */
  breakTimeStart?: Maybe<Scalars['Time']['output']>;
  collectedBins?: Maybe<Scalars['Int']['output']>;
  /** Relation to the Comment */
  comments: CommentTypeConnection;
  /** Relation to Cost */
  cost?: Maybe<DriverCostType>;
  currentRoute?: Maybe<RouteType>;
  /** Driver can have many days available (add them comma-separated) */
  daysAvailable?: Maybe<Array<Scalars['String']['output']>>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** Relation to Driver model */
  driverSkills: DriverSkillTypeConnection;
  /** Driver speed */
  driverSpeed: FleetManagementDriverDriverSpeedChoices;
  driverType: FleetManagementDriverDriverTypeChoices;
  driverpushnotificationSet: DriverPushNotificationTypeConnection;
  /** External driver id */
  employeeNumber?: Maybe<Scalars['String']['output']>;
  /** Depot as a end location */
  endLocation?: Maybe<DepotType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Latest location of the driver */
  latestLocation?: Maybe<RouteLocationHistoryDef>;
  /** Relation to License */
  license?: Maybe<LicenseType>;
  /** Limit per route distance */
  limitPerRouteDistance?: Maybe<Scalars['Float']['output']>;
  /** Relation to Mobile App */
  mobileApps: MobileAppTypeConnection;
  /** Navigation for user */
  navigationApplicationPreference?: Maybe<Scalars['String']['output']>;
  nextRoute?: Maybe<RouteType>;
  /** Primary vehicle for this driver */
  primaryVehicle?: Maybe<VehicleType>;
  pushNotifications: DriverPushNotificationStatusTypeConnection;
  /** Drivers that drives the vehicle on the route */
  routeVehicle: RouteVehicleTypeConnection;
  /** Driver to whom the session belongs */
  routeVehicleSessions: RouteVehicleSessionTypeConnection;
  routelocationhistorySet: RouteLocationHistoryDefConnection;
  /** Relation to Service */
  service?: Maybe<DriverServiceType>;
  skills: SkillTypeConnection;
  /** Depot as a start location */
  startLocation?: Maybe<DepotType>;
  /**
   *
   *             Indicates if the driver is working or not. Not enough data is signified by the 'unknown' status.
   *             Can hold one of the following string values: 'unknown', 'working', 'not-working'
   *
   */
  status?: Maybe<Scalars['String']['output']>;
  ticketSet: TicketDefConnection;
  /** Relation to User */
  user: UserType;
  /** Vehicles a driver has access to. For assistant drivers this field can be empty. */
  vehicles: VehicleTypeConnection;
  /** Time, when driver ended to work */
  workTimeEnd?: Maybe<Scalars['Time']['output']>;
  /** Time, when driver started to work */
  workTimeStart?: Maybe<Scalars['Time']['output']>;
};


/** Stores information about Driver */
export type DriverTypeCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Stores information about Driver */
export type DriverTypeDriverSkillsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Stores information about Driver */
export type DriverTypeDriverpushnotificationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Stores information about Driver */
export type DriverTypeMobileAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Stores information about Driver */
export type DriverTypePushNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Stores information about Driver */
export type DriverTypeRouteVehicleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Stores information about Driver */
export type DriverTypeRouteVehicleSessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Stores information about Driver */
export type DriverTypeRoutelocationhistorySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Stores information about Driver */
export type DriverTypeSkillsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Stores information about Driver */
export type DriverTypeTicketSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Stores information about Driver */
export type DriverTypeVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DriverTypeConnection = {
  __typename: 'DriverTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DriverType` and its cursor. */
export type DriverTypeEdge = {
  __typename: 'DriverTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriverType>;
};

export type EmailNotificationMethodDef = Node & {
  __typename: 'EmailNotificationMethodDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  notificationmethodPtr: NotificationMethodDef;
};

export type ErrorType = {
  __typename: 'ErrorType';
  field: Scalars['String']['output'];
  messages: Array<Scalars['String']['output']>;
};

/** An enumeration. */
export enum EventTicketEventEventTypeChoices {
  /** Status changed */
  A_1 = 'A_1',
  /** Assignee changed */
  A_2 = 'A_2',
  /** Priority changed */
  A_3 = 'A_3',
  /** Assigned team changed */
  A_4 = 'A_4',
  /** Modified */
  A_10 = 'A_10',
  /** Scan barcode */
  A_11 = 'A_11',
  /** Print label */
  A_12 = 'A_12',
  /** Pair RFID */
  A_13 = 'A_13',
  /** Associated message linked */
  A_20 = 'A_20',
  /** Associated message unlinked */
  A_21 = 'A_21'
}

/** An enumeration. */
export enum EventTicketEventFromPriorityChoices {
  /** None */
  A_0 = 'A_0',
  /** Log */
  A_1 = 'A_1',
  /** Medium */
  A_2 = 'A_2',
  /** High */
  A_3 = 'A_3',
  /** Critical */
  A_4 = 'A_4'
}

/** An enumeration. */
export enum EventTicketEventFromStatusChoices {
  /** Created */
  A_1 = 'A_1',
  /** Open */
  A_2 = 'A_2',
  /** Pending */
  A_3 = 'A_3',
  /** On hold */
  A_4 = 'A_4',
  /** In progress */
  A_5 = 'A_5',
  /** Completed */
  A_6 = 'A_6',
  /** Rejected */
  A_7 = 'A_7'
}

/** An enumeration. */
export enum EventTicketEventToPriorityChoices {
  /** None */
  A_0 = 'A_0',
  /** Log */
  A_1 = 'A_1',
  /** Medium */
  A_2 = 'A_2',
  /** High */
  A_3 = 'A_3',
  /** Critical */
  A_4 = 'A_4'
}

/** An enumeration. */
export enum EventTicketEventToStatusChoices {
  /** Created */
  A_1 = 'A_1',
  /** Open */
  A_2 = 'A_2',
  /** Pending */
  A_3 = 'A_3',
  /** On hold */
  A_4 = 'A_4',
  /** In progress */
  A_5 = 'A_5',
  /** Completed */
  A_6 = 'A_6',
  /** Rejected */
  A_7 = 'A_7'
}

export type ExportBillingRunToNavision = {
  __typename: 'ExportBillingRunToNavision';
  billingRun?: Maybe<BillingRunDef>;
};

export type ExportConfigDef = {
  __typename: 'ExportConfigDef';
  allowedExports?: Maybe<Array<Maybe<ExportConfigExportDef>>>;
};

export type ExportConfigExportDef = {
  __typename: 'ExportConfigExportDef';
  allowFuture?: Maybe<Scalars['Boolean']['output']>;
  allowPast?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['Boolean']['output']>;
  exportType?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<ExportConfigExportFieldDef>>>;
  startDate?: Maybe<Scalars['Boolean']['output']>;
};

export type ExportConfigExportFieldDef = {
  __typename: 'ExportConfigExportFieldDef';
  helpText?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ExportDebtorData = {
  __typename: 'ExportDebtorData';
  export?: Maybe<DebtorExportDef>;
};

/**
 *
 *     Choices that represent the all filters for ExportTemplate (ExportField)
 *
 */
export enum ExportFieldOperatorsTypes {
  CONTAINS = 'CONTAINS',
  ENDS_WITH = 'ENDS_WITH',
  EQUAL = 'EQUAL',
  GREATER_EQUAL_THAN = 'GREATER_EQUAL_THAN',
  GREATER_THAN = 'GREATER_THAN',
  IN_LIST = 'IN_LIST',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
  IS_NOT_NULL = 'IS_NOT_NULL',
  IS_NULL = 'IS_NULL',
  LESS_EQUAL_THAN = 'LESS_EQUAL_THAN',
  LESS_THAN = 'LESS_THAN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  NOT_EQUAL = 'NOT_EQUAL',
  NOT_IN_LIST = 'NOT_IN_LIST',
  STARTS_WITH = 'STARTS_WITH'
}

/** Fields for export by export type */
export type ExportFieldType = {
  __typename: 'ExportFieldType';
  column: Scalars['String']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  operators: Array<Maybe<ExportFieldOperatorsTypes>>;
  type: Scalars['String']['output'];
};

/** Filter for export */
export type ExportFilterType = {
  __typename: 'ExportFilterType';
  field: Scalars['String']['output'];
  operator: ExportFieldOperatorsTypes;
  value?: Maybe<Scalars['GenericScalar']['output']>;
};

/** Filter for export input type */
export type ExportFilterTypeInput = {
  field: Scalars['String']['input'];
  operator: ExportFieldOperatorsTypes;
  value?: InputMaybe<Scalars['GenericScalar']['input']>;
};

/** Filters for export by export type */
export type ExportFiltersSectionType = {
  __typename: 'ExportFiltersSectionType';
  /** Related export model */
  exportModel: AllExportTypes;
  /** Fields for filters */
  fields: Array<Maybe<ExportFieldType>>;
};

/**
 *
 *     Formats that can be used in ExportTemplate
 *
 */
export enum ExportFormats {
  CSV = 'CSV',
  XLSX = 'XLSX'
}

/** Export history */
export type ExportHistoryDef = Node & {
  __typename: 'ExportHistoryDef';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  export: ExportTemplateDef;
  externalRecipients: Array<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  internalRecipients: UserTypeConnection;
  progress: Scalars['Int']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: ExportImportExportHistoryStatusChoices;
  totalItems?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<UserType>;
};


/** Export history */
export type ExportHistoryDefInternalRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ExportHistoryDefConnection = {
  __typename: 'ExportHistoryDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExportHistoryDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ExportHistoryDef` and its cursor. */
export type ExportHistoryDefEdge = {
  __typename: 'ExportHistoryDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ExportHistoryDef>;
};

/**
 *
 *     Statuses that can be used in ExportHistory
 *
 */
export enum ExportHistoryStatuses {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW'
}

/** An enumeration. */
export enum ExportImportExportHistoryStatusChoices {
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Failed */
  FAILED = 'FAILED',
  /** In Progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** New */
  NEW = 'NEW'
}

/** An enumeration. */
export enum ExportImportImportHistoryFileDelimiterChoices {
  /** Comma */
  COMMA = 'COMMA',
  /** Pipe */
  PIPE = 'PIPE',
  /** Semicolon */
  SEMICOLON = 'SEMICOLON',
  /** Tab */
  TAB = 'TAB'
}

/** An enumeration. */
export enum ExportIntervalEnum {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY'
}

/** Export template */
export type ExportTemplateDef = Node & {
  __typename: 'ExportTemplateDef';
  automaticallySendExportToExternalRecepients?: Maybe<Scalars['Boolean']['output']>;
  automaticallySendExportToInternalRecepients?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: UserType;
  /** Total items for export */
  currentTotalItems?: Maybe<Scalars['Int']['output']>;
  dailyIntervalStartTime?: Maybe<Scalars['Time']['output']>;
  dailyIntervalStartingFrom?: Maybe<Scalars['Date']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** External recipients */
  exportExternalRecipients?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  exportFormat: ExportFormats;
  /** Export history */
  exportHistory?: Maybe<ExportHistoryDefConnection>;
  /** Internal recipients */
  exportInternalRecipients?: Maybe<UserTypeConnection>;
  exportIntervalSettings?: Maybe<ExportIntervalEnum>;
  exportIntervalValue?: Maybe<Scalars['Int']['output']>;
  exportType: ExportTypes;
  /** External recipients */
  externalRecipients?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Fields for export */
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Filters for export */
  filters?: Maybe<Array<Maybe<ExportFilterType>>>;
  frozen: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Internal recipients */
  internalRecipients?: Maybe<UserTypeConnection>;
  isNestedFields: Scalars['Boolean']['output'];
  /** Latest export count */
  latestExportCount?: Maybe<Scalars['Int']['output']>;
  /** Latest exported at */
  latestExportedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Latest exported by */
  latestExportedBy?: Maybe<UserType>;
  /** Latest progress */
  latestProgress?: Maybe<Scalars['Int']['output']>;
  /** Latest status */
  latestStatus?: Maybe<ExportHistoryStatuses>;
  /** Map segments for export */
  mapSegments?: Maybe<Scalars['GenericScalar']['output']>;
  monthlyIntervalSetting?: Maybe<MonthlyIntervalEnum>;
  name: Scalars['String']['output'];
  projects: ProjectTypeConnection;
  /** Flag for scheduled exports to send emails to external recipients */
  scheduleExportSendToExternalRecipients: Scalars['Boolean']['output'];
  /** Flag for scheduled exports to send emails to internal recipients */
  scheduleExportSendToInternalRecipients: Scalars['Boolean']['output'];
  scheduledExportExternalRecipients: Array<Scalars['String']['output']>;
  scheduledExportInternalRecipients: UserTypeConnection;
  updatedAt: Scalars['DateTime']['output'];
};


/** Export template */
export type ExportTemplateDefExportHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  exportType?: InputMaybe<Array<InputMaybe<ExportTypes>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Array<InputMaybe<ExportHistoryStatuses>>>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


/** Export template */
export type ExportTemplateDefExportInternalRecipientsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDriver?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


/** Export template */
export type ExportTemplateDefInternalRecipientsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDriver?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


/** Export template */
export type ExportTemplateDefProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Export template */
export type ExportTemplateDefScheduledExportInternalRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ExportTemplateDefConnection = {
  __typename: 'ExportTemplateDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExportTemplateDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ExportTemplateDef` and its cursor. */
export type ExportTemplateDefEdge = {
  __typename: 'ExportTemplateDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ExportTemplateDef>;
};

export type ExportTemplateScheduleSettingsUpdate = {
  __typename: 'ExportTemplateScheduleSettingsUpdate';
  exportTemplate?: Maybe<ExportTemplateDef>;
};

/** Total items for export */
export type ExportTemplateTotalItems = {
  __typename: 'ExportTemplateTotalItems';
  totalItems: Scalars['Int']['output'];
};

export type ExportType = Node & {
  __typename: 'ExportType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  excelFormat: Scalars['Boolean']['output'];
  exportType: CoreExportExportTypeChoices;
  file?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  progressPercent: Scalars['Int']['output'];
  status: CoreExportStatusChoices;
};

export type ExportTypeConnection = {
  __typename: 'ExportTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExportTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ExportType` and its cursor. */
export type ExportTypeEdge = {
  __typename: 'ExportTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ExportType>;
};

/**
 *
 *     Types that can be used in ExportTemplate
 *     Database value should represent model name
 *
 */
export enum ExportTypes {
  AGREEMENT = 'AGREEMENT',
  COLLECTION_CALENDAR = 'COLLECTION_CALENDAR',
  COLLECTION_CALENDAR_DATE = 'COLLECTION_CALENDAR_DATE',
  CONTAINER = 'CONTAINER',
  CONTAINER_TYPE = 'CONTAINER_TYPE',
  HAULER_PRICE = 'HAULER_PRICE',
  PICKUP_ORDER = 'PICKUP_ORDER',
  PICKUP_SETTING = 'PICKUP_SETTING',
  PRODUCT = 'PRODUCT',
  PROPERTY = 'PROPERTY',
  ROUTE = 'ROUTE',
  SERVICE = 'SERVICE',
  TICKET = 'TICKET',
  TICKET_TYPE = 'TICKET_TYPE',
  VEHICLE = 'VEHICLE',
  WASTE_FRACTION = 'WASTE_FRACTION'
}

export type FcmDeviceType = Node & {
  __typename: 'FCMDeviceType';
  /** Inactive devices will not be sent notifications */
  active: Scalars['Boolean']['output'];
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** Unique device identifier */
  deviceId?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  registrationId: Scalars['String']['output'];
  type: FcmDjangoFcmDeviceTypeChoices;
  user?: Maybe<UserType>;
};

export type FcmDeviceTypeConnection = {
  __typename: 'FCMDeviceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FcmDeviceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FCMDeviceType` and its cursor. */
export type FcmDeviceTypeEdge = {
  __typename: 'FCMDeviceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FcmDeviceType>;
};

/** An enumeration. */
export enum FcmDjangoFcmDeviceTypeChoices {
  /** android */
  ANDROID = 'ANDROID',
  /** ios */
  IOS = 'IOS',
  /** web */
  WEB = 'WEB'
}

/** An enumeration. */
export enum FieldTypeEnum {
  CUSTOM_TICKET_FIELD = 'CUSTOM_TICKET_FIELD',
  META_FIELD = 'META_FIELD',
  STATIC = 'STATIC'
}

export type FileListType = {
  __typename: 'FileListType';
  currentPath?: Maybe<Scalars['String']['output']>;
  dirs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  files?: Maybe<Array<Maybe<FileObjectType>>>;
};

export type FileObjectType = {
  __typename: 'FileObjectType';
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FillLevelMeasurementType = Node & {
  __typename: 'FillLevelMeasurementType';
  batteryLevel?: Maybe<Scalars['Float']['output']>;
  batteryPercentage?: Maybe<Scalars['Float']['output']>;
  containerDevice?: Maybe<DeviceToContainerType>;
  createdAt: Scalars['DateTime']['output'];
  devId?: Maybe<Scalars['String']['output']>;
  /** m */
  fillLevel: Scalars['Float']['output'];
  /** % */
  fillPercentage: Scalars['Float']['output'];
  /** @deprecated No longer used */
  fillPercentageMasked?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  measurement?: Maybe<ContainerType>;
  /** m */
  rawMeasurements?: Maybe<Array<Scalars['Float']['output']>>;
  rawMeasurementsPercentage?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  rsi?: Maybe<Scalars['Float']['output']>;
  /** Temperature in degree celsius */
  temperature?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FillLevelMeasurementTypeConnection = {
  __typename: 'FillLevelMeasurementTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FillLevelMeasurementTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FillLevelMeasurementType` and its cursor. */
export type FillLevelMeasurementTypeEdge = {
  __typename: 'FillLevelMeasurementTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FillLevelMeasurementType>;
};

/** An enumeration. */
export enum FleetManagementDriverDriverSpeedChoices {
  /** 2x faster */
  A_2_X_FASTER = 'A_2X_FASTER',
  /** 2x slower */
  A_2_X_SLOWER = 'A_2X_SLOWER',
  /** 3x faster */
  A_3_X_FASTER = 'A_3X_FASTER',
  /** 3x slower */
  A_3_X_SLOWER = 'A_3X_SLOWER',
  /** Normal */
  NORMAL = 'NORMAL'
}

/** An enumeration. */
export enum FleetManagementDriverDriverTypeChoices {
  /** Primary */
  PRIMARY = 'PRIMARY',
  /** Substitute */
  SUBSTITUTE = 'SUBSTITUTE'
}

/** An enumeration. */
export enum FleetManagementVehicleAbsenceAbsenceTypeChoices {
  /** broken */
  BROKEN = 'BROKEN',
  /** other */
  OTHER = 'OTHER'
}

export type FormErrorMutation = {
  __typename: 'FormErrorMutation';
  status?: Maybe<Scalars['String']['output']>;
};

export type GenerateBarcodesForContainers = {
  __typename: 'GenerateBarcodesForContainers';
  linkToPdfFile?: Maybe<Scalars['Upload']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GenerateCollectionCalendarsPickupSettings = {
  __typename: 'GenerateCollectionCalendarsPickupSettings';
  pickupSetting?: Maybe<PickupSettingsType>;
};

export type GenerateDatesForCollectionCalendars = {
  __typename: 'GenerateDatesForCollectionCalendars';
  collectionCalendars?: Maybe<Array<Maybe<CollectionCalendarDef>>>;
};

export enum GenericAddRemoveEventTypesGraphene {
  ADDED = 'ADDED',
  REMOVED = 'REMOVED'
}

export enum GenericMtmEventTypesGraphene {
  ADDED = 'ADDED',
  CHANGED = 'CHANGED',
  REMOVED = 'REMOVED'
}

export type GeoJsonFeaturesType = {
  __typename: 'GeoJSONFeaturesType';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  geometry?: Maybe<GeoJsonGeometryType>;
  properties?: Maybe<GeoJsonPropertiesType>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GeoJsonGeometryType = {
  __typename: 'GeoJSONGeometryType';
  coordinates?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GeoJsonPropertiesType = {
  __typename: 'GeoJSONPropertiesType';
  segments?: Maybe<Array<Maybe<GeoJsonSegmentType>>>;
  summary?: Maybe<GeoJsonSummaryType>;
  wayPoints?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type GeoJsonSegmentType = {
  __typename: 'GeoJSONSegmentType';
  distance?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<Array<Maybe<GeoJsonStepType>>>;
};

export type GeoJsonStepType = {
  __typename: 'GeoJSONStepType';
  distance?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  instruction?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  wayPoints?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type GeoJsonSummaryType = {
  __typename: 'GeoJSONSummaryType';
  distance?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
};

export type GeoJsonType = {
  __typename: 'GeoJSONType';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  features?: Maybe<Array<Maybe<GeoJsonFeaturesType>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GroupType = Node & {
  __typename: 'GroupType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: PermissionTypeConnection;
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  userSet: UserTypeConnection;
};


export type GroupTypePermissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type GroupTypeUserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupTypeConnection = {
  __typename: 'GroupTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GroupType` and its cursor. */
export type GroupTypeEdge = {
  __typename: 'GroupTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GroupType>;
};

export type HaulerDef = Node & {
  __typename: 'HaulerDef';
  areas?: Maybe<Array<AreaDef>>;
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  haulerpriceSet: HaulerPriceDefConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  routeSet: RouteTypeConnection;
  routeschemeSet: RouteSchemeTypeConnection;
};


export type HaulerDefHaulerpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type HaulerDefRouteSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type HaulerDefRouteschemeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type HaulerDefConnection = {
  __typename: 'HaulerDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HaulerDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `HaulerDef` and its cursor. */
export type HaulerDefEdge = {
  __typename: 'HaulerDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<HaulerDef>;
};

export type HaulerPriceDef = Node & {
  __typename: 'HaulerPriceDef';
  amount: Scalars['Int']['output'];
  area?: Maybe<AreaDef>;
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['Date']['output'];
  hauler: HaulerDef;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  product: ProductDef;
  startDate: Scalars['Date']['output'];
};

export type HaulerPriceDefConnection = {
  __typename: 'HaulerPriceDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HaulerPriceDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `HaulerPriceDef` and its cursor. */
export type HaulerPriceDefEdge = {
  __typename: 'HaulerPriceDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<HaulerPriceDef>;
};

export type HealthCheckSubscription = {
  __typename: 'HealthCheckSubscription';
  healthcheck?: Maybe<HealthCheckType>;
};

export type HealthCheckType = {
  __typename: 'HealthCheckType';
  currentDatetime?: Maybe<Scalars['DateTime']['output']>;
  mixpanel?: Maybe<Scalars['Boolean']['output']>;
  users?: Maybe<Scalars['Int']['output']>;
};

export enum HistoryObjectStateGraphene {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  SOFT_DELETED = 'SOFT_DELETED',
  UPDATED = 'UPDATED'
}

export type Impersonate = {
  __typename: 'Impersonate';
  token?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum ImportActions {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE'
}

/**
 *
 *     Delimiters that can be used in ImportHistory and ImportConflict
 *
 */
export enum ImportCsvDelimiters {
  COMMA = 'COMMA',
  PIPE = 'PIPE',
  SEMICOLON = 'SEMICOLON',
  TAB = 'TAB'
}

/**
 *
 *     Choices that represent the all field types for Import (ImportField)
 *
 */
export enum ImportFieldTypes {
  ARRAY = 'ARRAY',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  GLOBAL_ID = 'GLOBAL_ID',
  M2_M = 'M2M',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  TIME = 'TIME'
}

/** Tips for import file */
export type ImportFileTooltips = {
  __typename: 'ImportFileTooltips';
  alternativeLabels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  label: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  type: ImportFieldTypes;
};

/**
 *
 *     Formats that can be used in ImportHistory and ImportConflict
 *
 */
export enum ImportFormats {
  CSV = 'CSV',
  XLSX = 'XLSX'
}

/** Import history */
export type ImportHistoryDef = Node & {
  __typename: 'ImportHistoryDef';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createItems?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  fileDelimiter?: Maybe<ExportImportImportHistoryFileDelimiterChoices>;
  fileFormat: ImportFormats;
  fileUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  importAction: ImportActions;
  importFile?: Maybe<Scalars['String']['output']>;
  importType: ImportTypes;
  progress: Scalars['Int']['output'];
  project?: Maybe<ProjectType>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: ImportHistoryStatuses;
  totalItems?: Maybe<Scalars['Int']['output']>;
  updateItems?: Maybe<Scalars['Int']['output']>;
  user: UserType;
};

export type ImportHistoryDefConnection = {
  __typename: 'ImportHistoryDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ImportHistoryDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ImportHistoryDef` and its cursor. */
export type ImportHistoryDefEdge = {
  __typename: 'ImportHistoryDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ImportHistoryDef>;
};

/**
 *
 *     Statuses that can be used in ImportHistory
 *
 */
export enum ImportHistoryStatuses {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW'
}

/**
 *
 *     Types that can be used in ImportTemplate
 *
 */
export enum ImportTypes {
  COLLECTION_CALENDAR = 'COLLECTION_CALENDAR',
  COLLECTION_CALENDAR_DATE = 'COLLECTION_CALENDAR_DATE',
  CONTAINER = 'CONTAINER',
  CONTAINER_TYPE = 'CONTAINER_TYPE',
  PICKUP_SETTING = 'PICKUP_SETTING',
  PROPERTY = 'PROPERTY',
  TICKET = 'TICKET',
  VEHICLE = 'VEHICLE',
  WASTE_FRACTION = 'WASTE_FRACTION'
}

export type IntegrationGpsType = Node & {
  __typename: 'IntegrationGpsType';
  active: Scalars['Boolean']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  integrationgpsvehicleSet: IntegrationGpsVehicleTypeConnection;
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  updateFrequency: Scalars['Int']['output'];
  username?: Maybe<Scalars['String']['output']>;
};


export type IntegrationGpsTypeIntegrationgpsvehicleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationGpsTypeConnection = {
  __typename: 'IntegrationGpsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationGpsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntegrationGpsType` and its cursor. */
export type IntegrationGpsTypeEdge = {
  __typename: 'IntegrationGpsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationGpsType>;
};

export type IntegrationGpsVehicleType = Node & {
  __typename: 'IntegrationGpsVehicleType';
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  integration: IntegrationGpsType;
  uniqueId: Scalars['String']['output'];
  vehicle: VehicleType;
};

export type IntegrationGpsVehicleTypeConnection = {
  __typename: 'IntegrationGpsVehicleTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationGpsVehicleTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntegrationGpsVehicleType` and its cursor. */
export type IntegrationGpsVehicleTypeEdge = {
  __typename: 'IntegrationGpsVehicleTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationGpsVehicleType>;
};

/** An enumeration. */
export enum IntegrationsRfidVehicleConnectionProtocolChoices {
  /** FTP */
  FTP = 'FTP',
  /** MODBUS */
  MODBUS = 'MODBUS',
  /** SSH */
  SSH = 'SSH'
}

/** An enumeration. */
export enum IntegrationsRfidVehicleConnectionProviderChoices {
  /** Botek */
  BOTEK = 'BOTEK',
  /** Poultarp */
  POULTARP = 'POULTARP',
  /** Sweco */
  SWECO = 'SWECO'
}

export type InternalUpdateOrCreateSalesOfferInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  createdBy: Scalars['ID']['input'];
  currency: Scalars['String']['input'];
  endAt?: InputMaybe<Scalars['Date']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  introductionDescription: Scalars['String']['input'];
  introductionImage?: InputMaybe<Scalars['String']['input']>;
  introductionTitle: Scalars['String']['input'];
  language: Scalars['String']['input'];
  logoImage?: InputMaybe<Scalars['String']['input']>;
  pages?: InputMaybe<Array<InputMaybe<SalesOfferPageMultipleChoiceFieldInput>>>;
  planDescription: Scalars['String']['input'];
  planImage?: InputMaybe<Scalars['String']['input']>;
  planTitle: Scalars['String']['input'];
  products?: InputMaybe<Array<InputMaybe<SalesOfferProductMultipleChoiceFieldInput>>>;
  recipientName: Scalars['String']['input'];
  recipientTitle: Scalars['String']['input'];
  recurringInterval: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  services?: InputMaybe<Array<InputMaybe<SalesOfferServiceMultipleChoiceFieldInput>>>;
  showNotIncludedProducts?: InputMaybe<Scalars['Boolean']['input']>;
  showPrices?: InputMaybe<Scalars['Boolean']['input']>;
  startAt?: InputMaybe<Scalars['Date']['input']>;
  termsDescription: Scalars['String']['input'];
  termsImage?: InputMaybe<Scalars['String']['input']>;
  termsTitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type InternalUpdateOrCreateSalesOfferPayload = {
  __typename: 'InternalUpdateOrCreateSalesOfferPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  salesOffer?: Maybe<SalesOfferType>;
};

export type InviteUsers = {
  __typename: 'InviteUsers';
  users?: Maybe<Array<Maybe<UserType>>>;
};

export type InvoiceDef = Node & {
  __typename: 'InvoiceDef';
  accountAddress: Scalars['String']['output'];
  accountName: Scalars['String']['output'];
  accountTaxId: Scalars['String']['output'];
  agreement?: Maybe<AgreementDef>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  billingRun?: Maybe<BillingRunDef>;
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  customerAddress: Scalars['String']['output'];
  customerEmail: Scalars['String']['output'];
  customerName: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  invoiceItems: InvoiceItemDefConnection;
  invoiceNumber: Scalars['String']['output'];
  issuedAt?: Maybe<Scalars['DateTime']['output']>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  paymentMethod?: Maybe<PaymentMethodDef>;
  periodEnd?: Maybe<Scalars['Date']['output']>;
  periodStart?: Maybe<Scalars['Date']['output']>;
  property?: Maybe<PropertyDef>;
  propertyUser?: Maybe<PropertyUserDef>;
  status: CrmInvoiceStatusChoices;
  subtotal: Scalars['Int']['output'];
  taxAmount: Scalars['Int']['output'];
  ticket?: Maybe<TicketDef>;
  total: Scalars['Int']['output'];
};


export type InvoiceDefInvoiceItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type InvoiceDefConnection = {
  __typename: 'InvoiceDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvoiceDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `InvoiceDef` and its cursor. */
export type InvoiceDefEdge = {
  __typename: 'InvoiceDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<InvoiceDef>;
};

export type InvoiceItemDef = Node & {
  __typename: 'InvoiceItemDef';
  agreement?: Maybe<AgreementDef>;
  amount: Scalars['Int']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  ignoreDuringBilling: Scalars['Boolean']['output'];
  invoice?: Maybe<InvoiceDef>;
  invoiceCode: Scalars['String']['output'];
  metaData: Scalars['JSONString']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  periodEnd?: Maybe<Scalars['Date']['output']>;
  periodStart?: Maybe<Scalars['Date']['output']>;
  property?: Maybe<PropertyDef>;
  quantity: Scalars['Float']['output'];
  taxAmount: Scalars['Int']['output'];
  ticket?: Maybe<TicketDef>;
  yearlyPrice?: Maybe<Scalars['Decimal']['output']>;
  yearlyPriceTaxAmount?: Maybe<Scalars['Decimal']['output']>;
};

export type InvoiceItemDefConnection = {
  __typename: 'InvoiceItemDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvoiceItemDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `InvoiceItemDef` and its cursor. */
export type InvoiceItemDefEdge = {
  __typename: 'InvoiceItemDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<InvoiceItemDef>;
};

export type IoUserIsActiveType = {
  __typename: 'IoUserIsActiveType';
  hasCompany?: Maybe<Scalars['Boolean']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
};

export type IsDriverType = {
  __typename: 'IsDriverType';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type JobNotificationEventUnion = BulkActionProgressDef | ContainerBarcodeGenerationHistoryDef | ExportHistoryDef | ImportHistoryDef;

export type JobTitleType = Node & {
  __typename: 'JobTitleType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  pendingTitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  user?: Maybe<UserType>;
};

/** An enumeration. */
export enum LevelChoices {
  ALL = 'ALL',
  ONLY_CRM = 'ONLY_CRM',
  ONLY_TICKETS = 'ONLY_TICKETS'
}

export type LicenseInput = {
  licenseClass: Scalars['String']['input'];
  number: Scalars['String']['input'];
  region: Scalars['String']['input'];
  validFrom: Scalars['Date']['input'];
  validTo: Scalars['Date']['input'];
};

/** Describe Driver's License */
export type LicenseType = Node & {
  __typename: 'LicenseType';
  driver?: Maybe<DriverType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  licenseClass?: Maybe<Scalars['String']['output']>;
  /** License's identifier */
  number: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  validInRange?: Maybe<DateRangeField>;
};

export type LocationInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  municipalityCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  postalCodeName?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type LocationInputObject = {
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
};

export type LocationMutation = {
  __typename: 'LocationMutation';
  location?: Maybe<LocationType>;
};

export type LocationType = Node & {
  __typename: 'LocationType';
  companySet: CompanyTypeConnection;
  contactAddresses: CollectionInquiryTypeConnection;
  containerSet: ContainerTypeConnection;
  createdAt: Scalars['DateTime']['output'];
  /** Location where Depot is situated */
  depots: DepotTypeConnection;
  destinations: Array<DirectionMatrixType>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  inquiries: CollectionInquiryTypeConnection;
  internalTickets: TicketDefConnection;
  latitude?: Maybe<Scalars['Float']['output']>;
  localArea?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  municipalityCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  origins: Array<DirectionMatrixType>;
  placeId?: Maybe<Scalars['String']['output']>;
  /** User selected location on Google Maps */
  point: Scalars['GeoJSON']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  postalCodeName?: Maybe<Scalars['String']['output']>;
  propertySet: PropertyDefConnection;
  routelocationhistorySet: RouteLocationHistoryDefConnection;
  settingsSet: SettingsTypeConnection;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  vehicleSet: VehicleTypeConnection;
};


export type LocationTypeCompanySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationTypeContactAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationTypeContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationTypeDepotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationTypeInquiriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationTypeInternalTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationTypePropertySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationTypeRoutelocationhistorySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationTypeSettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationTypeVehicleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type LocationTypeConnection = {
  __typename: 'LocationTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LocationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `LocationType` and its cursor. */
export type LocationTypeEdge = {
  __typename: 'LocationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<LocationType>;
};

export type LocationWithContainerType = Node & {
  __typename: 'LocationWithContainerType';
  companySet: CompanyTypeConnection;
  contactAddresses: CollectionInquiryTypeConnection;
  containerSet: ContainerTypeConnection;
  containers?: Maybe<ContainerTypeConnection>;
  createdAt: Scalars['DateTime']['output'];
  /** Location where Depot is situated */
  depots: DepotTypeConnection;
  destinations: Array<DirectionMatrixType>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  inquiries: CollectionInquiryTypeConnection;
  internalTickets: TicketDefConnection;
  latitude?: Maybe<Scalars['Float']['output']>;
  localArea?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  municipalityCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  origins: Array<DirectionMatrixType>;
  placeId?: Maybe<Scalars['String']['output']>;
  /** User selected location on Google Maps */
  point: Scalars['GeoJSON']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  postalCodeName?: Maybe<Scalars['String']['output']>;
  propertySet: PropertyDefConnection;
  routelocationhistorySet: RouteLocationHistoryDefConnection;
  settingsSet: SettingsTypeConnection;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  vehicleSet: VehicleTypeConnection;
};


export type LocationWithContainerTypeCompanySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationWithContainerTypeContactAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationWithContainerTypeContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationWithContainerTypeContainersArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  allowedForRoute?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  collectionCalendar_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  collectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerId?: InputMaybe<Scalars['String']['input']>;
  containerType_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  containerType_Name_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  containerTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  device_IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  excludeCollectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeRouteSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  measurement_FillPercentage?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noProperty?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyNumber?: InputMaybe<Scalars['String']['input']>;
  propertyTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  q?: InputMaybe<Scalars['String']['input']>;
  routeIdsNotCompletedStops?: InputMaybe<Scalars['String']['input']>;
  routeScheme_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  routeSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchContact?: InputMaybe<Scalars['String']['input']>;
  sensor_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  storedAtDepot?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFractionIds?: InputMaybe<Scalars['String']['input']>;
  wasteFraction_WasteTypes_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  wasteFraction_WasteTypes_Name_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wasteTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  withDevices?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LocationWithContainerTypeDepotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationWithContainerTypeInquiriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationWithContainerTypeInternalTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationWithContainerTypePropertySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationWithContainerTypeRoutelocationhistorySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationWithContainerTypeSettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LocationWithContainerTypeVehicleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type LocationWithContainerTypeConnection = {
  __typename: 'LocationWithContainerTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LocationWithContainerTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `LocationWithContainerType` and its cursor. */
export type LocationWithContainerTypeEdge = {
  __typename: 'LocationWithContainerTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<LocationWithContainerType>;
};

export type LogViewPropertyEvent = {
  __typename: 'LogViewPropertyEvent';
  success: Scalars['Boolean']['output'];
};

export type LogViewTicketEvent = {
  __typename: 'LogViewTicketEvent';
  success: Scalars['Boolean']['output'];
};

export type LorawanDeviceType = BaseDeviceInterface & Node & {
  __typename: 'LorawanDeviceType';
  antenna: DeviceLorawanDeviceAntennaChoices;
  appKey?: Maybe<Scalars['String']['output']>;
  batch: Scalars['String']['output'];
  bootloaderFwVersion: Scalars['String']['output'];
  company?: Maybe<CompanyType>;
  connectionType?: Maybe<Scalars['String']['output']>;
  containerDevice?: Maybe<DeviceToContainerTypeConnection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  devId?: Maybe<Scalars['String']['output']>;
  deviceEui?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<DeviceTypeType>;
  hwVersion?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isContainer?: Maybe<Scalars['Boolean']['output']>;
  isGps?: Maybe<Scalars['Boolean']['output']>;
  measurements?: Maybe<FillLevelMeasurementTypeConnection>;
  modemFwVersion: Scalars['String']['output'];
  productNo: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  whsFwVersion: Scalars['String']['output'];
};


export type LorawanDeviceTypeContainerDeviceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type LorawanDeviceTypeMeasurementsArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type LorawanDeviceTypeConnection = {
  __typename: 'LorawanDeviceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LorawanDeviceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `LorawanDeviceType` and its cursor. */
export type LorawanDeviceTypeEdge = {
  __typename: 'LorawanDeviceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<LorawanDeviceType>;
};

export type ManageContainerTicketDef = Node & {
  __typename: 'ManageContainerTicketDef';
  assignedTeam?: Maybe<TeamType>;
  assignee?: Maybe<UserType>;
  collectionCalendar?: Maybe<CollectionCalendarDef>;
  company: CompanyType;
  companyCopy: CompanyType;
  container?: Maybe<ContainerType>;
  containerDestination?: Maybe<ContainerStorageLocationDef>;
  containerProduct?: Maybe<ProductDef>;
  containerSource?: Maybe<ContainerStorageLocationDef>;
  containerToAdd?: Maybe<ContainerType>;
  containerToReturn?: Maybe<ContainerType>;
  containerType?: Maybe<ContainerTypeType>;
  createdAt: Scalars['DateTime']['output'];
  customFields: Scalars['JSONString']['output'];
  deadline?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  ignoreServiceFee: Scalars['Boolean']['output'];
  lastView?: Maybe<TicketViewEventDef>;
  location?: Maybe<LocationWithContainerType>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  newContainerId?: Maybe<Scalars['String']['output']>;
  oldCollectionCalendar?: Maybe<CollectionCalendarDef>;
  oldContainerType?: Maybe<ContainerTypeType>;
  oldPickupSetting?: Maybe<PickupSettingsType>;
  oldRouteScheme?: Maybe<RouteSchemeType>;
  pickupSetting?: Maybe<PickupSettingsType>;
  possibleContainerId: Scalars['String']['output'];
  preferredDate?: Maybe<Scalars['Date']['output']>;
  priority: TicketTicketPriorityChoices;
  property?: Maybe<PropertyDef>;
  reporter?: Maybe<UserType>;
  reporterDriver?: Maybe<DriverType>;
  requestedBy?: Maybe<PropertyUserDef>;
  requestedByOther?: Maybe<Scalars['String']['output']>;
  rfid: Scalars['String']['output'];
  routeScheme?: Maybe<RouteSchemeType>;
  serviceFeeQuantity: Scalars['Int']['output'];
  status: TicketTicketStatusChoices;
  /** The user who changed the status */
  statusChangedBy?: Maybe<UserType>;
  /** The reason for the status change */
  statusChangedReason?: Maybe<TicketTicketStatusChangedReasonChoices>;
  /** The platform where the status was changed */
  statusChangedVia?: Maybe<TicketTicketStatusChangedViaChoices>;
  statusCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  statusCopy: TicketManageContainerTicketStatusCopyChoices;
  statusInProgressAt?: Maybe<Scalars['DateTime']['output']>;
  statusOnHoldAt?: Maybe<Scalars['DateTime']['output']>;
  statusOpenAt?: Maybe<Scalars['DateTime']['output']>;
  statusPendingAt?: Maybe<Scalars['DateTime']['output']>;
  statusRejectedAt?: Maybe<Scalars['DateTime']['output']>;
  ticketPtr: TicketDef;
  ticketType: TicketTypeDef;
  /** in minutes */
  timeSpent?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  viaPortal: Scalars['Boolean']['output'];
  wasteFraction?: Maybe<WasteFractionType>;
  wasteVolumeEstimate?: Maybe<Scalars['Float']['output']>;
  wasteWeightEstimate?: Maybe<Scalars['Float']['output']>;
};

export type ManageContainerTicketDefConnection = {
  __typename: 'ManageContainerTicketDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ManageContainerTicketDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ManageContainerTicketDef` and its cursor. */
export type ManageContainerTicketDefEdge = {
  __typename: 'ManageContainerTicketDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ManageContainerTicketDef>;
};

export type ManageFilesMutation = {
  __typename: 'ManageFilesMutation';
  status?: Maybe<Scalars['String']['output']>;
  uploadFiles?: Maybe<Array<Maybe<FileObjectType>>>;
};

export type MarkAllUserNotificationsRead = {
  __typename: 'MarkAllUserNotificationsRead';
  status?: Maybe<Scalars['String']['output']>;
};

export type MarkAllUserNotificationsSeen = {
  __typename: 'MarkAllUserNotificationsSeen';
  status?: Maybe<Scalars['String']['output']>;
};

export type MarkUserJobNotificationRead = {
  __typename: 'MarkUserJobNotificationRead';
  userJobNotification?: Maybe<UserJobNotificationType>;
};

export type MarkUserNotificationRead = {
  __typename: 'MarkUserNotificationRead';
  userNotification?: Maybe<UserNotificationType>;
};

export type MarkUserNotificationSeen = {
  __typename: 'MarkUserNotificationSeen';
  userNotification?: Maybe<UserNotificationType>;
};

export type MasterDataSyncDef = Node & {
  __typename: 'MasterDataSyncDef';
  company: CompanyType;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isDryRun: Scalars['Boolean']['output'];
  numPropertiesFailed: Scalars['Int']['output'];
  numPropertiesSynced: Scalars['Int']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: CrmMasterDataSyncStatusChoices;
  type: CrmMasterDataSyncTypeChoices;
};

export type MasterDataSyncDefConnection = {
  __typename: 'MasterDataSyncDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MasterDataSyncDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `MasterDataSyncDef` and its cursor. */
export type MasterDataSyncDefEdge = {
  __typename: 'MasterDataSyncDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<MasterDataSyncDef>;
};

export type MeasurementSettingsType = Node & {
  __typename: 'MeasurementSettingsType';
  custom: Scalars['Boolean']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deviceToContainerSet: DeviceToContainerTypeConnection;
  excludeDays?: Maybe<Array<Scalars['String']['output']>>;
  excludePeriodEnd?: Maybe<Scalars['Date']['output']>;
  excludePeriodEveryYear: Scalars['Boolean']['output'];
  excludePeriodStart?: Maybe<Scalars['Date']['output']>;
  hours?: Maybe<Array<Scalars['Int']['output']>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  measureAt: Scalars['Int']['output'];
  measurementExcludeDays?: Maybe<Array<Scalars['String']['output']>>;
  measurementHours?: Maybe<Array<Scalars['Int']['output']>>;
  measurementMeasurementsPerHour?: Maybe<Scalars['Int']['output']>;
  measurementsPerHour: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  project: ProjectType;
};


export type MeasurementSettingsTypeDeviceToContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MeasurementSettingsTypeConnection = {
  __typename: 'MeasurementSettingsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeasurementSettingsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MeasurementSettingsType` and its cursor. */
export type MeasurementSettingsTypeEdge = {
  __typename: 'MeasurementSettingsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<MeasurementSettingsType>;
};

export enum MediaContentEnumType {
  DOCS = 'DOCS',
  PHOTO = 'PHOTO'
}

/** An enumeration. */
export enum MetaTypeDisplayOnType {
  AGREEMENT_TABLE = 'AGREEMENT_TABLE',
  CONTAINER_TABLE = 'CONTAINER_TABLE',
  NAVIGATION_APP = 'NAVIGATION_APP',
  PROPERTY_CARD = 'PROPERTY_CARD',
  PROPERTY_TABLE = 'PROPERTY_TABLE'
}

/** Model for connection to the mobile application */
export type MobileAppType = Node & {
  __typename: 'MobileAppType';
  brand: Scalars['String']['output'];
  build: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  deviceUid: Scalars['String']['output'];
  /** Relation to Mobile App */
  drivers: DriverTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  model: Scalars['String']['output'];
  os: Scalars['String']['output'];
  systemVersion: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
};


/** Model for connection to the mobile application */
export type MobileAppTypeDriversArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MobileAppTypeConnection = {
  __typename: 'MobileAppTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MobileAppTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MobileAppType` and its cursor. */
export type MobileAppTypeEdge = {
  __typename: 'MobileAppTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<MobileAppType>;
};

export type MobileDeviceType = BaseDeviceInterface & Node & {
  __typename: 'MobileDeviceType';
  antenna: DeviceMobileDeviceAntennaChoices;
  batch: Scalars['String']['output'];
  bootloaderFwVersion: Scalars['String']['output'];
  company?: Maybe<CompanyType>;
  connectionType?: Maybe<Scalars['String']['output']>;
  containerDevice?: Maybe<DeviceToContainerTypeConnection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  devId?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<DeviceTypeType>;
  hwVersion?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  imei?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isContainer?: Maybe<Scalars['Boolean']['output']>;
  isGps?: Maybe<Scalars['Boolean']['output']>;
  measurements?: Maybe<FillLevelMeasurementTypeConnection>;
  modemFwVersion: Scalars['String']['output'];
  productNo: Scalars['String']['output'];
  simcard?: Maybe<SimCardType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  whsFwVersion: Scalars['String']['output'];
};


export type MobileDeviceTypeContainerDeviceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type MobileDeviceTypeMeasurementsArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MobileDeviceTypeConnection = {
  __typename: 'MobileDeviceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MobileDeviceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MobileDeviceType` and its cursor. */
export type MobileDeviceTypeEdge = {
  __typename: 'MobileDeviceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<MobileDeviceType>;
};

/** An enumeration. */
export enum MonthlyIntervalEnum {
  AT_THE_END_OF_THE_MONTH = 'at_the_end_of_the_month',
  AT_THE_START_OF_THE_MONTH = 'at_the_start_of_the_month'
}

export type Mutations = {
  __typename: 'Mutations';
  addAssociatedTicketsToCompanyEmailAccountMessage?: Maybe<AddAssociatedTicketsToCompanyEmailAccountMessage>;
  addCard?: Maybe<AddCard>;
  addContactToProperties?: Maybe<AddContactToProperties>;
  archiveBillingRun?: Maybe<ArchiveBillingRun>;
  archiveProduct?: Maybe<ArchiveProduct>;
  assignTicket?: Maybe<AssignTicket>;
  assignTicketToTeam?: Maybe<AssignTicketToTeam>;
  assignUserCompanyRole?: Maybe<AssignUserCompanyRole>;
  attachDeviceToContainer?: Maybe<AttachDeviceToContainer>;
  bulkCreateBulkWastePickupTicket?: Maybe<BulkCreateBulkWastePickupTicket>;
  bulkCreateManageContainerTicket?: Maybe<BulkCreateManageContainerTicket>;
  bulkCreateTicket?: Maybe<BulkCreateTicket>;
  bulkSetTicketRoutes?: Maybe<BulkSetTicketRoute>;
  /**
   * This mutation is preferred over UpdateAgreement when updating multiple agreements
   * at once to update common fields for all agreements (recurring and non-recurring).
   * This mutation will update the container status if needed.
   */
  bulkUpdateAgreement?: Maybe<BulkUpdateAgreement>;
  bulkUpdateCompanyEmailAccountMessage?: Maybe<BulkUpdateCompanyEmailAccountMessage>;
  bulkUpdateContainer?: Maybe<BulkUpdateContainer>;
  bulkUpdateTicket?: Maybe<BulkUpdateTicket>;
  checkDeviceId?: Maybe<CheckDeviceId>;
  completeAlert?: Maybe<CompleteAlert>;
  completeTicketDraft?: Maybe<CompleteTicketDraft>;
  confirmDriverPushNotification?: Maybe<ConfirmDriverPushNotification>;
  createAgreement?: Maybe<CreateAgreementPayload>;
  createBillingRun?: Maybe<CreateBillingRun>;
  createBulkWastePickupTicket?: Maybe<CreateBulkWastePickupTicketPayload>;
  createCompanyEmailAccountMessage?: Maybe<CreateCompanyEmailAccountMessagePayload>;
  createComparisonSettings?: Maybe<CreateComparisonSettingsMutationPayload>;
  createContainer?: Maybe<CreateContainer>;
  createContainerImport?: Maybe<CreateContainerImport>;
  createContainerMobile?: Maybe<CreateContainerMobile>;
  createContainerPrice?: Maybe<CreateContainerPricePayload>;
  createContainerType?: Maybe<CreateContainerType>;
  createContainerWeightImport?: Maybe<CreateContainerWeightImport>;
  createCostSettings?: Maybe<CreateCostSettingsMutationPayload>;
  createDriverPushNotification?: Maybe<CreateDriverPushNotificationPayload>;
  createExport?: Maybe<CreateExport>;
  createFillLevelDeviceMobile?: Maybe<CreateFillLevelDeviceMobile>;
  createManageContainerTicket?: Maybe<CreateManageContainerTicketPayload>;
  createMeasurementSettings?: Maybe<CreateMeasurementSettings>;
  createMobileDevice?: Maybe<CreateMobileDevice>;
  createNotificationSetting?: Maybe<CreateNotificationSetting>;
  createOrUpdateContainerIdGenerator?: Maybe<UpdateOrCreateContainerIdGeneratorPayload>;
  createOrUpdateDkdpCompanyCredentials?: Maybe<DkdpCompanyCredentialsMutationPayload>;
  createOrUpdateExportTemplate?: Maybe<CreateOrUpdateExportTemplate>;
  createOrUpdateRfidVehicleConnection?: Maybe<RfidVehicleConnectionMutationPayload>;
  createOrUpdateStopListCardConfig?: Maybe<CreateOrUpdateStopListCardConfig>;
  createOrUpdateUrbaserCompanyCredentials?: Maybe<UrbaserCredentialsMutationPayload>;
  createPrice?: Maybe<CreatePricePayload>;
  createRecurringPrice?: Maybe<CreateRecurringPricePayload>;
  createRoute?: Maybe<CreateRouteMutation>;
  createScheduledExportRun?: Maybe<CreateScheduledExportRun>;
  createSensor?: Maybe<CreateSensor>;
  createServicePrice?: Maybe<CreateServicePricePayload>;
  createTicket?: Maybe<CreateTicketPayload>;
  createTicketComment?: Maybe<CreateTicketCommentPayload>;
  createTicketCustomField?: Maybe<CreateTicketCustomFieldMutationPayload>;
  createTicketDraftEmail?: Maybe<CreateTicketDraftEmailPayload>;
  createTicketMessage?: Maybe<CreateTicketMessagePayload>;
  createUnconfirmedUser?: Maybe<CreateUnconfirmedUser>;
  createUser?: Maybe<CreateUser>;
  createWasteCollector?: Maybe<CreateWasteCollector>;
  createWasteFraction?: Maybe<CreateWasteFraction>;
  deleteAgreementTemplate?: Maybe<DeleteAgreementTemplate>;
  deleteAlertRule?: Maybe<DeleteAlertRule>;
  deleteArea?: Maybe<DeleteArea>;
  deleteCard?: Maybe<DeleteCard>;
  deleteCollectionCalendar?: Maybe<DeleteCollectionCalendar>;
  deleteCollectionCalendarDate?: Maybe<DeleteCollectionCalendarDate>;
  deleteCollectionCalendarDeviation?: Maybe<DeleteCollectionCalendarDeviation>;
  deleteCollectionCalendarWeek?: Maybe<DeleteCollectionCalendarWeek>;
  deleteCompanyContainerMetaType?: Maybe<DeleteCompanyContainerMetaType>;
  deleteCompanyEmailAccount?: Maybe<DeleteCompanyEmailAccount>;
  deleteCompanyEmailAccountMessage?: Maybe<DeleteCompanyEmailAccountMessage>;
  deleteCompanyEmailAccountRule?: Maybe<DeleteCompanyEmailAccountRule>;
  deleteCompanyMetaType?: Maybe<DeleteCompanyMetaType>;
  deleteCompanyRole?: Maybe<DeleteCompanyRole>;
  deleteComparisonSettings?: Maybe<DeleteComparisonSettingsMutation>;
  deleteContactProperties?: Maybe<DeleteContactProperties>;
  deleteContainer?: Maybe<DeleteContainer>;
  deleteContainerGroup?: Maybe<DeleteContainerGroup>;
  deleteContainerNotification?: Maybe<DeleteContainerNotification>;
  deleteContainerType?: Maybe<DeleteContainerType>;
  deleteCostSettings?: Maybe<DeleteCostSettingsMutation>;
  deleteDashboard?: Maybe<DeleteDashboard>;
  deleteDepot?: Maybe<DeleteDepot>;
  deleteDocument?: Maybe<DeleteDocument>;
  deleteDriver?: Maybe<DeleteDriver>;
  deleteExportTemplate?: Maybe<DeleteExportTemplate>;
  deleteFcmDevice?: Maybe<DeleteFcmDevice>;
  deleteHauler?: Maybe<DeleteHauler>;
  deleteHaulerPrice?: Maybe<DeleteHaulerPrice>;
  deleteImportHistory?: Maybe<DeleteImportHistory>;
  deleteIntegrationGpsVehicle?: Maybe<DeleteIntegrationGpsVehicle>;
  deleteMeasurementSettings?: Maybe<DeleteMeasurementSettings>;
  deleteNotificationSetting?: Maybe<DeleteNotificationSetting>;
  deletePickupSettings?: Maybe<DeletePickupSettingsMutation>;
  deleteProject?: Maybe<DeleteProject>;
  deleteProperty?: Maybe<DeleteProperty>;
  deletePropertyContact?: Maybe<DeletePropertyContact>;
  deletePropertyGroup?: Maybe<DeletePropertyGroup>;
  deletePropertyType?: Maybe<DeletePropertyType>;
  deleteScheduledExport?: Maybe<DeleteScheduledExport>;
  deleteService?: Maybe<DeleteService>;
  deleteTeam?: Maybe<DeleteTeam>;
  deleteTicketCategory?: Maybe<DeleteTicketCategory>;
  deleteTicketCustomField?: Maybe<DeleteTicketCustomFieldMutation>;
  deleteTicketMessageTemplate?: Maybe<DeleteTicketMessageTemplate>;
  deleteTicketType?: Maybe<DeleteTicketType>;
  deleteUser?: Maybe<DeleteUser>;
  deleteUserAbsence?: Maybe<DeleteUserAbsence>;
  deleteUserProject?: Maybe<DeleteUserProject>;
  deleteVehicle?: Maybe<DeleteVehicle>;
  deleteVehicleAbsence?: Maybe<DeleteVehicleAbsence>;
  deleteVehicleType?: Maybe<DeleteVehicleType>;
  deleteWasteCollector?: Maybe<DeleteWasteCollector>;
  deleteWasteFraction?: Maybe<DeleteWasteFraction>;
  deleteWasteType?: Maybe<DeleteWasteType>;
  demoInvitation?: Maybe<DemoInvitation>;
  detachSensor?: Maybe<DetachSensorMutation>;
  exportBillingRunToNavision?: Maybe<ExportBillingRunToNavision>;
  exportDebtorData?: Maybe<ExportDebtorData>;
  exportTemplateScheduleSettingsUpdate?: Maybe<ExportTemplateScheduleSettingsUpdate>;
  formError?: Maybe<FormErrorMutation>;
  generateBarcodesForContainers?: Maybe<GenerateBarcodesForContainers>;
  generateCollectionCalendarsPickupSettings?: Maybe<GenerateCollectionCalendarsPickupSettings>;
  generateDatesForCollectionCalendars?: Maybe<GenerateDatesForCollectionCalendars>;
  impersonate?: Maybe<Impersonate>;
  internalUpdateOrCreateSalesOffer?: Maybe<InternalUpdateOrCreateSalesOfferPayload>;
  inviteUsers?: Maybe<InviteUsers>;
  location?: Maybe<LocationMutation>;
  logViewPropertyEvent?: Maybe<LogViewPropertyEvent>;
  logViewTicketEvent?: Maybe<LogViewTicketEvent>;
  manageFiles?: Maybe<ManageFilesMutation>;
  markAllUserNotificationsRead?: Maybe<MarkAllUserNotificationsRead>;
  markAllUserNotificationsSeen?: Maybe<MarkAllUserNotificationsSeen>;
  markUserJobNotificationRead?: Maybe<MarkUserJobNotificationRead>;
  markUserNotificationRead?: Maybe<MarkUserNotificationRead>;
  markUserNotificationSeen?: Maybe<MarkUserNotificationSeen>;
  /**
   * Provide a presigned URL and authentication data for uploading attachments directly to S3.
   * The URL has the expiration time, defined in settings by AWS_PRESIGNED_URL_EXPIRY.
   */
  obtainAttachmentsDirectUploadUrl?: Maybe<ObtainAttachmentsDirectUploadUrl>;
  /**
   * Provide a presigned URL and authentication data for uploading attachments directly to S3.
   * The URL has the expiration time, defined in settings by AWS_PRESIGNED_URL_EXPIRY.
   */
  obtainImportDirectUploadUrl?: Maybe<ObtainImportDirectUploadUrl>;
  offlineMode?: Maybe<OfflineModeUpdateData>;
  orderSubscriptionQuote?: Maybe<OrderSubscriptionQuote>;
  partialUpdateCompanySettings?: Maybe<PartialUpdateCompanySettingsPayload>;
  passwordChange?: Maybe<PasswordChange>;
  passwordReset?: Maybe<PasswordReset>;
  pickupOrderApprovalCollectionAddApprovals?: Maybe<PickUpOrderApprovalCollectionAddApprovals>;
  pickupOrderApprovalCollectionDeleteApprovals?: Maybe<PickUpOrderApprovalCollectionDeleteApprovals>;
  pickupOrderApprovalCollectionUpdateApprovals?: Maybe<PickUpOrderApprovalCollectionUpdateApprovals>;
  publicUpdateUnconfirmedUser?: Maybe<PublicUpdateUnconfirmedUser>;
  refreshToken?: Maybe<Refresh>;
  refuseToken?: Maybe<RefuseToken>;
  registerFcmDevice?: Maybe<RegisterFcmDevicePayload>;
  removeAssociatedTicketsFromCompanyEmailAccountMessage?: Maybe<RemoveAssociatedTicketsFromCompanyEmailAccountMessage>;
  resendInvitation?: Maybe<ResendInvitation>;
  resetCompanyColorSettings?: Maybe<ResetCompanyColorSettings>;
  resetStopListCardConfig?: Maybe<ResetStopListCardConfig>;
  reverseSearchLocation?: Maybe<SearchLocationReverse>;
  /** Change primary vehicle for route */
  routeChangePrimaryVehicle?: Maybe<RouteChangePrimaryVehicle>;
  /** Change primary vehicle for route for mobile app */
  routeChangePrimaryVehicleFromMobile?: Maybe<RouteChangePrimaryVehicleMobile>;
  routeComplete?: Maybe<RouteComplete>;
  routeDelete?: Maybe<RouteDelete>;
  routeExport?: Maybe<RouteExportMutation>;
  /** Driver joins to a route on another vehicle */
  routeJoinDifferentVehicle?: Maybe<RouteJoinDifferentVehicle>;
  /** Driver joins to a vehicle that is already on a route */
  routeJoinSameVehicle?: Maybe<RouteJoinSameVehicle>;
  routeOptimize?: Maybe<RouteOptimize>;
  routeReset?: Maybe<RouteReset>;
  routeSchemeDelete?: Maybe<RouteSchemeDelete>;
  routeSchemeOptimize?: Maybe<RouteSchemeOptimize>;
  routeSchemeOptimizeNew?: Maybe<RouteSchemeOptimizeNew>;
  routeSchemePreview?: Maybe<RouteSchemePreview>;
  routeStart?: Maybe<RouteStart>;
  routeStartFromMobile?: Maybe<RouteStartMobileApp>;
  routeStopComplete?: Maybe<RouteStopComplete>;
  routeStopSetNext?: Maybe<RouteStopSetNext>;
  routeStopSetNextDepot?: Maybe<RouteStopSetNextDepot>;
  routeStopsAdd?: Maybe<RouteStopsAdd>;
  routeStopsComplete?: Maybe<RouteStopsComplete>;
  routeStopsMove?: Maybe<RouteStopsMove>;
  routeStopsOrder?: Maybe<RouteStopsOrder>;
  routeStopsUpdate?: Maybe<RouteStopsUpdate>;
  routeUnComplete?: Maybe<RouteUnComplete>;
  routeUpdateAutoComplete?: Maybe<RouteUpdateAutoComplete>;
  routeUpdateAutoCompleteNextStop?: Maybe<RouteUpdateAutoCompleteNextStop>;
  routeUpdateAutoOptimize?: Maybe<RouteUpdateAutoOptimize>;
  routeUpdateContainerGroupLocation?: Maybe<RouteUpdateContainerGroupLocation>;
  routeUpdateContainerLocation?: Maybe<RouteUpdateContainerLocation>;
  routeUpdateLocked?: Maybe<RouteUpdateLocked>;
  routeUpdateLockedStart?: Maybe<RouteUpdateLockedStart>;
  routeUpdateRouteVehicle?: Maybe<RouteUpdateRouteVehicle>;
  routeUpdateRouteVehicleDriver?: Maybe<RouteUpdateRouteVehicleDriver>;
  routeUpdateRouteVehicleMobile?: Maybe<RouteUpdateRouteVehicleMobileApp>;
  routeUpdateRouteVehicleTrailer?: Maybe<RouteUpdateRouteVehicleTrailer>;
  /** Complete route vehicle sessions in bulk */
  routeVehicleSessionsComplete?: Maybe<RouteVehicleSessionsComplete>;
  routesStopsComplete?: Maybe<RoutesStopsComplete>;
  routesStopsMove?: Maybe<RoutesStopsMove>;
  routesStopsUpdate?: Maybe<RoutesStopsUpdate>;
  saveTicketTypeOrder?: Maybe<SaveTicketTypeOrder>;
  saveTicketTypeOrderSettings?: Maybe<SaveTicketTypeOrderSettings>;
  searchLocation?: Maybe<SearchLocation>;
  selfPasswordChange?: Maybe<SelfPasswordChange>;
  sendMqttMessage?: Maybe<SendMqttMessage>;
  setAgreementShares?: Maybe<SetAgreementShares>;
  setContainerGroup?: Maybe<SetContainerGroup>;
  setContainerStorageDepot?: Maybe<SetContainerStorageDepot>;
  setDefaultCard?: Maybe<SetDefaultCard>;
  setPropertiesInPropertyGroup?: Maybe<SetPropertiesInPropertyGroup>;
  setTicketPriority?: Maybe<SetTicketPriority>;
  setTicketRoute?: Maybe<SetTicketRoute>;
  syncCustomFieldsWithTicketType?: Maybe<SyncCustomFieldsWithTicketTypeMutation>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  triggerExport?: Maybe<TriggerExport>;
  triggerImport?: Maybe<TriggerImport>;
  unpairFillLevelDevice?: Maybe<UnpairFillLevelDevice>;
  updateActiveProjects?: Maybe<UpdateActiveProjects>;
  updateAgreement?: Maybe<UpdateAgreementPayload>;
  updateBillingInformation?: Maybe<UpdateBillingInformation>;
  updateBulkWastePickupTicket?: Maybe<UpdateBulkWastePickupTicketPayload>;
  updateComparisonSettings?: Maybe<UpdateComparisonSettingsMutationPayload>;
  updateContainer?: Maybe<UpdateContainer>;
  updateContainerMobile?: Maybe<UpdateContainerMobile>;
  updateContainerPrice?: Maybe<UpdateContainerPricePayload>;
  updateContainerType?: Maybe<UpdateContainerType>;
  updateCostSettings?: Maybe<UpdateCostSettingsMutationPayload>;
  updateCurrentDriverLocation?: Maybe<UpdateCurrentDriverLocation>;
  updateManageContainerTicket?: Maybe<UpdateManageContainerTicketPayload>;
  updateMeasurementSettings?: Maybe<UpdateMeasurementSettings>;
  updateNotificationSetting?: Maybe<UpdateNotificationSetting>;
  updateOrCreateAgreementExport?: Maybe<UpdateOrCreateCreateAgreementExportPayload>;
  updateOrCreateAgreementTemplate?: Maybe<UpdateOrCreateAgreementTemplatePayload>;
  updateOrCreateAlertRule?: Maybe<UpdateOrCreateAlertRulePayload>;
  updateOrCreateArea?: Maybe<UpdateOrCreateAreaPayload>;
  updateOrCreateBulkProperty?: Maybe<PropertyBulkUpdateCreateMutation>;
  updateOrCreateBulkTicket?: Maybe<TicketBulkUpdateCreateMutation>;
  updateOrCreateCollectionCalendar?: Maybe<UpdateOrCreateCollectionCalendarPayload>;
  updateOrCreateCollectionCalendarDate?: Maybe<UpdateOrCreateCollectionCalendarDatePayload>;
  updateOrCreateCollectionCalendarDeviation?: Maybe<UpdateOrCreateCollectionCalendarDeviationPayload>;
  updateOrCreateCollectionCalendarWeek?: Maybe<UpdateOrCreateCollectionCalendarWeekPayload>;
  /** target_id: ID of Route, Depot, Driver, Vehicle or Container */
  updateOrCreateComment?: Maybe<UpdateOrCreateComment>;
  updateOrCreateCompany?: Maybe<UpdateOrCreateCompanyPayload>;
  updateOrCreateCompanyContainerMetaType?: Maybe<UpdateOrCreateCompanyContainerMetaType>;
  updateOrCreateCompanyEmailAccount?: Maybe<UpdateOrCreateCompanyEmailAccountPayload>;
  updateOrCreateCompanyEmailAccountRule?: Maybe<UpdateOrCreateCompanyEmailAccountRulePayload>;
  updateOrCreateCompanyMainSettings?: Maybe<UpdateOrCreateCompanyMainSettingsPayload>;
  updateOrCreateCompanyMetaType?: Maybe<UpdateOrCreateCompanyMetaTypePayload>;
  updateOrCreateCompanyRole?: Maybe<UpdateOrCreateCompanyRole>;
  updateOrCreateContact?: Maybe<UpdateOrCreateContactPayload>;
  /** Use UpdateOrCreateContainerNew instead of UpdateOrCreateContainer */
  updateOrCreateContainer?: Maybe<UpdateOrCreateContainer>;
  updateOrCreateContainerBulk?: Maybe<UpdateOrCreateBulkContainerMutation>;
  updateOrCreateContainerGroup?: Maybe<UpdateOrCreateContainerGroupPayload>;
  updateOrCreateContainerNew?: Maybe<UpdateOrCreateContainerNewPayload>;
  updateOrCreateContainerNotification?: Maybe<UpdateOrCreateContainerNotificationPayload>;
  updateOrCreateContainerProduct?: Maybe<UpdateOrCreateContainerProductPayload>;
  updateOrCreateContainerType?: Maybe<UpdateOrCreateContainerTypePayload>;
  updateOrCreateCriiptoApplication?: Maybe<UpdateOrCreateCreateCriiptoApplicationPayload>;
  updateOrCreateDashboard?: Maybe<UpdateOrCreateDashboardPayload>;
  updateOrCreateDepot?: Maybe<UpdateOrCreateDepot>;
  updateOrCreateDocument?: Maybe<UpdateOrCreateCreateDocumentPayload>;
  updateOrCreateDriver?: Maybe<UpdateOrCreateDriverPayload>;
  updateOrCreateHauler?: Maybe<UpdateOrCreateHaulerPayload>;
  updateOrCreateHaulerPrice?: Maybe<UpdateOrCreateHaulerPricePayload>;
  updateOrCreateIntegrationGps?: Maybe<UpdateOrCreateIntegrationGps>;
  updateOrCreateIntegrationGpsVehicle?: Maybe<UpdateOrCreateIntegrationGpsVehicle>;
  updateOrCreateMeasurementSettings?: Maybe<UpdateOrCreateMeasurementSettingsPayload>;
  updateOrCreatePickupOrderApprovalCollection?: Maybe<UpdateOrCreatePickUpOrderApprovalCollectionPayload>;
  updateOrCreatePickupSettings?: Maybe<UpdateOrCreatePickupSettingsPayload>;
  updateOrCreateProject?: Maybe<UpdateOrCreateProjectPayload>;
  updateOrCreateProjectSettings?: Maybe<UpdateOrCreateProjectSettingsPayload>;
  updateOrCreateProperty?: Maybe<UpdateOrCreatePropertyPayload>;
  updateOrCreatePropertyContact?: Maybe<UpdateOrCreatePropertyContactPayload>;
  updateOrCreatePropertyGroup?: Maybe<UpdateOrCreatePropertyGroupPayload>;
  updateOrCreatePropertyType?: Maybe<UpdateOrCreatePropertyTypePayload>;
  updateOrCreatePropertyUser?: Maybe<UpdateOrCreatePropertyUserPayload>;
  updateOrCreateRecurringProduct?: Maybe<UpdateOrCreateRecurringProductPayload>;
  updateOrCreateRouteScheme?: Maybe<UpdateOrCreateRouteSchemePayload>;
  updateOrCreateScheduledExport?: Maybe<UpdateOrCreateCreateScheduledExportPayload>;
  updateOrCreateService?: Maybe<UpdateOrCreateServicePayload>;
  updateOrCreateServiceProduct?: Maybe<UpdateOrCreateServiceProductPayload>;
  updateOrCreateSkill?: Maybe<UpdateOrCreateSkill>;
  updateOrCreateSubscriptionQuote?: Maybe<UpdateOrCreateSubscriptionQuote>;
  updateOrCreateTeam?: Maybe<UpdateOrCreateTeamPayload>;
  updateOrCreateTicketCategory?: Maybe<UpdateOrCreateTicketCategoryPayload>;
  updateOrCreateTicketMessageTemplate?: Maybe<UpdateOrCreateTicketMessageTemplatePayload>;
  updateOrCreateTicketType?: Maybe<UpdateOrCreateTicketTypePayload>;
  updateOrCreateUserAbsence?: Maybe<UpdateOrCreateUserAbsence>;
  updateOrCreateUserProject?: Maybe<UpdateOrCreateUserProjectPayload>;
  updateOrCreateVehicle?: Maybe<UpdateOrCreateVehiclePayload>;
  updateOrCreateVehicleAbsence?: Maybe<UpdateOrCreateVehicleAbsence>;
  updateOrCreateVehicleType?: Maybe<UpdateOrCreateVehicleTypePayload>;
  updateOrCreateWasteFraction?: Maybe<UpdateOrCreateWasteFraction>;
  updateOrCreateWasteFractionNew?: Maybe<UpdateOrCreateWasteFractionNewPayload>;
  updatePrice?: Maybe<UpdatePricePayload>;
  updateRecurringPrice?: Maybe<UpdateRecurringPricePayload>;
  updateServicePrice?: Maybe<UpdateServicePricePayload>;
  updateShippingInformation?: Maybe<UpdateShippingInformation>;
  updateTicket?: Maybe<UpdateTicketPayload>;
  updateTicketAttachments?: Maybe<UpdateTicketAttachments>;
  updateTicketCustomField?: Maybe<UpdateTicketCustomFieldMutationPayload>;
  updateTicketDraft?: Maybe<UpdateTicketDraftPayload>;
  updateTicketFollowers?: Maybe<UpdateTicketFollowers>;
  updateTicketStatus?: Maybe<UpdateTicketStatus>;
  updateTicketTypeOrder?: Maybe<UpdateTicketTypeOrder>;
  updateUnconfirmedUser?: Maybe<UpdateUnconfirmedUser>;
  updateUser?: Maybe<UpdateUser>;
  updateUserLayoutConfig?: Maybe<UpdateUserLayoutConfig>;
  updateUsername?: Maybe<UpdateUsername>;
  updateWasteCollector?: Maybe<UpdateWasteCollector>;
  updateWasteFraction?: Maybe<UpdateWasteFraction>;
  updateWasteType?: Maybe<UpdateWasteType>;
  verifyToken?: Maybe<Verify>;
};


export type MutationsAddAssociatedTicketsToCompanyEmailAccountMessageArgs = {
  selfId: Scalars['ID']['input'];
  ticketIds: Array<Scalars['ID']['input']>;
};


export type MutationsAddCardArgs = {
  billingInformationId: Scalars['ID']['input'];
  default: Scalars['Boolean']['input'];
  source: Scalars['String']['input'];
};


export type MutationsAddContactToPropertiesArgs = {
  addContactToProperties?: InputMaybe<Array<InputMaybe<AddContactToPropertyInput>>>;
  contactId: Scalars['ID']['input'];
};


export type MutationsArchiveBillingRunArgs = {
  archive: Scalars['Boolean']['input'];
  billingRun: Scalars['ID']['input'];
};


export type MutationsArchiveProductArgs = {
  archive: Scalars['Boolean']['input'];
  product: Scalars['ID']['input'];
};


export type MutationsAssignTicketArgs = {
  ticketId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsAssignTicketToTeamArgs = {
  teamId?: InputMaybe<Scalars['ID']['input']>;
  ticketId: Scalars['ID']['input'];
};


export type MutationsAssignUserCompanyRoleArgs = {
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationsAttachDeviceToContainerArgs = {
  containerId: Scalars['ID']['input'];
  customMeasurement: Scalars['Boolean']['input'];
  deviceAngle?: InputMaybe<Scalars['Float']['input']>;
  deviceId: Scalars['String']['input'];
  internalHeight: Scalars['Float']['input'];
  lid: Scalars['String']['input'];
  measurementSettingsId: Scalars['ID']['input'];
  offset: Scalars['Float']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationsBulkCreateBulkWastePickupTicketArgs = {
  input: Array<CreateBulkWastePickupTicketInput>;
};


export type MutationsBulkCreateManageContainerTicketArgs = {
  input: Array<CreateManageContainerTicketInput>;
};


export type MutationsBulkCreateTicketArgs = {
  input: Array<CreateTicketInput>;
};


export type MutationsBulkSetTicketRoutesArgs = {
  newRoutes?: InputMaybe<Array<BulkSetTicketRouteInput>>;
};


export type MutationsBulkUpdateAgreementArgs = {
  updateAgreements?: InputMaybe<Array<BulkAgreementUpdateInput>>;
};


export type MutationsBulkUpdateCompanyEmailAccountMessageArgs = {
  input: BulkUpdateCompanyEmailAccountMessageInput;
  selfIds: Array<Scalars['ID']['input']>;
};


export type MutationsBulkUpdateContainerArgs = {
  input: BulkUpdateContainerInput;
};


export type MutationsBulkUpdateTicketArgs = {
  input: BulkUpdateTicketInput;
};


export type MutationsCheckDeviceIdArgs = {
  deviceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationsCompleteAlertArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsCompleteTicketDraftArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsConfirmDriverPushNotificationArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsCreateAgreementArgs = {
  input: CreateAgreementInput;
};


export type MutationsCreateBillingRunArgs = {
  billingCutoffDateInclusive: Scalars['Date']['input'];
  exportAfterCreation?: InputMaybe<Scalars['Boolean']['input']>;
  externalPropertyNumber?: InputMaybe<Scalars['String']['input']>;
  includeBusiness?: InputMaybe<Scalars['Boolean']['input']>;
  includePrivate?: InputMaybe<Scalars['Boolean']['input']>;
  municipalityCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationsCreateBulkWastePickupTicketArgs = {
  input: CreateBulkWastePickupTicketInput;
};


export type MutationsCreateCompanyEmailAccountMessageArgs = {
  input: CreateCompanyEmailAccountMessageInput;
};


export type MutationsCreateComparisonSettingsArgs = {
  input: CreateComparisonSettingsMutationInput;
};


export type MutationsCreateContainerArgs = {
  barcode?: InputMaybe<Scalars['String']['input']>;
  containerId: Scalars['String']['input'];
  containerTypeId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  deviceToContainers?: InputMaybe<Array<InputMaybe<DeviceToContainerInputObject>>>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  overflowingSettings?: InputMaybe<OverflowingSettingsObject>;
  photo?: InputMaybe<Scalars['Upload']['input']>;
  pickupSettingsId?: InputMaybe<Scalars['ID']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  showOnRoute?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  wasteCollectorId?: InputMaybe<Scalars['ID']['input']>;
  wasteFractionId: Scalars['ID']['input'];
};


export type MutationsCreateContainerImportArgs = {
  delimiter?: InputMaybe<Scalars['String']['input']>;
  importFile: Scalars['Upload']['input'];
  projectId: Scalars['ID']['input'];
  shouldCreate?: InputMaybe<Scalars['Boolean']['input']>;
  shouldUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  shouldUpdateGeocodeLocation?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsCreateContainerMobileArgs = {
  containerId: Scalars['String']['input'];
  containerTypeId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  deviceToContainers?: InputMaybe<Scalars['JSONString']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  photo?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  showOnRoute?: InputMaybe<Scalars['Boolean']['input']>;
  wasteCollectorId?: InputMaybe<Scalars['ID']['input']>;
  wasteFractionId: Scalars['ID']['input'];
};


export type MutationsCreateContainerPriceArgs = {
  input: CreateContainerPriceInput;
};


export type MutationsCreateContainerTypeArgs = {
  containerAccess?: InputMaybe<Scalars['String']['input']>;
  diameter?: InputMaybe<Scalars['Float']['input']>;
  emplacement: Scalars['String']['input'];
  emptyingTime?: InputMaybe<Scalars['Int']['input']>;
  height: Scalars['Float']['input'];
  isCylindrical?: InputMaybe<Scalars['Boolean']['input']>;
  length?: InputMaybe<Scalars['Float']['input']>;
  mobility?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  overflowingSettings?: InputMaybe<OverflowingSettingsObject>;
  photo?: InputMaybe<Scalars['String']['input']>;
  pickupSettingsId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
  vehicleCoupling: Array<InputMaybe<Scalars['String']['input']>>;
  volume: Scalars['Float']['input'];
  width?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationsCreateContainerWeightImportArgs = {
  delimiter?: InputMaybe<Scalars['String']['input']>;
  importFile: Scalars['Upload']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationsCreateCostSettingsArgs = {
  input: CreateCostSettingsMutationInput;
};


export type MutationsCreateDriverPushNotificationArgs = {
  input: CreateDriverPushNotificationInput;
};


export type MutationsCreateExportArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  excelFormat?: InputMaybe<Scalars['Boolean']['input']>;
  exportType: Scalars['String']['input'];
  fields: Array<InputMaybe<Scalars['String']['input']>>;
  projectId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationsCreateFillLevelDeviceMobileArgs = {
  devId: Scalars['String']['input'];
};


export type MutationsCreateManageContainerTicketArgs = {
  input: CreateManageContainerTicketInput;
};


export type MutationsCreateMeasurementSettingsArgs = {
  excludeDays?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  excludePeriodEnd?: InputMaybe<Scalars['DateTime']['input']>;
  excludePeriodEveryYear?: InputMaybe<Scalars['Boolean']['input']>;
  excludePeriodStart?: InputMaybe<Scalars['DateTime']['input']>;
  hours?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  measureAt?: InputMaybe<Scalars['Int']['input']>;
  measurementsPerHour?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationsCreateMobileDeviceArgs = {
  devId: Scalars['String']['input'];
  deviceTypeId: Scalars['ID']['input'];
};


export type MutationsCreateNotificationSettingArgs = {
  condition: Scalars['String']['input'];
  containerId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  threshold: Scalars['Float']['input'];
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationsCreateOrUpdateContainerIdGeneratorArgs = {
  input: UpdateOrCreateContainerIdGeneratorInput;
};


export type MutationsCreateOrUpdateDkdpCompanyCredentialsArgs = {
  input: DkdpCompanyCredentialsMutationInput;
};


export type MutationsCreateOrUpdateExportTemplateArgs = {
  automaticallySendExportToExternalRecepients?: InputMaybe<Scalars['Boolean']['input']>;
  automaticallySendExportToInternalRecepients?: InputMaybe<Scalars['Boolean']['input']>;
  dailyIntervalStartTime?: InputMaybe<Scalars['Time']['input']>;
  dailyIntervalStartingFrom?: InputMaybe<Scalars['Date']['input']>;
  exportFormat: ExportFormats;
  exportIntervalSettings?: InputMaybe<ExportIntervalEnum>;
  exportIntervalValue?: InputMaybe<Scalars['Int']['input']>;
  exportType: ExportTypes;
  externalRecipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filters?: InputMaybe<Array<InputMaybe<ExportFilterTypeInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  internalRecipients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isNestedFields?: InputMaybe<Scalars['Boolean']['input']>;
  mapSegments?: InputMaybe<Scalars['GenericScalar']['input']>;
  monthlyIntervalSetting?: InputMaybe<MonthlyIntervalEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  projects: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationsCreateOrUpdateRfidVehicleConnectionArgs = {
  input: RfidVehicleConnectionMutationInput;
};


export type MutationsCreateOrUpdateStopListCardConfigArgs = {
  cardTypeConfig: CardConfigOptions;
  fields: Array<InputMaybe<Scalars['String']['input']>>;
  showWhoCompleted: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};


export type MutationsCreateOrUpdateUrbaserCompanyCredentialsArgs = {
  input: UrbaserCredentialsMutationInput;
};


export type MutationsCreatePriceArgs = {
  input: CreatePriceInput;
};


export type MutationsCreateRecurringPriceArgs = {
  input: CreateRecurringPriceInput;
};


export type MutationsCreateRouteArgs = {
  driverId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  routeSchemeId: Scalars['ID']['input'];
  scheduledDate: Scalars['Date']['input'];
  vehicleId: Scalars['ID']['input'];
  vehicleTrailerId: Scalars['ID']['input'];
};


export type MutationsCreateScheduledExportRunArgs = {
  fromDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  toDate: Scalars['DateTime']['input'];
};


export type MutationsCreateSensorArgs = {
  antenna?: InputMaybe<Scalars['String']['input']>;
  apn?: InputMaybe<Scalars['String']['input']>;
  appKey?: InputMaybe<Scalars['String']['input']>;
  batch?: InputMaybe<Scalars['String']['input']>;
  connectionSubtype?: InputMaybe<Scalars['String']['input']>;
  connectionType: Scalars['String']['input'];
  devEui?: InputMaybe<Scalars['String']['input']>;
  devId: Scalars['String']['input'];
  deviceTypeName: Scalars['String']['input'];
  hwVersion?: InputMaybe<Scalars['Int']['input']>;
  iccid?: InputMaybe<Scalars['String']['input']>;
  imei?: InputMaybe<Scalars['String']['input']>;
  imsi?: InputMaybe<Scalars['String']['input']>;
  isGps?: InputMaybe<Scalars['Boolean']['input']>;
  modemFwVersion?: InputMaybe<Scalars['String']['input']>;
  pac?: InputMaybe<Scalars['String']['input']>;
  productNo?: InputMaybe<Scalars['String']['input']>;
  whsFwVersion?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsCreateServicePriceArgs = {
  input: CreateServicePriceInput;
};


export type MutationsCreateTicketArgs = {
  input: CreateTicketInput;
};


export type MutationsCreateTicketCommentArgs = {
  input: CreateTicketCommentInput;
};


export type MutationsCreateTicketCustomFieldArgs = {
  input: CreateTicketCustomFieldMutationInput;
};


export type MutationsCreateTicketDraftEmailArgs = {
  input: CreateTicketDraftEmailInput;
};


export type MutationsCreateTicketMessageArgs = {
  input: CreateTicketMessageInput;
};


export type MutationsCreateUnconfirmedUserArgs = {
  email: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<Scalars['JSONString']['input']>;
};


export type MutationsCreateUserArgs = {
  fullName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  repeatPassword: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationsCreateWasteCollectorArgs = {
  companyName: Scalars['String']['input'];
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
};


export type MutationsCreateWasteFractionArgs = {
  projectId: Scalars['ID']['input'];
  wasteCategory: Scalars['String']['input'];
  wasteType: Array<InputMaybe<Scalars['ID']['input']>>;
  weightFactor?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationsDeleteAgreementTemplateArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteAlertRuleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsDeleteAreaArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCardArgs = {
  billingInformationId: Scalars['ID']['input'];
  cardId: Scalars['String']['input'];
};


export type MutationsDeleteCollectionCalendarArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCollectionCalendarDateArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCollectionCalendarDeviationArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCollectionCalendarWeekArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCompanyContainerMetaTypeArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCompanyEmailAccountArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCompanyEmailAccountMessageArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCompanyEmailAccountRuleArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCompanyMetaTypeArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCompanyRoleArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteComparisonSettingsArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteContactPropertiesArgs = {
  contactId: Scalars['ID']['input'];
  contactPropertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationsDeleteContainerArgs = {
  containerId: Scalars['ID']['input'];
};


export type MutationsDeleteContainerGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsDeleteContainerNotificationArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteContainerTypeArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteCostSettingsArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteDashboardArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteDepotArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsDeleteDriverArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsDeleteExportTemplateArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteFcmDeviceArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsDeleteHaulerArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteHaulerPriceArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteImportHistoryArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteIntegrationGpsVehicleArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteMeasurementSettingsArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteNotificationSettingArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeletePickupSettingsArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteProjectArgs = {
  projectId: Scalars['ID']['input'];
};


export type MutationsDeletePropertyArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeletePropertyContactArgs = {
  propertyContact: Scalars['ID']['input'];
};


export type MutationsDeletePropertyGroupArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeletePropertyTypeArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteScheduledExportArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteServiceArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteTeamArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteTicketCategoryArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteTicketCustomFieldArgs = {
  customFieldId: Scalars['ID']['input'];
};


export type MutationsDeleteTicketMessageTemplateArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteTicketTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsDeleteUserAbsenceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsDeleteUserProjectArgs = {
  userProjectId: Scalars['ID']['input'];
};


export type MutationsDeleteVehicleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsDeleteVehicleAbsenceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsDeleteVehicleTypeArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteWasteCollectorArgs = {
  projectId: Scalars['ID']['input'];
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteWasteFractionArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDeleteWasteTypeArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsDemoInvitationArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  demoPeriod?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  projects: Array<InputMaybe<ProjectsInputObject>>;
};


export type MutationsDetachSensorArgs = {
  deviceId: Scalars['ID']['input'];
};


export type MutationsExportBillingRunToNavisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsExportTemplateScheduleSettingsUpdateArgs = {
  automaticallySendExportToExternalRecepients?: InputMaybe<Scalars['Boolean']['input']>;
  automaticallySendExportToInternalRecepients?: InputMaybe<Scalars['Boolean']['input']>;
  dailyIntervalStartTime?: InputMaybe<Scalars['Time']['input']>;
  dailyIntervalStartingFrom?: InputMaybe<Scalars['Date']['input']>;
  exportIntervalSettings?: InputMaybe<ExportIntervalEnum>;
  exportIntervalValue?: InputMaybe<Scalars['Int']['input']>;
  externalRecipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  internalRecipients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  monthlyIntervalSetting?: InputMaybe<MonthlyIntervalEnum>;
};


export type MutationsFormErrorArgs = {
  errorType: Scalars['String']['input'];
};


export type MutationsGenerateBarcodesForContainersArgs = {
  barcodeGenerationInputList: Array<BarcodeGenerationInput>;
};


export type MutationsGenerateCollectionCalendarsPickupSettingsArgs = {
  selfId: Scalars['ID']['input'];
  weekdays: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationsGenerateDatesForCollectionCalendarsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  periodEnd: Scalars['Date']['input'];
};


export type MutationsImpersonateArgs = {
  impersonatedId: Scalars['ID']['input'];
};


export type MutationsInternalUpdateOrCreateSalesOfferArgs = {
  input: InternalUpdateOrCreateSalesOfferInput;
};


export type MutationsInviteUsersArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  emails: Array<Scalars['String']['input']>;
  isMaster: Scalars['Boolean']['input'];
  isSuper: Scalars['Boolean']['input'];
  projectIds: Array<Scalars['ID']['input']>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsLocationArgs = {
  input: LocationInput;
};


export type MutationsLogViewPropertyEventArgs = {
  propertyId: Scalars['ID']['input'];
};


export type MutationsLogViewTicketEventArgs = {
  ticketId: Scalars['ID']['input'];
};


export type MutationsManageFilesArgs = {
  contentType?: InputMaybe<MediaContentEnumType>;
  deletedFiles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  newFiles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objId: Scalars['ID']['input'];
  toDir?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsMarkAllUserNotificationsReadArgs = {
  directOnly?: InputMaybe<Scalars['Boolean']['input']>;
  followingOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsMarkAllUserNotificationsSeenArgs = {
  directOnly?: InputMaybe<Scalars['Boolean']['input']>;
  followingOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsMarkUserJobNotificationReadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsMarkUserNotificationReadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsMarkUserNotificationSeenArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsObtainAttachmentsDirectUploadUrlArgs = {
  inputData?: InputMaybe<DirectFileUploadStartInput>;
};


export type MutationsObtainImportDirectUploadUrlArgs = {
  inputData?: InputMaybe<DirectImportUploadStartInput>;
};


export type MutationsOfflineModeArgs = {
  createTickets?: InputMaybe<Array<InputMaybe<OfflineCreateTicketInput>>>;
  routeId: Scalars['ID']['input'];
  routeStopsComplete?: InputMaybe<Array<InputMaybe<OfflineRouteStopCompleteInput>>>;
};


export type MutationsOrderSubscriptionQuoteArgs = {
  billingInformationId: Scalars['ID']['input'];
  storeType: Scalars['String']['input'];
  stripeId: Scalars['String']['input'];
};


export type MutationsPartialUpdateCompanySettingsArgs = {
  input: PartialUpdateCompanySettingsInput;
};


export type MutationsPasswordChangeArgs = {
  newPassword: Scalars['String']['input'];
  repeatPassword: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  uidb64?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationsPickupOrderApprovalCollectionAddApprovalsArgs = {
  collectionId: Scalars['ID']['input'];
  pickupOrderIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationsPickupOrderApprovalCollectionDeleteApprovalsArgs = {
  collectionId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsPickupOrderApprovalCollectionUpdateApprovalsArgs = {
  collectionId: Scalars['ID']['input'];
  completeCollection: Scalars['Boolean']['input'];
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  remove: Scalars['Boolean']['input'];
};


export type MutationsPublicUpdateUnconfirmedUserArgs = {
  createUser?: InputMaybe<Scalars['Boolean']['input']>;
  createUserPassword?: InputMaybe<Scalars['String']['input']>;
  createUserPasswordRepeat?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  inviteUsers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metaCompanyName?: InputMaybe<Scalars['String']['input']>;
  metaCountry?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaFullName?: InputMaybe<Scalars['String']['input']>;
  metaHowDidYouHearAboutUs?: InputMaybe<Array<InputMaybe<PublicUpdateUnconfirmedUserHowDidYouHearAboutUs>>>;
  metaInterestedHardware?: InputMaybe<Array<InputMaybe<PublicUpdateUnconfirmedUserInterestedHardware>>>;
  metaInterestedSoftware?: InputMaybe<Array<InputMaybe<PublicUpdateUnconfirmedUserInterestedSoftware>>>;
  metaJobTitle?: InputMaybe<PublicUpdateUnconfirmedUserJobTitle>;
  metaNumberOfEmployees?: InputMaybe<PublicUpdateUnconfirmedUserNumberOfEmployees>;
  metaPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  metaSegment?: InputMaybe<PublicUpdateUnconfirmedUserSegment>;
  selfId: Scalars['ID']['input'];
};


export type MutationsRefreshTokenArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsRegisterFcmDeviceArgs = {
  input: RegisterFcmDeviceInput;
};


export type MutationsRemoveAssociatedTicketsFromCompanyEmailAccountMessageArgs = {
  selfId: Scalars['ID']['input'];
  ticketIds: Array<Scalars['ID']['input']>;
};


export type MutationsResendInvitationArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationsReverseSearchLocationArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
};


export type MutationsRouteChangePrimaryVehicleArgs = {
  routeVehicleId: Scalars['ID']['input'];
};


export type MutationsRouteChangePrimaryVehicleFromMobileArgs = {
  routeVehicleId: Scalars['ID']['input'];
};


export type MutationsRouteCompleteArgs = {
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteDeleteArgs = {
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteExportArgs = {
  excelFormat: Scalars['Boolean']['input'];
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteJoinDifferentVehicleArgs = {
  routeId?: InputMaybe<Scalars['ID']['input']>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsRouteJoinSameVehicleArgs = {
  routeVehicleId: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsRouteOptimizeArgs = {
  forceOptimize?: InputMaybe<Scalars['Boolean']['input']>;
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteResetArgs = {
  bypassRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  removeCreatedTickets?: InputMaybe<Scalars['Boolean']['input']>;
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteSchemeDeleteArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsRouteSchemeOptimizeArgs = {
  daysInAdvance: Scalars['Int']['input'];
  selfId: Scalars['ID']['input'];
};


export type MutationsRouteSchemeOptimizeNewArgs = {
  periodEnd: Scalars['Date']['input'];
  periodStart: Scalars['Date']['input'];
  selfId: Scalars['ID']['input'];
};


export type MutationsRouteSchemePreviewArgs = {
  routeSchemeInput: RouteSchemeInput;
};


export type MutationsRouteStartArgs = {
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteStartFromMobileArgs = {
  routeId: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsRouteStopCompleteArgs = {
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTicketsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fillLevel?: InputMaybe<Scalars['Float']['input']>;
  orderId: Scalars['String']['input'];
  qrCode?: InputMaybe<Scalars['String']['input']>;
  rfidAutoComplete?: InputMaybe<Scalars['Boolean']['input']>;
  routeId: Scalars['ID']['input'];
  ticketId?: InputMaybe<Scalars['ID']['input']>;
  ticketIdStopCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationsRouteStopSetNextArgs = {
  orderId: Scalars['String']['input'];
  routeId: Scalars['ID']['input'];
  useGroup?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsRouteStopSetNextDepotArgs = {
  depotType: Scalars['String']['input'];
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteStopsAddArgs = {
  completeStops?: InputMaybe<Scalars['Boolean']['input']>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routeId: Scalars['ID']['input'];
  ticketIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationsRouteStopsCompleteArgs = {
  routeId: Scalars['ID']['input'];
  stops: Array<InputMaybe<RouteStop>>;
};


export type MutationsRouteStopsMoveArgs = {
  orderIds: Array<InputMaybe<Scalars['String']['input']>>;
  routeId: Scalars['ID']['input'];
  toRouteId: Scalars['ID']['input'];
};


export type MutationsRouteStopsOrderArgs = {
  orderIds: Array<InputMaybe<Scalars['String']['input']>>;
  routeId: Scalars['ID']['input'];
  startStopNumber: Scalars['Int']['input'];
};


export type MutationsRouteStopsUpdateArgs = {
  bypassRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  createdTicketsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  delete: Scalars['Boolean']['input'];
  fillLevel?: InputMaybe<Scalars['Float']['input']>;
  optimize?: InputMaybe<Scalars['Boolean']['input']>;
  orderIds: Array<InputMaybe<Scalars['String']['input']>>;
  reset: Scalars['Boolean']['input'];
  resetCreatedTicket?: InputMaybe<Scalars['Boolean']['input']>;
  resetCreatedTickets?: InputMaybe<Scalars['Boolean']['input']>;
  resetWillReturn?: InputMaybe<Scalars['Boolean']['input']>;
  routeId: Scalars['ID']['input'];
  ticketId?: InputMaybe<Scalars['ID']['input']>;
  ticketIdStopCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationsRouteUnCompleteArgs = {
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteUpdateAutoCompleteArgs = {
  autoComplete: Scalars['Boolean']['input'];
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteUpdateAutoCompleteNextStopArgs = {
  autoCompleteNextStop: Scalars['Boolean']['input'];
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteUpdateAutoOptimizeArgs = {
  autoOptimize: Scalars['Boolean']['input'];
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteUpdateContainerGroupLocationArgs = {
  containerGroupId: Scalars['ID']['input'];
  locationLatitude: Scalars['Float']['input'];
  locationLongitude: Scalars['Float']['input'];
};


export type MutationsRouteUpdateContainerLocationArgs = {
  containerId: Scalars['ID']['input'];
  locationLatitude: Scalars['Float']['input'];
  locationLongitude: Scalars['Float']['input'];
};


export type MutationsRouteUpdateLockedArgs = {
  locked: Scalars['Boolean']['input'];
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteUpdateLockedStartArgs = {
  lockedStart: Scalars['Boolean']['input'];
  routeId: Scalars['ID']['input'];
};


export type MutationsRouteUpdateRouteVehicleArgs = {
  routeId: Scalars['ID']['input'];
  routeVehicleId: Scalars['ID']['input'];
  vehicleId: Scalars['ID']['input'];
};


export type MutationsRouteUpdateRouteVehicleDriverArgs = {
  driverId: Scalars['ID']['input'];
  routeId: Scalars['ID']['input'];
  routeVehicleId: Scalars['ID']['input'];
};


export type MutationsRouteUpdateRouteVehicleMobileArgs = {
  routeId: Scalars['ID']['input'];
  routeVehicleId: Scalars['ID']['input'];
  vehicleId: Scalars['ID']['input'];
};


export type MutationsRouteUpdateRouteVehicleTrailerArgs = {
  routeId: Scalars['ID']['input'];
  routeVehicleId: Scalars['ID']['input'];
  vehicleTrailerId: Scalars['ID']['input'];
};


export type MutationsRouteVehicleSessionsCompleteArgs = {
  routeVehicleSessionIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationsRoutesStopsCompleteArgs = {
  stopsData: Array<InputMaybe<RoutesStopsCompleteInputType>>;
};


export type MutationsRoutesStopsMoveArgs = {
  routesAndOrders: Array<InputMaybe<RoutesStopsMoveInputType>>;
};


export type MutationsRoutesStopsUpdateArgs = {
  delete: Scalars['Boolean']['input'];
  fillLevel?: InputMaybe<Scalars['Float']['input']>;
  optimize?: InputMaybe<Scalars['Boolean']['input']>;
  reset: Scalars['Boolean']['input'];
  routes: Array<InputMaybe<RouteStopsInputType>>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationsSaveTicketTypeOrderArgs = {
  platform?: InputMaybe<PlatformChoices>;
  routeScheme?: InputMaybe<Scalars['ID']['input']>;
  ticketTypeIds: Array<Scalars['ID']['input']>;
};


export type MutationsSaveTicketTypeOrderSettingsArgs = {
  ticketTypeCategoriseBy?: InputMaybe<TicketTypeOrderCategoriseBy>;
  ticketTypeNavAppOrderType?: InputMaybe<OrderTypeChoices>;
  ticketTypePlatformOrderType?: InputMaybe<OrderTypeChoices>;
  ticketTypePortalOrderType?: InputMaybe<OrderTypeChoices>;
};


export type MutationsSearchLocationArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  search: Scalars['String']['input'];
};


export type MutationsSelfPasswordChangeArgs = {
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  newPassword: Scalars['String']['input'];
  repeatPassword: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsSendMqttMessageArgs = {
  connectionType: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  strMessage?: InputMaybe<Scalars['String']['input']>;
  topic: Scalars['String']['input'];
};


export type MutationsSetAgreementSharesArgs = {
  agreementId: Scalars['ID']['input'];
  shares?: InputMaybe<Array<AgreementShareInput>>;
  splitEvenly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsSetContainerGroupArgs = {
  containerGroupId?: InputMaybe<Scalars['ID']['input']>;
  containerIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationsSetContainerStorageDepotArgs = {
  containerIds: Array<Scalars['ID']['input']>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  newStatus?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsSetDefaultCardArgs = {
  billingInformationId: Scalars['ID']['input'];
  cardId: Scalars['String']['input'];
};


export type MutationsSetPropertiesInPropertyGroupArgs = {
  propertyGroupId: Scalars['ID']['input'];
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationsSetTicketPriorityArgs = {
  priority: Scalars['String']['input'];
  ticketId: Scalars['ID']['input'];
};


export type MutationsSetTicketRouteArgs = {
  removeFromRoute?: InputMaybe<Scalars['Boolean']['input']>;
  routeId: Scalars['ID']['input'];
  ticketId: Scalars['ID']['input'];
};


export type MutationsSyncCustomFieldsWithTicketTypeArgs = {
  customFields: Array<InputMaybe<CustomFieldTicketTypeInput>>;
  ticketTypeId: Scalars['ID']['input'];
};


export type MutationsTokenAuthArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationsTriggerExportArgs = {
  exportTemplateId: Scalars['ID']['input'];
  externalRecipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalRecipients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationsTriggerImportArgs = {
  importAction: ImportActions;
  importFile: Scalars['String']['input'];
  importFileDelimiter?: InputMaybe<ImportCsvDelimiters>;
  importFileFormat: ImportFormats;
  importType: ImportTypes;
  project: Scalars['ID']['input'];
};


export type MutationsUnpairFillLevelDeviceArgs = {
  selfId: Scalars['ID']['input'];
};


export type MutationsUpdateActiveProjectsArgs = {
  activeProjects: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationsUpdateAgreementArgs = {
  input: UpdateAgreementInput;
};


export type MutationsUpdateBillingInformationArgs = {
  address: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  country: Scalars['String']['input'];
  invoiceEmail: Scalars['String']['input'];
  invoicePhone: Scalars['String']['input'];
  name: Scalars['String']['input'];
  vatNumber: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};


export type MutationsUpdateBulkWastePickupTicketArgs = {
  input: UpdateBulkWastePickupTicketInput;
};


export type MutationsUpdateComparisonSettingsArgs = {
  input: UpdateComparisonSettingsMutationInput;
};


export type MutationsUpdateContainerArgs = {
  barcode?: InputMaybe<Scalars['String']['input']>;
  containerId: Scalars['String']['input'];
  containerTypeId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  deviceToContainers?: InputMaybe<Array<InputMaybe<DeviceToContainerInputObject>>>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  overflowingSettings?: InputMaybe<OverflowingSettingsObject>;
  photo?: InputMaybe<Scalars['Upload']['input']>;
  pickupSettingsId?: InputMaybe<Scalars['ID']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  selfId: Scalars['ID']['input'];
  showOnRoute?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  wasteCollectorId?: InputMaybe<Scalars['ID']['input']>;
  wasteFractionId: Scalars['ID']['input'];
};


export type MutationsUpdateContainerMobileArgs = {
  containerId: Scalars['String']['input'];
  containerTypeId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  deviceToContainers?: InputMaybe<Scalars['JSONString']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  photo?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  selfId: Scalars['ID']['input'];
  showOnRoute?: InputMaybe<Scalars['Boolean']['input']>;
  wasteCollectorId?: InputMaybe<Scalars['ID']['input']>;
  wasteFractionId: Scalars['ID']['input'];
};


export type MutationsUpdateContainerPriceArgs = {
  input: UpdateContainerPriceInput;
};


export type MutationsUpdateContainerTypeArgs = {
  containerAccess?: InputMaybe<Scalars['String']['input']>;
  diameter?: InputMaybe<Scalars['Float']['input']>;
  emplacement: Scalars['String']['input'];
  emptyingTime?: InputMaybe<Scalars['Int']['input']>;
  height: Scalars['Float']['input'];
  isCylindrical?: InputMaybe<Scalars['Boolean']['input']>;
  length?: InputMaybe<Scalars['Float']['input']>;
  mobility?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  overflowingSettings?: InputMaybe<OverflowingSettingsObject>;
  photo?: InputMaybe<Scalars['String']['input']>;
  pickupSettingsId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
  selfId: Scalars['ID']['input'];
  vehicleCoupling?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: Scalars['Float']['input'];
  width?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationsUpdateCostSettingsArgs = {
  input: UpdateCostSettingsMutationInput;
};


export type MutationsUpdateCurrentDriverLocationArgs = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  autoComplete?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  placeId?: InputMaybe<Scalars['String']['input']>;
  routeId: Scalars['ID']['input'];
  speed?: InputMaybe<Scalars['Float']['input']>;
  vehicleId: Scalars['ID']['input'];
};


export type MutationsUpdateManageContainerTicketArgs = {
  input: UpdateManageContainerTicketInput;
};


export type MutationsUpdateMeasurementSettingsArgs = {
  excludeDays?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  excludePeriodEnd?: InputMaybe<Scalars['DateTime']['input']>;
  excludePeriodEveryYear?: InputMaybe<Scalars['Boolean']['input']>;
  excludePeriodStart?: InputMaybe<Scalars['DateTime']['input']>;
  hours?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  measureAt?: InputMaybe<Scalars['Int']['input']>;
  measurementsPerHour?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  selfId: Scalars['ID']['input'];
};


export type MutationsUpdateNotificationSettingArgs = {
  condition: Scalars['String']['input'];
  containerId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  selfId: Scalars['ID']['input'];
  threshold: Scalars['Float']['input'];
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationsUpdateOrCreateAgreementExportArgs = {
  input: UpdateOrCreateCreateAgreementExportInput;
};


export type MutationsUpdateOrCreateAgreementTemplateArgs = {
  input: UpdateOrCreateAgreementTemplateInput;
};


export type MutationsUpdateOrCreateAlertRuleArgs = {
  input: UpdateOrCreateAlertRuleInput;
};


export type MutationsUpdateOrCreateAreaArgs = {
  input: UpdateOrCreateAreaInput;
};


export type MutationsUpdateOrCreateBulkPropertyArgs = {
  data?: InputMaybe<PropertyBulkUpdateCreateData>;
  input?: InputMaybe<Array<InputMaybe<PropertyBulkUpdateCreateInput>>>;
};


export type MutationsUpdateOrCreateBulkTicketArgs = {
  data?: InputMaybe<TicketBulkUpdateCreateData>;
  input?: InputMaybe<Array<InputMaybe<TicketBulkUpdateCreateInput>>>;
};


export type MutationsUpdateOrCreateCollectionCalendarArgs = {
  input: UpdateOrCreateCollectionCalendarInput;
};


export type MutationsUpdateOrCreateCollectionCalendarDateArgs = {
  input: UpdateOrCreateCollectionCalendarDateInput;
};


export type MutationsUpdateOrCreateCollectionCalendarDeviationArgs = {
  input: UpdateOrCreateCollectionCalendarDeviationInput;
};


export type MutationsUpdateOrCreateCollectionCalendarWeekArgs = {
  input: UpdateOrCreateCollectionCalendarWeekInput;
};


export type MutationsUpdateOrCreateCommentArgs = {
  anonymous?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  selfId?: InputMaybe<Scalars['ID']['input']>;
  targetId: Scalars['ID']['input'];
  terms?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsUpdateOrCreateCompanyArgs = {
  input: UpdateOrCreateCompanyInput;
};


export type MutationsUpdateOrCreateCompanyContainerMetaTypeArgs = {
  companyId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
  selfId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsUpdateOrCreateCompanyEmailAccountArgs = {
  input: UpdateOrCreateCompanyEmailAccountInput;
};


export type MutationsUpdateOrCreateCompanyEmailAccountRuleArgs = {
  input: UpdateOrCreateCompanyEmailAccountRuleInput;
};


export type MutationsUpdateOrCreateCompanyMainSettingsArgs = {
  input: UpdateOrCreateCompanyMainSettingsInput;
};


export type MutationsUpdateOrCreateCompanyMetaTypeArgs = {
  input: UpdateOrCreateCompanyMetaTypeInput;
};


export type MutationsUpdateOrCreateCompanyRoleArgs = {
  name: Scalars['String']['input'];
  permissions: Array<PermissionChoices>;
  selfId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsUpdateOrCreateContactArgs = {
  input: UpdateOrCreateContactInput;
};


export type MutationsUpdateOrCreateContainerArgs = {
  allowedHoursEnd?: InputMaybe<Scalars['String']['input']>;
  allowedHoursStart?: InputMaybe<Scalars['String']['input']>;
  containerId: Scalars['String']['input'];
  containerMetaValues?: InputMaybe<Array<InputMaybe<ContainerMetaValueInputObject>>>;
  containerTypeId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  devicesToContainer?: InputMaybe<Array<InputMaybe<SensorInputObject>>>;
  locationLatitude: Scalars['Float']['input'];
  locationLongitude: Scalars['Float']['input'];
  locationName: Scalars['String']['input'];
  overflowingSettings?: InputMaybe<OverflowingSettingsObject>;
  photo?: InputMaybe<Scalars['Upload']['input']>;
  pickupMethod: Scalars['String']['input'];
  pickupSettingId?: InputMaybe<Scalars['ID']['input']>;
  prioritizeInStartOfRoute?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ID']['input'];
  selfId?: InputMaybe<Scalars['ID']['input']>;
  sensorFillLevel?: InputMaybe<Scalars['Int']['input']>;
  sensorFillLevelPeriodHours?: InputMaybe<Scalars['Int']['input']>;
  showOnRoute?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  wasteCollectorId?: InputMaybe<Scalars['ID']['input']>;
  wasteFractionId: Scalars['ID']['input'];
};


export type MutationsUpdateOrCreateContainerBulkArgs = {
  data?: InputMaybe<UpdateOrCreateBulkContainerData>;
  input?: InputMaybe<Array<InputMaybe<UpdateOrCreateBulkContainerInput>>>;
};


export type MutationsUpdateOrCreateContainerGroupArgs = {
  input: UpdateOrCreateContainerGroupInput;
};


export type MutationsUpdateOrCreateContainerNewArgs = {
  input: UpdateOrCreateContainerNewInput;
};


export type MutationsUpdateOrCreateContainerNotificationArgs = {
  input: UpdateOrCreateContainerNotificationInput;
};


export type MutationsUpdateOrCreateContainerProductArgs = {
  input: UpdateOrCreateContainerProductInput;
};


export type MutationsUpdateOrCreateContainerTypeArgs = {
  input: UpdateOrCreateContainerTypeInput;
};


export type MutationsUpdateOrCreateCriiptoApplicationArgs = {
  input: UpdateOrCreateCreateCriiptoApplicationInput;
};


export type MutationsUpdateOrCreateDashboardArgs = {
  input: UpdateOrCreateDashboardInput;
};


export type MutationsUpdateOrCreateDepotArgs = {
  closingTime?: InputMaybe<Scalars['Time']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  contactPerson?: InputMaybe<ContactPersonInputObject>;
  depotType: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  geofenceRadius?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<LocationInput>;
  locationLatitude?: InputMaybe<Scalars['Float']['input']>;
  locationLongitude?: InputMaybe<Scalars['Float']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
  openingTime?: InputMaybe<Scalars['Time']['input']>;
  removeLogo?: InputMaybe<Scalars['Boolean']['input']>;
  selfId?: InputMaybe<Scalars['ID']['input']>;
  timeToLeaveTheFacility?: InputMaybe<Scalars['Time']['input']>;
  timeToUnloadTruck?: InputMaybe<Scalars['Time']['input']>;
  wasteFractions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationsUpdateOrCreateDocumentArgs = {
  input: UpdateOrCreateCreateDocumentInput;
};


export type MutationsUpdateOrCreateDriverArgs = {
  input: UpdateOrCreateDriverInput;
};


export type MutationsUpdateOrCreateHaulerArgs = {
  input: UpdateOrCreateHaulerInput;
};


export type MutationsUpdateOrCreateHaulerPriceArgs = {
  input: UpdateOrCreateHaulerPriceInput;
};


export type MutationsUpdateOrCreateIntegrationGpsArgs = {
  active: Scalars['Boolean']['input'];
  companyId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  selfId?: InputMaybe<Scalars['ID']['input']>;
  username: Scalars['String']['input'];
};


export type MutationsUpdateOrCreateIntegrationGpsVehicleArgs = {
  integrationId: Scalars['ID']['input'];
  selfId?: InputMaybe<Scalars['ID']['input']>;
  uniqueId: Scalars['String']['input'];
  vehicleId: Scalars['ID']['input'];
};


export type MutationsUpdateOrCreateMeasurementSettingsArgs = {
  input: UpdateOrCreateMeasurementSettingsInput;
};


export type MutationsUpdateOrCreatePickupOrderApprovalCollectionArgs = {
  input: UpdateOrCreatePickUpOrderApprovalCollectionInput;
};


export type MutationsUpdateOrCreatePickupSettingsArgs = {
  input: UpdateOrCreatePickupSettingsInput;
};


export type MutationsUpdateOrCreateProjectArgs = {
  input: UpdateOrCreateProjectInput;
};


export type MutationsUpdateOrCreateProjectSettingsArgs = {
  input: UpdateOrCreateProjectSettingsInput;
};


export type MutationsUpdateOrCreatePropertyArgs = {
  input: UpdateOrCreatePropertyInput;
};


export type MutationsUpdateOrCreatePropertyContactArgs = {
  input: UpdateOrCreatePropertyContactInput;
};


export type MutationsUpdateOrCreatePropertyGroupArgs = {
  input: UpdateOrCreatePropertyGroupInput;
};


export type MutationsUpdateOrCreatePropertyTypeArgs = {
  input: UpdateOrCreatePropertyTypeInput;
};


export type MutationsUpdateOrCreatePropertyUserArgs = {
  input: UpdateOrCreatePropertyUserInput;
};


export type MutationsUpdateOrCreateRecurringProductArgs = {
  input: UpdateOrCreateRecurringProductInput;
};


export type MutationsUpdateOrCreateRouteSchemeArgs = {
  input: UpdateOrCreateRouteSchemeInput;
};


export type MutationsUpdateOrCreateScheduledExportArgs = {
  input: UpdateOrCreateCreateScheduledExportInput;
};


export type MutationsUpdateOrCreateServiceArgs = {
  input: UpdateOrCreateServiceInput;
};


export type MutationsUpdateOrCreateServiceProductArgs = {
  input: UpdateOrCreateServiceProductInput;
};


export type MutationsUpdateOrCreateSkillArgs = {
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  selfId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsUpdateOrCreateSubscriptionQuoteArgs = {
  billingInformationId: Scalars['ID']['input'];
  features: Array<InputMaybe<BillingSubscriptionQuoteFeatureInput>>;
  storeType: Scalars['String']['input'];
  stripeId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsUpdateOrCreateTeamArgs = {
  input: UpdateOrCreateTeamInput;
};


export type MutationsUpdateOrCreateTicketCategoryArgs = {
  input: UpdateOrCreateTicketCategoryInput;
};


export type MutationsUpdateOrCreateTicketMessageTemplateArgs = {
  input: UpdateOrCreateTicketMessageTemplateInput;
};


export type MutationsUpdateOrCreateTicketTypeArgs = {
  input: UpdateOrCreateTicketTypeInput;
};


export type MutationsUpdateOrCreateUserAbsenceArgs = {
  absenceType: Scalars['String']['input'];
  end: Scalars['Date']['input'];
  projectId: Scalars['ID']['input'];
  replacementUserId: Scalars['String']['input'];
  selfId?: InputMaybe<Scalars['ID']['input']>;
  start: Scalars['Date']['input'];
  userId: Scalars['String']['input'];
};


export type MutationsUpdateOrCreateUserProjectArgs = {
  input: UpdateOrCreateUserProjectInput;
};


export type MutationsUpdateOrCreateVehicleArgs = {
  input: UpdateOrCreateVehicleInput;
};


export type MutationsUpdateOrCreateVehicleAbsenceArgs = {
  absenceType: Scalars['String']['input'];
  end: Scalars['Date']['input'];
  projectId: Scalars['ID']['input'];
  replacementVehicleId: Scalars['String']['input'];
  selfId?: InputMaybe<Scalars['ID']['input']>;
  start: Scalars['Date']['input'];
  vehicleId: Scalars['String']['input'];
};


export type MutationsUpdateOrCreateVehicleTypeArgs = {
  input: UpdateOrCreateVehicleTypeInput;
};


export type MutationsUpdateOrCreateWasteFractionArgs = {
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  selfId?: InputMaybe<Scalars['ID']['input']>;
  wasteCategory: Scalars['String']['input'];
  wasteSubstance: Scalars['String']['input'];
  wasteType?: InputMaybe<Scalars['String']['input']>;
  weightFactor?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationsUpdateOrCreateWasteFractionNewArgs = {
  input: UpdateOrCreateWasteFractionNewInput;
};


export type MutationsUpdatePriceArgs = {
  input: UpdatePriceInput;
};


export type MutationsUpdateRecurringPriceArgs = {
  input: UpdateRecurringPriceInput;
};


export type MutationsUpdateServicePriceArgs = {
  input: UpdateServicePriceInput;
};


export type MutationsUpdateShippingInformationArgs = {
  companyId: Scalars['ID']['input'];
  shippingAddress: Scalars['String']['input'];
  shippingAddress2: Scalars['String']['input'];
  shippingCity: Scalars['String']['input'];
  shippingCountry: Scalars['String']['input'];
  shippingName: Scalars['String']['input'];
  shippingZipCode: Scalars['String']['input'];
};


export type MutationsUpdateTicketArgs = {
  input: UpdateTicketInput;
};


export type MutationsUpdateTicketAttachmentsArgs = {
  addAttachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id: Scalars['ID']['input'];
  ignoreServiceFee?: InputMaybe<Scalars['Boolean']['input']>;
  removeAttachmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationsUpdateTicketCustomFieldArgs = {
  input: UpdateTicketCustomFieldMutationInput;
};


export type MutationsUpdateTicketDraftArgs = {
  input: UpdateTicketDraftInput;
};


export type MutationsUpdateTicketFollowersArgs = {
  followerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ticketId: Scalars['ID']['input'];
};


export type MutationsUpdateTicketStatusArgs = {
  bypassRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  rfid?: InputMaybe<Scalars['String']['input']>;
  routeId?: InputMaybe<Scalars['ID']['input']>;
  status: Scalars['String']['input'];
  ticketId: Scalars['ID']['input'];
};


export type MutationsUpdateTicketTypeOrderArgs = {
  insertAfterTicketTypeId?: InputMaybe<Scalars['ID']['input']>;
  ticketTypeId: Scalars['ID']['input'];
};


export type MutationsUpdateUnconfirmedUserArgs = {
  createUser?: InputMaybe<Scalars['Boolean']['input']>;
  selfId: Scalars['ID']['input'];
};


export type MutationsUpdateUserArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  datetimeFormatLocale?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isMaster?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  selfId: Scalars['ID']['input'];
};


export type MutationsUpdateUserLayoutConfigArgs = {
  collectionContainersDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  collectionContainersOrder?: InputMaybe<Scalars['String']['input']>;
  collectionContainersOrderBy?: InputMaybe<Scalars['String']['input']>;
  collectionContainersPageSize?: InputMaybe<Scalars['String']['input']>;
  commentsDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  commentsOrder?: InputMaybe<Scalars['String']['input']>;
  commentsOrderBy?: InputMaybe<Scalars['String']['input']>;
  commentsPageSize?: InputMaybe<Scalars['Int']['input']>;
  containersDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  containersOrder?: InputMaybe<Scalars['String']['input']>;
  containersOrderBy?: InputMaybe<Scalars['String']['input']>;
  containersPageSize?: InputMaybe<Scalars['Int']['input']>;
  driverDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  driverIconsSize?: InputMaybe<Scalars['String']['input']>;
  driverOrder?: InputMaybe<Scalars['String']['input']>;
  driverOrderBy?: InputMaybe<Scalars['String']['input']>;
  driverPageSize?: InputMaybe<Scalars['Int']['input']>;
  inquiryDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inquiryOrder?: InputMaybe<Scalars['String']['input']>;
  inquiryOrderBy?: InputMaybe<Scalars['String']['input']>;
  inquiryPageSize?: InputMaybe<Scalars['Int']['input']>;
  locationsDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locationsIconsSize?: InputMaybe<Scalars['String']['input']>;
  locationsOrder?: InputMaybe<Scalars['String']['input']>;
  locationsOrderBy?: InputMaybe<Scalars['String']['input']>;
  locationsPageSize?: InputMaybe<Scalars['Int']['input']>;
  notificationsDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notificationsOrder?: InputMaybe<Scalars['String']['input']>;
  notificationsOrderBy?: InputMaybe<Scalars['String']['input']>;
  notificationsPageSize?: InputMaybe<Scalars['Int']['input']>;
  pickupOrdersDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pickupOrdersOrder?: InputMaybe<Scalars['String']['input']>;
  pickupOrdersOrderBy?: InputMaybe<Scalars['String']['input']>;
  pickupOrdersPageSize?: InputMaybe<Scalars['Int']['input']>;
  routeDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  routeOrder?: InputMaybe<Scalars['String']['input']>;
  routeOrderBy?: InputMaybe<Scalars['String']['input']>;
  routePageSize?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
  vehicleDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleIconsSize?: InputMaybe<Scalars['String']['input']>;
  vehicleOrder?: InputMaybe<Scalars['String']['input']>;
  vehicleOrderBy?: InputMaybe<Scalars['String']['input']>;
  vehiclePageSize?: InputMaybe<Scalars['Int']['input']>;
  vehicleTypesDisabledColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleTypesOrder?: InputMaybe<Scalars['String']['input']>;
  vehicleTypesOrderBy?: InputMaybe<Scalars['String']['input']>;
  vehicleTypesPageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationsUpdateUsernameArgs = {
  email: Scalars['String']['input'];
  selfId: Scalars['ID']['input'];
};


export type MutationsUpdateWasteCollectorArgs = {
  companyName: Scalars['String']['input'];
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  selfId: Scalars['ID']['input'];
};


export type MutationsUpdateWasteFractionArgs = {
  projectId: Scalars['ID']['input'];
  selfId: Scalars['ID']['input'];
  wasteCategory: Scalars['String']['input'];
  wasteType: Array<InputMaybe<Scalars['ID']['input']>>;
  weightFactor?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationsUpdateWasteTypeArgs = {
  name: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  selfId: Scalars['ID']['input'];
  weightFactor?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationsVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type NavigationAppVersionType = Node & {
  __typename: 'NavigationAppVersionType';
  buildNumber: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  disabled: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type NotificationMethodDef = Node & {
  __typename: 'NotificationMethodDef';
  company: CompanyType;
  containerNotifications: ContainerNotificationDefConnection;
  createdAt: Scalars['DateTime']['output'];
  emailnotificationmethod?: Maybe<EmailNotificationMethodDef>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  smsnotificationmethod?: Maybe<SmsNotificationMethodDef>;
};


export type NotificationMethodDefContainerNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type NotificationMethodDefConnection = {
  __typename: 'NotificationMethodDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationMethodDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `NotificationMethodDef` and its cursor. */
export type NotificationMethodDefEdge = {
  __typename: 'NotificationMethodDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<NotificationMethodDef>;
};

export type NotificationSettingsType = Node & {
  __typename: 'NotificationSettingsType';
  condition?: Maybe<Scalars['String']['output']>;
  container?: Maybe<ContainerType>;
  description: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  recipients?: Maybe<Array<Scalars['String']['output']>>;
  /** % */
  threshold: Scalars['Float']['output'];
  users: UserTypeConnection;
};


export type NotificationSettingsTypeUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type NotificationSettingsTypeConnection = {
  __typename: 'NotificationSettingsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationSettingsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `NotificationSettingsType` and its cursor. */
export type NotificationSettingsTypeEdge = {
  __typename: 'NotificationSettingsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<NotificationSettingsType>;
};

export type NotificationSubscription = {
  __typename: 'NotificationSubscription';
  notification?: Maybe<NotificationsType>;
};

/** An enumeration. */
export enum NotificationsDriverPushNotificationNotificationTypeChoices {
  /** Integration Error */
  INTEGRATION_ERROR = 'INTEGRATION_ERROR',
  /** Offline Mode Conflict */
  OFFLINE_MODE_CONFLICT = 'OFFLINE_MODE_CONFLICT',
  /** Stop Added */
  STOP_ADDED = 'STOP_ADDED',
  /** Stop Removed */
  STOP_REMOVED = 'STOP_REMOVED',
  /** Stop Ticket Created */
  STOP_TICKET_CREATED = 'STOP_TICKET_CREATED',
  /** Unknown */
  UNKNOWN = 'UNKNOWN'
}

/** An enumeration. */
export enum NotificationsNotificationStatusChoices {
  /** DEBUG */
  DEBUG = 'DEBUG',
  /** ERROR */
  ERROR = 'ERROR',
  /** INFO */
  INFO = 'INFO',
  /** SUCCESS */
  SUCCESS = 'SUCCESS',
  /** WARNING */
  WARNING = 'WARNING'
}

export type NotificationsType = Node & {
  __typename: 'NotificationsType';
  containerDevice?: Maybe<DeviceToContainerType>;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isRead: UserTypeConnection;
  message: Scalars['String']['output'];
  messageDa?: Maybe<Scalars['String']['output']>;
  messageEl?: Maybe<Scalars['String']['output']>;
  messageEn?: Maybe<Scalars['String']['output']>;
  messageFi?: Maybe<Scalars['String']['output']>;
  messageNo?: Maybe<Scalars['String']['output']>;
  messageSv?: Maybe<Scalars['String']['output']>;
  status: NotificationsNotificationStatusChoices;
  title: Scalars['String']['output'];
  titleDa?: Maybe<Scalars['String']['output']>;
  titleEl?: Maybe<Scalars['String']['output']>;
  titleEn?: Maybe<Scalars['String']['output']>;
  titleFi?: Maybe<Scalars['String']['output']>;
  titleNo?: Maybe<Scalars['String']['output']>;
  titleSv?: Maybe<Scalars['String']['output']>;
};


export type NotificationsTypeIsReadArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type NotificationsTypeConnection = {
  __typename: 'NotificationsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NotificationsType` and its cursor. */
export type NotificationsTypeEdge = {
  __typename: 'NotificationsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<NotificationsType>;
};

export type ObjectAnalyticsHeatMapType = {
  __typename: 'ObjectAnalyticsHeatMapType';
  amount?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type ObjectAnalyticsTimeSeriesType = {
  __typename: 'ObjectAnalyticsTimeSeriesType';
  amount?: Maybe<Scalars['Float']['output']>;
  timestamp?: Maybe<Scalars['Int']['output']>;
};

/**
 * Provide a presigned URL and authentication data for uploading attachments directly to S3.
 * The URL has the expiration time, defined in settings by AWS_PRESIGNED_URL_EXPIRY.
 */
export type ObtainAttachmentsDirectUploadUrl = {
  __typename: 'ObtainAttachmentsDirectUploadUrl';
  presignedData?: Maybe<Array<Maybe<DirectFileUploadUrlDef>>>;
};

/**
 * Provide a presigned URL and authentication data for uploading attachments directly to S3.
 * The URL has the expiration time, defined in settings by AWS_PRESIGNED_URL_EXPIRY.
 */
export type ObtainImportDirectUploadUrl = {
  __typename: 'ObtainImportDirectUploadUrl';
  presignedData?: Maybe<Array<Maybe<DirectFileUploadUrlDef>>>;
};

export type ObtainJsonWebToken = {
  __typename: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type OfflineCreateTicketInput = {
  /** If true, the user creating the ticket will be added to the followers */
  addToFollowers?: InputMaybe<Scalars['Boolean']['input']>;
  assignedTeam?: InputMaybe<Scalars['ID']['input']>;
  assignee?: InputMaybe<Scalars['ID']['input']>;
  attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  container?: InputMaybe<Scalars['ID']['input']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<TicketCustomFieldValueInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ignoreServiceFee?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['ID']['input']>;
  preferredDate?: InputMaybe<Scalars['Date']['input']>;
  /** If true, do not create the ticket, but preview what it would look like if created - e.g. to get the prices. */
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  priority: Scalars['String']['input'];
  property?: InputMaybe<Scalars['ID']['input']>;
  reporter?: InputMaybe<Scalars['ID']['input']>;
  reporterDriver?: InputMaybe<Scalars['ID']['input']>;
  requestedBy?: InputMaybe<Scalars['ID']['input']>;
  requestedByOther?: InputMaybe<Scalars['String']['input']>;
  sendSummary?: InputMaybe<SendTicketSummaryInput>;
  serviceProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  stop: Scalars['ID']['input'];
  ticketType: Scalars['ID']['input'];
};

export type OfflineModeUpdateData = {
  __typename: 'OfflineModeUpdateData';
  conflictNotifications?: Maybe<Array<Maybe<DriverPushNotificationType>>>;
};

export type OfflineRouteStopCompleteInput = {
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTicketsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fillLevel?: InputMaybe<Scalars['Float']['input']>;
  orderId: Scalars['String']['input'];
  qrCode?: InputMaybe<Scalars['String']['input']>;
  rfidAutoComplete?: InputMaybe<Scalars['Boolean']['input']>;
  routeId: Scalars['ID']['input'];
  ticketId?: InputMaybe<Scalars['ID']['input']>;
  ticketIdStopCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type OperationManagementType = {
  __typename: 'OperationManagementType';
  driversStatuses?: Maybe<Array<Maybe<AnalyticsCount>>>;
  vehiclesStatuses?: Maybe<Array<Maybe<AnalyticsCount>>>;
};

export type OrderSubscriptionQuote = {
  __typename: 'OrderSubscriptionQuote';
  subscription?: Maybe<BillingSubscription>;
};

/** An enumeration. */
export enum OrderTypeChoices {
  FREQUENCY = 'FREQUENCY',
  MANUALLY = 'MANUALLY'
}

export type OverflowingSettingsObject = {
  duration?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['Int']['input']>;
};

export type OverflowingSettingsType = Node & {
  __typename: 'OverflowingSettingsType';
  container?: Maybe<ContainerType>;
  containerType?: Maybe<ContainerTypeType>;
  createdAt: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  percentage: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PartialUpdateCompanySettingsInput = {
  backgroundImageForHomePage?: InputMaybe<Scalars['String']['input']>;
  backgroundImageForLoginPage?: InputMaybe<Scalars['String']['input']>;
  barcodeTemplate?: InputMaybe<Scalars['String']['input']>;
  borderRadius?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  colorClosestStop?: InputMaybe<Scalars['String']['input']>;
  colorEmptiedStop?: InputMaybe<Scalars['String']['input']>;
  colorEmptiedWithTicketStop?: InputMaybe<Scalars['String']['input']>;
  colorNextStop?: InputMaybe<Scalars['String']['input']>;
  colorNotEmptiedWithTicketStop?: InputMaybe<Scalars['String']['input']>;
  colorPendingStop?: InputMaybe<Scalars['String']['input']>;
  colorSkippedStop?: InputMaybe<Scalars['String']['input']>;
  colorWillReturnStop?: InputMaybe<Scalars['String']['input']>;
  crmCompanyName?: InputMaybe<Scalars['String']['input']>;
  crmContactsAssignUniqueId?: InputMaybe<Scalars['String']['input']>;
  crmContactsMaskIndividualTaxId?: InputMaybe<Scalars['Boolean']['input']>;
  crmCountry?: InputMaybe<Scalars['String']['input']>;
  crmCustomTextHome?: InputMaybe<Scalars['String']['input']>;
  crmCustomTextHomeTitle?: InputMaybe<Scalars['String']['input']>;
  crmCustomTextLogin?: InputMaybe<Scalars['String']['input']>;
  crmCustomTextLoginTitle?: InputMaybe<Scalars['String']['input']>;
  crmDefaultLanguage?: InputMaybe<Scalars['String']['input']>;
  crmEmailSender?: InputMaybe<Scalars['ID']['input']>;
  crmEnableContainerNotificationProviders?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crmHostname?: InputMaybe<Scalars['String']['input']>;
  crmLoginLogo?: InputMaybe<Scalars['Upload']['input']>;
  crmLoginMethod?: InputMaybe<Scalars['String']['input']>;
  crmLoginMethodUser?: InputMaybe<Scalars['String']['input']>;
  crmLoginMethodUserIsAdministrator?: InputMaybe<Scalars['Boolean']['input']>;
  crmLoginMethodUserIsCoOwner?: InputMaybe<Scalars['Boolean']['input']>;
  crmLoginMethodUserIsContact?: InputMaybe<Scalars['Boolean']['input']>;
  crmLoginMethodUserIsOwner?: InputMaybe<Scalars['Boolean']['input']>;
  crmLoginMethodUserIsPayer?: InputMaybe<Scalars['Boolean']['input']>;
  crmLogo?: InputMaybe<Scalars['Upload']['input']>;
  crmPdfFooter?: InputMaybe<Scalars['String']['input']>;
  crmPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  crmPrimaryColor?: InputMaybe<Scalars['String']['input']>;
  crmSecondaryColor?: InputMaybe<Scalars['String']['input']>;
  crmSmsSender?: InputMaybe<Scalars['String']['input']>;
  crmSmsSenderSignature?: InputMaybe<Scalars['String']['input']>;
  crmSyncPropertiesFromMasterData?: InputMaybe<Scalars['Boolean']['input']>;
  crmSyncPropertyContactsFromMasterData?: InputMaybe<Scalars['Boolean']['input']>;
  dkdpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  fileTemplateAgreementExport?: InputMaybe<Scalars['String']['input']>;
  fileTemplateCoreScheduledExport?: InputMaybe<Scalars['String']['input']>;
  fileTemplateWasteheroRouteExport?: InputMaybe<Scalars['String']['input']>;
  mainPortalFont?: InputMaybe<Scalars['String']['input']>;
  mainPortalFontFamily?: InputMaybe<Scalars['String']['input']>;
  mapCountry?: InputMaybe<Scalars['String']['input']>;
  metabaseEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  portalColorSettings?: InputMaybe<Scalars['String']['input']>;
  portalPageSettings?: InputMaybe<PortalPageSettingsInputObject>;
  removeCrmLoginLogo?: InputMaybe<Scalars['Boolean']['input']>;
  removeCrmLogo?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryPortalFont?: InputMaybe<Scalars['String']['input']>;
  secondaryPortalFontFamily?: InputMaybe<Scalars['String']['input']>;
  supportedLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PartialUpdateCompanySettingsPayload = {
  __typename: 'PartialUpdateCompanySettingsPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companySettings?: Maybe<CompanySettingsType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  portalPageSettings?: Maybe<Array<Maybe<PortalPageDescriptionSettingType>>>;
};

export type PasswordChange = {
  __typename: 'PasswordChange';
  status?: Maybe<Scalars['String']['output']>;
};

export type PasswordReset = {
  __typename: 'PasswordReset';
  status?: Maybe<Scalars['String']['output']>;
};

export type PasswordResetVerify = {
  __typename: 'PasswordResetVerify';
  status?: Maybe<Scalars['String']['output']>;
};

export type PayloadType = {
  __typename: 'PayloadType';
  exp?: Maybe<Scalars['Int']['output']>;
  origIat?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodDef = Node & {
  __typename: 'PaymentMethodDef';
  createdAt: Scalars['DateTime']['output'];
  details?: Maybe<CardPaymentMethodDef>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type PaymentMethodDefConnection = {
  __typename: 'PaymentMethodDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentMethodDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PaymentMethodDef` and its cursor. */
export type PaymentMethodDefEdge = {
  __typename: 'PaymentMethodDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PaymentMethodDef>;
};

/**
 *
 *     make sure to also add to:
 *     - get_permissions_from_obj_type
 *     - permission_choices_mapping
 *
 */
export enum PermissionChoices {
  AGREEMENT_TEMPLATE_CREATE = 'agreement_template__create',
  AGREEMENT_TEMPLATE_DELETE = 'agreement_template__delete',
  AGREEMENT_TEMPLATE_EDIT = 'agreement_template__edit',
  ALERT_CREATE = 'alert__create',
  ALERT_DELETE = 'alert__delete',
  ALERT_EDIT = 'alert__edit',
  ALERT_RULE_CREATE = 'alert_rule__create',
  ALERT_RULE_DELETE = 'alert_rule__delete',
  ALERT_RULE_EDIT = 'alert_rule__edit',
  ANALYTICS_DASHBOARD_CREATE = 'analytics_dashboard__create',
  ANALYTICS_DASHBOARD_DELETE = 'analytics_dashboard__delete',
  ANALYTICS_DASHBOARD_EDIT = 'analytics_dashboard__edit',
  APPROVAL_COLLECTION_CREATE = 'approval_collection__create',
  APPROVAL_COLLECTION_DELETE = 'approval_collection__delete',
  APPROVAL_COLLECTION_EDIT = 'approval_collection__edit',
  BILLING_INFORMATION_CREATE = 'billing_information__create',
  BILLING_INFORMATION_EDIT = 'billing_information__edit',
  BILLING_RUN_CREATE = 'billing_run__create',
  BILLING_RUN_DELETE = 'billing_run__delete',
  BILLING_RUN_EXPORT_CREATE = 'billing_run_export__create',
  COLLECTION_CALENDAR_CREATE = 'collection_calendar__create',
  COLLECTION_CALENDAR_DELETE = 'collection_calendar__delete',
  COLLECTION_CALENDAR_EDIT = 'collection_calendar__edit',
  COLLECTION_CALENDAR_DATE_CREATE = 'collection_calendar_date__create',
  COLLECTION_CALENDAR_DATE_DELETE = 'collection_calendar_date__delete',
  COLLECTION_CALENDAR_DATE_EDIT = 'collection_calendar_date__edit',
  COLLECTION_CALENDAR_DEVIATION_CREATE = 'collection_calendar_deviation__create',
  COLLECTION_CALENDAR_DEVIATION_DELETE = 'collection_calendar_deviation__delete',
  COLLECTION_CALENDAR_DEVIATION_EDIT = 'collection_calendar_deviation__edit',
  COLLECTION_WEEK_CREATE = 'collection_week__create',
  COLLECTION_WEEK_DELETE = 'collection_week__delete',
  COLLECTION_WEEK_EDIT = 'collection_week__edit',
  COMPANY_EDIT = 'company__edit',
  COMPANY_EMAIL_ACCOUNT_CREATE = 'company_email_account__create',
  COMPANY_EMAIL_ACCOUNT_DELETE = 'company_email_account__delete',
  COMPANY_EMAIL_ACCOUNT_EDIT = 'company_email_account__edit',
  COMPANY_EMAIL_ACCOUNT_MESSAGE_DELETE = 'company_email_account_message__delete',
  COMPANY_EMAIL_ACCOUNT_MESSAGE_EDIT = 'company_email_account_message__edit',
  COMPANY_SETTINGS_EDIT = 'company_settings__edit',
  CONTACT_CREATE = 'contact__create',
  CONTACT_EDIT = 'contact__edit',
  CONTACT_PROPERTY_CREATE = 'contact_property__create',
  CONTACT_PROPERTY_DELETE = 'contact_property__delete',
  CONTAINER_CREATE = 'container__create',
  CONTAINER_DELETE = 'container__delete',
  CONTAINER_EDIT = 'container__edit',
  CONTAINER_GROUP_CREATE = 'container_group__create',
  CONTAINER_GROUP_DELETE = 'container_group__delete',
  CONTAINER_GROUP_EDIT = 'container_group__edit',
  CONTAINER_TYPE_CREATE = 'container_type__create',
  CONTAINER_TYPE_DELETE = 'container_type__delete',
  CONTAINER_TYPE_EDIT = 'container_type__edit',
  CRIIPTO_APPLICATION_CREATE = 'criipto_application__create',
  CRIIPTO_APPLICATION_DELETE = 'criipto_application__delete',
  CRIIPTO_APPLICATION_EDIT = 'criipto_application__edit',
  CRM_PRICING_CREATE = 'crm_pricing__create',
  CRM_PRICING_DELETE = 'crm_pricing__delete',
  CRM_PRICING_EDIT = 'crm_pricing__edit',
  CRM_PROPERTY_CREATE = 'crm_property__create',
  CRM_PROPERTY_DELETE = 'crm_property__delete',
  CRM_PROPERTY_EDIT = 'crm_property__edit',
  CRM_PROPERTY_GROUP_CREATE = 'crm_property_group__create',
  CRM_PROPERTY_GROUP_DELETE = 'crm_property_group__delete',
  CRM_PROPERTY_GROUP_EDIT = 'crm_property_group__edit',
  CRM_PROPERTY_TYPE_CREATE = 'crm_property_type__create',
  CRM_PROPERTY_TYPE_DELETE = 'crm_property_type__delete',
  CRM_PROPERTY_TYPE_EDIT = 'crm_property_type__edit',
  CRM_SERVICE_CREATE = 'crm_service__create',
  CRM_SERVICE_DELETE = 'crm_service__delete',
  CRM_SERVICE_EDIT = 'crm_service__edit',
  DEBTOR_EXPORT_CREATE = 'debtor_export__create',
  DEPOT_CREATE = 'depot__create',
  DEPOT_DELETE = 'depot__delete',
  DEPOT_EDIT = 'depot__edit',
  DKDP_COMPANY_CREDENTIALS_CREATE = 'dkdp_company_credentials__create',
  DKDP_COMPANY_CREDENTIALS_DELETE = 'dkdp_company_credentials__delete',
  DKDP_COMPANY_CREDENTIALS_EDIT = 'dkdp_company_credentials__edit',
  DOCUMENT_CREATE = 'document__create',
  DOCUMENT_DELETE = 'document__delete',
  DOCUMENT_EDIT = 'document__edit',
  DRIVER_CREATE = 'driver__create',
  DRIVER_DELETE = 'driver__delete',
  DRIVER_EDIT = 'driver__edit',
  EXPORT_TEMPLATE_CREATE = 'export_template__create',
  EXPORT_TEMPLATE_DELETE = 'export_template__delete',
  EXPORT_TEMPLATE_EDIT = 'export_template__edit',
  IMPORT_HISTORY_CREATE = 'import_history__create',
  IMPORT_HISTORY_DELETE = 'import_history__delete',
  IMPORT_HISTORY_EDIT = 'import_history__edit',
  MEASUREMENT_SETTINGS_CREATE = 'measurement_settings__create',
  MEASUREMENT_SETTINGS_DELETE = 'measurement_settings__delete',
  MEASUREMENT_SETTINGS_EDIT = 'measurement_settings__edit',
  PICKUP_ORDER_CREATE = 'pickup_order__create',
  PICKUP_ORDER_DELETE = 'pickup_order__delete',
  PICKUP_ORDER_EDIT = 'pickup_order__edit',
  PICKUP_SETTINGS_CREATE = 'pickup_settings__create',
  PICKUP_SETTINGS_DELETE = 'pickup_settings__delete',
  PICKUP_SETTINGS_EDIT = 'pickup_settings__edit',
  PROJECT_CREATE = 'project__create',
  PROJECT_DELETE = 'project__delete',
  PROJECT_EDIT = 'project__edit',
  RFID_VEHICLE_CONNECTION_CREATE = 'rfid_vehicle_connection__create',
  RFID_VEHICLE_CONNECTION_DELETE = 'rfid_vehicle_connection__delete',
  RFID_VEHICLE_CONNECTION_EDIT = 'rfid_vehicle_connection__edit',
  ROLE_CREATE = 'role__create',
  ROLE_DELETE = 'role__delete',
  ROLE_EDIT = 'role__edit',
  ROUTE_CREATE = 'route__create',
  ROUTE_DELETE = 'route__delete',
  ROUTE_EDIT = 'route__edit',
  ROUTE_SCHEME_CREATE = 'route_scheme__create',
  ROUTE_SCHEME_DELETE = 'route_scheme__delete',
  ROUTE_SCHEME_EDIT = 'route_scheme__edit',
  ROUTE_VEHICLE_EDIT = 'route_vehicle__edit',
  STOP_LIST_CUSTOMISATION_CONFIG_EDIT = 'stop_list_customisation_config__edit',
  TEAM_CREATE = 'team__create',
  TEAM_DELETE = 'team__delete',
  TEAM_EDIT = 'team__edit',
  TICKET_CATEGORY_CREATE = 'ticket_category__create',
  TICKET_CATEGORY_DELETE = 'ticket_category__delete',
  TICKET_CATEGORY_EDIT = 'ticket_category__edit',
  TICKET_CUSTOM_FIELD_CREATE = 'ticket_custom_field__create',
  TICKET_CUSTOM_FIELD_DELETE = 'ticket_custom_field__delete',
  TICKET_CUSTOM_FIELD_EDIT = 'ticket_custom_field__edit',
  TICKET_CUSTOM_FIELD_VIEW = 'ticket_custom_field__view',
  TICKET_MESSAGE_TEMPLATE_CREATE = 'ticket_message_template__create',
  TICKET_MESSAGE_TEMPLATE_DELETE = 'ticket_message_template__delete',
  TICKET_MESSAGE_TEMPLATE_EDIT = 'ticket_message_template__edit',
  TICKET_TYPE_CREATE = 'ticket_type__create',
  TICKET_TYPE_DELETE = 'ticket_type__delete',
  TICKET_TYPE_EDIT = 'ticket_type__edit',
  USER_CREATE = 'user__create',
  USER_DELETE = 'user__delete',
  USER_EDIT = 'user__edit',
  USER_ABSENCE_CREATE = 'user_absence__create',
  USER_ABSENCE_DELETE = 'user_absence__delete',
  USER_ABSENCE_EDIT = 'user_absence__edit',
  USER_PROJECT_CREATE = 'user_project__create',
  USER_PROJECT_DELETE = 'user_project__delete',
  USER_PROJECT_EDIT = 'user_project__edit',
  VEHICLE_CREATE = 'vehicle__create',
  VEHICLE_DELETE = 'vehicle__delete',
  VEHICLE_EDIT = 'vehicle__edit',
  VEHICLE_ABSENCE_CREATE = 'vehicle_absence__create',
  VEHICLE_ABSENCE_DELETE = 'vehicle_absence__delete',
  VEHICLE_ABSENCE_EDIT = 'vehicle_absence__edit',
  VEHICLE_TYPE_CREATE = 'vehicle_type__create',
  VEHICLE_TYPE_DELETE = 'vehicle_type__delete',
  VEHICLE_TYPE_EDIT = 'vehicle_type__edit',
  WASTE_FRACTION_CREATE = 'waste_fraction__create',
  WASTE_FRACTION_DELETE = 'waste_fraction__delete',
  WASTE_FRACTION_EDIT = 'waste_fraction__edit'
}

export type PermissionType = Node & {
  __typename: 'PermissionType';
  codename: Scalars['String']['output'];
  groupSet: GroupTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Specific permissions for this user. */
  userSet: UserTypeConnection;
};


export type PermissionTypeGroupSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PermissionTypeUserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PermissionTypeConnection = {
  __typename: 'PermissionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PermissionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PermissionType` and its cursor. */
export type PermissionTypeEdge = {
  __typename: 'PermissionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PermissionType>;
};

export type PickUpOrderApprovalCollectionAddApprovals = {
  __typename: 'PickUpOrderApprovalCollectionAddApprovals';
  approvalCollection?: Maybe<PickUpOrderApprovalCollectionDef>;
};

export type PickUpOrderApprovalCollectionDef = Node & {
  __typename: 'PickUpOrderApprovalCollectionDef';
  averageWeightDeviation?: Maybe<Scalars['Float']['output']>;
  company: CompanyType;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<UserType>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pickuporderapprovalSet: PickUpOrderApprovalDefConnection;
  properties?: Maybe<Scalars['Int']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  totalWeight?: Maybe<Scalars['Float']['output']>;
  wasteFractions?: Maybe<Array<Maybe<WasteFractionType>>>;
};


export type PickUpOrderApprovalCollectionDefPickuporderapprovalSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PickUpOrderApprovalCollectionDefConnection = {
  __typename: 'PickUpOrderApprovalCollectionDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PickUpOrderApprovalCollectionDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PickUpOrderApprovalCollectionDef` and its cursor. */
export type PickUpOrderApprovalCollectionDefEdge = {
  __typename: 'PickUpOrderApprovalCollectionDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PickUpOrderApprovalCollectionDef>;
};

export type PickUpOrderApprovalCollectionDeleteApprovals = {
  __typename: 'PickUpOrderApprovalCollectionDeleteApprovals';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PickUpOrderApprovalCollectionUpdateApprovals = {
  __typename: 'PickUpOrderApprovalCollectionUpdateApprovals';
  approvalCollection?: Maybe<PickUpOrderApprovalCollectionDef>;
};

export type PickUpOrderApprovalDef = Node & {
  __typename: 'PickUpOrderApprovalDef';
  approvalCollection: PickUpOrderApprovalCollectionDef;
  company: CompanyType;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<UserType>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  pickupOrder: PickUpOrderType;
  weightDeviation?: Maybe<Scalars['Int']['output']>;
};

export type PickUpOrderApprovalDefConnection = {
  __typename: 'PickUpOrderApprovalDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PickUpOrderApprovalDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PickUpOrderApprovalDef` and its cursor. */
export type PickUpOrderApprovalDefEdge = {
  __typename: 'PickUpOrderApprovalDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PickUpOrderApprovalDef>;
};

export type PickUpOrderEventDef = Node & {
  __typename: 'PickUpOrderEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<PickUpOrderEventTypesGraphene>>>;
  fromCompletedDate?: Maybe<Scalars['DateTime']['output']>;
  fromStatus?: Maybe<Scalars['String']['output']>;
  fromWeight?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  pickuporder?: Maybe<PickUpOrderType>;
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toCompletedDate?: Maybe<Scalars['DateTime']['output']>;
  toStatus?: Maybe<Scalars['String']['output']>;
  toWeight?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<UserType>;
};

export enum PickUpOrderEventTypesGraphene {
  COMPLETED_DATE_CHANGE = 'COMPLETED_DATE_CHANGE',
  STATUS_CHANGE = 'STATUS_CHANGE',
  WEIGHT_CHANGE = 'WEIGHT_CHANGE'
}

export type PickUpOrderEventsWrapper = {
  __typename: 'PickUpOrderEventsWrapper';
  edges?: Maybe<Array<Maybe<PickUpOrderEventDef>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PickUpOrderToBringItem = ToBringContainerDef;

export type PickUpOrderType = Node & {
  __typename: 'PickUpOrderType';
  area?: Maybe<AreaDef>;
  autoCompletedBy?: Maybe<RoutesPickUpOrderAutoCompletedByChoices>;
  canBeReset?: Maybe<Scalars['Boolean']['output']>;
  /** Real time when driver finish pickup */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User who completed the stop manually */
  completedManualBy?: Maybe<UserType>;
  container?: Maybe<ContainerType>;
  containerLevelTicketStopCount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdFrom: RoutesPickUpOrderCreatedFromChoices;
  createdFromManualBy?: Maybe<UserType>;
  createdInquiry?: Maybe<CollectionInquiryType>;
  createdTicket?: Maybe<TicketDef>;
  createdTickets: TicketDefConnection;
  depot?: Maybe<DepotType>;
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  driverpushnotificationSet: DriverPushNotificationTypeConnection;
  estimatedFillPercentage?: Maybe<Scalars['Float']['output']>;
  estimatedWeight?: Maybe<Scalars['Float']['output']>;
  eventLog?: Maybe<PickUpOrderEventsWrapper>;
  fillLevel?: Maybe<Scalars['Float']['output']>;
  fillPercentage?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  lastCollectionDate?: Maybe<Scalars['DateTime']['output']>;
  location?: Maybe<LocationType>;
  measurementCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  measurementFillLevel?: Maybe<Scalars['Float']['output']>;
  measurementFillPercentage?: Maybe<Scalars['Float']['output']>;
  navigationLocation?: Maybe<LocationType>;
  orderId: Scalars['UUID']['output'];
  orderType?: Maybe<Scalars['String']['output']>;
  pickuporderapproval?: Maybe<PickUpOrderApprovalDef>;
  propertyLevelTicketStopCount?: Maybe<Scalars['Int']['output']>;
  resetWillReturn: Scalars['Boolean']['output'];
  route?: Maybe<RouteType>;
  routeinformationSet: RouteInformationTypeConnection;
  /** Scheduled driver arrival to pickup location */
  scheduledDriverArrival?: Maybe<Scalars['Time']['output']>;
  /** PickUp order status */
  status: RoutesPickUpOrderStatusChoices;
  stopNumber: Scalars['Int']['output'];
  ticket?: Maybe<TicketDef>;
  ticketLevelStopsCount?: Maybe<Scalars['Int']['output']>;
  toBring: Array<PickUpOrderToBringItem>;
  updatedAt: Scalars['DateTime']['output'];
  useGroup: Scalars['Boolean']['output'];
  /** Vehicle session when the stop was added by a driver */
  vehicleSessionAdded?: Maybe<RouteVehicleSessionType>;
  /** Vehicle session when the stop was completed by a driver */
  vehicleSessionCompleted?: Maybe<RouteVehicleSessionType>;
  versions: PickUpOrderTypeConnection;
  volume?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weightAmount?: Maybe<Scalars['Int']['output']>;
  weightDeviation?: Maybe<Scalars['Int']['output']>;
};


export type PickUpOrderTypeCreatedTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PickUpOrderTypeDriverpushnotificationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PickUpOrderTypeEventLogArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pickupOrderEventTypes?: InputMaybe<Array<InputMaybe<PickUpOrderEventTypesGraphene>>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type PickUpOrderTypeRouteinformationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PickUpOrderTypeVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PickUpOrderTypeConnection = {
  __typename: 'PickUpOrderTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PickUpOrderTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PickUpOrderType` and its cursor. */
export type PickUpOrderTypeEdge = {
  __typename: 'PickUpOrderTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PickUpOrderType>;
};

export type PickupDayType = Node & {
  __typename: 'PickupDayType';
  containerSet: ContainerTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pickupSettings?: Maybe<PickupSettingsType>;
  timeFrom: Scalars['Time']['output'];
  timeTo: Scalars['Time']['output'];
  weekday?: Maybe<Scalars['String']['output']>;
};


export type PickupDayTypeContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PickupDayTypeConnection = {
  __typename: 'PickupDayTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PickupDayTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PickupDayType` and its cursor. */
export type PickupDayTypeEdge = {
  __typename: 'PickupDayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PickupDayType>;
};

export type PickupSettingsEventDef = Node & {
  __typename: 'PickupSettingsEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<PickupSettingsEventTypesGraphene>>>;
  fromAllowedHoursFrom?: Maybe<Scalars['Time']['output']>;
  fromAllowedHoursTo?: Maybe<Scalars['Time']['output']>;
  fromAmountDaysBetweenPickups?: Maybe<Scalars['Int']['output']>;
  fromCollectionPerWeek?: Maybe<Scalars['Int']['output']>;
  fromEmptyingIntervalFrom?: Maybe<Scalars['Int']['output']>;
  fromEmptyingIntervalTo?: Maybe<Scalars['Int']['output']>;
  fromExcludeDays?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fromFirstCollection?: Maybe<Scalars['Date']['output']>;
  fromFixedPickupsPeriod?: Maybe<Scalars['String']['output']>;
  fromMinimumDaysBetweenPickup?: Maybe<Scalars['Int']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  fromPickupInterval?: Maybe<Scalars['String']['output']>;
  fromPickupRepeatPeriod?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  pickupSettings?: Maybe<PickupSettingsType>;
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toAllowedHoursFrom?: Maybe<Scalars['Time']['output']>;
  toAllowedHoursTo?: Maybe<Scalars['Time']['output']>;
  toAmountDaysBetweenPickups?: Maybe<Scalars['Int']['output']>;
  toCollectionPerWeek?: Maybe<Scalars['Int']['output']>;
  toEmptyingIntervalFrom?: Maybe<Scalars['Int']['output']>;
  toEmptyingIntervalTo?: Maybe<Scalars['Int']['output']>;
  toExcludeDays?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  toFirstCollection?: Maybe<Scalars['Date']['output']>;
  toFixedPickupsPeriod?: Maybe<Scalars['String']['output']>;
  toMinimumDaysBetweenPickup?: Maybe<Scalars['Int']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  toPickupInterval?: Maybe<Scalars['String']['output']>;
  toPickupRepeatPeriod?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<UserType>;
};

export enum PickupSettingsEventTypesGraphene {
  COLLECTION_PER_WEEK_CHANGE = 'COLLECTION_PER_WEEK_CHANGE',
  MINIMUM_DAYS_BETWEEN_PICKUP_CHANGE = 'MINIMUM_DAYS_BETWEEN_PICKUP_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE',
  PICKUP_REPEAT_PERIOD_CHANGE = 'PICKUP_REPEAT_PERIOD_CHANGE'
}

export type PickupSettingsType = Node & {
  __typename: 'PickupSettingsType';
  allowedHoursFrom?: Maybe<Scalars['Time']['output']>;
  allowedHoursTo?: Maybe<Scalars['Time']['output']>;
  amountDaysBetweenPickups?: Maybe<Scalars['Int']['output']>;
  collectionPerWeek?: Maybe<Scalars['Int']['output']>;
  collectioncalendarSet: CollectionCalendarDefConnection;
  collectionsInAYear?: Maybe<Scalars['Int']['output']>;
  container?: Maybe<ContainerType>;
  containerTypes: ContainerTypeTypeConnection;
  containersCount?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  emptyingIntervalFrom?: Maybe<Scalars['Time']['output']>;
  emptyingIntervalTo?: Maybe<Scalars['Time']['output']>;
  excludeDays?: Maybe<Array<Scalars['String']['output']>>;
  firstCollection?: Maybe<Scalars['Date']['output']>;
  fixedPickupsPeriod?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  latestCollectionCalendarDate?: Maybe<Scalars['Date']['output']>;
  minimumDaysBetweenPickup?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pickupDay: PickupDayTypeConnection;
  pickupInterval?: Maybe<Scalars['String']['output']>;
  pickupRepeatPeriod?: Maybe<Scalars['Int']['output']>;
  pickupSetting: ContainerTypeConnection;
  project?: Maybe<ProjectType>;
  scheduledexportSet: ScheduledExportDefConnection;
  scheduledexportrunSet: ScheduledExportRunDefConnection;
};


export type PickupSettingsTypeCollectioncalendarSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PickupSettingsTypeContainerTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PickupSettingsTypePickupDayArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PickupSettingsTypePickupSettingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PickupSettingsTypeScheduledexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PickupSettingsTypeScheduledexportrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PickupSettingsTypeConnection = {
  __typename: 'PickupSettingsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PickupSettingsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PickupSettingsType` and its cursor. */
export type PickupSettingsTypeEdge = {
  __typename: 'PickupSettingsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PickupSettingsType>;
};

/** An enumeration. */
export enum PlatformChoices {
  NAV_APP = 'NAV_APP',
  PLATFORM = 'PLATFORM',
  PORTAL = 'PORTAL'
}

export type PolygonInput = {
  coordinates?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>>;
};

/** An enumeration. */
export enum PortalPageChoices {
  HOME = 'HOME',
  LOGIN = 'LOGIN'
}

export type PortalPageDescriptionSettingType = Node & {
  __typename: 'PortalPageDescriptionSettingType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  page?: Maybe<PortalPageChoices>;
  sections?: Maybe<Array<Maybe<PortalPageSectionSettingType>>>;
  title: Scalars['String']['output'];
};

export type PortalPageDescriptionSettingTypeConnection = {
  __typename: 'PortalPageDescriptionSettingTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PortalPageDescriptionSettingTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PortalPageDescriptionSettingType` and its cursor. */
export type PortalPageDescriptionSettingTypeEdge = {
  __typename: 'PortalPageDescriptionSettingTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PortalPageDescriptionSettingType>;
};

export type PortalPageSectionSettingInputObject = {
  backgroundImage?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  linkLabel?: InputMaybe<Scalars['String']['input']>;
  linkUrl?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PortalPageSectionSettingType = Node & {
  __typename: 'PortalPageSectionSettingType';
  backgroundImage: Scalars['String']['output'];
  description: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  linkLabel: Scalars['String']['output'];
  linkUrl: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type PortalPageSettingsInputObject = {
  page?: InputMaybe<PortalPageChoices>;
  sections?: InputMaybe<Array<InputMaybe<PortalPageSectionSettingInputObject>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PriceDef = Node & {
  __typename: 'PriceDef';
  amount: Scalars['Int']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  endDate: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  product: ProductDef;
  recurringInterval: CrmPriceRecurringIntervalChoices;
  startDate: Scalars['Date']['output'];
  weightAmount: Scalars['Int']['output'];
};

export type PriceDefConnection = {
  __typename: 'PriceDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PriceDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PriceDef` and its cursor. */
export type PriceDefEdge = {
  __typename: 'PriceDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PriceDef>;
};

export type PriceEventDef = Node & {
  __typename: 'PriceEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<PriceEventTypesGraphene>>>;
  fromAmount?: Maybe<Scalars['Int']['output']>;
  fromEndDate?: Maybe<Scalars['Date']['output']>;
  fromRecurringInterval?: Maybe<Scalars['String']['output']>;
  fromStartDate?: Maybe<Scalars['Date']['output']>;
  fromWeightAmount?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  price?: Maybe<PriceDef>;
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toAmount?: Maybe<Scalars['Int']['output']>;
  toEndDate?: Maybe<Scalars['Date']['output']>;
  toRecurringInterval?: Maybe<Scalars['String']['output']>;
  toStartDate?: Maybe<Scalars['Date']['output']>;
  toWeightAmount?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<UserType>;
};

export enum PriceEventTypesGraphene {
  AMOUNT_CHANGE = 'AMOUNT_CHANGE',
  END_DATE_CHANGE = 'END_DATE_CHANGE',
  RECURRING_INTERVAL_CHANGE = 'RECURRING_INTERVAL_CHANGE',
  START_DATE_CHANGE = 'START_DATE_CHANGE',
  WEIGHT_AMOUNT_CHANGE = 'WEIGHT_AMOUNT_CHANGE'
}

export type ProductDef = Node & {
  __typename: 'ProductDef';
  activePrice?: Maybe<ActivePriceDef>;
  agreementSet: AgreementDefConnection;
  agreementtemplateproductSet: AgreementTemplateProductDefConnection;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  company: CompanyType;
  containerType?: Maybe<ContainerTypeType>;
  createdAt: Scalars['DateTime']['output'];
  enableOnPortal: Scalars['Boolean']['output'];
  externalInvoiceCode: Scalars['String']['output'];
  haulerpriceSet: HaulerPriceDefConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  includeOnInvoicesIfFree: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nameOnInvoice: Scalars['String']['output'];
  pickupSetting?: Maybe<PickupSettingsType>;
  prices: PriceDefConnection;
  productType: CrmProductProductTypeChoices;
  propertyType?: Maybe<PropertyTypeDef>;
  service?: Maybe<ServiceDef>;
  updatedAt: Scalars['DateTime']['output'];
  wasteFraction?: Maybe<WasteFractionType>;
};


export type ProductDefAgreementSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductDefAgreementtemplateproductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductDefHaulerpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductDefPricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductDefConnection = {
  __typename: 'ProductDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ProductDef` and its cursor. */
export type ProductDefEdge = {
  __typename: 'ProductDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ProductDef>;
};

export type ProductEventDef = Node & {
  __typename: 'ProductEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<ProductEventTypesGraphene>>>;
  fromArchivedAt?: Maybe<Scalars['DateTime']['output']>;
  fromContainerType?: Maybe<ContainerTypeType>;
  fromEnableOnPortal?: Maybe<Scalars['Boolean']['output']>;
  fromExternalInvoiceCode?: Maybe<Scalars['String']['output']>;
  fromIncludeOnInvoicesIfFree?: Maybe<Scalars['Boolean']['output']>;
  fromIsDefault?: Maybe<Scalars['Boolean']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  fromNameOnInvoice?: Maybe<Scalars['String']['output']>;
  fromPickupSetting?: Maybe<PickupSettingsType>;
  fromProductType?: Maybe<Scalars['String']['output']>;
  fromPropertyType?: Maybe<PropertyTypeType>;
  fromService?: Maybe<ServiceDef>;
  fromWasteFraction?: Maybe<WasteFractionType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  product?: Maybe<ProductDef>;
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toArchivedAt?: Maybe<Scalars['DateTime']['output']>;
  toContainerType?: Maybe<ContainerTypeType>;
  toEnableOnPortal?: Maybe<Scalars['Boolean']['output']>;
  toExternalInvoiceCode?: Maybe<Scalars['String']['output']>;
  toIncludeOnInvoicesIfFree?: Maybe<Scalars['Boolean']['output']>;
  toIsDefault?: Maybe<Scalars['Boolean']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  toNameOnInvoice?: Maybe<Scalars['String']['output']>;
  toPickupSetting?: Maybe<PickupSettingsType>;
  toProductType?: Maybe<Scalars['String']['output']>;
  toPropertyType?: Maybe<PropertyTypeType>;
  toService?: Maybe<ServiceDef>;
  toWasteFraction?: Maybe<WasteFractionType>;
  user?: Maybe<UserType>;
};

export enum ProductEventTypesGraphene {
  ARCHIVED_AT_CHANGE = 'ARCHIVED_AT_CHANGE',
  CONTAINER_TYPE_CHANGE = 'CONTAINER_TYPE_CHANGE',
  ENABLE_ON_PORTAL_CHANGE = 'ENABLE_ON_PORTAL_CHANGE',
  EXTERNAL_INVOICE_CODE_CHANGE = 'EXTERNAL_INVOICE_CODE_CHANGE',
  INCLUDE_ON_INVOICES_IF_FREE_CHANGE = 'INCLUDE_ON_INVOICES_IF_FREE_CHANGE',
  IS_DEFAULT_CHANGE = 'IS_DEFAULT_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE',
  NAME_ON_INVOICE_CHANGE = 'NAME_ON_INVOICE_CHANGE',
  PICKUP_SETTING_CHANGE = 'PICKUP_SETTING_CHANGE',
  PROPERTY_TYPE_CHANGE = 'PROPERTY_TYPE_CHANGE',
  SERVICE_CHANGE = 'SERVICE_CHANGE',
  WASTE_FRACTION_CHANGE = 'WASTE_FRACTION_CHANGE'
}

export type ProjectType = Node & {
  __typename: 'ProjectType';
  collectioninquirytypingSet: CollectionInquiryTypingTypeConnection;
  /** Hex color code */
  color?: Maybe<Scalars['String']['output']>;
  company: CompanyType;
  companyemailaccountruleSet: CompanyEmailAccountRuleDefConnection;
  containerSet: ContainerTypeConnection;
  containerTypes: ContainerTypeTypeConnection;
  containergroupSet: ContainerGroupDefConnection;
  containerimportSet: ContainerImportTypeConnection;
  containertypeSet: ContainerTypeTypeConnection;
  containerweightimportSet: ContainerWeightImportTypeConnection;
  creatingDemoData: Scalars['Boolean']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exportSet: ExportTypeConnection;
  exporttemplateSet: ExportTemplateDefConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  importHistory: ImportHistoryDefConnection;
  inquiries: CollectionInquiryTypeConnection;
  logo?: Maybe<Scalars['String']['output']>;
  measurementsettingsSet: MeasurementSettingsTypeConnection;
  myJobTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pickupsettingsSet: PickupSettingsTypeConnection;
  routeSettings: RouteSettingsTypeConnection;
  routes: RouteTypeConnection;
  routeschemeSet: RouteSchemeTypeConnection;
  settings?: Maybe<SettingsType>;
  ticketdraftSet: TicketDraftDefConnection;
  userAccessLevel?: Maybe<Scalars['String']['output']>;
  userabsenceSet: UserAbsenceTypeConnection;
  userprojectSet: UserProjectTypeConnection;
  vehicleSet: VehicleTypeConnection;
  vehicleabsenceSet: VehicleAbsenceTypeConnection;
  wasteFractions: WasteFractionTypeConnection;
  wastefractionSet: WasteFractionTypeConnection;
  wastetypeSet: WasteTypeTypeConnection;
};


export type ProjectTypeCollectioninquirytypingSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeCompanyemailaccountruleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeContainerTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeContainergroupSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeContainerimportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeContainertypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeContainerweightimportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeExportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeExporttemplateSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeImportHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeInquiriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeMeasurementsettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypePickupsettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeRouteSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeRoutesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeRouteschemeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeTicketdraftSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeUserabsenceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeUserprojectSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeVehicleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeVehicleabsenceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeWasteFractionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeWastefractionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeWastetypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectTypeConnection = {
  __typename: 'ProjectTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ProjectType` and its cursor. */
export type ProjectTypeEdge = {
  __typename: 'ProjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ProjectType>;
};

export type ProjectsInputObject = {
  amountOfUsers?: InputMaybe<Scalars['Int']['input']>;
  centerLocation?: InputMaybe<LocationInputObject>;
  collectionInquiries?: InputMaybe<Scalars['Int']['input']>;
  containerDevicePercentage?: InputMaybe<Scalars['Int']['input']>;
  containerTypes?: InputMaybe<Scalars['Int']['input']>;
  containers?: InputMaybe<Scalars['Int']['input']>;
  demoPeriod?: InputMaybe<Scalars['Int']['input']>;
  depots?: InputMaybe<Scalars['Int']['input']>;
  drivers?: InputMaybe<Scalars['Int']['input']>;
  fillLevelHistoryDays?: InputMaybe<Scalars['Int']['input']>;
  fillLevelHistoryEmpties?: InputMaybe<Scalars['Int']['input']>;
  fillLevelHistoryMeasurementsPerDay?: InputMaybe<Scalars['Int']['input']>;
  fillLevelHistorySpikesPercentage?: InputMaybe<Scalars['Int']['input']>;
  forceContainerRoadLocations?: InputMaybe<Scalars['Boolean']['input']>;
  maxLocationRetries?: InputMaybe<Scalars['Int']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
  vehicleTypes?: InputMaybe<Scalars['Int']['input']>;
  vehicles?: InputMaybe<Scalars['Int']['input']>;
  wasteFractions?: InputMaybe<Scalars['Int']['input']>;
  wasteStations?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyBulkUpdateCreateData = {
  area?: InputMaybe<Scalars['ID']['input']>;
  externalPropertyNumber?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationInput>;
  metaData?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['ID']['input'];
};

export type PropertyBulkUpdateCreateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PropertyBulkUpdateCreateMutation = {
  __typename: 'PropertyBulkUpdateCreateMutation';
  propertyList?: Maybe<Array<Maybe<PropertyDef>>>;
};

export type PropertyClusterDef = {
  __typename: 'PropertyClusterDef';
  duplicatedPoints?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  pointsOnTheSameLocation: Scalars['Boolean']['output'];
  propertyTypes?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  totalCount: Scalars['Int']['output'];
};

export type PropertyCollectionLogDef = {
  __typename: 'PropertyCollectionLogDef';
  agreement?: Maybe<AgreementDef>;
  date?: Maybe<Scalars['Date']['output']>;
  pickupOrder?: Maybe<PickUpOrderType>;
  status?: Maybe<PropertyCollectionLogStatus>;
};

/** An enumeration. */
export enum PropertyCollectionLogStatus {
  COMPLETE = 'COMPLETE',
  COMPLETE_NOT_EMPTIED = 'COMPLETE_NOT_EMPTIED',
  MISSED = 'MISSED',
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED'
}

export type PropertyContactDef = Node & {
  __typename: 'PropertyContactDef';
  /** @deprecated Use address fields instead */
  address: Scalars['String']['output'];
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  coName: Scalars['String']['output'];
  company: CompanyType;
  companyType: Scalars['String']['output'];
  contactType: CrmPropertyContactContactTypeChoices;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  ean: Scalars['String']['output'];
  email: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  endedBySync?: Maybe<MasterDataSyncDef>;
  externalContactId: Scalars['String']['output'];
  externalPropertyContactId: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isAdministrator: Scalars['Boolean']['output'];
  isCoOwner: Scalars['Boolean']['output'];
  isContact: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  isPayer: Scalars['Boolean']['output'];
  isTenant: Scalars['Boolean']['output'];
  masterDataUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note: Scalars['String']['output'];
  otherPhoneNumbers: Array<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  postalCodeName: Scalars['String']['output'];
  property: PropertyDef;
  propertyUsers?: Maybe<Array<Maybe<PropertyUserDef>>>;
  startDate: Scalars['DateTime']['output'];
  startedBySync?: Maybe<MasterDataSyncDef>;
  taxId: Scalars['String']['output'];
  taxSubId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PropertyContactDefConnection = {
  __typename: 'PropertyContactDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PropertyContactDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PropertyContactDef` and its cursor. */
export type PropertyContactDefEdge = {
  __typename: 'PropertyContactDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PropertyContactDef>;
};

export type PropertyDef = Node & {
  __typename: 'PropertyDef';
  activeAgreementsCount?: Maybe<Scalars['Int']['output']>;
  agreements?: Maybe<AgreementDefConnection>;
  agreementshareSet: AgreementShareDefConnection;
  area?: Maybe<AreaDef>;
  collectionLog?: Maybe<Array<Maybe<PropertyCollectionLogDef>>>;
  company: CompanyType;
  contactProperties: ContactPropertyDefConnection;
  contacts: PropertyContactDefConnection;
  containernotificationSet: ContainerNotificationDefConnection;
  createdAt: Scalars['DateTime']['output'];
  createdByPropertySync?: Maybe<MasterDataSyncDef>;
  crmPortalToken?: Maybe<Scalars['String']['output']>;
  decodedId?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  eventLog?: Maybe<PropertyEventLogEventWrapper>;
  externalPropertyNumber: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  invoiceItems: InvoiceItemDefConnection;
  invoiceSet: InvoiceDefConnection;
  isPropertySyncDraft: Scalars['Boolean']['output'];
  lastView?: Maybe<PropertyViewEventDef>;
  location: LocationWithContainerType;
  metaData?: Maybe<Scalars['JSONString']['output']>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  note: Scalars['String']['output'];
  owner?: Maybe<PropertyContactDef>;
  propertyGroup?: Maybe<PropertyGroupDef>;
  propertyuserSet: PropertyUserDefConnection;
  recurringPricing?: Maybe<Array<PriceDef>>;
  searchVectorUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  ticketdraftSet: TicketDraftDefConnection;
  tickets: TicketDefConnection;
  totalFees?: Maybe<Scalars['Int']['output']>;
  type: PropertyTypeDef;
  updatedAt: Scalars['DateTime']['output'];
  viewEvents: PropertyViewEventDefConnection;
};


export type PropertyDefAgreementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveOrFuture?: InputMaybe<Scalars['Boolean']['input']>;
  isContainerAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  isFuture?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  property?: InputMaybe<Scalars['ID']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type PropertyDefAgreementshareSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyDefCollectionLogArgs = {
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate: Scalars['Date']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['Date']['input'];
  wasteFractionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type PropertyDefContactPropertiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyDefContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyDefContainernotificationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyDefEventLogArgs = {
  addAgreementEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addPickupOrdersEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addPropertyEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addTicketComments?: InputMaybe<Scalars['Boolean']['input']>;
  addTicketEvents?: InputMaybe<Scalars['Boolean']['input']>;
  agreementEventTypes?: InputMaybe<Array<InputMaybe<AgreementEventTypesGraphene>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  isTicketMessageInbound?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pickupOrderEventTypes?: InputMaybe<Array<InputMaybe<PickUpOrderEventTypesGraphene>>>;
  propertyEventTypes?: InputMaybe<Array<InputMaybe<PropertyEventTypesGraphene>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  ticketEventTypes?: InputMaybe<Scalars['TicketEventTypeValuesList']['input']>;
  ticketMessageVia?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketTypes?: InputMaybe<Scalars['FromGlobalIDValuesList']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type PropertyDefInvoiceItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyDefInvoiceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyDefPropertyuserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyDefTicketdraftSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyDefTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyDefViewEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyDefConnection = {
  __typename: 'PropertyDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PropertyDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PropertyDef` and its cursor. */
export type PropertyDefEdge = {
  __typename: 'PropertyDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PropertyDef>;
};

export type PropertyDefFilterInput = {
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  company?: InputMaybe<Scalars['ID']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludePropertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  hasBlankAddress?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lastViewedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchContact?: InputMaybe<Scalars['String']['input']>;
  searchContactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  searchExternalPropertyNumber?: InputMaybe<Scalars['String']['input']>;
  searchLocation?: InputMaybe<Scalars['String']['input']>;
  searchOwner?: InputMaybe<Scalars['String']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PropertyEventDef = Node & {
  __typename: 'PropertyEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<PropertyEventTypesGraphene>>>;
  fromDescription?: Maybe<Scalars['String']['output']>;
  fromLocation?: Maybe<LocationType>;
  fromMetaData?: Maybe<Scalars['JSONString']['output']>;
  fromPropertyGroup?: Maybe<PropertyGroupDef>;
  fromPropertyNumber?: Maybe<Scalars['String']['output']>;
  fromPropertyType?: Maybe<PropertyTypeType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  property?: Maybe<PropertyDef>;
  propertyGroup?: Maybe<PropertyGroupDef>;
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toDescription?: Maybe<Scalars['String']['output']>;
  toLocation?: Maybe<LocationType>;
  toMetaData?: Maybe<Scalars['JSONString']['output']>;
  toPropertyGroup?: Maybe<PropertyGroupDef>;
  toPropertyNumber?: Maybe<Scalars['String']['output']>;
  toPropertyType?: Maybe<PropertyTypeType>;
  user?: Maybe<UserType>;
};

export type PropertyEventLogEvent = AgreementEventDef | PickUpOrderEventDef | PropertyEventDef | TicketCommentDef | TicketEventDef | TicketMessageDef;

export type PropertyEventLogEventWrapper = {
  __typename: 'PropertyEventLogEventWrapper';
  edges?: Maybe<Array<Maybe<PropertyEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum PropertyEventTypesGraphene {
  DESCRIPTION_CHANGE = 'DESCRIPTION_CHANGE',
  LOCATION_CHANGE = 'LOCATION_CHANGE',
  META_DATA_CHANGE = 'META_DATA_CHANGE',
  PROPERTY_GROUP_CHANGE = 'PROPERTY_GROUP_CHANGE',
  PROPERTY_NUMBER_CHANGE = 'PROPERTY_NUMBER_CHANGE',
  PROPERTY_TYPE_CHANGE = 'PROPERTY_TYPE_CHANGE'
}

export type PropertyGroupDef = Node & {
  __typename: 'PropertyGroupDef';
  canBeDeleted?: Maybe<Scalars['Boolean']['output']>;
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  eventLog?: Maybe<PropertyEventLogEventWrapper>;
  groupType: CrmPropertyGroupGroupTypeChoices;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  metaData?: Maybe<Scalars['JSONString']['output']>;
  name: Scalars['String']['output'];
  properties: PropertyDefConnection;
  singleAddress: Scalars['String']['output'];
  singleCity: Scalars['String']['output'];
  singleCountry: Scalars['String']['output'];
  singleInvoiceEmail: Scalars['String']['output'];
  singlePhone: Scalars['String']['output'];
  singleTaxId: Scalars['String']['output'];
  singleZipCode: Scalars['String']['output'];
  totalContainers: Scalars['Int']['output'];
};


export type PropertyGroupDefEventLogArgs = {
  addPickupOrders?: InputMaybe<Scalars['Boolean']['input']>;
  addPropertyEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addTicketComments?: InputMaybe<Scalars['Boolean']['input']>;
  addTicketEvents?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  isTicketMessageInbound?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ticketEventTypes?: InputMaybe<Scalars['TicketEventTypeValuesList']['input']>;
  ticketMessageVia?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type PropertyGroupDefPropertiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyGroupDefConnection = {
  __typename: 'PropertyGroupDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PropertyGroupDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PropertyGroupDef` and its cursor. */
export type PropertyGroupDefEdge = {
  __typename: 'PropertyGroupDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PropertyGroupDef>;
};

export type PropertyPointMapDef = {
  __typename: 'PropertyPointMapDef';
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  propertyType: Scalars['ID']['output'];
};

export type PropertyTypeDef = Node & {
  __typename: 'PropertyTypeDef';
  agreementtemplateSet: AgreementTemplateDefConnection;
  company: CompanyType;
  containerpriceSet: ContainerPriceDefConnection;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  propertySet: PropertyDefConnection;
  recurringpriceSet: RecurringPriceDefConnection;
  scheduledexportSet: ScheduledExportDefConnection;
  scheduledexportrunSet: ScheduledExportRunDefConnection;
  servicepriceSet: ServicePriceDefConnection;
};


export type PropertyTypeDefAgreementtemplateSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeDefContainerpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeDefPropertySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeDefRecurringpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeDefScheduledexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeDefScheduledexportrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeDefServicepriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyTypeDefConnection = {
  __typename: 'PropertyTypeDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PropertyTypeDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PropertyTypeDef` and its cursor. */
export type PropertyTypeDefEdge = {
  __typename: 'PropertyTypeDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PropertyTypeDef>;
};

export type PropertyTypeType = Node & {
  __typename: 'PropertyTypeType';
  agreementtemplateSet: AgreementTemplateDefConnection;
  company: CompanyType;
  containerpriceSet: ContainerPriceDefConnection;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  propertySet: PropertyDefConnection;
  recurringpriceSet: RecurringPriceDefConnection;
  scheduledexportSet: ScheduledExportDefConnection;
  scheduledexportrunSet: ScheduledExportRunDefConnection;
  servicepriceSet: ServicePriceDefConnection;
};


export type PropertyTypeTypeAgreementtemplateSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeTypeContainerpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeTypePropertySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeTypeRecurringpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeTypeScheduledexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeTypeScheduledexportrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PropertyTypeTypeServicepriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyUserDef = Node & {
  __typename: 'PropertyUserDef';
  anonymous: Scalars['Boolean']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  language: CrmPropertyUserLanguageChoices;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  properties: PropertyDefConnection;
  token: Scalars['UUID']['output'];
  username: Scalars['String']['output'];
};


export type PropertyUserDefPropertiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyUserDefConnection = {
  __typename: 'PropertyUserDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PropertyUserDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PropertyUserDef` and its cursor. */
export type PropertyUserDefEdge = {
  __typename: 'PropertyUserDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PropertyUserDef>;
};

export type PropertyViewEventDef = Node & {
  __typename: 'PropertyViewEventDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  createdByAction: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  property: PropertyDef;
  viewedBy?: Maybe<UserType>;
};

export type PropertyViewEventDefConnection = {
  __typename: 'PropertyViewEventDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PropertyViewEventDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PropertyViewEventDef` and its cursor. */
export type PropertyViewEventDefEdge = {
  __typename: 'PropertyViewEventDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PropertyViewEventDef>;
};

export type PublicUpdateUnconfirmedUser = {
  __typename: 'PublicUpdateUnconfirmedUser';
  unconfirmedUser?: Maybe<UnconfirmedUserType>;
};

/** An enumeration. */
export enum PublicUpdateUnconfirmedUserHowDidYouHearAboutUs {
  EMAIL = 'EMAIL',
  FACEBOOK_ADS = 'FACEBOOK_ADS',
  GOOGLE_ADS = 'GOOGLE_ADS',
  LINKEDIN_ADS = 'LINKEDIN_ADS',
  SEARCH_ENGINE = 'SEARCH_ENGINE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
  YOUTUBE_ADS = 'YOUTUBE_ADS'
}

/** An enumeration. */
export enum PublicUpdateUnconfirmedUserInterestedHardware {
  AXLE_WEIGHT_LOADER = 'AXLE_WEIGHT_LOADER',
  FILL_LEVEL_SENSOR = 'FILL_LEVEL_SENSOR',
  QR_CODE_STICKERS = 'QR_CODE_STICKERS',
  RFID_TAGES_AND_READERS = 'RFID_TAGES_AND_READERS',
  WEIGHT_BRIDGE = 'WEIGHT_BRIDGE'
}

/** An enumeration. */
export enum PublicUpdateUnconfirmedUserInterestedSoftware {
  ASSET_MANAGEMENT = 'ASSET_MANAGEMENT',
  CITIZEN_MANAGEMENT = 'CITIZEN_MANAGEMENT',
  DRIVER_NAVIGATION = 'DRIVER_NAVIGATION',
  FLEET_MANAGEMENT = 'FLEET_MANAGEMENT',
  OPERATION_MANAGEMENT = 'OPERATION_MANAGEMENT'
}

/** An enumeration. */
export enum PublicUpdateUnconfirmedUserJobTitle {
  BACK_OFFICE_EMPLOYEE = 'BACK_OFFICE_EMPLOYEE',
  INNOVATION_LEADER = 'INNOVATION_LEADER',
  OPERATIONS_LEADER = 'OPERATIONS_LEADER',
  OTHER = 'OTHER',
  PARTNER = 'PARTNER',
  POLITICIAN = 'POLITICIAN',
  ROUTE_PLANNER = 'ROUTE_PLANNER',
  SERVICE_EMPLOYEE = 'SERVICE_EMPLOYEE',
  SYSTEMS_INTEGRATOR = 'SYSTEMS_INTEGRATOR',
  TRANSFORMATION_LEADER = 'TRANSFORMATION_LEADER',
  WASTE_OPERATIONS_MANAGER = 'WASTE_OPERATIONS_MANAGER'
}

/** An enumeration. */
export enum PublicUpdateUnconfirmedUserNumberOfEmployees {
  FROM_2_5 = 'FROM_2_5',
  FROM_6_10 = 'FROM_6_10',
  FROM_11_25 = 'FROM_11_25',
  FROM_26_50 = 'FROM_26_50',
  FROM_51_200 = 'FROM_51_200',
  FROM_201_500 = 'FROM_201_500',
  FROM_501 = 'FROM_501',
  JUST_ME = 'JUST_ME'
}

/** An enumeration. */
export enum PublicUpdateUnconfirmedUserSegment {
  LOCAL_AUTHORITY = 'LOCAL_AUTHORITY',
  ORGANIZATION = 'ORGANIZATION',
  OTHER = 'OTHER',
  PARTNER = 'PARTNER',
  WASTE_COLLECTOR = 'WASTE_COLLECTOR'
}

/** An enumeration. */
export enum PushNotificationTypes {
  INTEGRATION_ERROR = 'INTEGRATION_ERROR',
  OFFLINE_MODE_CONFLICT = 'OFFLINE_MODE_CONFLICT',
  STOP_ADDED = 'STOP_ADDED',
  STOP_REMOVED = 'STOP_REMOVED',
  STOP_TICKET_CREATED = 'STOP_TICKET_CREATED',
  UNKNOWN = 'UNKNOWN'
}

export type Query = {
  __typename: 'Query';
  _debug?: Maybe<DjangoDebug>;
  /** The ID of the object */
  agreement?: Maybe<AgreementDef>;
  /** The ID of the object */
  agreementTemplates?: Maybe<AgreementTemplateDef>;
  /** The ID of the object */
  alert?: Maybe<AlertDef>;
  /** The ID of the object */
  alertEvent?: Maybe<AlertEventDef>;
  /** The ID of the object */
  alertRecipient?: Maybe<AlertRuleDef>;
  /** The ID of the object */
  alertRule?: Maybe<AlertRuleDef>;
  allAgreementExports?: Maybe<AgreementExportDefConnection>;
  allAgreementTemplates?: Maybe<AgreementTemplateDefConnection>;
  allAgreements?: Maybe<AgreementDefConnection>;
  allAlertEvents?: Maybe<AlertEventDefConnection>;
  allAlertRules?: Maybe<AlertRuleDefConnection>;
  allAlerts?: Maybe<AlertDefConnection>;
  allAreas?: Maybe<AreaDefConnection>;
  allBillingRuns?: Maybe<BillingRunDefConnection>;
  allBulkWasteFractions?: Maybe<WasteFractionTypeConnection>;
  allCollectionCalendar?: Maybe<CollectionCalendarDefConnection>;
  allCollectionCalendarDates?: Maybe<CollectionCalendarDateDefConnection>;
  allCollectionCalendarDeviation?: Maybe<CollectionCalendarDeviationDefConnection>;
  allCollectionCalendarWeek?: Maybe<CollectionCalendarWeekDefConnection>;
  allCommentTerms?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  allCompanies?: Maybe<CompanyTypeConnection>;
  allCompanyContainerMetaTypes?: Maybe<CompanyContainerMetaTypeTypeConnection>;
  allCompanyEmailAccountMessages?: Maybe<CompanyEmailAccountMessageDefConnection>;
  allCompanyEmailAccountRules?: Maybe<CompanyEmailAccountRuleDefConnection>;
  allCompanyEmailAccounts?: Maybe<CompanyEmailAccountDefConnection>;
  allCompanyMetaTypes?: Maybe<CompanyMetaTypeDefConnection>;
  allCompanyRoles?: Maybe<CompanyRoleDefConnection>;
  allComparisonSettings?: Maybe<ComparisonSettingsTypeConnection>;
  allContactProperties?: Maybe<ContactPropertyDefConnection>;
  allContacts?: Maybe<ContactDefConnection>;
  allContainerGroups?: Maybe<ContainerGroupDefConnection>;
  allContainerImports?: Maybe<ContainerImportTypeConnection>;
  allContainerNotifications?: Maybe<ContainerNotificationDefConnection>;
  /** @deprecated Use allProducts */
  allContainerPrices?: Maybe<ContainerPriceDefConnection>;
  allContainerTypes?: Maybe<ContainerTypeTypeConnection>;
  allContainerWeightImports?: Maybe<ContainerWeightImportTypeConnection>;
  allContainers?: Maybe<ContainerTypeConnection>;
  allCostSettings?: Maybe<CostSettingsTypeConnection>;
  allCriiptoApplication?: Maybe<CriiptoApplicationDefConnection>;
  allDashboards?: Maybe<DashboardDefConnection>;
  allDebtorExports?: Maybe<DebtorExportDefConnection>;
  /** List of all depots */
  allDepots?: Maybe<DepotTypeConnection>;
  allDeviceTypes?: Maybe<DeviceTypeTypeConnection>;
  allDocuments?: Maybe<DocumentDefConnection>;
  allDriverNotifications?: Maybe<DriverPushNotificationTypeConnection>;
  /** List of all drivers */
  allDrivers?: Maybe<DriverTypeConnection>;
  allExportTemplates?: Maybe<ExportTemplateDefConnection>;
  allExports?: Maybe<ExportTypeConnection>;
  allExternalPropertyNumbers?: Maybe<Array<Scalars['String']['output']>>;
  allFillLevelMeasurements?: Maybe<FillLevelMeasurementTypeConnection>;
  allHaulerPrices?: Maybe<HaulerPriceDefConnection>;
  allHaulers?: Maybe<HaulerDefConnection>;
  allImportHistory?: Maybe<ImportHistoryDefConnection>;
  allInquiries?: Maybe<CollectionInquiryTypeConnection>;
  allInquiryTypings?: Maybe<CollectionInquiryTypingTypeConnection>;
  allIntegrationGps?: Maybe<IntegrationGpsTypeConnection>;
  allIntegrationGpsVehicle?: Maybe<IntegrationGpsVehicleTypeConnection>;
  allInvoiceItems?: Maybe<InvoiceItemDefConnection>;
  allInvoices?: Maybe<InvoiceDefConnection>;
  allLocations?: Maybe<LocationTypeConnection>;
  allLorawanDevices?: Maybe<LorawanDeviceTypeConnection>;
  allMasterDataSyncs?: Maybe<MasterDataSyncDefConnection>;
  allMeasurementSettings?: Maybe<MeasurementSettingsTypeConnection>;
  allMobileDevices?: Maybe<MobileDeviceTypeConnection>;
  allMunicipalityCodes?: Maybe<Array<Scalars['String']['output']>>;
  allNotificationSettings?: Maybe<NotificationSettingsTypeConnection>;
  allNotifications?: Maybe<NotificationsTypeConnection>;
  allPermissions?: Maybe<PermissionTypeConnection>;
  allPickupOrderApprovalCollections?: Maybe<PickUpOrderApprovalCollectionDefConnection>;
  allPickupOrderApprovals?: Maybe<PickUpOrderApprovalDefConnection>;
  allPickupOrders?: Maybe<PickUpOrderTypeConnection>;
  allPickupSettings?: Maybe<PickupSettingsTypeConnection>;
  allPrices?: Maybe<PriceDefConnection>;
  allProducts?: Maybe<ProductDefConnection>;
  allProjects?: Maybe<ProjectTypeConnection>;
  allProperties?: Maybe<PropertyDefConnection>;
  /** @deprecated Use allContacts/allContactProperties */
  allPropertyContacts?: Maybe<PropertyContactDefConnection>;
  allPropertyGroups?: Maybe<PropertyGroupDefConnection>;
  allPropertyTypes?: Maybe<PropertyTypeDefConnection>;
  allPropertyUsers?: Maybe<PropertyUserDefConnection>;
  /** @deprecated Use allProducts */
  allRecurringPrices?: Maybe<RecurringPriceDefConnection>;
  allRouteProblemErrors?: Maybe<RouteProblemErrorDefConnection>;
  allRouteProblems?: Maybe<RouteProblemDefConnection>;
  /** List of all Route Schemes */
  allRouteSchemes?: Maybe<RouteSchemeTypeConnection>;
  /** List of all Route Settings */
  allRouteSettings?: Maybe<RouteSettingsTypeConnection>;
  allRouteVehicleSessions?: Maybe<RouteVehicleSessionTypeConnection>;
  allRoutes?: Maybe<RouteTypeConnection>;
  allScheduledExport?: Maybe<ScheduledExportDefConnection>;
  allScheduledExportRun?: Maybe<ScheduledExportRunDefConnection>;
  /** @deprecated Use allProducts */
  allServicePrices?: Maybe<ServicePriceDefConnection>;
  allServices?: Maybe<ServiceDefConnection>;
  allSettings?: Maybe<SettingsTypeConnection>;
  allSigFoxDevices?: Maybe<SigFoxDeviceTypeConnection>;
  /** List of all skills */
  allSkills?: Maybe<SkillTypeConnection>;
  allStopListFieldOptions?: Maybe<Array<Maybe<StopListFieldDef>>>;
  allTeams?: Maybe<TeamTypeConnection>;
  allTicketCategories?: Maybe<TicketCategoryDefConnection>;
  allTicketDrafts?: Maybe<TicketDraftDefConnection>;
  allTicketMessageTemplates?: Maybe<TicketMessageTemplateDefConnection>;
  allTicketTypes?: Maybe<TicketTypeDefConnection>;
  allTickets?: Maybe<TicketDefConnection>;
  allUserAbsences?: Maybe<UserAbsenceTypeConnection>;
  allUserEventsNotifications?: Maybe<UserNotificationsEventsWrapper>;
  allUserJobNotifications?: Maybe<UserJobNotificationTypeConnection>;
  allUserNotifications?: Maybe<UserNotificationTypeConnection>;
  allUsers?: Maybe<UserTypeConnection>;
  allVehicleAbsences?: Maybe<VehicleAbsenceTypeConnection>;
  /** List of all vehicle sizes */
  allVehicleSizes?: Maybe<VehicleSizeDefConnection>;
  /** List of all vehicle types */
  allVehicleTypes?: Maybe<VehicleTypeTypeConnection>;
  /** List of all vehicles */
  allVehicles?: Maybe<VehicleTypeConnection>;
  allWasteFractions?: Maybe<WasteFractionTypeConnection>;
  allWasteTypes?: Maybe<WasteTypeTypeConnection>;
  analytics?: Maybe<AnalyticsType>;
  /** The ID of the object */
  area?: Maybe<AreaDef>;
  /** The ID of the object */
  billingRun?: Maybe<BillingRunDef>;
  businessAnalytics?: Maybe<BusinessAnalyticsType>;
  /** The ID of the object */
  collectionCalendar?: Maybe<CollectionCalendarDef>;
  /** The ID of the object */
  collectionCalendarDate?: Maybe<CollectionCalendarDateDef>;
  /** The ID of the object */
  collectionCalendarDeviation?: Maybe<CollectionCalendarDeviationDef>;
  /** The ID of the object */
  collectionCalendarWeek?: Maybe<CollectionCalendarWeekDef>;
  /** The ID of the object */
  company?: Maybe<CompanyType>;
  /** The ID of the object */
  companyContainerMetaType?: Maybe<CompanyContainerMetaTypeType>;
  /** The ID of the object */
  companyEmailAccount?: Maybe<CompanyEmailAccountDef>;
  /** The ID of the object */
  companyEmailAccountMessage?: Maybe<CompanyEmailAccountMessageDef>;
  /** The ID of the object */
  companyEmailAccountRule?: Maybe<CompanyEmailAccountRuleDef>;
  /** The ID of the object */
  companyMetaType?: Maybe<CompanyMetaTypeDef>;
  /** The ID of the object */
  companyRole?: Maybe<CompanyRoleDef>;
  companyRolesConfig?: Maybe<Array<Maybe<CompanyRoleConfigDef>>>;
  companySettings?: Maybe<CompanySettingsType>;
  companyTicketTypeCustomFields?: Maybe<TicketCustomFieldDefConnection>;
  /** The ID of the object */
  comparisonSettings?: Maybe<ComparisonSettingsType>;
  /** The ID of the object */
  contact?: Maybe<ContactDef>;
  /** The ID of the object */
  contactProperty?: Maybe<ContactPropertyDef>;
  container?: Maybe<ContainerType>;
  containerEventLog?: Maybe<ContainerEventLogEvent>;
  /** The ID of the object */
  containerGroup?: Maybe<ContainerGroupDef>;
  containerIdGeneratorSettings?: Maybe<ContainerIdGeneratorSettingsDef>;
  containerImport?: Maybe<ContainerImportType>;
  containerMap: ClusteredContainerMapDef;
  /** The ID of the object */
  containerNotification?: Maybe<ContainerNotificationDef>;
  /** The ID of the object */
  containerType?: Maybe<ContainerTypeType>;
  containerWeightImport?: Maybe<ContainerWeightImportType>;
  containersGroupedByLocation?: Maybe<LocationWithContainerTypeConnection>;
  /** The ID of the object */
  costSettings?: Maybe<CostSettingsType>;
  /** The ID of the object */
  criiptoApplication?: Maybe<CriiptoApplicationDef>;
  /** The ID of the object */
  dashboard?: Maybe<DashboardDef>;
  /** The ID of the object */
  debtorExport?: Maybe<DebtorExportDef>;
  /** The ID of the object */
  depot?: Maybe<DepotType>;
  /** The ID of the object */
  deviceToContainerSet?: Maybe<DeviceToContainerType>;
  /** The ID of the object */
  deviceType?: Maybe<DeviceTypeType>;
  dkdpCompanyCredentials?: Maybe<DkdpCompanyCredentialsDef>;
  dkdpEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object */
  document?: Maybe<DocumentDef>;
  /** The ID of the object */
  driver?: Maybe<DriverType>;
  driverNotificationById?: Maybe<DriverPushNotificationType>;
  driverNotificationsList?: Maybe<DriverPushNotificationTypeConnection>;
  driverSummaryCard?: Maybe<DriverSummaryCardType>;
  /** The ID of the object */
  export?: Maybe<ExportType>;
  exportConfig?: Maybe<ExportConfigDef>;
  /** The ID of the object */
  exportHistory?: Maybe<ExportHistoryDef>;
  /** List of available fields for export model */
  exportModelFields?: Maybe<Array<Maybe<ExportFieldType>>>;
  /** List of available filters for export model */
  exportModelFilters?: Maybe<Array<Maybe<ExportFiltersSectionType>>>;
  /** List of Export model that allowed to use segments */
  exportModelSegments?: Maybe<Array<Maybe<ExportTypes>>>;
  /** The ID of the object */
  exportTemplate?: Maybe<ExportTemplateDef>;
  /** Returns current total items for export template */
  exportTemplateTotalItems?: Maybe<ExportTemplateTotalItems>;
  fileList?: Maybe<FileListType>;
  fillLevelDevice?: Maybe<BaseDeviceInterface>;
  /** The ID of the object */
  fillLevelMeasurements?: Maybe<FillLevelMeasurementType>;
  getHistoryLocation?: Maybe<RouteLocationHistoryDef>;
  /** The ID of the object */
  hauler?: Maybe<HaulerDef>;
  /** The ID of the object */
  haulerPrice?: Maybe<HaulerPriceDef>;
  healthcheck?: Maybe<HealthCheckType>;
  importFileTooltips?: Maybe<Array<Maybe<ImportFileTooltips>>>;
  /** The ID of the object */
  importHistory?: Maybe<ImportHistoryDef>;
  /** The ID of the object */
  inquiry?: Maybe<CollectionInquiryType>;
  /** The ID of the object */
  inquiryTyping?: Maybe<CollectionInquiryTypingType>;
  /** The ID of the object */
  integrationGps?: Maybe<IntegrationGpsType>;
  /** The ID of the object */
  integrationGpsVehicle?: Maybe<IntegrationGpsVehicleType>;
  internalAllCompanies?: Maybe<CompanyTypeConnection>;
  /** The ID of the object */
  internalAllSalesOffer?: Maybe<SalesOfferType>;
  internalAllSalesOffers?: Maybe<SalesOfferTypeConnection>;
  internalAllSalesOffersFeatures?: Maybe<BillingFeatureTypeConnection>;
  internalAllUnconfirmedUsers?: Maybe<UnconfirmedUserTypeConnection>;
  /** The ID of the object */
  internalCompany?: Maybe<CompanyType>;
  /** The ID of the object */
  internalUnconfirmedUser?: Maybe<UnconfirmedUserType>;
  /** The ID of the object */
  invoice?: Maybe<InvoiceDef>;
  /** The ID of the object */
  invoiceItem?: Maybe<InvoiceItemDef>;
  isDriver?: Maybe<IsDriverType>;
  isDriverEditable?: Maybe<CheckDriverEditableType>;
  /** The ID of the object */
  masterDataSync?: Maybe<MasterDataSyncDef>;
  me?: Maybe<UserType>;
  /** The ID of the object */
  measurementSettings?: Maybe<MeasurementSettingsType>;
  /** The ID of the object */
  mobileDeviceType?: Maybe<MobileDeviceType>;
  navigationAppVersion?: Maybe<NavigationAppVersionType>;
  operationManagementStatuses?: Maybe<OperationManagementType>;
  passwordResetVerify?: Maybe<PasswordResetVerify>;
  /** The ID of the object */
  pickupOrder?: Maybe<PickUpOrderType>;
  /** The ID of the object */
  pickupOrderApproval?: Maybe<PickUpOrderApprovalDef>;
  /** The ID of the object */
  pickupOrderApprovalCollection?: Maybe<PickUpOrderApprovalCollectionDef>;
  /** The ID of the object */
  pickupSetting?: Maybe<PickupSettingsType>;
  /** The ID of the object */
  price?: Maybe<PriceDef>;
  /** The ID of the object */
  product?: Maybe<ProductDef>;
  /** The ID of the object */
  project?: Maybe<ProjectType>;
  /** The ID of the object */
  property?: Maybe<PropertyDef>;
  /** The ID of the object */
  propertyContact?: Maybe<PropertyContactDef>;
  propertyEventLog?: Maybe<PropertyEventLogEvent>;
  /** The ID of the object */
  propertyGroup?: Maybe<PropertyGroupDef>;
  propertyMap: ClusteredPropertyMapDef;
  /** The ID of the object */
  propertyType?: Maybe<PropertyTypeDef>;
  /** The ID of the object */
  propertyUser?: Maybe<PropertyUserDef>;
  publicUnconfirmedUser?: Maybe<UnconfirmedUserType>;
  rfidVehicleConnection?: Maybe<RfidVehicleConnectionDef>;
  /** The ID of the object */
  route?: Maybe<RouteType>;
  routeDriversGeojsonById?: Maybe<Array<Maybe<DriverGeoJsonType>>>;
  routeDriversGeojsons?: Maybe<Array<Maybe<DriverGeoJsonType>>>;
  /** The ID of the object */
  routeExport?: Maybe<RouteExportType>;
  routeGeojson?: Maybe<GeoJsonType>;
  /** The ID of the object */
  routeProblem?: Maybe<RouteProblemDef>;
  /** The ID of the object */
  routeScheme?: Maybe<RouteSchemeType>;
  /** The ID of the object */
  routeSettings?: Maybe<RouteSettingsType>;
  routesGeojson?: Maybe<Array<Maybe<GeoJsonType>>>;
  /** The ID of the object */
  scheduledExport?: Maybe<ScheduledExportDef>;
  /** The ID of the object */
  scheduledExportRun?: Maybe<ScheduledExportRunDef>;
  /** The ID of the object */
  service?: Maybe<ServiceDef>;
  /** The ID of the object */
  settings?: Maybe<SettingsType>;
  stopListFieldsOptions?: Maybe<Array<Maybe<StopListFieldOptionDef>>>;
  stripeQuote?: Maybe<BillingSubscriptionQuote>;
  /** The ID of the object */
  team?: Maybe<TeamType>;
  /** The ID of the object */
  ticket?: Maybe<TicketDef>;
  /** The ID of the object */
  ticketCategory?: Maybe<TicketCategoryDef>;
  ticketCustomField?: Maybe<TicketCustomFieldDef>;
  /** The ID of the object */
  ticketDraft?: Maybe<TicketDraftDef>;
  ticketEventLog?: Maybe<TicketEventLogEvent>;
  ticketMap?: Maybe<ClusteredTicketMapDef>;
  /** The ID of the object */
  ticketMessageTemplate?: Maybe<TicketMessageTemplateDef>;
  ticketPricing: TicketPricing;
  /** The ID of the object */
  ticketType?: Maybe<TicketTypeDef>;
  ticketTypeCounts?: Maybe<Array<Maybe<TicketTypeCountDef>>>;
  urbaserCompanyCredentials?: Maybe<UrbaserCredentialsDef>;
  /** The ID of the object */
  user?: Maybe<UserType>;
  /** The ID of the object */
  userAbsence?: Maybe<UserAbsenceType>;
  userConfig?: Maybe<UserTypeConfigType>;
  userIsActive?: Maybe<IoUserIsActiveType>;
  /** The ID of the object */
  userLayoutConfig?: Maybe<UserLayoutConfigType>;
  /** Get the total count of user notifications (with granular counts) */
  userNotificationsCounter?: Maybe<UserNotificationsCounterType>;
  userProject?: Maybe<UserProjectType>;
  /** The ID of the object */
  vehicle?: Maybe<VehicleType>;
  /** The ID of the object */
  vehicleAbsence?: Maybe<VehicleAbsenceType>;
  /** The ID of the object */
  vehicleSize?: Maybe<VehicleSizeDef>;
  /** The ID of the object */
  vehicleType?: Maybe<VehicleTypeType>;
  /** The ID of the object */
  wasteCollector?: Maybe<WasteCollectorType>;
  /** The ID of the object */
  wasteFraction?: Maybe<WasteFractionType>;
};


export type QueryAgreementArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAgreementTemplatesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAlertArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAlertEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAlertRecipientArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAlertRuleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAllAgreementExportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllAgreementTemplatesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  propertyType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllAgreementsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveOrFuture?: InputMaybe<Scalars['Boolean']['input']>;
  isContainerAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  isFuture?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  property?: InputMaybe<Scalars['ID']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllAlertEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  alert?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  alertRecipient?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllAlertRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  alerts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  alertRule?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  closedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllAreasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllBillingRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAllBulkWasteFractionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  vehicles?: InputMaybe<Scalars['String']['input']>;
  wasteCategory?: InputMaybe<Scalars['String']['input']>;
  wasteSubstance?: InputMaybe<Scalars['String']['input']>;
  wasteType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCollectionCalendarArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  allowUsageInCombinedCalendarsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  collectionCalendarWeek?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  determinePeriodBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isCombined?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  weekdays?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAllCollectionCalendarDatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  collectionCalendarWeeks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  collectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  collectionDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  collectionDate_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  collectionDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCollectionCalendarDeviationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  collectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  endDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  endDate_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  endDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  startDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  startDate_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  startDate_Lte?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryAllCollectionCalendarWeekArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCompanyContainerMetaTypesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCompanyEmailAccountMessagesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  assignedTeam?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignee?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  receiverEmail?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  senderEmail?: InputMaybe<Scalars['String']['input']>;
  sentAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  sentAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  threadStartersOnly?: InputMaybe<Scalars['Boolean']['input']>;
  ticketType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllCompanyEmailAccountRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyEmailAccount?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCompanyEmailAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isSynced?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCompanyMetaTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCompanyRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllComparisonSettingsArgs = {
  activeProjects: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllContactPropertiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterProperty?: InputMaybe<PropertyDefFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchContacts?: InputMaybe<Scalars['String']['input']>;
  searchProperties?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  externalPropertyNumber?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  hasEmail?: InputMaybe<Scalars['Boolean']['input']>;
  hasPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxId?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  taxSubId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllContainerGroupsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  containerTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  wasteFractions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllContainerImportsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllContainerNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  containers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllContainerPricesArgs = {
  From?: InputMaybe<Scalars['Date']['input']>;
  To?: InputMaybe<Scalars['Date']['input']>;
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllContainerTypesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllContainerWeightImportsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllContainersArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  allowedForRoute?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  bbox?: InputMaybe<BBoxInput>;
  before?: InputMaybe<Scalars['String']['input']>;
  clusterId?: InputMaybe<Scalars['String']['input']>;
  collectionCalendar_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  collectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerId?: InputMaybe<Scalars['String']['input']>;
  containerType_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  containerType_Name_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  containerTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  device_IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  excludeCollectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeRouteSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterCostSettings?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  measurement_FillPercentage?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noProperty?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  polygons?: InputMaybe<Array<InputMaybe<PolygonInput>>>;
  propertyGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyNumber?: InputMaybe<Scalars['String']['input']>;
  propertyTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  q?: InputMaybe<Scalars['String']['input']>;
  routeIdsNotCompletedStops?: InputMaybe<Scalars['String']['input']>;
  routeScheme_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  routeSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchContact?: InputMaybe<Scalars['String']['input']>;
  sensor_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  storedAtDepot?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFractionIds?: InputMaybe<Scalars['String']['input']>;
  wasteFraction_WasteTypes_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  wasteFraction_WasteTypes_Name_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wasteTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllCostSettingsArgs = {
  activeProjects: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllCriiptoApplicationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllDashboardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllDebtorExportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAllDepotsArgs = {
  activeCompanies?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  depotType_Iexact?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  wasteFractions?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllDeviceTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllDocumentsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllDriverNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  notificationType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  receiver?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routeId?: InputMaybe<Scalars['ID']['input']>;
  sender?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sentAt?: InputMaybe<Scalars['String']['input']>;
  stopIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllDriversArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  havingVehicle?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  primaryVehicle?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status_Iexact?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllExportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  exportType?: InputMaybe<Array<InputMaybe<ExportTypes>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  frozen?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryAllExportsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excelFormat?: InputMaybe<Scalars['Boolean']['input']>;
  exportType?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllExternalPropertyNumbersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllFillLevelMeasurementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllHaulerPricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hauler?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllHaulersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  areas?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllImportHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  importType?: InputMaybe<Array<InputMaybe<ImportTypes>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllInquiriesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  containers?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  deadline_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  deadline_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  inquiryTypes?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  priorities?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllInquiryTypingsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllIntegrationGpsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllIntegrationGpsVehicleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  integration?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  vehicle?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllInvoiceItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  agreement?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  billingRun?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  invoice?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  periodEndFrom?: InputMaybe<Scalars['Date']['input']>;
  periodEndTo?: InputMaybe<Scalars['Date']['input']>;
  periodStartFrom?: InputMaybe<Scalars['Date']['input']>;
  periodStartTo?: InputMaybe<Scalars['Date']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ticket?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllInvoicesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticket?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllLocationsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllLorawanDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllMasterDataSyncsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  startedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  startedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAllMeasurementSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllMobileDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllMunicipalityCodesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllNotificationSettingsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  containerId?: InputMaybe<Scalars['ID']['input']>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllNotificationsArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_Iexact?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllPermissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllPickupOrderApprovalCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  completedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryAllPickupOrderApprovalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvalCollection?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  completedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupOrder?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllPickupOrdersArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  approvalCollection?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  collectionCalendarIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  completeApproval?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  completedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  completedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container?: InputMaybe<Scalars['ID']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerPickupSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  container_PickupMethod?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdInquiry?: InputMaybe<Scalars['ID']['input']>;
  createdInquiry_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  createdInquiry_Status?: InputMaybe<Scalars['String']['input']>;
  createdTicketsTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  driverIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fillLevel_Gte?: InputMaybe<Scalars['Float']['input']>;
  fillLevel_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  fillLevel_Lte?: InputMaybe<Scalars['Float']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noApproval?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderType_Icontains?: InputMaybe<Scalars['String']['input']>;
  pendingApproval?: InputMaybe<Scalars['Boolean']['input']>;
  propertyGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  route?: InputMaybe<Scalars['ID']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routeScheduledDayGte?: InputMaybe<Scalars['Date']['input']>;
  routeScheduledDayLte?: InputMaybe<Scalars['Date']['input']>;
  route_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stopStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketAssigneeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketAssigneeTeams?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketCreatedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketOrContainerIsnull?: InputMaybe<Scalars['Boolean']['input']>;
  ticketPriority?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketReportedByPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  ticketReporterByDriver?: InputMaybe<Scalars['Boolean']['input']>;
  ticketRequestedByPortal?: InputMaybe<Scalars['Boolean']['input']>;
  ticketStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticket_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFractionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllPickupSettingsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  container_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Scalars['ID']['input']>;
  project_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllPricesArgs = {
  From?: InputMaybe<Scalars['Date']['input']>;
  To?: InputMaybe<Scalars['Date']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasActivePrice?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  productType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  propertyType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllPropertiesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  bbox?: InputMaybe<BBoxInput>;
  before?: InputMaybe<Scalars['String']['input']>;
  clusterId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludePropertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasBlankAddress?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastViewedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  polygons?: InputMaybe<Array<InputMaybe<PolygonInput>>>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchContact?: InputMaybe<Scalars['String']['input']>;
  searchContactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  searchExternalPropertyNumber?: InputMaybe<Scalars['String']['input']>;
  searchLocation?: InputMaybe<Scalars['String']['input']>;
  searchOwner?: InputMaybe<Scalars['String']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAllPropertyContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasEmail?: InputMaybe<Scalars['Boolean']['input']>;
  hasEmailNotification?: InputMaybe<Scalars['Boolean']['input']>;
  hasPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  hasSmsNotification?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxId?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllPropertyGroupsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Scalars['Float']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllPropertyTypesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllPropertyUsersArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasEmail?: InputMaybe<Scalars['Boolean']['input']>;
  hasPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllRecurringPricesArgs = {
  From?: InputMaybe<Scalars['Date']['input']>;
  To?: InputMaybe<Scalars['Date']['input']>;
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  propertyType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryAllRouteProblemErrorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  routeProblems?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllRouteProblemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cancelledAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  cancelledAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  cancelledAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  endedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  endedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  endedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  errorAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  errorAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  errorAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  routeSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  solvedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  solvedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  solvedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  startedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  startedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  startedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryAllRouteSchemesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Scalars['ID']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllRouteSettingsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  project?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllRouteVehicleSessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  completedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  completedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  drivers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  route?: InputMaybe<Scalars['ID']['input']>;
  routeId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  startedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  startedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllRoutesArgs = {
  Type?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  applicableVehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  completedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  containers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  drivers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeRoutes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterByDay?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  plannedDrivers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  plannedVehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routeSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  scheduledDay?: InputMaybe<Scalars['Date']['input']>;
  scheduledDay_Gte?: InputMaybe<Scalars['Date']['input']>;
  scheduledDay_Lte?: InputMaybe<Scalars['Date']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  startedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFractions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllScheduledExportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllScheduledExportRunArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  newAgreements?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  removedAgreements?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledExport?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  tickets?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryAllServicePricesArgs = {
  From?: InputMaybe<Scalars['Date']['input']>;
  To?: InputMaybe<Scalars['Date']['input']>;
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  propertyType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllServicesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  serviceType_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  serviceTypes?: InputMaybe<Array<InputMaybe<ServiceType>>>;
};


export type QueryAllSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllSigFoxDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllSkillsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code_Iexact?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllTicketCategoriesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ticketType?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllTicketDraftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  open?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllTicketMessageTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllTicketTypesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  category?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  enableOnNavigationApp?: InputMaybe<Scalars['Boolean']['input']>;
  enableOnPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  enableOnPortal?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isContainerLevel?: InputMaybe<Scalars['Boolean']['input']>;
  isPropertyLevel?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderTicketType?: InputMaybe<TicketTypeOrderingObject>;
  search?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  serviceServiceType?: InputMaybe<Scalars['String']['input']>;
  strictSearch?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllTicketsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  allowedForRoute?: InputMaybe<Scalars['ID']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignedTeam?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignee?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  bbox?: InputMaybe<BBoxInput>;
  before?: InputMaybe<Scalars['String']['input']>;
  clusterId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerBarcode?: InputMaybe<Scalars['String']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdOn?: InputMaybe<Scalars['String']['input']>;
  excludeStatuses?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeTicketTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastViewedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  needToPairRfid?: InputMaybe<Scalars['Boolean']['input']>;
  needToScanBarcode?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  polygonSelection?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>>>>;
  priority?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  property?: InputMaybe<Scalars['ID']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reportedByPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  reporter?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reporterByDriver?: InputMaybe<Scalars['Boolean']['input']>;
  reporterDriver?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedByPortal?: InputMaybe<Scalars['Boolean']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statusCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusInProgressAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusInProgressAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusOnHoldAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusOnHoldAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusOpenAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusOpenAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusPendingAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusPendingAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusRejectedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusRejectedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ticketType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeCategory?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeService?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeServiceServiceType?: InputMaybe<Scalars['String']['input']>;
  ticketType_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllUserAbsencesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  end_Gte?: InputMaybe<Scalars['Date']['input']>;
  end_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  start_Gte?: InputMaybe<Scalars['Date']['input']>;
  start_Lte?: InputMaybe<Scalars['Date']['input']>;
  users?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllUserEventsNotificationsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  directOnly?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  followingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
  unseen?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAllUserJobNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAllUserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  directOnly?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  followingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
  unseen?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAllUsersArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDriver?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllVehicleAbsencesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  vehicle?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllVehicleSizesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Istartswith?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllVehicleTypesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Istartswith?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllVehiclesArgs = {
  Type?: InputMaybe<Scalars['String']['input']>;
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  drivers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasPrimaryDriver?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  primaryDriver?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routeSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status_Iexact?: InputMaybe<Scalars['String']['input']>;
  vehicleTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryAllWasteFractionsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  vehicles?: InputMaybe<Scalars['String']['input']>;
  wasteCategory?: InputMaybe<Scalars['String']['input']>;
  wasteSubstance?: InputMaybe<Scalars['String']['input']>;
  wasteType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllWasteTypesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAnalyticsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAreaArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBillingRunArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBusinessAnalyticsArgs = {
  From: Scalars['DateTime']['input'];
  To: Scalars['DateTime']['input'];
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  comparisonFrom: Scalars['DateTime']['input'];
  comparisonTo: Scalars['DateTime']['input'];
  period?: InputMaybe<Scalars['String']['input']>;
  periodType: Scalars['String']['input'];
  selectedCombinations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryCollectionCalendarArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCollectionCalendarDateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCollectionCalendarDeviationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCollectionCalendarWeekArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyContainerMetaTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyEmailAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyEmailAccountMessageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyEmailAccountRuleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyMetaTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanySettingsArgs = {
  dns?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompanyTicketTypeCustomFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  inputType?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  ticketTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryComparisonSettingsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactPropertyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContainerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  prefetchPickupOrders?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryContainerEventLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContainerGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContainerImportArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryContainerMapArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  allowedForRoute?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  bbox: BBoxInput;
  collectionCalendar_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  collectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerId?: InputMaybe<Scalars['String']['input']>;
  containerType_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  containerType_Name_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  containerTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  device_IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  excludeCollectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeRouteSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  measurement_FillPercentage?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noProperty?: InputMaybe<Scalars['Boolean']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyNumber?: InputMaybe<Scalars['String']['input']>;
  propertyTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  q?: InputMaybe<Scalars['String']['input']>;
  routeIdsNotCompletedStops?: InputMaybe<Scalars['String']['input']>;
  routeScheme_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  routeSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchContact?: InputMaybe<Scalars['String']['input']>;
  sensor_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  storedAtDepot?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFractionIds?: InputMaybe<Scalars['String']['input']>;
  wasteFraction_WasteTypes_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  wasteFraction_WasteTypes_Name_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wasteTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  zoomLevel: Scalars['Int']['input'];
};


export type QueryContainerNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContainerTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContainerWeightImportArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryContainersGroupedByLocationArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  withDevices?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCostSettingsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCriiptoApplicationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDashboardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDebtorExportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDepotArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDeviceToContainerSetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDeviceTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDriverArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDriverNotificationByIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDriverNotificationsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  notificationType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  receiver?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routeId?: InputMaybe<Scalars['ID']['input']>;
  sender?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sentAt?: InputMaybe<Scalars['String']['input']>;
  stopIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryExportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportModelFieldsArgs = {
  exportType: ExportTypes;
};


export type QueryExportModelFiltersArgs = {
  exportType: ExportTypes;
};


export type QueryExportTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportTemplateTotalItemsArgs = {
  exportType: ExportTypes;
  filters?: InputMaybe<Array<InputMaybe<ExportFilterTypeInput>>>;
  mapSegments?: InputMaybe<Scalars['GenericScalar']['input']>;
  projects: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type QueryFileListArgs = {
  contentType?: InputMaybe<MediaContentEnumType>;
  objId: Scalars['ID']['input'];
  relativePath: Scalars['String']['input'];
};


export type QueryFillLevelDeviceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFillLevelMeasurementsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetHistoryLocationArgs = {
  datetime: Scalars['DateTime']['input'];
};


export type QueryHaulerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHaulerPriceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryImportFileTooltipsArgs = {
  importType: ImportTypes;
};


export type QueryImportHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInquiryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInquiryTypingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIntegrationGpsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIntegrationGpsVehicleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInternalAllCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInternalAllSalesOfferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInternalAllSalesOffersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInternalAllSalesOffersFeaturesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInternalAllUnconfirmedUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdUser_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInternalCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInternalUnconfirmedUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoiceItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIsDriverArgs = {
  driverId: Scalars['ID']['input'];
};


export type QueryMasterDataSyncArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMeasurementSettingsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMobileDeviceTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNavigationAppVersionArgs = {
  buildNumber: Scalars['Int']['input'];
};


export type QueryOperationManagementStatusesArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPasswordResetVerifyArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
  uidb64?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPickupOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPickupOrderApprovalArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPickupOrderApprovalCollectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPickupSettingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPriceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPropertyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPropertyContactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPropertyEventLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPropertyGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPropertyMapArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  bbox: BBoxInput;
  company?: InputMaybe<Scalars['ID']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludePropertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  hasBlankAddress?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lastViewedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchContact?: InputMaybe<Scalars['String']['input']>;
  searchContactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  searchExternalPropertyNumber?: InputMaybe<Scalars['String']['input']>;
  searchLocation?: InputMaybe<Scalars['String']['input']>;
  searchOwner?: InputMaybe<Scalars['String']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
  zoomLevel: Scalars['Int']['input'];
};


export type QueryPropertyTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPropertyUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPublicUnconfirmedUserArgs = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type QueryRfidVehicleConnectionArgs = {
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryRouteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRouteDriversGeojsonByIdArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryRouteDriversGeojsonsArgs = {
  routeId: Scalars['ID']['input'];
};


export type QueryRouteExportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRouteGeojsonArgs = {
  fromOrderId?: InputMaybe<Scalars['String']['input']>;
  routeId: Scalars['ID']['input'];
  toOrderId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRouteProblemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRouteSchemeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRouteSettingsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRoutesGeojsonArgs = {
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryScheduledExportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScheduledExportRunArgs = {
  id: Scalars['ID']['input'];
};


export type QueryServiceArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySettingsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStopListFieldsOptionsArgs = {
  cardTypeConfig: CardConfigOptions;
};


export type QueryStripeQuoteArgs = {
  id: Scalars['String']['input'];
};


export type QueryTeamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTicketArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTicketCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTicketCustomFieldArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTicketDraftArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTicketEventLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTicketMapArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  allowedForRoute?: InputMaybe<Scalars['ID']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignedTeam?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignee?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  bbox: BBoxInput;
  company?: InputMaybe<Scalars['ID']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerBarcode?: InputMaybe<Scalars['String']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdOn?: InputMaybe<Scalars['String']['input']>;
  excludeStatuses?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeTicketTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lastViewedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  needToPairRfid?: InputMaybe<Scalars['Boolean']['input']>;
  needToScanBarcode?: InputMaybe<Scalars['Boolean']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  polygonSelection?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>>>>;
  priority?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  property?: InputMaybe<Scalars['ID']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reportedByPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  reporter?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reporterByDriver?: InputMaybe<Scalars['Boolean']['input']>;
  reporterDriver?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedByPortal?: InputMaybe<Scalars['Boolean']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statusCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusInProgressAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusInProgressAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusOnHoldAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusOnHoldAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusOpenAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusOpenAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusPendingAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusPendingAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusRejectedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusRejectedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ticketType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeCategory?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeService?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeServiceServiceType?: InputMaybe<Scalars['String']['input']>;
  ticketType_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  zoomLevel: Scalars['Int']['input'];
};


export type QueryTicketMessageTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTicketTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTicketTypeCountsArgs = {
  filterTicket?: InputMaybe<TicketDefFilterInput>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserAbsenceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserIsActiveArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserLayoutConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserNotificationsCounterArgs = {
  daysInThePast?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserProjectArgs = {
  projectId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryVehicleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVehicleAbsenceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVehicleSizeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVehicleTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWasteCollectorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWasteFractionArgs = {
  id: Scalars['ID']['input'];
};

export type RfidVehicleConnectionDef = Node & {
  __typename: 'RFIDVehicleConnectionDef';
  communication: Scalars['String']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  ip: Scalars['String']['output'];
  password: Scalars['String']['output'];
  pathToFiles: Scalars['String']['output'];
  protocol: IntegrationsRfidVehicleConnectionProtocolChoices;
  provider: IntegrationsRfidVehicleConnectionProviderChoices;
  syncInterval: Scalars['Int']['output'];
  username: Scalars['String']['output'];
  vehicle: VehicleType;
};

export type RfidVehicleConnectionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  communication?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  pathToFiles?: InputMaybe<Scalars['String']['input']>;
  protocol?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  syncInterval?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  vehicle: Scalars['ID']['input'];
};

export type RfidVehicleConnectionMutationPayload = {
  __typename: 'RFIDVehicleConnectionMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  rFIDVehicleConnection?: Maybe<RfidVehicleConnectionDef>;
  rfidVehicleConnection?: Maybe<RfidVehicleConnectionDef>;
};

export type RecurringPriceDef = Node & {
  __typename: 'RecurringPriceDef';
  amount: Scalars['Int']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  invoiceName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  propertyType?: Maybe<PropertyTypeDef>;
  recurringInterval: Scalars['Int']['output'];
  startDate: Scalars['Date']['output'];
};

export type RecurringPriceDefConnection = {
  __typename: 'RecurringPriceDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RecurringPriceDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RecurringPriceDef` and its cursor. */
export type RecurringPriceDefEdge = {
  __typename: 'RecurringPriceDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RecurringPriceDef>;
};

export type Refresh = {
  __typename: 'Refresh';
  payload?: Maybe<PayloadType>;
  refreshExpiresIn: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type RefuseToken = {
  __typename: 'RefuseToken';
  token?: Maybe<Scalars['String']['output']>;
};

export type RegisterFcmDeviceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Unique device identifier */
  deviceId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  registrationId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type RegisterFcmDevicePayload = {
  __typename: 'RegisterFCMDevicePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  fCMDevice?: Maybe<FcmDeviceType>;
  fcmDevice?: Maybe<FcmDeviceType>;
};

export type RemoveAssociatedTicketsFromCompanyEmailAccountMessage = {
  __typename: 'RemoveAssociatedTicketsFromCompanyEmailAccountMessage';
  companyEmailAccountMessage?: Maybe<CompanyEmailAccountMessageDef>;
};

export type ReportedFromDef = {
  __typename: 'ReportedFromDef';
  reporterDriverId?: Maybe<Scalars['ID']['output']>;
  reporterId?: Maybe<Scalars['ID']['output']>;
};

export type ResendInvitation = {
  __typename: 'ResendInvitation';
  status?: Maybe<Scalars['String']['output']>;
};

export type ResetCompanyColorSettings = {
  __typename: 'ResetCompanyColorSettings';
  companySettings?: Maybe<CompanySettingsType>;
};

export type ResetStopListCardConfig = {
  __typename: 'ResetStopListCardConfig';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RouteAnalyticsType = {
  __typename: 'RouteAnalyticsType';
  objects?: Maybe<RouteTypeConnection>;
  objects_Count?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};


export type RouteAnalyticsTypeObjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteAvailableContainersSubscription = {
  __typename: 'RouteAvailableContainersSubscription';
  addedContainers?: Maybe<Array<Maybe<ContainerType>>>;
  removedContainers?: Maybe<Array<Maybe<ContainerType>>>;
};

/** Change primary vehicle for route */
export type RouteChangePrimaryVehicle = {
  __typename: 'RouteChangePrimaryVehicle';
  route?: Maybe<RouteType>;
};

/** Change primary vehicle for route for mobile app */
export type RouteChangePrimaryVehicleMobile = {
  __typename: 'RouteChangePrimaryVehicleMobile';
  route?: Maybe<RouteType>;
};

export type RouteComplete = {
  __typename: 'RouteComplete';
  route?: Maybe<RouteType>;
};

export type RouteDelete = {
  __typename: 'RouteDelete';
  route?: Maybe<RouteType>;
};

export type RouteEventDef = Node & {
  __typename: 'RouteEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<RouteEventTypesGraphene>>>;
  fromAutoComplete?: Maybe<Scalars['Boolean']['output']>;
  fromAutoCompleteNextStop?: Maybe<Scalars['Boolean']['output']>;
  fromCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  fromLocked?: Maybe<Scalars['Boolean']['output']>;
  fromLockedStart?: Maybe<Scalars['Boolean']['output']>;
  fromStartedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  route?: Maybe<RouteType>;
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toAutoComplete?: Maybe<Scalars['Boolean']['output']>;
  toAutoCompleteNextStop?: Maybe<Scalars['Boolean']['output']>;
  toCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  toLocked?: Maybe<Scalars['Boolean']['output']>;
  toLockedStart?: Maybe<Scalars['Boolean']['output']>;
  toStartedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UserType>;
};

export type RouteEventLogEvent = PickUpOrderEventDef | RouteEventDef | RouteVehicleEventDef;

export type RouteEventLogEventWrapper = {
  __typename: 'RouteEventLogEventWrapper';
  edges?: Maybe<Array<Maybe<RouteEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum RouteEventTypesGraphene {
  AUTO_COMPLETE_CHANGE = 'AUTO_COMPLETE_CHANGE',
  AUTO_COMPLETE_NEXT_STOP_CHANGE = 'AUTO_COMPLETE_NEXT_STOP_CHANGE',
  COMPLETED_AT_CHANGE = 'COMPLETED_AT_CHANGE',
  LOCKED_CHANGE = 'LOCKED_CHANGE',
  LOCKED_START_CHANGE = 'LOCKED_START_CHANGE',
  STARTED_AT_CHANGE = 'STARTED_AT_CHANGE'
}

export type RouteExportMutation = {
  __typename: 'RouteExportMutation';
  routeExport?: Maybe<RouteExportType>;
};

export type RouteExportType = Node & {
  __typename: 'RouteExportType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: UserType;
  excelFormat: Scalars['Boolean']['output'];
  file?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type RouteExportTypeConnection = {
  __typename: 'RouteExportTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteExportTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteExportType` and its cursor. */
export type RouteExportTypeEdge = {
  __typename: 'RouteExportTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteExportType>;
};

export type RouteInformationType = Node & {
  __typename: 'RouteInformationType';
  completedCollections?: Maybe<Scalars['Int']['output']>;
  completedDistance?: Maybe<Scalars['Float']['output']>;
  completedOffloads?: Maybe<Scalars['Int']['output']>;
  completedWeight?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  endTime?: Maybe<Scalars['Time']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  routeSet: RouteTypeConnection;
  startTime?: Maybe<Scalars['Time']['output']>;
  stops: PickUpOrderTypeConnection;
  totalCollections?: Maybe<Scalars['Int']['output']>;
  totalDistance?: Maybe<Scalars['Float']['output']>;
  totalOffloads?: Maybe<Scalars['Int']['output']>;
  totalWeight?: Maybe<Scalars['Int']['output']>;
  version: Scalars['Int']['output'];
};


export type RouteInformationTypeRouteSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteInformationTypeStopsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteInformationTypeConnection = {
  __typename: 'RouteInformationTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteInformationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteInformationType` and its cursor. */
export type RouteInformationTypeEdge = {
  __typename: 'RouteInformationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteInformationType>;
};

/** Driver joins to a route on another vehicle */
export type RouteJoinDifferentVehicle = {
  __typename: 'RouteJoinDifferentVehicle';
  route?: Maybe<RouteType>;
};

/** Driver joins to a vehicle that is already on a route */
export type RouteJoinSameVehicle = {
  __typename: 'RouteJoinSameVehicle';
  route?: Maybe<RouteType>;
};

export type RouteLocationHistoryDef = Node & {
  __typename: 'RouteLocationHistoryDef';
  appVersion?: Maybe<Scalars['String']['output']>;
  autoComplete: Scalars['Boolean']['output'];
  autoCompleteDistance?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  driver?: Maybe<DriverType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  location: LocationWithContainerType;
  route: RouteType;
  speed: Scalars['Float']['output'];
  token?: Maybe<Scalars['String']['output']>;
  /** Vehicle driver was in on the route */
  vehicle?: Maybe<VehicleType>;
};

export type RouteLocationHistoryDefConnection = {
  __typename: 'RouteLocationHistoryDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteLocationHistoryDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteLocationHistoryDef` and its cursor. */
export type RouteLocationHistoryDefEdge = {
  __typename: 'RouteLocationHistoryDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteLocationHistoryDef>;
};

export type RouteOptimize = {
  __typename: 'RouteOptimize';
  route?: Maybe<RouteType>;
};

export type RouteProblemDef = Node & {
  __typename: 'RouteProblemDef';
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  cancelledBy?: Maybe<RouteProblemDef>;
  checkInterval: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  errorAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  jobId?: Maybe<Scalars['String']['output']>;
  lbCookie?: Maybe<Scalars['String']['output']>;
  maxTime: Scalars['Int']['output'];
  parent?: Maybe<RouteProblemDef>;
  periodEnd?: Maybe<Scalars['Date']['output']>;
  periodStart?: Maybe<Scalars['Date']['output']>;
  problemFile?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
  route?: Maybe<RouteType>;
  routeScheme?: Maybe<RouteSchemeType>;
  routeproblemSet: RouteProblemDefConnection;
  solutionFile?: Maybe<Scalars['String']['output']>;
  solvedAt?: Maybe<Scalars['DateTime']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type RouteProblemDefRouteproblemSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteProblemDefConnection = {
  __typename: 'RouteProblemDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteProblemDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteProblemDef` and its cursor. */
export type RouteProblemDefEdge = {
  __typename: 'RouteProblemDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteProblemDef>;
};

export type RouteProblemErrorDef = Node & {
  __typename: 'RouteProblemErrorDef';
  createdAt: Scalars['DateTime']['output'];
  exception: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  routeProblem: RouteProblemDef;
  shouldEnd: Scalars['Boolean']['output'];
  shouldRaise: Scalars['Boolean']['output'];
};

export type RouteProblemErrorDefConnection = {
  __typename: 'RouteProblemErrorDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteProblemErrorDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteProblemErrorDef` and its cursor. */
export type RouteProblemErrorDefEdge = {
  __typename: 'RouteProblemErrorDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteProblemErrorDef>;
};

export type RouteReset = {
  __typename: 'RouteReset';
  route?: Maybe<RouteType>;
};

export type RouteSchemeDelete = {
  __typename: 'RouteSchemeDelete';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RouteSchemeEventDef = Node & {
  __typename: 'RouteSchemeEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<RouteSchemeEventTypesGraphene>>>;
  fromAllowDriverToAddStops?: Maybe<Scalars['Boolean']['output']>;
  fromAllowDriverToEditContainers?: Maybe<Scalars['Boolean']['output']>;
  fromAllowDriverToMoveStops?: Maybe<Scalars['Boolean']['output']>;
  fromAllowDriverToOnlyCompleteNextStop?: Maybe<Scalars['Boolean']['output']>;
  fromAllowDriverToTakeStops?: Maybe<Scalars['Boolean']['output']>;
  fromCollectionMethod?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  fromObjectives?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fromPromptDriverForFillLevelOnContainer?: Maybe<Scalars['Boolean']['output']>;
  fromPromptDriverForQrCodeOnContainer?: Maybe<Scalars['Boolean']['output']>;
  fromPromptDriverForWeightOnContainer?: Maybe<Scalars['Boolean']['output']>;
  fromPromptDriverForWeightOnWasteStation?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  routeScheme?: Maybe<RouteSchemeType>;
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toAllowDriverToAddStops?: Maybe<Scalars['Boolean']['output']>;
  toAllowDriverToEditContainers?: Maybe<Scalars['Boolean']['output']>;
  toAllowDriverToMoveStops?: Maybe<Scalars['Boolean']['output']>;
  toAllowDriverToOnlyCompleteNextStop?: Maybe<Scalars['Boolean']['output']>;
  toAllowDriverToTakeStops?: Maybe<Scalars['Boolean']['output']>;
  toCollectionMethod?: Maybe<Scalars['String']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  toObjectives?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  toPromptDriverForFillLevelOnContainer?: Maybe<Scalars['Boolean']['output']>;
  toPromptDriverForQrCodeOnContainer?: Maybe<Scalars['Boolean']['output']>;
  toPromptDriverForWeightOnContainer?: Maybe<Scalars['Boolean']['output']>;
  toPromptDriverForWeightOnWasteStation?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<UserType>;
};

export type RouteSchemeEventLogEvent = RouteSchemeEventDef;

export type RouteSchemeEventLogEventWrapper = {
  __typename: 'RouteSchemeEventLogEventWrapper';
  edges?: Maybe<Array<Maybe<RouteSchemeEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum RouteSchemeEventTypesGraphene {
  COLLECTION_METHOD_CHANGE = 'COLLECTION_METHOD_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE',
  NAV_APP_CONFIGURATION_CHANGE = 'NAV_APP_CONFIGURATION_CHANGE',
  OBJECTIVES_CHANGE = 'OBJECTIVES_CHANGE'
}

export type RouteSchemeInput = {
  allowDriverToAddStops: Scalars['Boolean']['input'];
  allowDriverToEditContainers: Scalars['Boolean']['input'];
  allowDriverToMoveStops: Scalars['Boolean']['input'];
  allowDriverToOnlyCompleteNextStop: Scalars['Boolean']['input'];
  allowDriverToTakeStops: Scalars['Boolean']['input'];
  autoCompleteDistance?: InputMaybe<Scalars['Int']['input']>;
  autoCompleteDuration?: InputMaybe<Scalars['Int']['input']>;
  autoCompleteRfid?: InputMaybe<Scalars['Boolean']['input']>;
  collectionMethod: Scalars['String']['input'];
  defaultAutoComplete?: InputMaybe<Scalars['Boolean']['input']>;
  defaultAutoCompleteNextStop?: InputMaybe<Scalars['Boolean']['input']>;
  defaultRouteLockedStart: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  promptDriverForFillLevelOnContainer: Scalars['Boolean']['input'];
  promptDriverForQrCodeOnContainer: Scalars['Boolean']['input'];
  promptDriverForWeightOnContainer: Scalars['Boolean']['input'];
  promptDriverForWeightOnWasteStation: Scalars['Boolean']['input'];
  vehicles: Array<InputMaybe<RouteSchemeVehicleInput>>;
  wasteFractionFillLevelSets: Array<InputMaybe<RouteSchemeWasteFractionFillLevelInput>>;
};

export type RouteSchemeOptimize = {
  __typename: 'RouteSchemeOptimize';
  routeScheme?: Maybe<RouteSchemeType>;
};

export type RouteSchemeOptimizeNew = {
  __typename: 'RouteSchemeOptimizeNew';
  routeScheme?: Maybe<RouteSchemeType>;
};

export type RouteSchemePreview = {
  __typename: 'RouteSchemePreview';
  routeSchemePreview?: Maybe<RouteSchemePreviewType>;
};

export type RouteSchemePreviewRouteType = {
  __typename: 'RouteSchemePreviewRouteType';
  distance?: Maybe<Scalars['Int']['output']>;
  driver?: Maybe<DriverType>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  stops?: Maybe<Scalars['Int']['output']>;
  vehicle?: Maybe<VehicleType>;
  wasteFraction?: Maybe<WasteFractionType>;
};

export type RouteSchemePreviewTotalsDayType = {
  __typename: 'RouteSchemePreviewTotalsDayType';
  date?: Maybe<Scalars['Date']['output']>;
  distance?: Maybe<Scalars['Int']['output']>;
  stops?: Maybe<Scalars['Int']['output']>;
  vehicles?: Maybe<Scalars['Int']['output']>;
  workingHours?: Maybe<Scalars['Int']['output']>;
};

export type RouteSchemePreviewTotalsType = {
  __typename: 'RouteSchemePreviewTotalsType';
  days?: Maybe<Array<Maybe<RouteSchemePreviewTotalsDayType>>>;
  distance?: Maybe<Scalars['Int']['output']>;
  distanceCompare?: Maybe<Scalars['Int']['output']>;
  stops?: Maybe<Scalars['Int']['output']>;
  stopsCompare?: Maybe<Scalars['Int']['output']>;
  vehicles?: Maybe<Scalars['Int']['output']>;
  workingHours?: Maybe<Scalars['Int']['output']>;
  workingHoursCompare?: Maybe<Scalars['Int']['output']>;
};

export type RouteSchemePreviewType = {
  __typename: 'RouteSchemePreviewType';
  routes?: Maybe<Array<Maybe<RouteSchemePreviewRouteType>>>;
  totals?: Maybe<RouteSchemePreviewTotalsType>;
};

export type RouteSchemeType = Node & {
  __typename: 'RouteSchemeType';
  /** If this is enabled, drivers will be able to collaborate on routes */
  allowCollaborationOnRoute: Scalars['Boolean']['output'];
  allowDriverToAddStops: Scalars['Boolean']['output'];
  allowDriverToEditContainers: Scalars['Boolean']['output'];
  allowDriverToMoveStops: Scalars['Boolean']['output'];
  allowDriverToOnlyCompleteNextStop: Scalars['Boolean']['output'];
  /** If this is enabled, drivers will be able to start routes before the scheduled date */
  allowDriverToStartRouteBeforeScheduledDate: Scalars['Boolean']['output'];
  allowDriverToTakeStops: Scalars['Boolean']['output'];
  /** If this is enabled, drivers which have allowed vehicles that intersect with the vehicles from route scheme will be able to view routes generated by this route scheme */
  allowDriverWithRouteSchemeVehiclesAccessToViewRoutes: Scalars['Boolean']['output'];
  autoCompleteDistance: Scalars['Int']['output'];
  autoCompleteDuration: Scalars['Int']['output'];
  autoCompletePlannedContainers: Scalars['Boolean']['output'];
  autoCompleteRfid: Scalars['Boolean']['output'];
  byPassDriverWorkTimeEnd: Scalars['Boolean']['output'];
  collectionMethod: Scalars['String']['output'];
  collectionMethodSemiDynamicUsePrediction: Scalars['Boolean']['output'];
  collectionMethodTicketsCollectionCalender?: Maybe<CollectionCalendarDef>;
  collectioncalendardeviationtoperiodSet: CollectionCalendarDeviationToPeriodDefConnection;
  containerSet: ContainerTypeConnection;
  containersCount?: Maybe<Scalars['Int']['output']>;
  defaultAutoComplete: Scalars['Boolean']['output'];
  defaultAutoCompleteNextStop: Scalars['Boolean']['output'];
  defaultRouteAutoOptimize: Scalars['Boolean']['output'];
  defaultRouteLockedStart: Scalars['Boolean']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  eventLog?: Maybe<RouteSchemeEventLogEventWrapper>;
  generateRoutes: Scalars['Boolean']['output'];
  generateRoutesDays: Scalars['Int']['output'];
  hauler?: Maybe<HaulerDef>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  keepManuallyAddedStops: Scalars['Boolean']['output'];
  managecontainerticketSet: ManageContainerTicketDefConnection;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  objectives: Array<Scalars['String']['output']>;
  project: ProjectType;
  promptDriverForFillLevelOnContainer: Scalars['Boolean']['output'];
  promptDriverForQrCodeOnContainer: Scalars['Boolean']['output'];
  promptDriverForWeightOnContainer: Scalars['Boolean']['output'];
  promptDriverForWeightOnWasteStation: Scalars['Boolean']['output'];
  /** If this is enabled, drivers will not be able to modify turn by turn navigation in the mobile app */
  restrictTurnByTurnUsage: Scalars['Boolean']['output'];
  routeProblemDefaultMaxTime: Scalars['Int']['output'];
  routeproblemSet: RouteProblemDefConnection;
  routesGenerated: RouteTypeConnection;
  routesGeneratedBy: Scalars['Time']['output'];
  scheduledexportSet: ScheduledExportDefConnection;
  scheduledexportrunSet: ScheduledExportRunDefConnection;
  scheduleonrouteactionSet: ScheduleOnRouteActionDefConnection;
  /** If this is enabled, turn by turn navigation will be enabled by default in the mobile app */
  turnByTurnIsOn: Scalars['Boolean']['output'];
  vehicleDays: RouteSchemeVehicleDayTypeConnection;
  vehicleTrailerCapacityMode: Scalars['String']['output'];
  vehicles: VehicleTypeConnection;
  vehiclesCount?: Maybe<Scalars['Int']['output']>;
  wasteFractionsFillLevelSets: RouteSchemeWasteFractionFillLevelSetTypeConnection;
};


export type RouteSchemeTypeCollectioncalendardeviationtoperiodSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSchemeTypeContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSchemeTypeEventLogArgs = {
  addRouteSchemeEvents?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  routeSchemeEventTypes?: InputMaybe<Array<InputMaybe<RouteSchemeEventTypesGraphene>>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type RouteSchemeTypeManagecontainerticketSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSchemeTypeRouteproblemSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSchemeTypeRoutesGeneratedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSchemeTypeScheduledexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSchemeTypeScheduledexportrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSchemeTypeScheduleonrouteactionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSchemeTypeVehicleDaysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSchemeTypeVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSchemeTypeWasteFractionsFillLevelSetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteSchemeTypeConnection = {
  __typename: 'RouteSchemeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteSchemeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteSchemeType` and its cursor. */
export type RouteSchemeTypeEdge = {
  __typename: 'RouteSchemeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteSchemeType>;
};

export type RouteSchemeVehicleDayMultipleChoiceFieldInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  vehicle: Scalars['ID']['input'];
  vehicleTrailer: Scalars['ID']['input'];
  weekday: Scalars['String']['input'];
};

export type RouteSchemeVehicleDayType = Node & {
  __typename: 'RouteSchemeVehicleDayType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  routeschemeSet: RouteSchemeTypeConnection;
  vehicle: VehicleType;
  vehicleTrailer: VehicleTrailerType;
  weekday: Scalars['String']['output'];
};


export type RouteSchemeVehicleDayTypeRouteschemeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteSchemeVehicleDayTypeConnection = {
  __typename: 'RouteSchemeVehicleDayTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteSchemeVehicleDayTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteSchemeVehicleDayType` and its cursor. */
export type RouteSchemeVehicleDayTypeEdge = {
  __typename: 'RouteSchemeVehicleDayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteSchemeVehicleDayType>;
};

export type RouteSchemeVehicleInput = {
  vehicleId: Scalars['ID']['input'];
};

export type RouteSchemeWasteFractionFillLevelInput = {
  fillLevelEnd: Scalars['Int']['input'];
  fillLevelStart: Scalars['Int']['input'];
  wasteFractionId: Scalars['ID']['input'];
};

export type RouteSchemeWasteFractionFillLevelSetMultipleChoiceFieldInput = {
  fillLevelEnd: Scalars['Int']['input'];
  fillLevelStart: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  wasteFraction: Scalars['ID']['input'];
};

export type RouteSchemeWasteFractionFillLevelSetType = Node & {
  __typename: 'RouteSchemeWasteFractionFillLevelSetType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  routeschemeSet: RouteSchemeTypeConnection;
  sensorFillLevel: Scalars['Int']['output'];
  sensorFillLevelEnd: Scalars['Int']['output'];
  sensorFillLevelPeriodHours: Scalars['Int']['output'];
  wasteFraction: WasteFractionType;
};


export type RouteSchemeWasteFractionFillLevelSetTypeRouteschemeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteSchemeWasteFractionFillLevelSetTypeConnection = {
  __typename: 'RouteSchemeWasteFractionFillLevelSetTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteSchemeWasteFractionFillLevelSetTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteSchemeWasteFractionFillLevelSetType` and its cursor. */
export type RouteSchemeWasteFractionFillLevelSetTypeEdge = {
  __typename: 'RouteSchemeWasteFractionFillLevelSetTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteSchemeWasteFractionFillLevelSetType>;
};

export type RouteSettingsType = Node & {
  __typename: 'RouteSettingsType';
  containerType: ContainerTypeTypeConnection;
  depot: DepotTypeConnection;
  /** Measurement are in percent */
  emptyAt?: Maybe<Scalars['String']['output']>;
  /** Be extra aware of overflowing bins */
  extraAware: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  optimizeBy: Scalars['String']['output'];
  project: ProjectType;
  scheduleAhead?: Maybe<Scalars['Int']['output']>;
  vehicleType: VehicleTypeTypeConnection;
  wasteFraction: WasteFractionType;
};


export type RouteSettingsTypeContainerTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSettingsTypeDepotArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteSettingsTypeVehicleTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteSettingsTypeConnection = {
  __typename: 'RouteSettingsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteSettingsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteSettingsType` and its cursor. */
export type RouteSettingsTypeEdge = {
  __typename: 'RouteSettingsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteSettingsType>;
};

export type RouteStart = {
  __typename: 'RouteStart';
  route?: Maybe<RouteType>;
};

export type RouteStartMobileApp = {
  __typename: 'RouteStartMobileApp';
  route?: Maybe<RouteType>;
};

export type RouteStop = {
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTicketsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fillLevel?: InputMaybe<Scalars['Float']['input']>;
  orderId: Scalars['String']['input'];
  qrCode?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['ID']['input']>;
  ticketIdStopCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type RouteStopComplete = {
  __typename: 'RouteStopComplete';
  route?: Maybe<RouteType>;
};

export type RouteStopSetNext = {
  __typename: 'RouteStopSetNext';
  route?: Maybe<RouteType>;
};

export type RouteStopSetNextDepot = {
  __typename: 'RouteStopSetNextDepot';
  route?: Maybe<RouteType>;
};

export type RouteStopsAdd = {
  __typename: 'RouteStopsAdd';
  route?: Maybe<RouteType>;
};

export type RouteStopsComplete = {
  __typename: 'RouteStopsComplete';
  errors?: Maybe<Array<Maybe<RouteStopsCompleteError>>>;
  route?: Maybe<RouteType>;
};

export type RouteStopsCompleteError = {
  __typename: 'RouteStopsCompleteError';
  error?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
};

export type RouteStopsInputType = {
  routeId: Scalars['ID']['input'];
  stops: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type RouteStopsMove = {
  __typename: 'RouteStopsMove';
  route?: Maybe<RouteType>;
};

export type RouteStopsOrder = {
  __typename: 'RouteStopsOrder';
  route?: Maybe<RouteType>;
};

export type RouteStopsSubscription = {
  __typename: 'RouteStopsSubscription';
  addedStops?: Maybe<Array<Maybe<PickUpOrderType>>>;
  removedStops?: Maybe<Array<Maybe<PickUpOrderType>>>;
  updatedStops?: Maybe<Array<Maybe<PickUpOrderType>>>;
};

export type RouteStopsUpdate = {
  __typename: 'RouteStopsUpdate';
  route?: Maybe<RouteType>;
};

export type RouteSubscription = {
  __typename: 'RouteSubscription';
  route?: Maybe<RouteType>;
};

export type RouteTrip = {
  __typename: 'RouteTrip';
  current?: Maybe<Scalars['Boolean']['output']>;
  stops?: Maybe<Array<Maybe<PickUpOrderType>>>;
};

export type RouteType = Node & {
  __typename: 'RouteType';
  autoComplete: Scalars['Boolean']['output'];
  autoCompleteNextStop: Scalars['Boolean']['output'];
  autoOptimize: Scalars['Boolean']['output'];
  availableAutoCompleteContainers?: Maybe<Array<Maybe<ContainerType>>>;
  canBeReset?: Maybe<Scalars['Boolean']['output']>;
  comment?: Maybe<CommentType>;
  /** DateTime on which this route was finished by driver */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedStopsCount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  driverpushnotificationSet: DriverPushNotificationTypeConnection;
  efficiency?: Maybe<Scalars['Float']['output']>;
  /** Approximated time on which this route will be finished by driver */
  endTime?: Maybe<Scalars['Time']['output']>;
  eventLog?: Maybe<RouteEventLogEventWrapper>;
  hauler?: Maybe<HaulerDef>;
  historicLocations?: Maybe<Array<Maybe<RouteLocationHistoryDef>>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  informations?: Maybe<RouteInformationTypeConnection>;
  locked: Scalars['Boolean']['output'];
  lockedStart: Scalars['Boolean']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  nextStop?: Maybe<PickUpOrderType>;
  project: ProjectType;
  rating?: Maybe<Scalars['Int']['output']>;
  /** Real distance (m) */
  realDistance: Scalars['Float']['output'];
  routeScheme?: Maybe<RouteSchemeType>;
  /** Route on which driver drives the vehicle */
  routeVehicles: RouteVehicleTypeConnection;
  routeexportSet: RouteExportTypeConnection;
  routelocationhistorySet: RouteLocationHistoryDefConnection;
  routeproblemSet: RouteProblemDefConnection;
  routeschemeSet: RouteSchemeTypeConnection;
  /** Date on which this route is planned */
  scheduledDay?: Maybe<Scalars['Date']['output']>;
  /** Approximated time on which this route will be started by driver */
  startTime?: Maybe<Scalars['Time']['output']>;
  /** DateTime on which this route was started by driver */
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   *
   *             Indicates the current status of the route. Not enough data is signified by the 'unknown' status.
   *             Can hold one of the following string values: 'pending', 'completed', 'working', 'not-working', 'unknown'
   *
   */
  status?: Maybe<Scalars['String']['output']>;
  stops?: Maybe<PickUpOrderTypeConnection>;
  stopsCount?: Maybe<Scalars['Int']['output']>;
  totalCollectedWeight?: Maybe<Scalars['Float']['output']>;
  /** Approximated distance (m) */
  totalDistance?: Maybe<Scalars['Float']['output']>;
  totalDuration?: Maybe<Scalars['Int']['output']>;
  /** Approximated traveled distance (m) */
  totalTraveledDistance?: Maybe<Scalars['Float']['output']>;
  totalTraveledDuration?: Maybe<Scalars['Int']['output']>;
  totalVolume?: Maybe<Scalars['Float']['output']>;
  trips?: Maybe<Array<Maybe<RouteTrip>>>;
  updatedAt: Scalars['DateTime']['output'];
  useTurnByTurn?: Maybe<Scalars['Boolean']['output']>;
  /** Vehicles that can be used in the route and is defined in the route scheme. Used to keep track of the vehicles that can be used in the route. Which in its turn is used to know which drivers can be assigned to the route depending on vehicles they can drive. */
  vehiclesFromRouteScheme: VehicleTypeConnection;
  wasteFractions?: Maybe<Array<Maybe<WasteFractionType>>>;
};


export type RouteTypeAvailableAutoCompleteContainersArgs = {
  bbox?: InputMaybe<BBoxInput>;
};


export type RouteTypeDriverpushnotificationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteTypeEventLogArgs = {
  addPickupOrdersEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addRouteEvents?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pickupOrderEventTypes?: InputMaybe<Array<InputMaybe<PickUpOrderEventTypesGraphene>>>;
  routeEventTypes?: InputMaybe<Array<InputMaybe<RouteEventTypesGraphene>>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type RouteTypeHistoricLocationsArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  latestOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RouteTypeInformationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  latest?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteTypeRouteVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteTypeRouteexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteTypeRoutelocationhistorySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteTypeRouteproblemSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteTypeRouteschemeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteTypeStopsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvalCollection?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  bbox?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  collectionCalendarIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  completeApproval?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  completedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  completedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container?: InputMaybe<Scalars['ID']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerPickupSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  container_PickupMethod?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdInquiry?: InputMaybe<Scalars['ID']['input']>;
  createdInquiry_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  createdInquiry_Status?: InputMaybe<Scalars['String']['input']>;
  createdTicketsTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  driverIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fillLevel_Gte?: InputMaybe<Scalars['Float']['input']>;
  fillLevel_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  fillLevel_Lte?: InputMaybe<Scalars['Float']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noApproval?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderType_Icontains?: InputMaybe<Scalars['String']['input']>;
  pendingApproval?: InputMaybe<Scalars['Boolean']['input']>;
  polygonSelection?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>>>>;
  propertyGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  route?: InputMaybe<Scalars['ID']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routeScheduledDayGte?: InputMaybe<Scalars['Date']['input']>;
  routeScheduledDayLte?: InputMaybe<Scalars['Date']['input']>;
  route_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stopStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketAssigneeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketAssigneeTeams?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketCreatedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketOrContainerIsnull?: InputMaybe<Scalars['Boolean']['input']>;
  ticketPriority?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketReportedByPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  ticketReporterByDriver?: InputMaybe<Scalars['Boolean']['input']>;
  ticketRequestedByPortal?: InputMaybe<Scalars['Boolean']['input']>;
  ticketStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticket_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFractionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type RouteTypeVehiclesFromRouteSchemeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteTypeConnection = {
  __typename: 'RouteTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteType` and its cursor. */
export type RouteTypeEdge = {
  __typename: 'RouteTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteType>;
};

export type RouteUnComplete = {
  __typename: 'RouteUnComplete';
  route?: Maybe<RouteType>;
};

export type RouteUpdateAutoComplete = {
  __typename: 'RouteUpdateAutoComplete';
  route?: Maybe<RouteType>;
};

export type RouteUpdateAutoCompleteNextStop = {
  __typename: 'RouteUpdateAutoCompleteNextStop';
  route?: Maybe<RouteType>;
};

export type RouteUpdateAutoOptimize = {
  __typename: 'RouteUpdateAutoOptimize';
  route?: Maybe<RouteType>;
};

export type RouteUpdateContainerGroupLocation = {
  __typename: 'RouteUpdateContainerGroupLocation';
  containerGroup?: Maybe<ContainerGroupDef>;
};

export type RouteUpdateContainerLocation = {
  __typename: 'RouteUpdateContainerLocation';
  container?: Maybe<ContainerType>;
};

export type RouteUpdateLocked = {
  __typename: 'RouteUpdateLocked';
  route?: Maybe<RouteType>;
};

export type RouteUpdateLockedStart = {
  __typename: 'RouteUpdateLockedStart';
  route?: Maybe<RouteType>;
};

export type RouteUpdateRouteVehicle = {
  __typename: 'RouteUpdateRouteVehicle';
  route?: Maybe<RouteType>;
};

export type RouteUpdateRouteVehicleDriver = {
  __typename: 'RouteUpdateRouteVehicleDriver';
  route?: Maybe<RouteType>;
};

export type RouteUpdateRouteVehicleMobileApp = {
  __typename: 'RouteUpdateRouteVehicleMobileApp';
  route?: Maybe<RouteType>;
};

export type RouteUpdateRouteVehicleTrailer = {
  __typename: 'RouteUpdateRouteVehicleTrailer';
  route?: Maybe<RouteType>;
};

export type RouteVehicleEventDef = Node & {
  __typename: 'RouteVehicleEventDef';
  createdAt: Scalars['DateTime']['output'];
  fromDriver?: Maybe<DriverType>;
  fromIsPrimary?: Maybe<Scalars['Boolean']['output']>;
  fromVehicle?: Maybe<VehicleType>;
  fromVehicleTrailer?: Maybe<VehicleTrailerType>;
  fromVehicleTrailerCapacityMode?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  routeVehicle?: Maybe<RouteVehicleType>;
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toDriver?: Maybe<DriverType>;
  toIsPrimary?: Maybe<Scalars['Boolean']['output']>;
  toVehicle?: Maybe<VehicleType>;
  toVehicleTrailer?: Maybe<VehicleTrailerType>;
  toVehicleTrailerCapacityMode?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};

export type RouteVehicleEventLogEvent = RouteVehicleEventDef;

export type RouteVehicleEventLogEventWrapper = {
  __typename: 'RouteVehicleEventLogEventWrapper';
  edges?: Maybe<Array<Maybe<RouteVehicleEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum RouteVehicleEventTypesGraphene {
  DRIVER_CHANGE = 'DRIVER_CHANGE',
  IS_PRIMARY_CHANGE = 'IS_PRIMARY_CHANGE',
  VEHICLE_CHANGE = 'VEHICLE_CHANGE',
  VEHICLE_TRAILER_CAPACITY_MODE_CHANGE = 'VEHICLE_TRAILER_CAPACITY_MODE_CHANGE',
  VEHICLE_TRAILER_CHANGE = 'VEHICLE_TRAILER_CHANGE'
}

export type RouteVehicleSessionType = Node & {
  __typename: 'RouteVehicleSessionType';
  /** Vehicle on which driver actually was during the session */
  actualVehicle?: Maybe<VehicleType>;
  /** Date and time on which driver stopped driving the vehicle on the route */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** Arbitrary description of the vehicle session */
  description?: Maybe<Scalars['String']['output']>;
  /** Driver to whom the session belongs */
  driver: DriverType;
  /** Vehicle session when the stop was completed by a driver */
  endedStops: PickUpOrderTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastActive?: Maybe<Scalars['DateTime']['output']>;
  /** Real distance for the session (m) */
  realDistance: Scalars['Float']['output'];
  /** Vehicle that is used in the route */
  routeVehicle: RouteVehicleType;
  /** Date and time on which driver started driving the vehicle on the route. Can be considered as creation time of the session (i.e. used for ordering) */
  startedAt: Scalars['DateTime']['output'];
  /** Vehicle session when the stop was added by a driver */
  startedStops: PickUpOrderTypeConnection;
};


export type RouteVehicleSessionTypeEndedStopsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RouteVehicleSessionTypeStartedStopsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteVehicleSessionTypeConnection = {
  __typename: 'RouteVehicleSessionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteVehicleSessionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RouteVehicleSessionType` and its cursor. */
export type RouteVehicleSessionTypeEdge = {
  __typename: 'RouteVehicleSessionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteVehicleSessionType>;
};

/** Complete route vehicle sessions in bulk */
export type RouteVehicleSessionsComplete = {
  __typename: 'RouteVehicleSessionsComplete';
  route?: Maybe<RouteType>;
  routeVehicleSessions?: Maybe<Array<Maybe<RouteVehicleSessionType>>>;
};

export type RouteVehicleType = Node & {
  __typename: 'RouteVehicleType';
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** Drivers that drives the vehicle on the route */
  driver: DriverType;
  eventLog?: Maybe<RouteVehicleEventLogEventWrapper>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Is set to True if it is the only vehicle on the route or if it was added to the route first when generating the route */
  isPrimary: Scalars['Boolean']['output'];
  /** Route on which driver drives the vehicle */
  route: RouteType;
  routeVehicleSessions?: Maybe<Array<Maybe<RouteVehicleSessionType>>>;
  updatedAt: Scalars['DateTime']['output'];
  /** Vehicle that is used in the route */
  vehicle: VehicleType;
  vehicleTrailer?: Maybe<VehicleTrailerType>;
  vehicleTrailerCapacityMode: RoutesRouteVehicleVehicleTrailerCapacityModeChoices;
};


export type RouteVehicleTypeEventLogArgs = {
  addRouteVehicleEvents?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  routeVehicleEventTypes?: InputMaybe<Array<InputMaybe<RouteVehicleEventTypesGraphene>>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type RouteVehicleTypeConnection = {
  __typename: 'RouteVehicleTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteVehicleTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RouteVehicleType` and its cursor. */
export type RouteVehicleTypeEdge = {
  __typename: 'RouteVehicleTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RouteVehicleType>;
};

/** An enumeration. */
export enum RoutesCollectionCalendarDeterminePeriodByChoices {
  /** Month and date */
  MONTH_AND_DATE = 'MONTH_AND_DATE',
  /** Week number */
  WEEK_NUMBER = 'WEEK_NUMBER'
}

/** An enumeration. */
export enum RoutesCollectionInquiryIssueTypeChoices {
  /** Bulky waste */
  BULKY_WASTE = 'BULKY_WASTE',
  /** Damaged */
  DAMAGED = 'DAMAGED',
  /** Mis-sorted waste */
  MISSORTED = 'MISSORTED',
  /** Others */
  OTHERS = 'OTHERS',
  /** Overflowing */
  OVERFLOWING = 'OVERFLOWING'
}

/** An enumeration. */
export enum RoutesCollectionInquiryTypingStatusChoices {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Disabled */
  DISABLED = 'DISABLED'
}

/** An enumeration. */
export enum RoutesPickUpOrderAutoCompletedByChoices {
  /** manual */
  MANUAL = 'MANUAL',
  /** radius */
  RADIUS = 'RADIUS',
  /** rfid */
  RFID = 'RFID'
}

/** An enumeration. */
export enum RoutesPickUpOrderCreatedFromChoices {
  /** Dynamic */
  DYNAMIC = 'DYNAMIC',
  /** Manual */
  MANUAL = 'MANUAL',
  /** Semi-dynamic */
  SEMI_DYNAMIC = 'SEMI_DYNAMIC',
  /** static */
  STATIC = 'STATIC'
}

/** An enumeration. */
export enum RoutesPickUpOrderStatusChoices {
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Connected to route */
  CONNECTED_TO_ROUTE = 'CONNECTED_TO_ROUTE',
  /** Skipped */
  SKIPPED = 'SKIPPED'
}

/** An enumeration. */
export enum RoutesRouteVehicleVehicleTrailerCapacityModeChoices {
  /** Industry */
  INDUSTRY = 'INDUSTRY',
  /** Normal */
  NORMAL = 'NORMAL',
  /** Switch */
  SWITCH = 'SWITCH'
}

export type RoutesStopsComplete = {
  __typename: 'RoutesStopsComplete';
  errors?: Maybe<Array<Maybe<RouteStopsCompleteError>>>;
  routes?: Maybe<Array<Maybe<RouteType>>>;
};

export type RoutesStopsCompleteInputType = {
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTicketsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fillLevel?: InputMaybe<Scalars['Float']['input']>;
  qrCode?: InputMaybe<Scalars['String']['input']>;
  routeId: Scalars['ID']['input'];
  stopId: Scalars['ID']['input'];
  ticketId?: InputMaybe<Scalars['ID']['input']>;
  ticketIdStopCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type RoutesStopsMove = {
  __typename: 'RoutesStopsMove';
  routes?: Maybe<Array<Maybe<RouteType>>>;
};

export type RoutesStopsMoveInputType = {
  fromRouteId: Scalars['ID']['input'];
  orderIds: Array<InputMaybe<Scalars['ID']['input']>>;
  toRouteId: Scalars['ID']['input'];
};

export type RoutesStopsUpdate = {
  __typename: 'RoutesStopsUpdate';
  route?: Maybe<Array<Maybe<RouteType>>>;
};

export type RuleActionUnionDef = AssignToTeamActionDef | AssignToUserActionDef | ChangePriorityActionDef | ChangeStatusActionDef | ContainerActionDef | CreatePropertyActionDef | CreateTicketActionDef | ScheduleOnRouteActionDef | SendConfirmationMessageToPropertyUserActionDef | SendEmailActionDef | SendMessageToPropertyUserActionDef;

export type SmsNotificationMethodDef = Node & {
  __typename: 'SMSNotificationMethodDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  notificationmethodPtr: NotificationMethodDef;
  phoneNumber: Scalars['String']['output'];
};

export type SalesOfferPageMultipleChoiceFieldInput = {
  description: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type SalesOfferPageType = Node & {
  __typename: 'SalesOfferPageType';
  description: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  salesOffer: SalesOfferType;
  title: Scalars['String']['output'];
};

export type SalesOfferPageTypeConnection = {
  __typename: 'SalesOfferPageTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SalesOfferPageTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SalesOfferPageType` and its cursor. */
export type SalesOfferPageTypeEdge = {
  __typename: 'SalesOfferPageTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SalesOfferPageType>;
};

export type SalesOfferProductMultipleChoiceFieldInput = {
  billingFeature: Scalars['ID']['input'];
  discount: Scalars['Int']['input'];
  grossTotalAmountOverwrite?: InputMaybe<Scalars['Int']['input']>;
  prices: Array<InputMaybe<SalesOfferProductPriceInput>>;
};

export type SalesOfferProductPriceInput = {
  price: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type SalesOfferProductPriceType = Node & {
  __typename: 'SalesOfferProductPriceType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  price: BillingFeatureProductPriceType;
  quantity: Scalars['Int']['output'];
  salesOfferProduct: SalesOfferProductType;
};

export type SalesOfferProductPriceTypeConnection = {
  __typename: 'SalesOfferProductPriceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SalesOfferProductPriceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SalesOfferProductPriceType` and its cursor. */
export type SalesOfferProductPriceTypeEdge = {
  __typename: 'SalesOfferProductPriceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SalesOfferProductPriceType>;
};

export type SalesOfferProductType = Node & {
  __typename: 'SalesOfferProductType';
  billingFeature: BillingFeatureType;
  discount: Scalars['Int']['output'];
  grossTotalAmountOverwrite?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  prices?: Maybe<Array<Maybe<SalesOfferProductPriceType>>>;
  salesOffer: SalesOfferType;
  salesofferproductpriceSet: SalesOfferProductPriceTypeConnection;
};


export type SalesOfferProductTypeSalesofferproductpriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SalesOfferProductTypeConnection = {
  __typename: 'SalesOfferProductTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SalesOfferProductTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SalesOfferProductType` and its cursor. */
export type SalesOfferProductTypeEdge = {
  __typename: 'SalesOfferProductTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SalesOfferProductType>;
};

export type SalesOfferServiceMultipleChoiceFieldInput = {
  discount: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  unitAmount: Scalars['Int']['input'];
};

export type SalesOfferServiceType = Node & {
  __typename: 'SalesOfferServiceType';
  discount: Scalars['Int']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  salesOffer: SalesOfferType;
  title: Scalars['String']['output'];
  unitAmount: Scalars['Int']['output'];
};

export type SalesOfferServiceTypeConnection = {
  __typename: 'SalesOfferServiceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SalesOfferServiceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SalesOfferServiceType` and its cursor. */
export type SalesOfferServiceTypeEdge = {
  __typename: 'SalesOfferServiceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SalesOfferServiceType>;
};

export type SalesOfferType = Node & {
  __typename: 'SalesOfferType';
  company?: Maybe<CompanyType>;
  companyName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: UserType;
  currency: Scalars['String']['output'];
  endAt?: Maybe<Scalars['Date']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  introductionDescription: Scalars['String']['output'];
  introductionImage?: Maybe<Scalars['String']['output']>;
  introductionTitle: Scalars['String']['output'];
  language: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  logoImage?: Maybe<Scalars['String']['output']>;
  planDescription: Scalars['String']['output'];
  planImage?: Maybe<Scalars['String']['output']>;
  planTitle: Scalars['String']['output'];
  recipientName: Scalars['String']['output'];
  recipientTitle: Scalars['String']['output'];
  recurringInterval: Scalars['String']['output'];
  recurringIntervalCount: Scalars['Int']['output'];
  salesofferpageSet: SalesOfferPageTypeConnection;
  salesofferproductSet: SalesOfferProductTypeConnection;
  salesofferserviceSet: SalesOfferServiceTypeConnection;
  scope: Scalars['String']['output'];
  showNotIncludedProducts: Scalars['Boolean']['output'];
  showPrices: Scalars['Boolean']['output'];
  startAt?: Maybe<Scalars['Date']['output']>;
  termsDescription: Scalars['String']['output'];
  termsImage?: Maybe<Scalars['String']['output']>;
  termsTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


export type SalesOfferTypeSalesofferpageSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SalesOfferTypeSalesofferproductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SalesOfferTypeSalesofferserviceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SalesOfferTypeConnection = {
  __typename: 'SalesOfferTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SalesOfferTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SalesOfferType` and its cursor. */
export type SalesOfferTypeEdge = {
  __typename: 'SalesOfferTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SalesOfferType>;
};

export type SaveTicketTypeOrder = {
  __typename: 'SaveTicketTypeOrder';
  ticketTypes: Array<TicketTypeDef>;
};

export type SaveTicketTypeOrderSettings = {
  __typename: 'SaveTicketTypeOrderSettings';
  companySettings?: Maybe<CompanySettingsType>;
};

export type ScheduleOnRouteActionDef = Node & {
  __typename: 'ScheduleOnRouteActionDef';
  area?: Maybe<AreaDef>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  manual: Scalars['Boolean']['output'];
  order: Scalars['Int']['output'];
  routeScheme: RouteSchemeType;
  rule: TicketRuleDef;
};

export type ScheduleOnRouteActionDefConnection = {
  __typename: 'ScheduleOnRouteActionDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ScheduleOnRouteActionDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ScheduleOnRouteActionDef` and its cursor. */
export type ScheduleOnRouteActionDefEdge = {
  __typename: 'ScheduleOnRouteActionDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ScheduleOnRouteActionDef>;
};

export type ScheduleOnRouteActionMutationInput = {
  area?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  manual?: InputMaybe<Scalars['Boolean']['input']>;
  routeScheme: Scalars['ID']['input'];
};

export type ScheduledExportDef = Node & {
  __typename: 'ScheduledExportDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  excelFormat: Scalars['Boolean']['output'];
  exportAgreementEnd: Scalars['Boolean']['output'];
  exportAgreementNew: Scalars['Boolean']['output'];
  exportAgreementRemoved: Scalars['Boolean']['output'];
  exportAgreementStart: Scalars['Boolean']['output'];
  exportContainerCollectionCalendar: Scalars['Boolean']['output'];
  exportContainerCollectionCalendarFromDate: Scalars['Boolean']['output'];
  exportContainerCollectionCalendarWeek: Scalars['Boolean']['output'];
  exportContainerDescription: Scalars['Boolean']['output'];
  exportContainerId: Scalars['Boolean']['output'];
  exportContainerLocation: Scalars['Boolean']['output'];
  exportContainerMetaTypes?: Maybe<Array<Maybe<CompanyMetaTypeDef>>>;
  exportContainerPickupSetting: Scalars['Boolean']['output'];
  exportContainerRouteDescription: Scalars['Boolean']['output'];
  exportContainerRouteScheme: Scalars['Boolean']['output'];
  exportContainerType: Scalars['Boolean']['output'];
  exportContainerWasteFraction: Scalars['Boolean']['output'];
  exportPropertyArea: Scalars['Boolean']['output'];
  exportPropertyId: Scalars['Boolean']['output'];
  exportPropertyLocation: Scalars['Boolean']['output'];
  exportPropertyMetaTypes?: Maybe<Array<Maybe<CompanyMetaTypeDef>>>;
  exportPropertyType: Scalars['Boolean']['output'];
  exportTicketBulkWasteItems: Scalars['Boolean']['output'];
  exportTicketDescription: Scalars['Boolean']['output'];
  exportTicketPreferredDate: Scalars['Boolean']['output'];
  exportTicketType: Scalars['Boolean']['output'];
  filterContainerMetaTypes?: Maybe<Array<Maybe<ScheduledExportMetaTypeDef>>>;
  filterContainerPickupSettings?: Maybe<Array<Maybe<PickupSettingsType>>>;
  filterContainerRouteSchemes?: Maybe<Array<Maybe<RouteSchemeType>>>;
  filterContainerTypes?: Maybe<Array<Maybe<ContainerTypeType>>>;
  filterContainerWasteFractions?: Maybe<Array<Maybe<WasteFractionType>>>;
  filterPropertyMetaTypes?: Maybe<Array<Maybe<ScheduledExportMetaTypeDef>>>;
  filterPropertyTypes?: Maybe<Array<Maybe<PropertyTypeDef>>>;
  filterTicketDate: CrmScheduledExportFilterTicketDateChoices;
  filterTicketStatus: Array<Scalars['Int']['output']>;
  filterTicketTypes?: Maybe<Array<Maybe<TicketTypeDef>>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  includeAgreements: Scalars['Boolean']['output'];
  includeTickets: Scalars['Boolean']['output'];
  interval: Scalars['Int']['output'];
  intervalDayHour?: Maybe<Scalars['Int']['output']>;
  intervalFuture: Scalars['Boolean']['output'];
  intervalMonthDay?: Maybe<Scalars['Int']['output']>;
  intervalStart: Scalars['DateTime']['output'];
  intervalType: CrmScheduledExportIntervalTypeChoices;
  intervalWeekIsoweekday?: Maybe<Scalars['Int']['output']>;
  language: CrmScheduledExportLanguageChoices;
  name: Scalars['String']['output'];
  nextFromDate?: Maybe<Scalars['DateTime']['output']>;
  nextRunDatetime?: Maybe<Scalars['DateTime']['output']>;
  nextToDate?: Maybe<Scalars['DateTime']['output']>;
  recipients: Array<Scalars['String']['output']>;
  scheduledexportrunSet: ScheduledExportRunDefConnection;
};


export type ScheduledExportDefScheduledexportrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduledExportDefConnection = {
  __typename: 'ScheduledExportDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ScheduledExportDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ScheduledExportDef` and its cursor. */
export type ScheduledExportDefEdge = {
  __typename: 'ScheduledExportDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ScheduledExportDef>;
};

export type ScheduledExportMetaTypeDef = Node & {
  __typename: 'ScheduledExportMetaTypeDef';
  companyMetaValue: CompanyMetaTypeDef;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type ScheduledExportMetaTypeDefConnection = {
  __typename: 'ScheduledExportMetaTypeDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ScheduledExportMetaTypeDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ScheduledExportMetaTypeDef` and its cursor. */
export type ScheduledExportMetaTypeDefEdge = {
  __typename: 'ScheduledExportMetaTypeDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ScheduledExportMetaTypeDef>;
};

export type ScheduledExportMetaTypeInput = {
  companyMetaValue?: InputMaybe<Scalars['ID']['input']>;
  value: Scalars['String']['input'];
};

export type ScheduledExportRunDef = Node & {
  __typename: 'ScheduledExportRunDef';
  company: CompanyType;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  excelFormat: Scalars['Boolean']['output'];
  exportAgreementEnd: Scalars['Boolean']['output'];
  exportAgreementNew: Scalars['Boolean']['output'];
  exportAgreementRemoved: Scalars['Boolean']['output'];
  exportAgreementStart: Scalars['Boolean']['output'];
  exportContainerCollectionCalendar: Scalars['Boolean']['output'];
  exportContainerCollectionCalendarFromDate: Scalars['Boolean']['output'];
  exportContainerCollectionCalendarWeek: Scalars['Boolean']['output'];
  exportContainerDescription: Scalars['Boolean']['output'];
  exportContainerId: Scalars['Boolean']['output'];
  exportContainerLocation: Scalars['Boolean']['output'];
  exportContainerMetaTypes: CompanyMetaTypeDefConnection;
  exportContainerPickupSetting: Scalars['Boolean']['output'];
  exportContainerRouteDescription: Scalars['Boolean']['output'];
  exportContainerRouteScheme: Scalars['Boolean']['output'];
  exportContainerType: Scalars['Boolean']['output'];
  exportContainerWasteFraction: Scalars['Boolean']['output'];
  exportFile?: Maybe<Scalars['String']['output']>;
  exportPropertyArea: Scalars['Boolean']['output'];
  exportPropertyId: Scalars['Boolean']['output'];
  exportPropertyLocation: Scalars['Boolean']['output'];
  exportPropertyMetaTypes: CompanyMetaTypeDefConnection;
  exportPropertyType: Scalars['Boolean']['output'];
  exportTicketBulkWasteItems: Scalars['Boolean']['output'];
  exportTicketDescription: Scalars['Boolean']['output'];
  exportTicketPreferredDate: Scalars['Boolean']['output'];
  exportTicketType: Scalars['Boolean']['output'];
  filterContainerMetaTypes: ScheduledExportMetaTypeDefConnection;
  filterContainerPickupSettings: PickupSettingsTypeConnection;
  filterContainerRouteSchemes: RouteSchemeTypeConnection;
  filterContainerTypes: ContainerTypeTypeConnection;
  filterContainerWasteFractions: WasteFractionTypeConnection;
  filterPropertyMetaTypes: ScheduledExportMetaTypeDefConnection;
  filterPropertyTypes: PropertyTypeDefConnection;
  filterTicketDate: CrmScheduledExportRunFilterTicketDateChoices;
  filterTicketStatus: Array<Scalars['Int']['output']>;
  filterTicketTypes: TicketTypeDefConnection;
  fromDate: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  includeAgreements: Scalars['Boolean']['output'];
  includeTickets: Scalars['Boolean']['output'];
  language: CrmScheduledExportRunLanguageChoices;
  newAgreements?: Maybe<AgreementDefConnection>;
  newAgreementsCount?: Maybe<Scalars['Int']['output']>;
  properties: PropertyDefConnection;
  recipients: Array<Scalars['String']['output']>;
  removedAgreements?: Maybe<AgreementDefConnection>;
  removedAgreementsCount?: Maybe<Scalars['Int']['output']>;
  scheduled: Scalars['Boolean']['output'];
  scheduledExport: ScheduledExportDef;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  tickets?: Maybe<TicketDefConnection>;
  ticketsCount?: Maybe<Scalars['Int']['output']>;
  toDate: Scalars['DateTime']['output'];
};


export type ScheduledExportRunDefExportContainerMetaTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefExportPropertyMetaTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefFilterContainerMetaTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefFilterContainerPickupSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefFilterContainerRouteSchemesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefFilterContainerTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefFilterContainerWasteFractionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefFilterPropertyMetaTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefFilterPropertyTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefFilterTicketTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefNewAgreementsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveOrFuture?: InputMaybe<Scalars['Boolean']['input']>;
  isContainerAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  isFuture?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  property?: InputMaybe<Scalars['ID']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type ScheduledExportRunDefPropertiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduledExportRunDefRemovedAgreementsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  container?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveOrFuture?: InputMaybe<Scalars['Boolean']['input']>;
  isContainerAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  isFuture?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  property?: InputMaybe<Scalars['ID']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type ScheduledExportRunDefTicketsArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  allowedForRoute?: InputMaybe<Scalars['ID']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignedTeam?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignee?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerBarcode?: InputMaybe<Scalars['String']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdOn?: InputMaybe<Scalars['String']['input']>;
  excludeStatuses?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeTicketTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastViewedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  needToPairRfid?: InputMaybe<Scalars['Boolean']['input']>;
  needToScanBarcode?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  priority?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  property?: InputMaybe<Scalars['ID']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reportedByPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  reporter?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reporterByDriver?: InputMaybe<Scalars['Boolean']['input']>;
  reporterDriver?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedByPortal?: InputMaybe<Scalars['Boolean']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statusCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusInProgressAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusInProgressAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusOnHoldAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusOnHoldAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusOpenAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusOpenAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusPendingAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusPendingAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusRejectedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusRejectedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ticketType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeCategory?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeService?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeServiceServiceType?: InputMaybe<Scalars['String']['input']>;
  ticketType_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ScheduledExportRunDefConnection = {
  __typename: 'ScheduledExportRunDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ScheduledExportRunDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ScheduledExportRunDef` and its cursor. */
export type ScheduledExportRunDefEdge = {
  __typename: 'ScheduledExportRunDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ScheduledExportRunDef>;
};

export type SearchLocation = {
  __typename: 'SearchLocation';
  locations?: Maybe<Array<Maybe<SearchLocationDef>>>;
};

export type SearchLocationDef = {
  __typename: 'SearchLocationDef';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  municipalityCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  placeId?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  postalCodeName?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
};

export type SearchLocationReverse = {
  __typename: 'SearchLocationReverse';
  locations?: Maybe<Array<Maybe<SearchLocationDef>>>;
};

export type SelfPasswordChange = {
  __typename: 'SelfPasswordChange';
  status?: Maybe<Scalars['String']['output']>;
};

export type SendConfirmationMessageToPropertyUserActionDef = Node & {
  __typename: 'SendConfirmationMessageToPropertyUserActionDef';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  rule: TicketRuleDef;
  via?: Maybe<TicketSendConfirmationMessageToPropertyUserActionViaChoices>;
};

export type SendConfirmationMessageToPropertyUserActionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  via?: InputMaybe<Scalars['String']['input']>;
};

export type SendEmailActionDef = Node & {
  __typename: 'SendEmailActionDef';
  body: Scalars['String']['output'];
  companyEmailAccount: CompanyEmailAccountDef;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  rule: TicketRuleDef;
  subject: Scalars['String']['output'];
  toEmail: Scalars['String']['output'];
  toName: Scalars['String']['output'];
};

export type SendEmailActionMutationInput = {
  body: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyEmailAccount: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  toEmail: Scalars['String']['input'];
  toName?: InputMaybe<Scalars['String']['input']>;
};

export type SendMessageToPropertyUserActionDef = Node & {
  __typename: 'SendMessageToPropertyUserActionDef';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  rule: TicketRuleDef;
  via?: Maybe<TicketSendMessageToPropertyUserActionViaChoices>;
};

export type SendMessageToPropertyUserActionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  via?: InputMaybe<Scalars['String']['input']>;
};

export type SendMqttMessage = {
  __typename: 'SendMqttMessage';
  status?: Maybe<Scalars['String']['output']>;
};

export type SendTicketSummaryInput = {
  toUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  via: Scalars['String']['input'];
};

export type SensorInputObject = {
  deviceAngle?: InputMaybe<Scalars['Float']['input']>;
  internalHeight: Scalars['Float']['input'];
  lid: Scalars['String']['input'];
  measurementSettingId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Float']['input']>;
  sensorId: Scalars['String']['input'];
};

export type ServiceDef = Node & {
  __typename: 'ServiceDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  productSet: ProductDefConnection;
  serviceType?: Maybe<ServiceType>;
  servicepriceSet: ServicePriceDefConnection;
  tickettypeSet: TicketTypeDefConnection;
};


export type ServiceDefProductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceDefServicepriceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceDefTickettypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ServiceDefConnection = {
  __typename: 'ServiceDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ServiceDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ServiceDef` and its cursor. */
export type ServiceDefEdge = {
  __typename: 'ServiceDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ServiceDef>;
};

export type ServicePriceDef = Node & {
  __typename: 'ServicePriceDef';
  amount: Scalars['Int']['output'];
  company: CompanyType;
  containerType?: Maybe<ContainerTypeType>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  invoiceName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  propertyType?: Maybe<PropertyTypeDef>;
  service: ServiceDef;
  startDate: Scalars['Date']['output'];
  wasteFraction?: Maybe<WasteFractionType>;
};

export type ServicePriceDefConnection = {
  __typename: 'ServicePriceDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ServicePriceDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ServicePriceDef` and its cursor. */
export type ServicePriceDefEdge = {
  __typename: 'ServicePriceDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ServicePriceDef>;
};

/** An enumeration. */
export enum ServiceType {
  ADD_CONTAINER = 'ADD_CONTAINER',
  BULK_WASTE_PICKUP = 'BULK_WASTE_PICKUP',
  CHANGE_CONTAINER_COLLECTION_CALENDAR = 'CHANGE_CONTAINER_COLLECTION_CALENDAR',
  CHANGE_CONTAINER_PICKUP_SETTING = 'CHANGE_CONTAINER_PICKUP_SETTING',
  CHANGE_CONTAINER_ROUTE_SCHEME = 'CHANGE_CONTAINER_ROUTE_SCHEME',
  CHANGE_CONTAINER_TYPE = 'CHANGE_CONTAINER_TYPE',
  CONTACT_SYNC = 'CONTACT_SYNC',
  EXTRA_EMPTYING = 'EXTRA_EMPTYING',
  MISSING_COLLECTION = 'MISSING_COLLECTION',
  NEW_PROPERTY = 'NEW_PROPERTY',
  REMOVE_CONTAINER = 'REMOVE_CONTAINER'
}

export type SetAgreementShares = {
  __typename: 'SetAgreementShares';
  agreement?: Maybe<AgreementDef>;
};

export type SetContainerGroup = {
  __typename: 'SetContainerGroup';
  containers?: Maybe<Array<Maybe<ContainerType>>>;
};

export type SetContainerStorageDepot = {
  __typename: 'SetContainerStorageDepot';
  status?: Maybe<Scalars['String']['output']>;
};

export type SetDefaultCard = {
  __typename: 'SetDefaultCard';
  billingInformation?: Maybe<BillingInformationType>;
};

export type SetPropertiesInPropertyGroup = {
  __typename: 'SetPropertiesInPropertyGroup';
  propertyGroup?: Maybe<PropertyGroupDef>;
};

export type SetTicketPriority = {
  __typename: 'SetTicketPriority';
  ticket?: Maybe<TicketDef>;
};

export type SetTicketRoute = {
  __typename: 'SetTicketRoute';
  ticket?: Maybe<TicketDef>;
};

export type SettingsType = Node & {
  __typename: 'SettingsType';
  /** 12-hour clock? */
  clockFormat12: Scalars['Boolean']['output'];
  cluster: Scalars['Boolean']['output'];
  company?: Maybe<CompanyType>;
  country: Scalars['String']['output'];
  crmEnabled: Scalars['Boolean']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  dashboardLocation?: Maybe<LocationWithContainerType>;
  drivingDistance?: Maybe<Scalars['String']['output']>;
  emptyingMethod?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  measuringDistance?: Maybe<Scalars['String']['output']>;
  /** mins */
  pickupTime: Scalars['Int']['output'];
  temperature?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  volume?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['String']['output']>;
};

export type SettingsTypeConnection = {
  __typename: 'SettingsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SettingsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SettingsType` and its cursor. */
export type SettingsTypeEdge = {
  __typename: 'SettingsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SettingsType>;
};

export type SigFoxDeviceType = BaseDeviceInterface & Node & {
  __typename: 'SigFoxDeviceType';
  antenna: DeviceSigFoxDeviceAntennaChoices;
  batch: Scalars['String']['output'];
  bootloaderFwVersion: Scalars['String']['output'];
  company?: Maybe<CompanyType>;
  connectionType?: Maybe<Scalars['String']['output']>;
  containerDevice?: Maybe<DeviceToContainerTypeConnection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  devId?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<DeviceTypeType>;
  hwVersion?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isContainer?: Maybe<Scalars['Boolean']['output']>;
  isGps?: Maybe<Scalars['Boolean']['output']>;
  measurements?: Maybe<FillLevelMeasurementTypeConnection>;
  modemFwVersion: Scalars['String']['output'];
  pac?: Maybe<SigFoxPacType>;
  productNo: Scalars['String']['output'];
  sigFoxPac?: Maybe<SigFoxPacType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  whsFwVersion: Scalars['String']['output'];
};


export type SigFoxDeviceTypeContainerDeviceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SigFoxDeviceTypeMeasurementsArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SigFoxDeviceTypeConnection = {
  __typename: 'SigFoxDeviceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SigFoxDeviceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SigFoxDeviceType` and its cursor. */
export type SigFoxDeviceTypeEdge = {
  __typename: 'SigFoxDeviceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SigFoxDeviceType>;
};

export type SigFoxPacType = Node & {
  __typename: 'SigFoxPacType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  pac?: Maybe<Scalars['String']['output']>;
  sigfoxdevice?: Maybe<SigFoxDeviceType>;
};

export type SimCardType = Node & {
  __typename: 'SimCardType';
  apn: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  iccid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  imsi: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  mobiledevice?: Maybe<MobileDeviceType>;
  updatedAt: Scalars['DateTime']['output'];
};

/**
 * For some reason, without class like this with the CompanyEmailAccountMessage
 * the CreateCompanyEmailAccountMessage mutation experiences this:
 *     "Exception: No type registered for model: CompanyEmailAccountMessage"
 */
export type SimpleCompanyEmailAccountMessageDef = Node & {
  __typename: 'SimpleCompanyEmailAccountMessageDef';
  assignedTeam?: Maybe<TeamType>;
  assignee?: Maybe<UserType>;
  /** Tickets associated with the message */
  associatedTickets: TicketDefConnection;
  attachments: CompanyEmailAccountMessageAttachmentDefConnection;
  body: Scalars['String']['output'];
  companyEmailAccount: CompanyEmailAccountDef;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  errorAt?: Maybe<Scalars['DateTime']['output']>;
  fromEmail: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  providerId?: Maybe<Scalars['String']['output']>;
  sendAt?: Maybe<Scalars['DateTime']['output']>;
  snippet: Scalars['String']['output'];
  status: CoreCompanyEmailAccountMessageStatusChoices;
  subject?: Maybe<Scalars['String']['output']>;
  threadId?: Maybe<Scalars['String']['output']>;
  threadMessages: CompanyEmailAccountMessageDefConnection;
  threadStarter?: Maybe<CompanyEmailAccountMessageDef>;
  ticketMessage?: Maybe<TicketMessageDef>;
  ticketdraftSet: TicketDraftDefConnection;
  toEmail?: Maybe<Scalars['String']['output']>;
  toEmailName?: Maybe<Scalars['String']['output']>;
  userNotifications: UserNotificationTypeConnection;
};


/**
 * For some reason, without class like this with the CompanyEmailAccountMessage
 * the CreateCompanyEmailAccountMessage mutation experiences this:
 *     "Exception: No type registered for model: CompanyEmailAccountMessage"
 */
export type SimpleCompanyEmailAccountMessageDefAssociatedTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * For some reason, without class like this with the CompanyEmailAccountMessage
 * the CreateCompanyEmailAccountMessage mutation experiences this:
 *     "Exception: No type registered for model: CompanyEmailAccountMessage"
 */
export type SimpleCompanyEmailAccountMessageDefAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * For some reason, without class like this with the CompanyEmailAccountMessage
 * the CreateCompanyEmailAccountMessage mutation experiences this:
 *     "Exception: No type registered for model: CompanyEmailAccountMessage"
 */
export type SimpleCompanyEmailAccountMessageDefThreadMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * For some reason, without class like this with the CompanyEmailAccountMessage
 * the CreateCompanyEmailAccountMessage mutation experiences this:
 *     "Exception: No type registered for model: CompanyEmailAccountMessage"
 */
export type SimpleCompanyEmailAccountMessageDefTicketdraftSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * For some reason, without class like this with the CompanyEmailAccountMessage
 * the CreateCompanyEmailAccountMessage mutation experiences this:
 *     "Exception: No type registered for model: CompanyEmailAccountMessage"
 */
export type SimpleCompanyEmailAccountMessageDefUserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Describes Driver's skills */
export type SkillType = Node & {
  __typename: 'SkillType';
  /** Code of skill */
  code: Scalars['String']['output'];
  /** Company, which skill related to */
  company: CompanyType;
  /** Skill description */
  description: Scalars['String']['output'];
  driverSet: DriverTypeConnection;
  /** Relation to Skill model */
  driverSkills: DriverSkillTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Skill naming */
  name: Scalars['String']['output'];
};


/** Describes Driver's skills */
export type SkillTypeDriverSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Driver's skills */
export type SkillTypeDriverSkillsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SkillTypeConnection = {
  __typename: 'SkillTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SkillTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SkillType` and its cursor. */
export type SkillTypeEdge = {
  __typename: 'SkillTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SkillType>;
};

export type StatsType = {
  __typename: 'StatsType';
  containerLogs?: Maybe<Array<Maybe<ContainerLogDaysType>>>;
  containers?: Maybe<ContainerTypeConnection>;
};


export type StatsTypeContainerLogsArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
};


export type StatsTypeContainersArgs = {
  From?: InputMaybe<Scalars['DateTime']['input']>;
  To?: InputMaybe<Scalars['DateTime']['input']>;
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  allowedForRoute?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  collectionCalendar_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  collectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerId?: InputMaybe<Scalars['String']['input']>;
  containerType_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  containerType_Name_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  containerTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  device_IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  excludeCollectionCalendars?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeRouteSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  measurement_FillPercentage?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noProperty?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyNumber?: InputMaybe<Scalars['String']['input']>;
  propertyTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  q?: InputMaybe<Scalars['String']['input']>;
  routeIdsNotCompletedStops?: InputMaybe<Scalars['String']['input']>;
  routeScheme_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  routeSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchContact?: InputMaybe<Scalars['String']['input']>;
  sensor_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  storedAtDepot?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFractionIds?: InputMaybe<Scalars['String']['input']>;
  wasteFraction_WasteTypes_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  wasteFraction_WasteTypes_Name_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wasteTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type StopListCardConfigType = Node & {
  __typename: 'StopListCardConfigType';
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  showWhoCompleted: Scalars['Boolean']['output'];
  stopCardConfig?: Maybe<CardConfigOptions>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StopListCardConfigTypeConnection = {
  __typename: 'StopListCardConfigTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StopListCardConfigTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StopListCardConfigType` and its cursor. */
export type StopListCardConfigTypeEdge = {
  __typename: 'StopListCardConfigTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<StopListCardConfigType>;
};

export type StopListFieldDef = {
  __typename: 'StopListFieldDef';
  configType?: Maybe<CardConfigOptions>;
  stopListFieldOptions?: Maybe<Array<Maybe<StopListFieldOptionDef>>>;
};

export type StopListFieldOptionDef = {
  __typename: 'StopListFieldOptionDef';
  kind?: Maybe<FieldTypeEnum>;
  label?: Maybe<Scalars['String']['output']>;
  metaFieldName?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename: 'Subscription';
  onCompanyEmailAccountMessageChanged?: Maybe<CompanyEmailAccountMessageChangedSubscription>;
  onCurrentDriverLocationChange?: Maybe<CurrentDriverLocationSubscription>;
  onHealthCheck?: Maybe<HealthCheckSubscription>;
  onNotification?: Maybe<NotificationSubscription>;
  onRouteAvailableContainersChange?: Maybe<RouteAvailableContainersSubscription>;
  onRouteChange?: Maybe<RouteSubscription>;
  onRouteStopsChange?: Maybe<RouteStopsSubscription>;
  onTicketChanged?: Maybe<TicketChangedSubscription>;
  onTicketDraftChanged?: Maybe<TicketDraftChangedSubscription>;
  onUserJobNotification?: Maybe<UserJobNotificationSubscription>;
  onUserNotification?: Maybe<UserNotificationSubscription>;
  onVehiclePositionChange?: Maybe<VehicleSubscription>;
};


export type SubscriptionOnCompanyEmailAccountMessageChangedArgs = {
  companyEmailAccountMessageId: Scalars['ID']['input'];
};


export type SubscriptionOnCurrentDriverLocationChangeArgs = {
  routeId: Scalars['ID']['input'];
};


export type SubscriptionOnNotificationArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionOnRouteAvailableContainersChangeArgs = {
  routeId: Scalars['ID']['input'];
};


export type SubscriptionOnRouteChangeArgs = {
  routeId: Scalars['ID']['input'];
};


export type SubscriptionOnRouteStopsChangeArgs = {
  routeId: Scalars['ID']['input'];
};


export type SubscriptionOnTicketChangedArgs = {
  ticketId: Scalars['ID']['input'];
};


export type SubscriptionOnTicketDraftChangedArgs = {
  ticketDraftId: Scalars['ID']['input'];
};


export type SubscriptionOnVehiclePositionChangeArgs = {
  vehiclesIds: Scalars['String']['input'];
};

export type SyncCustomFieldsWithTicketTypeMutation = {
  __typename: 'SyncCustomFieldsWithTicketTypeMutation';
  ticketType?: Maybe<TicketTypeDef>;
};

export type TeamType = Node & {
  __typename: 'TeamType';
  alertrecipientSet: AlertRecipientDefConnection;
  assignedTeamCompanyemailaccountmessages: CompanyEmailAccountMessageDefConnection;
  assignedTeamTickets: TicketDefConnection;
  assigntoteamactionSet: AssignToTeamActionDefConnection;
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  members: UserTypeConnection;
  name: Scalars['String']['output'];
};


export type TeamTypeAlertrecipientSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TeamTypeAssignedTeamCompanyemailaccountmessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TeamTypeAssignedTeamTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TeamTypeAssigntoteamactionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TeamTypeMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TeamTypeConnection = {
  __typename: 'TeamTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TeamType` and its cursor. */
export type TeamTypeEdge = {
  __typename: 'TeamTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TeamType>;
};

export type TicketAttachmentDef = Node & {
  __typename: 'TicketAttachmentDef';
  deleted?: Maybe<Scalars['DateTime']['output']>;
  eventLog?: Maybe<TicketAttachmentEventLogEventWrapper>;
  file: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  ticket: TicketDef;
};


export type TicketAttachmentDefEventLogArgs = {
  addTicketAttachmentEvents?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ticketAttachmentEventTypes?: InputMaybe<Array<InputMaybe<GenericAddRemoveEventTypesGraphene>>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type TicketAttachmentDefConnection = {
  __typename: 'TicketAttachmentDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketAttachmentDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketAttachmentDef` and its cursor. */
export type TicketAttachmentDefEdge = {
  __typename: 'TicketAttachmentDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketAttachmentDef>;
};

export type TicketAttachmentEventDef = Node & {
  __typename: 'TicketAttachmentEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<GenericAddRemoveEventTypesGraphene>>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  ticketAttachment?: Maybe<TicketAttachmentDef>;
  user?: Maybe<UserType>;
};

export type TicketAttachmentEventLogEvent = TicketAttachmentEventDef;

export type TicketAttachmentEventLogEventWrapper = {
  __typename: 'TicketAttachmentEventLogEventWrapper';
  edges?: Maybe<Array<Maybe<TicketAttachmentEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TicketBulkUpdateCreateData = {
  agreementEndDate?: InputMaybe<Scalars['Date']['input']>;
  agreementExcludeFromBilling?: InputMaybe<Scalars['Boolean']['input']>;
  agreementStartDate?: InputMaybe<Scalars['Date']['input']>;
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  assigneeFollowAutomationRules?: InputMaybe<Scalars['Boolean']['input']>;
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  collectionCalendarId?: InputMaybe<Scalars['ID']['input']>;
  companyEmailAccountMessages?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  companyId: Scalars['ID']['input'];
  containerId?: InputMaybe<Scalars['ID']['input']>;
  containerProductId?: InputMaybe<Scalars['ID']['input']>;
  containerTypeId?: InputMaybe<Scalars['ID']['input']>;
  customFieldsValues?: InputMaybe<Array<InputMaybe<TicketCustomFieldValueInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionAction?: InputMaybe<DescriptionActionEnum>;
  fillLevel?: InputMaybe<Scalars['Float']['input']>;
  ignorePriority?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ignoreServiceFee?: InputMaybe<Scalars['Boolean']['input']>;
  internalNote?: InputMaybe<TicketCommentInput>;
  internalNoteAttachmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  message?: InputMaybe<Scalars['String']['input']>;
  messageAttachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  messageReceivers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  messageSendingFrom?: InputMaybe<Scalars['ID']['input']>;
  messageType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pickupSettingId?: InputMaybe<Scalars['ID']['input']>;
  preferredDate?: InputMaybe<Scalars['Date']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  qrCode?: InputMaybe<Scalars['String']['input']>;
  reporterDriverId?: InputMaybe<Scalars['ID']['input']>;
  reporterId?: InputMaybe<Scalars['ID']['input']>;
  requestedById?: InputMaybe<Scalars['ID']['input']>;
  requestedByOther?: InputMaybe<Scalars['String']['input']>;
  routeId?: InputMaybe<Scalars['ID']['input']>;
  routeSchemeId?: InputMaybe<Scalars['ID']['input']>;
  runInBackground?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleToRoute?: InputMaybe<Scalars['ID']['input']>;
  serviceId?: InputMaybe<Scalars['ID']['input']>;
  serviceProducts?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Scalars['String']['input']>;
  stopId?: InputMaybe<Scalars['ID']['input']>;
  ticketIdStopCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  ticketTypeId?: InputMaybe<Scalars['ID']['input']>;
  wasteFractionId?: InputMaybe<Scalars['ID']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type TicketBulkUpdateCreateInput = {
  containerId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  stopId?: InputMaybe<Scalars['ID']['input']>;
};

export type TicketBulkUpdateCreateMutation = {
  __typename: 'TicketBulkUpdateCreateMutation';
  ticketList?: Maybe<Array<Maybe<TicketDef>>>;
};

export type TicketCategoryDef = Node & {
  __typename: 'TicketCategoryDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tickettypeSet: TicketTypeDefConnection;
};


export type TicketCategoryDefTickettypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TicketCategoryDefConnection = {
  __typename: 'TicketCategoryDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketCategoryDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketCategoryDef` and its cursor. */
export type TicketCategoryDefEdge = {
  __typename: 'TicketCategoryDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketCategoryDef>;
};

/** An enumeration. */
export enum TicketChangePriorityActionPriorityChoices {
  /** None */
  A_0 = 'A_0',
  /** Log */
  A_1 = 'A_1',
  /** Medium */
  A_2 = 'A_2',
  /** High */
  A_3 = 'A_3',
  /** Critical */
  A_4 = 'A_4'
}

/** An enumeration. */
export enum TicketChangeStatusActionStatusChoices {
  /** Created */
  A_1 = 'A_1',
  /** Open */
  A_2 = 'A_2',
  /** Pending */
  A_3 = 'A_3',
  /** On hold */
  A_4 = 'A_4',
  /** In progress */
  A_5 = 'A_5',
  /** Completed */
  A_6 = 'A_6',
  /** Rejected */
  A_7 = 'A_7'
}

export type TicketChangedSubscription = {
  __typename: 'TicketChangedSubscription';
  ticket?: Maybe<TicketDef>;
};

export type TicketClusterDef = {
  __typename: 'TicketClusterDef';
  createdFrom?: Maybe<Array<Maybe<ReportedFromDef>>>;
  duplicatedPoints?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  pointsOnTheSameLocation: Scalars['Boolean']['output'];
  ticketStatuses?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  ticketTypes?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  totalCount: Scalars['Int']['output'];
};

export type TicketCommentAttachmentDef = Node & {
  __typename: 'TicketCommentAttachmentDef';
  deleted?: Maybe<Scalars['DateTime']['output']>;
  file: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  ticketComment: TicketCommentDef;
};

export type TicketCommentAttachmentEventDef = Node & {
  __typename: 'TicketCommentAttachmentEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<GenericAddRemoveEventTypesGraphene>>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  ticketMessageAttachment?: Maybe<TicketCommentAttachmentDef>;
  user?: Maybe<UserType>;
};

export type TicketCommentDef = Node & {
  __typename: 'TicketCommentDef';
  attachments?: Maybe<Array<Maybe<TicketCommentAttachmentDef>>>;
  author?: Maybe<UserType>;
  authorName?: Maybe<Scalars['String']['output']>;
  body: Scalars['String']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  ticket: TicketDef;
};

export type TicketCommentDefConnection = {
  __typename: 'TicketCommentDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketCommentDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketCommentDef` and its cursor. */
export type TicketCommentDefEdge = {
  __typename: 'TicketCommentDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketCommentDef>;
};

export type TicketCommentInput = {
  body: Scalars['String']['input'];
};

/** An enumeration. */
export enum TicketCreateTicketActionNewTicketPriorityChoices {
  /** None */
  A_0 = 'A_0',
  /** Log */
  A_1 = 'A_1',
  /** Medium */
  A_2 = 'A_2',
  /** High */
  A_3 = 'A_3',
  /** Critical */
  A_4 = 'A_4'
}

export type TicketCustomFieldDef = Node & {
  __typename: 'TicketCustomFieldDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  displayHelpText: Scalars['Boolean']['output'];
  displayOn: Array<Scalars['String']['output']>;
  displayPrefilledValue: Scalars['Boolean']['output'];
  displayUnit: Scalars['Boolean']['output'];
  helpText: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  inputType: TicketTicketCustomFieldInputTypeChoices;
  interval: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Array<Maybe<CustomFieldOptionSelectDef>>>;
  placeholder: Scalars['String']['output'];
  prefilledValue: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  ticketTypes?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  tickettypeTicketTypes: TicketTypeDefConnection;
  tickettypeconnectioncustomfieldSet: TicketTypeCustomFieldsConnectionConnection;
  unit: Scalars['String']['output'];
};


export type TicketCustomFieldDefTickettypeTicketTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketCustomFieldDefTickettypeconnectioncustomfieldSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TicketCustomFieldDefConnection = {
  __typename: 'TicketCustomFieldDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketCustomFieldDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TicketCustomFieldDef` and its cursor. */
export type TicketCustomFieldDefEdge = {
  __typename: 'TicketCustomFieldDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketCustomFieldDef>;
};

export type TicketCustomFieldValueInput = {
  connectionId: Scalars['ID']['input'];
  multiSelectValue?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  numberValue?: InputMaybe<Scalars['Float']['input']>;
  selectValue?: InputMaybe<Scalars['ID']['input']>;
  textValue?: InputMaybe<Scalars['String']['input']>;
};

export type TicketDef = Node & {
  __typename: 'TicketDef';
  /** Active agreement of related container */
  activeAgreement?: Maybe<AgreementDef>;
  agreement?: Maybe<AgreementDef>;
  /** Ended agreement of related container */
  agreementEnded?: Maybe<AgreementDef>;
  agreementStarted: AgreementDefConnection;
  agreementsEndedBy?: Maybe<Array<Maybe<AgreementDef>>>;
  agreementsStartedBy?: Maybe<Array<Maybe<AgreementDef>>>;
  assignedTeam?: Maybe<TeamType>;
  assignee?: Maybe<UserType>;
  /** Tickets associated with the message */
  associatedInboxMessages: CompanyEmailAccountMessageDefConnection;
  attachments: TicketAttachmentDefConnection;
  availableRoutes?: Maybe<RouteTypeConnection>;
  billingContainerPrice?: Maybe<Scalars['Int']['output']>;
  billingServicePrice?: Maybe<Scalars['Int']['output']>;
  bulkwastepickupticket?: Maybe<BulkWastePickupTicketDef>;
  canBeRejected?: Maybe<Scalars['Boolean']['output']>;
  company: CompanyType;
  completeActionsRequirements?: Maybe<CompleteActionsRequirements>;
  container?: Maybe<ContainerType>;
  containerPrice?: Maybe<PriceDef>;
  containerProduct?: Maybe<ProductDef>;
  createdAt: Scalars['DateTime']['output'];
  createdAtStop: PickUpOrderTypeConnection;
  customFields: Scalars['JSONString']['output'];
  customValues?: Maybe<Array<Maybe<CustomTicketValuesDef>>>;
  deadline?: Maybe<Scalars['DateTime']['output']>;
  defaultReplyTo?: Maybe<TicketDefaultReplyToDef>;
  description: Scalars['String']['output'];
  eventLog?: Maybe<TicketEventLogEventWrapper>;
  followers: UserTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** @deprecated Check if serviceProducts is empty instead */
  ignoreServiceFee: Scalars['Boolean']['output'];
  invoice?: Maybe<InvoiceDef>;
  invoiceItems: InvoiceItemDefConnection;
  isCustomLocation?: Maybe<Scalars['Boolean']['output']>;
  lastView?: Maybe<TicketViewEventDef>;
  latestNotificationCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  location?: Maybe<LocationType>;
  managecontainerticket?: Maybe<ManageContainerTicketDef>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  notificationsLog?: Maybe<CommonUserNotificationsWrapper>;
  pickupOrder?: Maybe<PickUpOrderType>;
  pickuporderSet: PickUpOrderTypeConnection;
  possibleContainerId?: Maybe<Scalars['String']['output']>;
  preferredDate?: Maybe<Scalars['Date']['output']>;
  priority: TicketTicketPriorityChoices;
  property?: Maybe<PropertyDef>;
  propertyTotalPriceAtBillingTime?: Maybe<Scalars['Int']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  reporter?: Maybe<UserType>;
  reporterDriver?: Maybe<DriverType>;
  requestedBy?: Maybe<PropertyUserDef>;
  requestedByOther?: Maybe<Scalars['String']['output']>;
  rfid: Scalars['String']['output'];
  route?: Maybe<RouteType>;
  scheduledexportrunSet: ScheduledExportRunDefConnection;
  serviceFeeQuantity: Scalars['Int']['output'];
  servicePrices?: Maybe<Array<Maybe<PriceDef>>>;
  serviceProducts: ProductDefConnection;
  status: TicketTicketStatusChoices;
  /** The user who changed the status */
  statusChangedBy?: Maybe<UserType>;
  /** The reason for the status change */
  statusChangedReason?: Maybe<TicketTicketStatusChangedReasonChoices>;
  /** The platform where the status was changed */
  statusChangedVia?: Maybe<TicketTicketStatusChangedViaChoices>;
  statusCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  statusInProgressAt?: Maybe<Scalars['DateTime']['output']>;
  statusOnHoldAt?: Maybe<Scalars['DateTime']['output']>;
  statusOpenAt?: Maybe<Scalars['DateTime']['output']>;
  statusPendingAt?: Maybe<Scalars['DateTime']['output']>;
  statusRejectedAt?: Maybe<Scalars['DateTime']['output']>;
  statusTransitions?: Maybe<Array<TicketStatusTransition>>;
  ticketType: TicketTypeDef;
  ticketcommentSet: TicketCommentDefConnection;
  ticketdraftSet: TicketDraftDefConnection;
  ticketeventSet: TicketEventDefConnection;
  ticketmessageSet: TicketMessageDefConnection;
  ticketvieweventSet: TicketViewEventDefConnection;
  /** in minutes */
  timeSpent?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userNotifications: UserNotificationTypeConnection;
  viaPortal: Scalars['Boolean']['output'];
  wasteVolumeEstimate?: Maybe<Scalars['Float']['output']>;
  wasteWeightEstimate?: Maybe<Scalars['Float']['output']>;
};


export type TicketDefAgreementStartedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefAssociatedInboxMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefAvailableRoutesArgs = {
  Type?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  applicableVehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  completedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  containers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  drivers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeRoutes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterByDay?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  plannedDrivers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  plannedVehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routeSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  scheduledDay?: InputMaybe<Scalars['Date']['input']>;
  scheduledDay_Gte?: InputMaybe<Scalars['Date']['input']>;
  scheduledDay_Lte?: InputMaybe<Scalars['Date']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  startedAt_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFractions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type TicketDefCreatedAtStopArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefEventLogArgs = {
  addTicketAttachmentEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addTicketCommentAttachmentEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addTicketComments?: InputMaybe<Scalars['Boolean']['input']>;
  addTicketEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addTicketMessageAttachmentEvents?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  isTicketMessageInbound?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ticketAttachmentEventTypes?: InputMaybe<Array<InputMaybe<GenericAddRemoveEventTypesGraphene>>>;
  ticketCommentAttachmentEventTypes?: InputMaybe<Array<InputMaybe<GenericAddRemoveEventTypesGraphene>>>;
  ticketEventTypes?: InputMaybe<Scalars['TicketEventTypeValuesList']['input']>;
  ticketMessageAttachmentEventTypes?: InputMaybe<Array<InputMaybe<GenericAddRemoveEventTypesGraphene>>>;
  ticketMessageVia?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketTypes?: InputMaybe<Scalars['FromGlobalIDValuesList']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type TicketDefFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefInvoiceItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefNotificationsLogArgs = {
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  directOnly?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  followingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
  unseen?: InputMaybe<Scalars['Boolean']['input']>;
};


export type TicketDefPickuporderSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefScheduledexportrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefServiceProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefTicketcommentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefTicketdraftSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefTicketeventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefTicketmessageSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefTicketvieweventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketDefUserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TicketDefConnection = {
  __typename: 'TicketDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketDef` and its cursor. */
export type TicketDefEdge = {
  __typename: 'TicketDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketDef>;
};

export type TicketDefFilterInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  allowedForRoute?: InputMaybe<Scalars['ID']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignedTeam?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignee?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  company?: InputMaybe<Scalars['ID']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerBarcode?: InputMaybe<Scalars['String']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdOn?: InputMaybe<Scalars['String']['input']>;
  excludeStatuses?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeTicketTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lastViewedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  needToPairRfid?: InputMaybe<Scalars['Boolean']['input']>;
  needToScanBarcode?: InputMaybe<Scalars['Boolean']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  priority?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  property?: InputMaybe<Scalars['ID']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reportedByPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  reporter?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reporterByDriver?: InputMaybe<Scalars['Boolean']['input']>;
  reporterDriver?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedByPortal?: InputMaybe<Scalars['Boolean']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statusCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusInProgressAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusInProgressAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusOnHoldAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusOnHoldAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusOpenAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusOpenAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusPendingAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusPendingAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusRejectedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusRejectedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ticketType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeCategory?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeService?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeServiceServiceType?: InputMaybe<Scalars['String']['input']>;
  ticketType_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TicketDefaultReplyToDef = {
  __typename: 'TicketDefaultReplyToDef';
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<TicketDefaultReplyToTypeEnum>;
};

export enum TicketDefaultReplyToTypeEnum {
  SENDER_EMAIL = 'sender_email',
  SENDER_PROPERTY_CONTACT = 'sender_property_contact',
  SENDER_PROPERTY_USER = 'sender_property_user'
}

export type TicketDraftChangedSubscription = {
  __typename: 'TicketDraftChangedSubscription';
  ticketDraft?: Maybe<TicketDraftDef>;
};

export type TicketDraftDef = Node & {
  __typename: 'TicketDraftDef';
  assignee?: Maybe<UserType>;
  company: CompanyType;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  container?: Maybe<ContainerType>;
  createdAt: Scalars['DateTime']['output'];
  createdTicket?: Maybe<TicketDef>;
  emails: CompanyEmailAccountMessageDefConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  project?: Maybe<ProjectType>;
  property?: Maybe<PropertyDef>;
  senderEmail: Scalars['String']['output'];
  senderPropertyContact?: Maybe<PropertyContactDef>;
  senderPropertyUser?: Maybe<PropertyUserDef>;
  ticketType?: Maybe<TicketTypeDef>;
};


export type TicketDraftDefEmailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TicketDraftDefConnection = {
  __typename: 'TicketDraftDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketDraftDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketDraftDef` and its cursor. */
export type TicketDraftDefEdge = {
  __typename: 'TicketDraftDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketDraftDef>;
};

export type TicketEventDef = Node & {
  __typename: 'TicketEventDef';
  barcodeFileUrl: Scalars['String']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  createdByAction: Scalars['Boolean']['output'];
  eventType: EventTicketEventEventTypeChoices;
  fromAssignedTeam?: Maybe<TeamType>;
  fromAssignee?: Maybe<UserType>;
  fromAssociatedInboxMessages: CompanyEmailAccountMessageDefConnection;
  fromPriority?: Maybe<EventTicketEventFromPriorityChoices>;
  fromStatus?: Maybe<EventTicketEventFromStatusChoices>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  modifiedData?: Maybe<Scalars['JSONString']['output']>;
  ticket: TicketDef;
  toAssignedTeam?: Maybe<TeamType>;
  toAssignee?: Maybe<UserType>;
  toAssociatedInboxMessages: CompanyEmailAccountMessageDefConnection;
  toPriority?: Maybe<EventTicketEventToPriorityChoices>;
  toStatus?: Maybe<EventTicketEventToStatusChoices>;
};


export type TicketEventDefFromAssociatedInboxMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketEventDefToAssociatedInboxMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TicketEventDefConnection = {
  __typename: 'TicketEventDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketEventDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketEventDef` and its cursor. */
export type TicketEventDefEdge = {
  __typename: 'TicketEventDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketEventDef>;
};

export type TicketEventLogEvent = TicketAttachmentEventDef | TicketCommentAttachmentEventDef | TicketCommentDef | TicketEventDef | TicketMessageAttachmentEventDef | TicketMessageDef;

export type TicketEventLogEventWrapper = {
  __typename: 'TicketEventLogEventWrapper';
  edges?: Maybe<Array<Maybe<TicketEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum TicketManageContainerTicketStatusCopyChoices {
  /** Created */
  A_1 = 'A_1',
  /** Open */
  A_2 = 'A_2',
  /** Pending */
  A_3 = 'A_3',
  /** On hold */
  A_4 = 'A_4',
  /** In progress */
  A_5 = 'A_5',
  /** Completed */
  A_6 = 'A_6',
  /** Rejected */
  A_7 = 'A_7'
}

export type TicketMapPointDef = {
  __typename: 'TicketMapPointDef';
  fillLevel?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  reporterDriverId?: Maybe<Scalars['Int']['output']>;
  reporterId?: Maybe<Scalars['Int']['output']>;
  statusId?: Maybe<Scalars['String']['output']>;
  ticketTypeId?: Maybe<Scalars['String']['output']>;
  ticketTypeName?: Maybe<Scalars['String']['output']>;
};

export type TicketMessageAttachmentDef = Node & {
  __typename: 'TicketMessageAttachmentDef';
  deleted?: Maybe<Scalars['DateTime']['output']>;
  file: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  ticketMessage: TicketMessageDef;
};

export type TicketMessageAttachmentEventDef = Node & {
  __typename: 'TicketMessageAttachmentEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<GenericAddRemoveEventTypesGraphene>>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  ticketMessageAttachment?: Maybe<TicketMessageAttachmentDef>;
  user?: Maybe<UserType>;
};

export type TicketMessageDef = Node & {
  __typename: 'TicketMessageDef';
  attachments?: Maybe<Array<Maybe<TicketMessageAttachmentDef>>>;
  body: Scalars['String']['output'];
  company: CompanyType;
  companyEmailAccount?: Maybe<CompanyEmailAccountDef>;
  companyemailaccountmessage?: Maybe<CompanyEmailAccountMessageDef>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  createdByAction: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isInbound?: Maybe<Scalars['Boolean']['output']>;
  isOutbound?: Maybe<Scalars['Boolean']['output']>;
  messageStatus: TicketTicketMessageMessageStatusChoices;
  receiverEmail?: Maybe<Scalars['String']['output']>;
  receiverPropertyContact?: Maybe<PropertyContactDef>;
  receiverPropertyUser?: Maybe<PropertyUserDef>;
  sender?: Maybe<UserType>;
  senderEmail?: Maybe<Scalars['String']['output']>;
  senderPropertyContact?: Maybe<PropertyContactDef>;
  senderPropertyUser?: Maybe<PropertyUserDef>;
  subject?: Maybe<Scalars['String']['output']>;
  ticket: TicketDef;
  via?: Maybe<TicketTicketMessageViaChoices>;
};

export type TicketMessageDefConnection = {
  __typename: 'TicketMessageDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketMessageDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketMessageDef` and its cursor. */
export type TicketMessageDefEdge = {
  __typename: 'TicketMessageDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketMessageDef>;
};

export type TicketMessageTemplateDef = Node & {
  __typename: 'TicketMessageTemplateDef';
  company: CompanyType;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note: Scalars['String']['output'];
  substitutedContent?: Maybe<Scalars['String']['output']>;
};


export type TicketMessageTemplateDefSubstitutedContentArgs = {
  ticketId: Scalars['ID']['input'];
};

export type TicketMessageTemplateDefConnection = {
  __typename: 'TicketMessageTemplateDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketMessageTemplateDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketMessageTemplateDef` and its cursor. */
export type TicketMessageTemplateDefEdge = {
  __typename: 'TicketMessageTemplateDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketMessageTemplateDef>;
};

export type TicketPricing = {
  __typename: 'TicketPricing';
  addContainer: Array<TicketPricingContainerPriceDef>;
  changeContainerType: Array<TicketPricingContainerPriceDef>;
  changePickupSetting: Array<TicketPricingContainerPriceDef>;
  otherContainerLevel?: Maybe<TicketPricingInfoDef>;
  otherPropertyLevel?: Maybe<TicketPricingInfoDef>;
};


export type TicketPricingAddContainerArgs = {
  propertyIds: Array<Scalars['ID']['input']>;
  ticketId?: InputMaybe<Scalars['ID']['input']>;
};


export type TicketPricingChangeContainerTypeArgs = {
  containerIds: Array<Scalars['ID']['input']>;
  ticketId?: InputMaybe<Scalars['ID']['input']>;
};


export type TicketPricingChangePickupSettingArgs = {
  containerIds: Array<Scalars['ID']['input']>;
  ticketId?: InputMaybe<Scalars['ID']['input']>;
};


export type TicketPricingOtherContainerLevelArgs = {
  containerIds: Array<Scalars['ID']['input']>;
  ticketId?: InputMaybe<Scalars['ID']['input']>;
  ticketTypeId: Scalars['ID']['input'];
};


export type TicketPricingOtherPropertyLevelArgs = {
  propertyIds: Array<Scalars['ID']['input']>;
  ticketId?: InputMaybe<Scalars['ID']['input']>;
  ticketTypeId: Scalars['ID']['input'];
};

export type TicketPricingContainerPriceDef = {
  __typename: 'TicketPricingContainerPriceDef';
  containerPrice?: Maybe<ContainerPriceOptionDef>;
  /** @deprecated Use service_prices instead in order to support multiple choices */
  servicePrice?: Maybe<PriceDef>;
  servicePrices?: Maybe<Array<PriceDef>>;
};

export type TicketPricingInfoDef = {
  __typename: 'TicketPricingInfoDef';
  mixed: Scalars['Boolean']['output'];
  /** @deprecated Use service_prices instead in order to support multiple choices */
  servicePrice?: Maybe<PriceDef>;
  servicePrices?: Maybe<Array<PriceDef>>;
};

export type TicketRuleDef = Node & {
  __typename: 'TicketRuleDef';
  actions?: Maybe<Array<RuleActionUnionDef>>;
  createdAt: Scalars['DateTime']['output'];
  fromStatus?: Maybe<TicketRuleFromStatusChoices>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  lastTriggered?: Maybe<Scalars['DateTime']['output']>;
  ticketType: TicketTypeDef;
  toStatus: TicketRuleToStatusChoices;
};

export type TicketRuleDefConnection = {
  __typename: 'TicketRuleDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketRuleDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketRuleDef` and its cursor. */
export type TicketRuleDefEdge = {
  __typename: 'TicketRuleDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketRuleDef>;
};

/** An enumeration. */
export enum TicketRuleFromStatusChoices {
  /** Created */
  A_1 = 'A_1',
  /** Open */
  A_2 = 'A_2',
  /** Pending */
  A_3 = 'A_3',
  /** On hold */
  A_4 = 'A_4',
  /** In progress */
  A_5 = 'A_5',
  /** Completed */
  A_6 = 'A_6',
  /** Rejected */
  A_7 = 'A_7'
}

/** An enumeration. */
export enum TicketRuleToStatusChoices {
  /** Created */
  A_1 = 'A_1',
  /** Open */
  A_2 = 'A_2',
  /** Pending */
  A_3 = 'A_3',
  /** On hold */
  A_4 = 'A_4',
  /** In progress */
  A_5 = 'A_5',
  /** Completed */
  A_6 = 'A_6',
  /** Rejected */
  A_7 = 'A_7'
}

/** An enumeration. */
export enum TicketSendConfirmationMessageToPropertyUserActionViaChoices {
  /** Dkdp */
  DKDP = 'DKDP',
  /** Email */
  EMAIL = 'EMAIL',
  /** Nemsms */
  NEMSMS = 'NEMSMS',
  /** Sms */
  SMS = 'SMS'
}

/** An enumeration. */
export enum TicketSendMessageToPropertyUserActionViaChoices {
  /** Dkdp */
  DKDP = 'DKDP',
  /** Email */
  EMAIL = 'EMAIL',
  /** Nemsms */
  NEMSMS = 'NEMSMS',
  /** Sms */
  SMS = 'SMS'
}

export type TicketStatusDef = {
  __typename: 'TicketStatusDef';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TicketStatusTransition = {
  __typename: 'TicketStatusTransition';
  actions?: Maybe<Array<RuleActionUnionDef>>;
  status: Scalars['String']['output'];
};

/** An enumeration. */
export enum TicketTicketCustomFieldInputTypeChoices {
  /** Multi Select */
  MULTI_SELECT = 'MULTI_SELECT',
  /** Number */
  NUMBER = 'NUMBER',
  /** Select */
  SELECT = 'SELECT',
  /** Signature */
  SIGNATURE = 'SIGNATURE',
  /** Text Area */
  TEXT_AREA = 'TEXT_AREA'
}

/** An enumeration. */
export enum TicketTicketMessageMessageStatusChoices {
  /** Failed */
  FAILED = 'FAILED',
  /** In progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Success */
  SUCCESS = 'SUCCESS'
}

/** An enumeration. */
export enum TicketTicketMessageViaChoices {
  /** Dkdp */
  DKDP = 'DKDP',
  /** Email */
  EMAIL = 'EMAIL',
  /** Nemsms */
  NEMSMS = 'NEMSMS',
  /** Sms */
  SMS = 'SMS'
}

/** An enumeration. */
export enum TicketTicketPriorityChoices {
  /** None */
  A_0 = 'A_0',
  /** Log */
  A_1 = 'A_1',
  /** Medium */
  A_2 = 'A_2',
  /** High */
  A_3 = 'A_3',
  /** Critical */
  A_4 = 'A_4'
}

/** An enumeration. */
export enum TicketTicketStatusChangedReasonChoices {
  /** Manual */
  MANUAL = 'MANUAL',
  /** Route reset */
  ROUTE_RESET = 'ROUTE_RESET',
  /** Stop reset */
  STOP_RESET = 'STOP_RESET',
  /** Unspecified */
  UNSPECIFIED = 'UNSPECIFIED'
}

/** An enumeration. */
export enum TicketTicketStatusChangedViaChoices {
  /** Nav App */
  NAV_APP = 'NAV_APP',
  /** Platform */
  PLATFORM = 'PLATFORM',
  /** Portal */
  PORTAL = 'PORTAL'
}

/** An enumeration. */
export enum TicketTicketStatusChoices {
  /** Created */
  A_1 = 'A_1',
  /** Open */
  A_2 = 'A_2',
  /** Pending */
  A_3 = 'A_3',
  /** On hold */
  A_4 = 'A_4',
  /** In progress */
  A_5 = 'A_5',
  /** Completed */
  A_6 = 'A_6',
  /** Rejected */
  A_7 = 'A_7'
}

export type TicketTypeActionInput = {
  assignToTeamAction?: InputMaybe<AssignToTeamActionMutationInput>;
  assignToUserAction?: InputMaybe<AssignToUserActionMutationInput>;
  changePriorityAction?: InputMaybe<ChangePriorityActionMutationInput>;
  changeStatusAction?: InputMaybe<ChangeStatusActionMutationInput>;
  containerAction?: InputMaybe<ContainerActionMutationInput>;
  createPropertyAction?: InputMaybe<CreatePropertyActionMutationInput>;
  createTicketAction?: InputMaybe<CreateTicketActionMutationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  scheduleOnRouteAction?: InputMaybe<ScheduleOnRouteActionMutationInput>;
  sendConfirmationMessageToPropertyUserAction?: InputMaybe<SendConfirmationMessageToPropertyUserActionMutationInput>;
  sendEmailAction?: InputMaybe<SendEmailActionMutationInput>;
  sendMessageToPropertyUserAction?: InputMaybe<SendMessageToPropertyUserActionMutationInput>;
};

export type TicketTypeAvailableDateDef = {
  __typename: 'TicketTypeAvailableDateDef';
  container?: Maybe<ContainerType>;
  dates?: Maybe<Array<Maybe<Scalars['Date']['output']>>>;
};

export type TicketTypeCountDef = {
  __typename: 'TicketTypeCountDef';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TicketTypeCustomFieldsConnection = Node & {
  __typename: 'TicketTypeCustomFieldsConnection';
  connectedCustomValues: CustomTicketValuesDefConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  ticketCustomField?: Maybe<TicketCustomFieldDef>;
  ticketType: TicketTypeDef;
};


export type TicketTypeCustomFieldsConnectionConnectedCustomValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TicketTypeCustomFieldsConnectionConnection = {
  __typename: 'TicketTypeCustomFieldsConnectionConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketTypeCustomFieldsConnectionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TicketTypeCustomFieldsConnection` and its cursor. */
export type TicketTypeCustomFieldsConnectionEdge = {
  __typename: 'TicketTypeCustomFieldsConnectionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketTypeCustomFieldsConnection>;
};

export type TicketTypeDef = Node & {
  __typename: 'TicketTypeDef';
  assigneeSetAutomatically: Scalars['Boolean']['output'];
  availableDates?: Maybe<Array<Maybe<TicketTypeAvailableDateDef>>>;
  category: TicketCategoryDef;
  company: CompanyType;
  companyemailaccountruleSet: CompanyEmailAccountRuleDefConnection;
  containerDestination?: Maybe<ContainerStorageLocationDef>;
  containerSource?: Maybe<ContainerStorageLocationDef>;
  createdAt: Scalars['DateTime']['output'];
  createticketactionSet: CreateTicketActionDefConnection;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  enableOnNavigationApp: Scalars['Boolean']['output'];
  enableOnPlatform: Scalars['Boolean']['output'];
  enableOnPortal: Scalars['Boolean']['output'];
  eventLog?: Maybe<TicketTypeEventsWrapper>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isContainerLevel: Scalars['Boolean']['output'];
  isPropertyLevel: Scalars['Boolean']['output'];
  manualCitizenPortalOrder?: Maybe<Scalars['Int']['output']>;
  manualNavigationAppOrder?: Maybe<Scalars['Int']['output']>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  navigationAppRequireAttachment: Scalars['Boolean']['output'];
  order: Scalars['Int']['output'];
  portalConfirmSubtitle: Scalars['String']['output'];
  portalConfirmTitle: Scalars['String']['output'];
  portalContactInformationEmailRequired: Scalars['Boolean']['output'];
  portalContactInformationNameRequired: Scalars['Boolean']['output'];
  portalContactInformationNextButtonLabel: Scalars['String']['output'];
  portalContactInformationPhoneNumberRequired: Scalars['Boolean']['output'];
  portalDateHelpText: Scalars['String']['output'];
  portalDateHidden: Scalars['Boolean']['output'];
  portalDateLabel: Scalars['String']['output'];
  portalDateMaxDaysInFutureForBulkWaste: Scalars['Int']['output'];
  portalDateMinDaysInFutureForBulkWaste: Scalars['Int']['output'];
  portalDateRequired: Scalars['Boolean']['output'];
  portalDescription: Scalars['String']['output'];
  portalDropdownHelpText: Scalars['String']['output'];
  portalDropdownHidden: Scalars['Boolean']['output'];
  portalDropdownLabel: Scalars['String']['output'];
  portalDropdownOptions: Array<Scalars['String']['output']>;
  portalDropdownRequired: Scalars['Boolean']['output'];
  portalNoteHelpText: Scalars['String']['output'];
  portalNoteHidden: Scalars['Boolean']['output'];
  portalNoteLabel: Scalars['String']['output'];
  portalNoteRequired: Scalars['Boolean']['output'];
  portalPriceShowZero: Scalars['Boolean']['output'];
  portalRequireContactInformation: Scalars['Boolean']['output'];
  portalServiceTypeBulkWasteLimit: Scalars['Int']['output'];
  portalServiceTypeBulkWasteLimitHelpText: Scalars['String']['output'];
  portalServiceTypeBulkWasteLimitLabel: Scalars['String']['output'];
  rules: TicketRuleDefConnection;
  scheduledexportSet: ScheduledExportDefConnection;
  scheduledexportrunSet: ScheduledExportRunDefConnection;
  service?: Maybe<ServiceDef>;
  statusTransitions?: Maybe<Array<TicketTypeStatusTransition>>;
  ticketCustomFieldConnections?: Maybe<Array<Maybe<TicketTypeCustomFieldsConnection>>>;
  ticketCustomFields: TicketCustomFieldDefConnection;
  ticketSet?: Maybe<TicketDefConnection>;
  ticketdraftSet: TicketDraftDefConnection;
  tickettypeconnectioncustomfieldSet: TicketTypeCustomFieldsConnectionConnection;
  vehicletrailertickettypeholdSet: VehicleTrailerTicketTypeHoldTypeConnection;
};


export type TicketTypeDefAvailableDatesArgs = {
  containerIds: Array<InputMaybe<Scalars['ID']['input']>>;
  propertyId: Scalars['ID']['input'];
};


export type TicketTypeDefCompanyemailaccountruleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketTypeDefCreateticketactionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketTypeDefEventLogArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ticketTypeEventTypes?: InputMaybe<Array<InputMaybe<TicketTypeEventTypesShortGraphene>>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type TicketTypeDefRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketTypeDefScheduledexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketTypeDefScheduledexportrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketTypeDefTicketCustomFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketTypeDefTicketSetArgs = {
  activeProjects?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  allowedForRoute?: InputMaybe<Scalars['ID']['input']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignedTeam?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  assignee?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerBarcode?: InputMaybe<Scalars['String']['input']>;
  containerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containerType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdOn?: InputMaybe<Scalars['String']['input']>;
  excludeStatuses?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeTicketTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastViewedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  needToPairRfid?: InputMaybe<Scalars['Boolean']['input']>;
  needToScanBarcode?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  priority?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  property?: InputMaybe<Scalars['ID']['input']>;
  propertyGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reportedByPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  reporter?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reporterByDriver?: InputMaybe<Scalars['Boolean']['input']>;
  reporterDriver?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedByPortal?: InputMaybe<Scalars['Boolean']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statusCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusInProgressAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusInProgressAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusOnHoldAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusOnHoldAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusOpenAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusOpenAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusPendingAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusPendingAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  statusRejectedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  statusRejectedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ticketType?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeCategory?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeService?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ticketTypeServiceServiceType?: InputMaybe<Scalars['String']['input']>;
  ticketType_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
  wasteFraction?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type TicketTypeDefTicketdraftSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketTypeDefTickettypeconnectioncustomfieldSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TicketTypeDefVehicletrailertickettypeholdSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TicketTypeDefConnection = {
  __typename: 'TicketTypeDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketTypeDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketTypeDef` and its cursor. */
export type TicketTypeDefEdge = {
  __typename: 'TicketTypeDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketTypeDef>;
};

export type TicketTypeEventDef = Node & {
  __typename: 'TicketTypeEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<TicketTypeEventTypesShortGraphene>>>;
  fromCategory?: Maybe<TicketCategoryDef>;
  fromEnableOnNavigationApp?: Maybe<Scalars['Boolean']['output']>;
  fromEnableOnPlatform?: Maybe<Scalars['Boolean']['output']>;
  fromEnableOnPortal?: Maybe<Scalars['Boolean']['output']>;
  fromIsContainerLevel?: Maybe<Scalars['Boolean']['output']>;
  fromIsPropertyLevel?: Maybe<Scalars['Boolean']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  fromNavigationAppRequireAttachment?: Maybe<Scalars['Boolean']['output']>;
  fromOrder?: Maybe<Scalars['Int']['output']>;
  fromService?: Maybe<ServiceDef>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  ticketType?: Maybe<TicketTypeDef>;
  toCategory?: Maybe<TicketCategoryDef>;
  toEnableOnNavigationApp?: Maybe<Scalars['Boolean']['output']>;
  toEnableOnPlatform?: Maybe<Scalars['Boolean']['output']>;
  toEnableOnPortal?: Maybe<Scalars['Boolean']['output']>;
  toIsContainerLevel?: Maybe<Scalars['Boolean']['output']>;
  toIsPropertyLevel?: Maybe<Scalars['Boolean']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  toNavigationAppRequireAttachment?: Maybe<Scalars['Boolean']['output']>;
  toOrder?: Maybe<Scalars['Int']['output']>;
  toService?: Maybe<ServiceDef>;
  user?: Maybe<UserType>;
};

export enum TicketTypeEventTypesShortGraphene {
  CATEGORY_CHANGE = 'CATEGORY_CHANGE',
  ENABLE_ON_CHANGE = 'ENABLE_ON_CHANGE',
  IS_LEVEL_CHANGE = 'IS_LEVEL_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE',
  NAVIGATION_APP_REQUIRE_ATTACHMENT_CHANGE = 'NAVIGATION_APP_REQUIRE_ATTACHMENT_CHANGE',
  ORDER_CHANGE = 'ORDER_CHANGE',
  SERVICE_CHANGE = 'SERVICE_CHANGE'
}

export type TicketTypeEventsWrapper = {
  __typename: 'TicketTypeEventsWrapper';
  edges?: Maybe<Array<Maybe<TicketTypeEventDef>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum TicketTypeOrderCategoriseBy {
  NONE = 'NONE',
  ROUTE_SCHEME = 'ROUTE_SCHEME'
}

export type TicketTypeOrderingObject = {
  levels?: InputMaybe<Array<InputMaybe<LevelChoices>>>;
  orderType?: InputMaybe<OrderTypeChoices>;
  platform?: InputMaybe<PlatformChoices>;
  routeScheme?: InputMaybe<Scalars['ID']['input']>;
};

export type TicketTypeStatusTransition = {
  __typename: 'TicketTypeStatusTransition';
  actions?: Maybe<Array<RuleActionUnionDef>>;
  fromStatus?: Maybe<Scalars['String']['output']>;
  restrictionRoles?: Maybe<Array<CompanyRoleDef>>;
  toStatus: Scalars['String']['output'];
};

export type TicketTypeStatusTransitionInput = {
  actions?: InputMaybe<Array<TicketTypeActionInput>>;
  fromStatus?: InputMaybe<Scalars['String']['input']>;
  restrictionRoles?: InputMaybe<Array<Scalars['ID']['input']>>;
  toStatus: Scalars['String']['input'];
};

export type TicketViewEventDef = Node & {
  __typename: 'TicketViewEventDef';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  createdByAction: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  ticket: TicketDef;
  viewedBy?: Maybe<UserType>;
};

export type TicketViewEventDefConnection = {
  __typename: 'TicketViewEventDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketViewEventDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketViewEventDef` and its cursor. */
export type TicketViewEventDefEdge = {
  __typename: 'TicketViewEventDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketViewEventDef>;
};

export type ToBringContainerDef = {
  __typename: 'ToBringContainerDef';
  container?: Maybe<ContainerType>;
  containerType: ContainerTypeType;
  ticket: ManageContainerTicketDef;
  wasteFraction: WasteFractionType;
};

export type TriggerExport = {
  __typename: 'TriggerExport';
  exportHistory?: Maybe<ExportHistoryDef>;
};

export type TriggerImport = {
  __typename: 'TriggerImport';
  importHistory?: Maybe<ImportHistoryDef>;
};

export type UnconfirmedUserType = Node & {
  __typename: 'UnconfirmedUserType';
  createdAt: Scalars['DateTime']['output'];
  createdUser?: Maybe<UserType>;
  email: Scalars['String']['output'];
  externalCompanyGeoLat?: Maybe<Scalars['Float']['output']>;
  externalCompanyGeoLng?: Maybe<Scalars['Float']['output']>;
  externalCompanyLocation?: Maybe<Scalars['String']['output']>;
  externalCompanyLogo?: Maybe<Scalars['String']['output']>;
  externalCompanyMetricEmployees?: Maybe<Scalars['String']['output']>;
  externalCompanyMetricEstimatedAnnualRevenue?: Maybe<Scalars['String']['output']>;
  externalCrmDealId?: Maybe<Scalars['String']['output']>;
  externalCrmId?: Maybe<Scalars['String']['output']>;
  externalPersonAvatar?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  inviteUsers?: Maybe<Array<Scalars['String']['output']>>;
  ip?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  linkClickedAt?: Maybe<Scalars['DateTime']['output']>;
  metaCompanyName?: Maybe<Scalars['String']['output']>;
  metaCountry?: Maybe<Scalars['String']['output']>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaFullName?: Maybe<Scalars['String']['output']>;
  metaHowDidYouHearAboutUs: Array<Scalars['String']['output']>;
  metaInterestedHardware: Array<Scalars['String']['output']>;
  metaInterestedSoftware: Array<Scalars['String']['output']>;
  metaJobTitle?: Maybe<Scalars['String']['output']>;
  metaNumberOfEmployees?: Maybe<Scalars['String']['output']>;
  metaPhoneNumber?: Maybe<Scalars['String']['output']>;
  metaSegment?: Maybe<Scalars['String']['output']>;
};

export type UnconfirmedUserTypeConnection = {
  __typename: 'UnconfirmedUserTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UnconfirmedUserTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `UnconfirmedUserType` and its cursor. */
export type UnconfirmedUserTypeEdge = {
  __typename: 'UnconfirmedUserTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UnconfirmedUserType>;
};

export type UnpairFillLevelDevice = {
  __typename: 'UnpairFillLevelDevice';
  fillLevelDevice?: Maybe<BaseDeviceInterface>;
};

export type UpdateActiveProjects = {
  __typename: 'UpdateActiveProjects';
  status?: Maybe<Scalars['String']['output']>;
};

export type UpdateAgreementInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  excludeFromBilling?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type UpdateAgreementPayload = {
  __typename: 'UpdateAgreementPayload';
  agreement?: Maybe<AgreementDef>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateBillingInformation = {
  __typename: 'UpdateBillingInformation';
  billingInformation?: Maybe<BillingInformationType>;
};

export type UpdateBulkWastePickupTicketInput = {
  addAttachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<TicketCustomFieldValueInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** Deprecated: Send empty serviceProducts instead */
  ignoreServiceFee?: InputMaybe<Scalars['Boolean']['input']>;
  items: Array<InputMaybe<BulkWastePickupTicketItemInput>>;
  location?: InputMaybe<Scalars['ID']['input']>;
  preferredDate?: InputMaybe<Scalars['Date']['input']>;
  priority: Scalars['String']['input'];
  removeAttachmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  serviceProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateBulkWastePickupTicketPayload = {
  __typename: 'UpdateBulkWastePickupTicketPayload';
  bulkWastePickupTicket?: Maybe<TicketDef>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateComparisonSettingsMutationInput = {
  amountOfCollection?: InputMaybe<Scalars['Int']['input']>;
  amountOfCollectionPeriod?: InputMaybe<Scalars['String']['input']>;
  averageFillLevel?: InputMaybe<Scalars['Float']['input']>;
  chanceOfOverflowing?: InputMaybe<Scalars['Float']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Cost per collection */
  collectionCost: Scalars['Float']['input'];
  /** Cost per tonne */
  costPerTonne?: InputMaybe<Scalars['Float']['input']>;
  costSettings: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Income per collection */
  incomePerTonne?: InputMaybe<Scalars['Float']['input']>;
  /** Cost per overflowing collection */
  overflowingCost?: InputMaybe<Scalars['Float']['input']>;
  /** kg for m^3 */
  weightFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateComparisonSettingsMutationPayload = {
  __typename: 'UpdateComparisonSettingsMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comparisonSettings?: Maybe<ComparisonSettingsType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateContainer = {
  __typename: 'UpdateContainer';
  container?: Maybe<ContainerType>;
};

export type UpdateContainerMobile = {
  __typename: 'UpdateContainerMobile';
  container?: Maybe<ContainerType>;
};

export type UpdateContainerPriceInput = {
  amount: Scalars['Int']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** If true, then soft-delete the price, ignoring the rest of the input */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  invoiceName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateContainerPricePayload = {
  __typename: 'UpdateContainerPricePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  containerPrice?: Maybe<ContainerPriceDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateContainerType = {
  __typename: 'UpdateContainerType';
  containerType?: Maybe<ContainerTypeType>;
};

export type UpdateCostSettingsMutationInput = {
  amountOfCollection?: InputMaybe<Scalars['Int']['input']>;
  amountOfCollectionPeriod?: InputMaybe<Scalars['String']['input']>;
  averageFillLevel?: InputMaybe<Scalars['Float']['input']>;
  chanceOfOverflowing?: InputMaybe<Scalars['Float']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Cost per collection */
  collectionCost: Scalars['Float']['input'];
  containerType: Scalars['ID']['input'];
  /** Cost per tonne */
  costPerTonne?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Income per collection */
  incomePerTonne?: InputMaybe<Scalars['Float']['input']>;
  /** Cost per overflowing collection */
  overflowingCost?: InputMaybe<Scalars['Float']['input']>;
  ownValues?: InputMaybe<Scalars['Boolean']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
  wasteFraction: Scalars['ID']['input'];
  /** kg for m^3 */
  weightFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCostSettingsMutationPayload = {
  __typename: 'UpdateCostSettingsMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  costSettings?: Maybe<CostSettingsType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateCurrentDriverLocation = {
  __typename: 'UpdateCurrentDriverLocation';
  driver?: Maybe<DriverType>;
  route?: Maybe<RouteType>;
};

export type UpdateManageContainerTicketInput = {
  addAttachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  collectionCalendar?: InputMaybe<Scalars['ID']['input']>;
  containerDestination?: InputMaybe<ContainerStorageLocationInput>;
  containerProduct?: InputMaybe<Scalars['ID']['input']>;
  containerSource?: InputMaybe<ContainerStorageLocationInput>;
  containerToAdd?: InputMaybe<Scalars['ID']['input']>;
  containerType?: InputMaybe<Scalars['ID']['input']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<TicketCustomFieldValueInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** Deprecated: Send empty serviceProducts instead */
  ignoreServiceFee?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['ID']['input']>;
  newContainerId?: InputMaybe<Scalars['String']['input']>;
  pickupSetting?: InputMaybe<Scalars['ID']['input']>;
  preferredDate?: InputMaybe<Scalars['Date']['input']>;
  priority: Scalars['String']['input'];
  removeAttachmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  routeScheme?: InputMaybe<Scalars['ID']['input']>;
  serviceProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wasteFraction?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateManageContainerTicketPayload = {
  __typename: 'UpdateManageContainerTicketPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  manageContainerTicket?: Maybe<TicketDef>;
};

export type UpdateMeasurementSettings = {
  __typename: 'UpdateMeasurementSettings';
  measurementSettings?: Maybe<MeasurementSettingsType>;
};

export type UpdateNotificationSetting = {
  __typename: 'UpdateNotificationSetting';
  notificationSetting?: Maybe<NotificationSettingsType>;
};

export type UpdateOrCreateContainerIdGeneratorInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyDisplayOption: Scalars['String']['input'];
  componentsOrder?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  containerTypeDisplayOption: Scalars['String']['input'];
  incrementFrom: Scalars['Int']['input'];
  lastNumber: Scalars['Int']['input'];
  pickupSettingsDisplayOption: Scalars['String']['input'];
  prefix: Scalars['String']['input'];
  separator: Scalars['String']['input'];
  wasteFractionDisplayOption: Scalars['String']['input'];
};

export type UpdateOrCreateContainerIdGeneratorPayload = {
  __typename: 'UpdateORCreateContainerIDGeneratorPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  containerIDGeneratorSettings?: Maybe<ContainerIdGeneratorSettingsDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateAgreementTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  products: Array<InputMaybe<AgreementTemplateProductMultipleChoiceFieldInput>>;
  propertyType: Scalars['ID']['input'];
};

export type UpdateOrCreateAgreementTemplatePayload = {
  __typename: 'UpdateOrCreateAgreementTemplatePayload';
  agreementTemplate?: Maybe<AgreementTemplateDef>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateAlertRuleInput = {
  alertRecipients?: InputMaybe<Array<InputMaybe<AlertRuleAlertRecipientMultipleChoiceFieldInput>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  containerTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  intervalType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  percentageThreshold: Scalars['Float']['input'];
  wasteFractions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateOrCreateAlertRulePayload = {
  __typename: 'UpdateOrCreateAlertRulePayload';
  alertRule?: Maybe<AlertRuleDef>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateAreaInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateOrCreateAreaPayload = {
  __typename: 'UpdateOrCreateAreaPayload';
  area?: Maybe<AreaDef>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateBulkContainerData = {
  collectionCalendarId?: InputMaybe<Scalars['ID']['input']>;
  containerId?: InputMaybe<Scalars['String']['input']>;
  containerTypeId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  deviceToContainerSet?: InputMaybe<Array<InputMaybe<DeviceToContainerInputObject>>>;
  location?: InputMaybe<LocationInput>;
  locationCurb?: InputMaybe<Scalars['Boolean']['input']>;
  metaData?: InputMaybe<Scalars['String']['input']>;
  overflowingSettings?: InputMaybe<OverflowingSettingsObject>;
  photo?: InputMaybe<Scalars['Upload']['input']>;
  pickupMethod?: InputMaybe<Scalars['String']['input']>;
  pickupSettingId?: InputMaybe<Scalars['ID']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  prioritizeInStartOfRoute?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ID']['input'];
  routeDescription?: InputMaybe<Scalars['String']['input']>;
  routeSchemeId?: InputMaybe<Scalars['ID']['input']>;
  sensorFillLevel?: InputMaybe<Scalars['Int']['input']>;
  showOnRoute?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  wasteCollectorId?: InputMaybe<Scalars['ID']['input']>;
  wasteFractionId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrCreateBulkContainerInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrCreateBulkContainerMutation = {
  __typename: 'UpdateOrCreateBulkContainerMutation';
  containerList?: Maybe<Array<Maybe<ContainerType>>>;
};

export type UpdateOrCreateCollectionCalendarDateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  collectionCalendar: Scalars['ID']['input'];
  collectionDate: Scalars['Date']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrCreateCollectionCalendarDatePayload = {
  __typename: 'UpdateOrCreateCollectionCalendarDatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  collectionCalendarDate?: Maybe<CollectionCalendarDateDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCollectionCalendarDeviationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  collectionCalendars: Array<InputMaybe<Scalars['ID']['input']>>;
  endDate: Scalars['Date']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  periods: Array<InputMaybe<CollectionCalendarDeviationToPeriodInput>>;
  startDate: Scalars['Date']['input'];
};

export type UpdateOrCreateCollectionCalendarDeviationPayload = {
  __typename: 'UpdateOrCreateCollectionCalendarDeviationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  collectionCalendarDeviation?: Maybe<CollectionCalendarDeviationDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCollectionCalendarInput = {
  allowUsageInCombinedCalendarsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  combinedCollectionCalendars?: InputMaybe<Array<InputMaybe<CollectionCalendarPeriodInput>>>;
  determinePeriodBy?: InputMaybe<Scalars['String']['input']>;
  fromDate: Scalars['Date']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  pickupSettings: Scalars['ID']['input'];
  week: Scalars['ID']['input'];
  weekdays?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateOrCreateCollectionCalendarPayload = {
  __typename: 'UpdateOrCreateCollectionCalendarPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  collectionCalendar?: Maybe<CollectionCalendarDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCollectionCalendarWeekInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  startWeekNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOrCreateCollectionCalendarWeekPayload = {
  __typename: 'UpdateOrCreateCollectionCalendarWeekPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  collectionCalendarWeek?: Maybe<CollectionCalendarWeekDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

/** target_id: ID of Route, Depot, Driver, Vehicle or Container */
export type UpdateOrCreateComment = {
  __typename: 'UpdateOrCreateComment';
  comment?: Maybe<CommentType>;
};

export type UpdateOrCreateCompanyContainerMetaType = {
  __typename: 'UpdateOrCreateCompanyContainerMetaType';
  companyContainerMetaType?: Maybe<CompanyContainerMetaTypeType>;
};

export type UpdateOrCreateCompanyEmailAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrCreateCompanyEmailAccountPayload = {
  __typename: 'UpdateOrCreateCompanyEmailAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyEmailAccount?: Maybe<CompanyEmailAccountDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCompanyEmailAccountRuleInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyEmailAccount: Scalars['ID']['input'];
  defaultAssignee?: InputMaybe<Scalars['ID']['input']>;
  defaultProject?: InputMaybe<Scalars['ID']['input']>;
  defaultTicketType?: InputMaybe<Scalars['ID']['input']>;
  emailFilter: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrCreateCompanyEmailAccountRulePayload = {
  __typename: 'UpdateOrCreateCompanyEmailAccountRulePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyEmailAccountRule?: Maybe<CompanyEmailAccountRuleDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Hex color code */
  color?: InputMaybe<Scalars['String']['input']>;
  dashboardLocationLatitude?: InputMaybe<Scalars['Float']['input']>;
  dashboardLocationLongitude?: InputMaybe<Scalars['Float']['input']>;
  dashboardLocationName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
  removeLogo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOrCreateCompanyMainSettingsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 12-hour clock? */
  clockFormat12?: InputMaybe<Scalars['Boolean']['input']>;
  cluster?: InputMaybe<Scalars['Boolean']['input']>;
  country: Scalars['String']['input'];
  crmEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  currency: Scalars['String']['input'];
  dashboardLocation?: InputMaybe<Scalars['ID']['input']>;
  dashboardLocationLatitude?: InputMaybe<Scalars['Float']['input']>;
  dashboardLocationLongitude?: InputMaybe<Scalars['Float']['input']>;
  dashboardLocationName?: InputMaybe<Scalars['String']['input']>;
  drivingDistance: Scalars['String']['input'];
  temperature: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  volume: Scalars['String']['input'];
  weight: Scalars['String']['input'];
};

export type UpdateOrCreateCompanyMainSettingsPayload = {
  __typename: 'UpdateOrCreateCompanyMainSettingsPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  settings?: Maybe<SettingsType>;
};

export type UpdateOrCreateCompanyMetaTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copyFrom?: InputMaybe<Scalars['String']['input']>;
  displayOn: Array<MetaTypeDisplayOnType>;
  entity: Scalars['String']['input'];
  helpText?: InputMaybe<Scalars['String']['input']>;
  icon: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  inputType: Scalars['String']['input'];
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  rolesCanEdit?: InputMaybe<Array<Scalars['ID']['input']>>;
  rolesCanView?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateOrCreateCompanyMetaTypePayload = {
  __typename: 'UpdateOrCreateCompanyMetaTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyMetaType?: Maybe<CompanyMetaTypeDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCompanyPayload = {
  __typename: 'UpdateOrCreateCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCompanyRole = {
  __typename: 'UpdateOrCreateCompanyRole';
  companyRole?: Maybe<CompanyRoleDef>;
};

export type UpdateOrCreateContactInput = {
  addContactToProperties?: InputMaybe<Array<InputMaybe<AddContactToPropertyInput>>>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  coName?: InputMaybe<Scalars['String']['input']>;
  contactType: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  ean?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  otherPhoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  postalCodeName?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  taxSubId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrCreateContactPayload = {
  __typename: 'UpdateOrCreateContactPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<ContactDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

/** Use UpdateOrCreateContainerNew instead of UpdateOrCreateContainer */
export type UpdateOrCreateContainer = {
  __typename: 'UpdateOrCreateContainer';
  container?: Maybe<ContainerType>;
};

export type UpdateOrCreateContainerGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  containers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<LocationInput>;
  locationCurb?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  project: Scalars['ID']['input'];
};

export type UpdateOrCreateContainerGroupPayload = {
  __typename: 'UpdateOrCreateContainerGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  containerGroup?: Maybe<ContainerGroupDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateContainerNewInput = {
  allowedHoursEnd?: InputMaybe<Scalars['Time']['input']>;
  allowedHoursStart?: InputMaybe<Scalars['Time']['input']>;
  /** Custom barcode or container id */
  barcode?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  collectionCalendar?: InputMaybe<Scalars['ID']['input']>;
  containerId: Scalars['String']['input'];
  containerType: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  devicesToContainer?: InputMaybe<Array<InputMaybe<SensorInputObject>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<LocationInput>;
  locationCurb?: InputMaybe<Scalars['Boolean']['input']>;
  metaData?: InputMaybe<Scalars['String']['input']>;
  pickupMethod: Scalars['String']['input'];
  pickupSetting?: InputMaybe<Scalars['ID']['input']>;
  prioritizeInStartOfRoute?: InputMaybe<Scalars['Boolean']['input']>;
  project: Scalars['ID']['input'];
  rfid?: InputMaybe<Scalars['String']['input']>;
  routeDescription?: InputMaybe<Scalars['String']['input']>;
  routeScheme?: InputMaybe<Scalars['ID']['input']>;
  sensorFillLevel?: InputMaybe<Scalars['Int']['input']>;
  sensorFillLevelPeriodHours?: InputMaybe<Scalars['Int']['input']>;
  status: Scalars['String']['input'];
  vehicle?: InputMaybe<Scalars['ID']['input']>;
  wasteFraction: Scalars['ID']['input'];
};

export type UpdateOrCreateContainerNewPayload = {
  __typename: 'UpdateOrCreateContainerNewPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  container?: Maybe<ContainerType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateContainerNotificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  container: Scalars['ID']['input'];
  daysBefore: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notificationType: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  property: Scalars['ID']['input'];
};

export type UpdateOrCreateContainerNotificationPayload = {
  __typename: 'UpdateOrCreateContainerNotificationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  containerNotification?: Maybe<ContainerNotificationDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateContainerProductInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  containerType: Scalars['ID']['input'];
  enableOnPortal?: InputMaybe<Scalars['Boolean']['input']>;
  externalInvoiceCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeOnInvoicesIfFree?: InputMaybe<Scalars['Boolean']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  nameOnInvoice: Scalars['String']['input'];
  pickupSetting: Scalars['ID']['input'];
  propertyType: Scalars['ID']['input'];
  wasteFraction: Scalars['ID']['input'];
};

export type UpdateOrCreateContainerProductPayload = {
  __typename: 'UpdateOrCreateContainerProductPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  product?: Maybe<ProductDef>;
};

export type UpdateOrCreateContainerTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** m */
  diameter?: InputMaybe<Scalars['Float']['input']>;
  emplacement: Scalars['String']['input'];
  emptyingTime: Scalars['Int']['input'];
  /** m */
  height: Scalars['Float']['input'];
  icon?: InputMaybe<Scalars['Upload']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isCylindrical?: InputMaybe<Scalars['Boolean']['input']>;
  /** m */
  length?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  project?: InputMaybe<Scalars['ID']['input']>;
  removeIcon?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleCoupling: Array<InputMaybe<Scalars['String']['input']>>;
  /** m³ */
  volume: Scalars['Float']['input'];
  /** m */
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOrCreateContainerTypePayload = {
  __typename: 'UpdateOrCreateContainerTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  containerType?: Maybe<ContainerTypeType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCreateAgreementExportInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  fromDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  mode: Scalars['String']['input'];
  modeChangeIncludeNew?: InputMaybe<Scalars['Boolean']['input']>;
  modeChangeIncludeRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  runExport: Scalars['Boolean']['input'];
  toDate: Scalars['DateTime']['input'];
};

export type UpdateOrCreateCreateAgreementExportPayload = {
  __typename: 'UpdateOrCreateCreateAgreementExportPayload';
  agreementExport?: Maybe<AgreementExportDef>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCreateCriiptoApplicationInput = {
  allowBusiness?: InputMaybe<Scalars['Boolean']['input']>;
  allowedAcrValues: Array<InputMaybe<Scalars['String']['input']>>;
  clientId: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clientSecret: Scalars['String']['input'];
  hostname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrCreateCreateCriiptoApplicationPayload = {
  __typename: 'UpdateOrCreateCreateCriiptoApplicationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  criiptoApplication?: Maybe<CriiptoApplicationDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCreateDocumentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateOrCreateCreateDocumentPayload = {
  __typename: 'UpdateOrCreateCreateDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<DocumentDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateCreateScheduledExportInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createRun: Scalars['Boolean']['input'];
  excelFormat?: InputMaybe<Scalars['Boolean']['input']>;
  exportAgreementEnd?: InputMaybe<Scalars['Boolean']['input']>;
  exportAgreementNew?: InputMaybe<Scalars['Boolean']['input']>;
  exportAgreementRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  exportAgreementStart?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerCollectionCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerCollectionCalendarFromDate?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerCollectionCalendarWeek?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerDescription?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerId?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerLocation?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerMetaTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  exportContainerPickupSetting?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerRouteDescription?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerRouteScheme?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerType?: InputMaybe<Scalars['Boolean']['input']>;
  exportContainerWasteFraction?: InputMaybe<Scalars['Boolean']['input']>;
  exportPropertyArea?: InputMaybe<Scalars['Boolean']['input']>;
  exportPropertyId?: InputMaybe<Scalars['Boolean']['input']>;
  exportPropertyLocation?: InputMaybe<Scalars['Boolean']['input']>;
  exportPropertyMetaTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  exportPropertyType?: InputMaybe<Scalars['Boolean']['input']>;
  exportTicketBulkWasteItems?: InputMaybe<Scalars['Boolean']['input']>;
  exportTicketDescription?: InputMaybe<Scalars['Boolean']['input']>;
  exportTicketPreferredDate?: InputMaybe<Scalars['Boolean']['input']>;
  exportTicketType?: InputMaybe<Scalars['Boolean']['input']>;
  filterContainerMetaTypes?: InputMaybe<Array<InputMaybe<ScheduledExportMetaTypeInput>>>;
  filterContainerPickupSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterContainerRouteSchemes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterContainerTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterContainerWasteFractions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterPropertyMetaTypes?: InputMaybe<Array<InputMaybe<ScheduledExportMetaTypeInput>>>;
  filterPropertyTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterTicketDate: Scalars['String']['input'];
  filterTicketStatus?: InputMaybe<Scalars['String']['input']>;
  filterTicketTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeAgreements?: InputMaybe<Scalars['Boolean']['input']>;
  includeTickets?: InputMaybe<Scalars['Boolean']['input']>;
  interval: Scalars['Int']['input'];
  intervalDayHour?: InputMaybe<Scalars['Int']['input']>;
  intervalFuture?: InputMaybe<Scalars['Boolean']['input']>;
  intervalMonthDay?: InputMaybe<Scalars['Int']['input']>;
  intervalStart: Scalars['DateTime']['input'];
  intervalType: Scalars['String']['input'];
  intervalWeekIsoweekday?: InputMaybe<Scalars['Int']['input']>;
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateOrCreateCreateScheduledExportPayload = {
  __typename: 'UpdateOrCreateCreateScheduledExportPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  scheduledExport?: Maybe<ScheduledExportDef>;
};

export type UpdateOrCreateDashboardInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  rolesCanView?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateOrCreateDashboardPayload = {
  __typename: 'UpdateOrCreateDashboardPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dashboard?: Maybe<DashboardDef>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateDepot = {
  __typename: 'UpdateOrCreateDepot';
  depot?: Maybe<DepotType>;
};

export type UpdateOrCreateDriverInput = {
  /** In minutes */
  breakDuration?: InputMaybe<Scalars['DriverBreakDurationInput']['input']>;
  /** Time, when driver have scheduled break */
  breakTimeEnd?: InputMaybe<Scalars['Time']['input']>;
  /** Time, when driver have scheduled break */
  breakTimeStart?: InputMaybe<Scalars['Time']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cost?: InputMaybe<DriverCostInput>;
  daysAvailable: Array<InputMaybe<Scalars['String']['input']>>;
  driverType: Scalars['String']['input'];
  /** External driver id */
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  license?: InputMaybe<LicenseInput>;
  primaryVehicle?: InputMaybe<Scalars['ID']['input']>;
  user: Scalars['ID']['input'];
  vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Time, when driver ended to work */
  workTimeEnd?: InputMaybe<Scalars['Time']['input']>;
  /** Time, when driver started to work */
  workTimeStart?: InputMaybe<Scalars['Time']['input']>;
};

export type UpdateOrCreateDriverPayload = {
  __typename: 'UpdateOrCreateDriverPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  driver?: Maybe<DriverType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOrCreateHaulerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrCreateHaulerPayload = {
  __typename: 'UpdateOrCreateHaulerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  hauler?: Maybe<HaulerDef>;
};

export type UpdateOrCreateHaulerPriceInput = {
  amount: Scalars['Int']['input'];
  area?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  hauler: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  product: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
};

export type UpdateOrCreateHaulerPricePayload = {
  __typename: 'UpdateOrCreateHaulerPricePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  haulerPrice?: Maybe<HaulerPriceDef>;
};

export type UpdateOrCreateIntegrationGps = {
  __typename: 'UpdateOrCreateIntegrationGps';
  integrationGps?: Maybe<IntegrationGpsType>;
};

export type UpdateOrCreateIntegrationGpsVehicle = {
  __typename: 'UpdateOrCreateIntegrationGpsVehicle';
  integrationGpsVehicle?: Maybe<IntegrationGpsVehicleType>;
};

export type UpdateOrCreateMeasurementSettingsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  excludeDays?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hours?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  measurementExcludeDays?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  measurementHours?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  measurementMeasurementsPerHour?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  project: Scalars['ID']['input'];
};

export type UpdateOrCreateMeasurementSettingsPayload = {
  __typename: 'UpdateOrCreateMeasurementSettingsPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  measurementSettings?: Maybe<MeasurementSettingsType>;
};

export type UpdateOrCreatePickUpOrderApprovalCollectionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrCreatePickUpOrderApprovalCollectionPayload = {
  __typename: 'UpdateOrCreatePickUpOrderApprovalCollectionPayload';
  approvalCollection?: Maybe<PickUpOrderApprovalCollectionDef>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  pickUpOrderApprovalCollection?: Maybe<PickUpOrderApprovalCollectionDef>;
};

export type UpdateOrCreatePickupSettingsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  collectionPerWeek?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  minimumDaysBetweenPickup?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Count of weeks between repeats */
  pickupRepeatPeriod?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrCreatePickupSettingsPayload = {
  __typename: 'UpdateOrCreatePickupSettingsPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  pickupSetting?: Maybe<PickupSettingsType>;
  pickupSettings?: Maybe<PickupSettingsType>;
};

export type UpdateOrCreateProjectInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Hex color code */
  color?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  removeLogo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOrCreateProjectPayload = {
  __typename: 'UpdateOrCreateProjectPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  project?: Maybe<ProjectType>;
};

export type UpdateOrCreateProjectSettingsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 12-hour clock? */
  clockFormat12?: InputMaybe<Scalars['Boolean']['input']>;
  country: Scalars['String']['input'];
  crmEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  currency: Scalars['String']['input'];
  dashboardLocationLatitude: Scalars['Float']['input'];
  dashboardLocationLongitude: Scalars['Float']['input'];
  dashboardLocationName: Scalars['String']['input'];
  drivingDistance: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  temperature: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  volume: Scalars['String']['input'];
  weight: Scalars['String']['input'];
};

export type UpdateOrCreateProjectSettingsPayload = {
  __typename: 'UpdateOrCreateProjectSettingsPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  project?: Maybe<ProjectType>;
  settings?: Maybe<SettingsType>;
};

export type UpdateOrCreatePropertyContactInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  coName?: InputMaybe<Scalars['String']['input']>;
  contactType: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  ean?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAdministrator?: InputMaybe<Scalars['Boolean']['input']>;
  isCoOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isContact?: InputMaybe<Scalars['Boolean']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isPayer?: InputMaybe<Scalars['Boolean']['input']>;
  isTenant?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  otherPhoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  postalCodeName?: InputMaybe<Scalars['String']['input']>;
  property: Scalars['ID']['input'];
  taxId?: InputMaybe<Scalars['String']['input']>;
  taxSubId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrCreatePropertyContactPayload = {
  __typename: 'UpdateOrCreatePropertyContactPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  propertyContact?: Maybe<PropertyContactDef>;
};

export type UpdateOrCreatePropertyGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupType: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  metaData?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  singleAddress?: InputMaybe<Scalars['String']['input']>;
  singleCity?: InputMaybe<Scalars['String']['input']>;
  singleCountry?: InputMaybe<Scalars['String']['input']>;
  singleInvoiceEmail?: InputMaybe<Scalars['String']['input']>;
  singlePhone?: InputMaybe<Scalars['String']['input']>;
  singleTaxId?: InputMaybe<Scalars['String']['input']>;
  singleZipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrCreatePropertyGroupPayload = {
  __typename: 'UpdateOrCreatePropertyGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  propertyGroup?: Maybe<PropertyGroupDef>;
};

export type UpdateOrCreatePropertyInput = {
  area?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalPropertyNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<LocationInput>;
  metaData?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['ID']['input'];
};

export type UpdateOrCreatePropertyPayload = {
  __typename: 'UpdateOrCreatePropertyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  property?: Maybe<PropertyDef>;
};

export type UpdateOrCreatePropertyTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateOrCreatePropertyTypePayload = {
  __typename: 'UpdateOrCreatePropertyTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  propertyType?: Maybe<PropertyTypeDef>;
};

export type UpdateOrCreatePropertyUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberToken?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UpdateOrCreatePropertyUserPayload = {
  __typename: 'UpdateOrCreatePropertyUserPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  propertyUser?: Maybe<PropertyUserDef>;
};

export type UpdateOrCreateRecurringProductInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enableOnPortal?: InputMaybe<Scalars['Boolean']['input']>;
  externalInvoiceCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeOnInvoicesIfFree?: InputMaybe<Scalars['Boolean']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  nameOnInvoice: Scalars['String']['input'];
  propertyType?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrCreateRecurringProductPayload = {
  __typename: 'UpdateOrCreateRecurringProductPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  product?: Maybe<ProductDef>;
};

export type UpdateOrCreateRouteSchemeInput = {
  /** If this is enabled, drivers will be able to collaborate on routes */
  allowCollaborationOnRoute?: InputMaybe<Scalars['Boolean']['input']>;
  allowDriverToAddStops?: InputMaybe<Scalars['Boolean']['input']>;
  allowDriverToEditContainers?: InputMaybe<Scalars['Boolean']['input']>;
  allowDriverToMoveStops?: InputMaybe<Scalars['Boolean']['input']>;
  allowDriverToOnlyCompleteNextStop?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is enabled, drivers will be able to start routes before the scheduled date */
  allowDriverToStartRouteBeforeScheduledDate?: InputMaybe<Scalars['Boolean']['input']>;
  allowDriverToTakeStops?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is enabled, drivers which have allowed vehicles that intersect with the vehicles from route scheme will be able to view routes generated by this route scheme */
  allowDriverWithRouteSchemeVehiclesAccessToViewRoutes?: InputMaybe<Scalars['Boolean']['input']>;
  autoCompleteDistance: Scalars['Int']['input'];
  autoCompleteDuration: Scalars['Int']['input'];
  autoCompletePlannedContainers?: InputMaybe<Scalars['Boolean']['input']>;
  autoCompleteRfid?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  collectionMethod: Scalars['String']['input'];
  collectionMethodSemiDynamicUsePrediction?: InputMaybe<Scalars['Boolean']['input']>;
  collectionMethodTicketsCollectionCalender?: InputMaybe<Scalars['ID']['input']>;
  defaultAutoComplete?: InputMaybe<Scalars['Boolean']['input']>;
  defaultAutoCompleteNextStop?: InputMaybe<Scalars['Boolean']['input']>;
  defaultRouteAutoOptimize?: InputMaybe<Scalars['Boolean']['input']>;
  defaultRouteLockedStart?: InputMaybe<Scalars['Boolean']['input']>;
  generateRoutes?: InputMaybe<Scalars['Boolean']['input']>;
  generateRoutesDays: Scalars['Int']['input'];
  hauler?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keepManuallyAddedStops?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  objectives: Scalars['String']['input'];
  project: Scalars['ID']['input'];
  promptDriverForFillLevelOnContainer?: InputMaybe<Scalars['Boolean']['input']>;
  promptDriverForQrCodeOnContainer?: InputMaybe<Scalars['Boolean']['input']>;
  promptDriverForWeightOnContainer?: InputMaybe<Scalars['Boolean']['input']>;
  promptDriverForWeightOnWasteStation?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is enabled, drivers will not be able to modify turn by turn navigation in the mobile app */
  restrictTurnByTurnUsage?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is enabled, turn by turn navigation will be enabled by default in the mobile app */
  turnByTurnIsOn?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleDays: Array<InputMaybe<RouteSchemeVehicleDayMultipleChoiceFieldInput>>;
  vehicleTrailerCapacityMode: Scalars['String']['input'];
  vehicles: Array<InputMaybe<Scalars['ID']['input']>>;
  wasteFractionsFillLevelSets?: InputMaybe<Array<InputMaybe<RouteSchemeWasteFractionFillLevelSetMultipleChoiceFieldInput>>>;
};

export type UpdateOrCreateRouteSchemePayload = {
  __typename: 'UpdateOrCreateRouteSchemePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  routeScheme?: Maybe<RouteSchemeType>;
};

export type UpdateOrCreateServiceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  serviceType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrCreateServicePayload = {
  __typename: 'UpdateOrCreateServicePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  service?: Maybe<ServiceDef>;
};

export type UpdateOrCreateServiceProductInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  containerType?: InputMaybe<Scalars['ID']['input']>;
  enableOnPortal?: InputMaybe<Scalars['Boolean']['input']>;
  externalInvoiceCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeOnInvoicesIfFree?: InputMaybe<Scalars['Boolean']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  nameOnInvoice: Scalars['String']['input'];
  propertyType?: InputMaybe<Scalars['ID']['input']>;
  service: Scalars['ID']['input'];
  wasteFraction?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrCreateServiceProductPayload = {
  __typename: 'UpdateOrCreateServiceProductPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  product?: Maybe<ProductDef>;
};

export type UpdateOrCreateSkill = {
  __typename: 'UpdateOrCreateSkill';
  skill?: Maybe<SkillType>;
};

export type UpdateOrCreateSubscriptionQuote = {
  __typename: 'UpdateOrCreateSubscriptionQuote';
  billingSubscriptionQuote?: Maybe<BillingSubscriptionQuote>;
};

export type UpdateOrCreateTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  members?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
};

export type UpdateOrCreateTeamPayload = {
  __typename: 'UpdateOrCreateTeamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  team?: Maybe<TeamType>;
};

export type UpdateOrCreateTicketCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateOrCreateTicketCategoryPayload = {
  __typename: 'UpdateOrCreateTicketCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ticketCategory?: Maybe<TicketCategoryDef>;
};

export type UpdateOrCreateTicketMessageTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrCreateTicketMessageTemplatePayload = {
  __typename: 'UpdateOrCreateTicketMessageTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ticketMessageTemplate?: Maybe<TicketMessageTemplateDef>;
};

export type UpdateOrCreateTicketTypeInput = {
  category: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  containerDestination?: InputMaybe<ContainerStorageLocationInput>;
  containerSource?: InputMaybe<ContainerStorageLocationInput>;
  enableOnNavigationApp?: InputMaybe<Scalars['Boolean']['input']>;
  enableOnPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  enableOnPortal?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isContainerLevel?: InputMaybe<Scalars['Boolean']['input']>;
  isPropertyLevel?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  navigationAppRequireAttachment?: InputMaybe<Scalars['Boolean']['input']>;
  portalConfirmSubtitle?: InputMaybe<Scalars['String']['input']>;
  portalConfirmTitle?: InputMaybe<Scalars['String']['input']>;
  portalContactInformationEmailRequired?: InputMaybe<Scalars['Boolean']['input']>;
  portalContactInformationNameRequired?: InputMaybe<Scalars['Boolean']['input']>;
  portalContactInformationNextButtonLabel?: InputMaybe<Scalars['String']['input']>;
  portalContactInformationPhoneNumberRequired?: InputMaybe<Scalars['Boolean']['input']>;
  portalDateHelpText?: InputMaybe<Scalars['String']['input']>;
  portalDateHidden?: InputMaybe<Scalars['Boolean']['input']>;
  portalDateLabel?: InputMaybe<Scalars['String']['input']>;
  portalDateMaxDaysInFutureForBulkWaste?: InputMaybe<Scalars['Int']['input']>;
  portalDateMinDaysInFutureForBulkWaste?: InputMaybe<Scalars['Int']['input']>;
  portalDateRequired?: InputMaybe<Scalars['Boolean']['input']>;
  portalDescription?: InputMaybe<Scalars['String']['input']>;
  portalDropdownHelpText?: InputMaybe<Scalars['String']['input']>;
  portalDropdownHidden?: InputMaybe<Scalars['Boolean']['input']>;
  portalDropdownLabel?: InputMaybe<Scalars['String']['input']>;
  portalDropdownOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  portalDropdownRequired?: InputMaybe<Scalars['Boolean']['input']>;
  portalNoteHelpText?: InputMaybe<Scalars['String']['input']>;
  portalNoteHidden?: InputMaybe<Scalars['Boolean']['input']>;
  portalNoteLabel?: InputMaybe<Scalars['String']['input']>;
  portalNoteRequired?: InputMaybe<Scalars['Boolean']['input']>;
  portalPriceShowZero?: InputMaybe<Scalars['Boolean']['input']>;
  portalRequireContactInformation?: InputMaybe<Scalars['Boolean']['input']>;
  portalServiceTypeBulkWasteLimit?: InputMaybe<Scalars['Int']['input']>;
  portalServiceTypeBulkWasteLimitHelpText?: InputMaybe<Scalars['String']['input']>;
  portalServiceTypeBulkWasteLimitLabel?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['ID']['input']>;
  statusTransitions?: InputMaybe<Array<TicketTypeStatusTransitionInput>>;
};

export type UpdateOrCreateTicketTypePayload = {
  __typename: 'UpdateOrCreateTicketTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ticketType?: Maybe<TicketTypeDef>;
};

export type UpdateOrCreateUserAbsence = {
  __typename: 'UpdateOrCreateUserAbsence';
  userAbsence?: Maybe<UserAbsenceType>;
};

export type UpdateOrCreateUserProjectInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** This user has permission to change project settings. */
  isSuper?: InputMaybe<Scalars['Boolean']['input']>;
  project: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};

export type UpdateOrCreateUserProjectPayload = {
  __typename: 'UpdateOrCreateUserProjectPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  userProject?: Maybe<UserProjectType>;
};

export type UpdateOrCreateVehicleAbsence = {
  __typename: 'UpdateOrCreateVehicleAbsence';
  vehicleAbsence?: Maybe<VehicleAbsenceType>;
};

export type UpdateOrCreateVehicleInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Hex color code */
  color?: InputMaybe<Scalars['String']['input']>;
  depots: Array<InputMaybe<Scalars['ID']['input']>>;
  externalIdentifier?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  licensePlateNumber?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  /** Vehicle's naming */
  name: Scalars['String']['input'];
  project: Scalars['ID']['input'];
  removeLogo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Status of vehicle */
  status: Scalars['String']['input'];
  vehicleSize: Scalars['ID']['input'];
  vehicleType: Scalars['ID']['input'];
  wasteStations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateOrCreateVehiclePayload = {
  __typename: 'UpdateOrCreateVehiclePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  vehicle?: Maybe<VehicleType>;
};

export type UpdateOrCreateVehicleTypeInput = {
  Type: Scalars['String']['input'];
  /** Type of vehicle's body */
  body?: InputMaybe<Scalars['String']['input']>;
  /** Type of body's mechanization */
  bodyMechanization?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** With compactor */
  compactor?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  /** Crane variant */
  crane?: InputMaybe<Scalars['String']['input']>;
  /** Fixed cost for the Vehicle per day */
  fixedCost?: InputMaybe<Scalars['Float']['input']>;
  /** Fuel cost per kilometer (less is better) */
  fuelCostPerKm?: InputMaybe<Scalars['Float']['input']>;
  /** Fuel cost per litter (less is better) */
  fuelCostPerLtr?: InputMaybe<Scalars['Float']['input']>;
  fuelTankCapacity?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle fuel type */
  fuelType?: InputMaybe<Scalars['String']['input']>;
  /** Fuel usage per kilometer (less is better) */
  fuelUsagePerKm?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Type of lifting's mechanization */
  liftingMechanization?: InputMaybe<Scalars['String']['input']>;
  /** Type of loading location */
  loadingLocation?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle's manufacturer */
  manufacturer: Scalars['String']['input'];
  /** Vehicle manufacturer's year */
  manufacturerYear: Scalars['String']['input'];
  /** Vehicle's model */
  model: Scalars['String']['input'];
  /** Name of vehicle type */
  name: Scalars['String']['input'];
  trailers: Array<InputMaybe<VehicleTrailerMultipleChoiceFieldInput>>;
  /** Vehicle's trim */
  trim: Scalars['String']['input'];
};

export type UpdateOrCreateVehicleTypePayload = {
  __typename: 'UpdateOrCreateVehicleTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  vehicleType?: Maybe<VehicleTypeType>;
};

export type UpdateOrCreateWasteFraction = {
  __typename: 'UpdateOrCreateWasteFraction';
  wasteFraction?: Maybe<WasteFractionType>;
};

export type UpdateOrCreateWasteFractionNewInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['Upload']['input']>;
  iconPreset?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  project: Scalars['ID']['input'];
  removeIcon?: InputMaybe<Scalars['Boolean']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  wasteCategory: Scalars['String']['input'];
  wasteSubstance: Scalars['String']['input'];
  wasteType: Scalars['String']['input'];
  /** kg for m3 */
  weightFactor: Scalars['Float']['input'];
};

export type UpdateOrCreateWasteFractionNewPayload = {
  __typename: 'UpdateOrCreateWasteFractionNewPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  wasteFraction?: Maybe<WasteFractionType>;
};

export type UpdatePriceInput = {
  amount: Scalars['Int']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  id: Scalars['ID']['input'];
  recurringInterval: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
  weightAmount: Scalars['Int']['input'];
};

export type UpdatePricePayload = {
  __typename: 'UpdatePricePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  price?: Maybe<PriceDef>;
};

export type UpdateRecurringPriceInput = {
  amount: Scalars['Int']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** If true, then soft-delete the price, ignoring the rest of the input */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  invoiceName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateRecurringPricePayload = {
  __typename: 'UpdateRecurringPricePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  recurringPrice?: Maybe<RecurringPriceDef>;
};

export type UpdateServicePriceInput = {
  amount: Scalars['Int']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** If true, then soft-delete the price, ignoring the rest of the input */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  invoiceName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateServicePricePayload = {
  __typename: 'UpdateServicePricePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  servicePrice?: Maybe<ServicePriceDef>;
};

export type UpdateShippingInformation = {
  __typename: 'UpdateShippingInformation';
  billingInformation?: Maybe<BillingInformationType>;
};

/** Either userId or teamId can be provided, or neither, but not both */
export type UpdateTicketAssigneeInput = {
  teamId?: InputMaybe<Scalars['ID']['input']>;
  ticketId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateTicketAttachments = {
  __typename: 'UpdateTicketAttachments';
  ticket?: Maybe<TicketDef>;
};

export type UpdateTicketCustomFieldMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayHelpText?: InputMaybe<Scalars['Boolean']['input']>;
  displayOn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayPrefilledValue?: InputMaybe<Scalars['Boolean']['input']>;
  displayUnit?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inputType?: InputMaybe<Scalars['String']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<CustomFieldOptionSelectInput>>>;
  order?: InputMaybe<Scalars['Int']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  prefilledValue?: InputMaybe<Scalars['Int']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTicketCustomFieldMutationPayload = {
  __typename: 'UpdateTicketCustomFieldMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ticketCustomField?: Maybe<TicketCustomFieldDef>;
};

export type UpdateTicketDraftInput = {
  assignee?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  container?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  project?: InputMaybe<Scalars['ID']['input']>;
  property?: InputMaybe<Scalars['ID']['input']>;
  ticketType?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateTicketDraftPayload = {
  __typename: 'UpdateTicketDraftPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ticketDraft?: Maybe<TicketDraftDef>;
};

export type UpdateTicketFollowers = {
  __typename: 'UpdateTicketFollowers';
  ticket?: Maybe<TicketDef>;
};

export type UpdateTicketInput = {
  addAttachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<TicketCustomFieldValueInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** Deprecated: Send empty serviceProducts instead */
  ignoreServiceFee?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['ID']['input']>;
  preferredDate?: InputMaybe<Scalars['Date']['input']>;
  priority: Scalars['String']['input'];
  removeAttachmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  serviceProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateTicketPayload = {
  __typename: 'UpdateTicketPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ticket?: Maybe<TicketDef>;
};

export type UpdateTicketPriorityInput = {
  priority: Scalars['String']['input'];
  ticketId: Scalars['ID']['input'];
};

export type UpdateTicketStatus = {
  __typename: 'UpdateTicketStatus';
  ticket?: Maybe<TicketDef>;
};

export type UpdateTicketStatusInput = {
  status: Scalars['String']['input'];
  ticketId: Scalars['ID']['input'];
};

export type UpdateTicketTypeOrder = {
  __typename: 'UpdateTicketTypeOrder';
  ticketTypes: Array<TicketTypeDef>;
};

export type UpdateUnconfirmedUser = {
  __typename: 'UpdateUnconfirmedUser';
  unconfirmedUser?: Maybe<UnconfirmedUserType>;
};

export type UpdateUser = {
  __typename: 'UpdateUser';
  user?: Maybe<UserType>;
};

export type UpdateUserLayoutConfig = {
  __typename: 'UpdateUserLayoutConfig';
  userLayoutConfig?: Maybe<UserLayoutConfigType>;
};

export type UpdateUsername = {
  __typename: 'UpdateUsername';
  token: Scalars['String']['output'];
  user: UserType;
};

export type UpdateWasteCollector = {
  __typename: 'UpdateWasteCollector';
  wasteCollector?: Maybe<WasteCollectorType>;
};

export type UpdateWasteFraction = {
  __typename: 'UpdateWasteFraction';
  wasteFraction?: Maybe<WasteFractionType>;
};

export type UpdateWasteType = {
  __typename: 'UpdateWasteType';
  wasteType?: Maybe<WasteTypeType>;
};

export type UrbaserCredentialsDef = Node & {
  __typename: 'UrbaserCredentialsDef';
  company: CompanyType;
  contract: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  mappingForCollectionCalendars: Scalars['JSONString']['output'];
  mappingForRouteScheme: Scalars['JSONString']['output'];
  mappingForTicketTypes: Scalars['JSONString']['output'];
  mappingForVehicles: Scalars['JSONString']['output'];
  password: Scalars['String']['output'];
  updateAgreementsScheduler: Scalars['Boolean']['output'];
  updateCollectionCalendersScheduler: Scalars['Boolean']['output'];
  updateRoutesScheduler: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
  whbasicdataUrl: Scalars['String']['output'];
  whcalenderUrl: Scalars['String']['output'];
  whregUrl: Scalars['String']['output'];
  whregUrlLastId: Scalars['Int']['output'];
  whregimageImageUrl: Scalars['String']['output'];
  whregimageLastId: Scalars['Int']['output'];
};

export type UrbaserCredentialsMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
  whbasicdataUrl: Scalars['String']['input'];
  whcalenderUrl: Scalars['String']['input'];
  whregUrl: Scalars['String']['input'];
  whregimageImageUrl: Scalars['String']['input'];
};

export type UrbaserCredentialsMutationPayload = {
  __typename: 'UrbaserCredentialsMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  urbaserCredentials?: Maybe<UrbaserCredentialsDef>;
};

export type UserAbsenceType = Node & {
  __typename: 'UserAbsenceType';
  absenceType: CoreUserAbsenceAbsenceTypeChoices;
  end: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  project: ProjectType;
  replacementUser: UserType;
  start: Scalars['Date']['output'];
  user: UserType;
};

export type UserAbsenceTypeConnection = {
  __typename: 'UserAbsenceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserAbsenceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `UserAbsenceType` and its cursor. */
export type UserAbsenceTypeEdge = {
  __typename: 'UserAbsenceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserAbsenceType>;
};

export type UserJobNotificationSubscription = {
  __typename: 'UserJobNotificationSubscription';
  userJobNotification?: Maybe<UserJobNotificationType>;
};

export type UserJobNotificationType = Node & {
  __typename: 'UserJobNotificationType';
  companyEmailAccountMessage?: Maybe<CompanyEmailAccountMessageDef>;
  createdAt: Scalars['DateTime']['output'];
  /** The date the notification was read. */
  dateRead?: Maybe<Scalars['DateTime']['output']>;
  /** The date the notification was seen, but not read. */
  dateSeen?: Maybe<Scalars['DateTime']['output']>;
  event?: Maybe<JobNotificationEventUnion>;
  eventObjectId: Scalars['Int']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  ticket?: Maybe<TicketDef>;
  user: UserType;
};

export type UserJobNotificationTypeConnection = {
  __typename: 'UserJobNotificationTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserJobNotificationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `UserJobNotificationType` and its cursor. */
export type UserJobNotificationTypeEdge = {
  __typename: 'UserJobNotificationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserJobNotificationType>;
};

export type UserLayoutConfigType = Node & {
  __typename: 'UserLayoutConfigType';
  analyticsCalculationType: CoreUserLayoutConfigAnalyticsCalculationTypeChoices;
  analyticsCustomPeriodComparisonFrom?: Maybe<Scalars['DateTime']['output']>;
  analyticsCustomPeriodComparisonTo?: Maybe<Scalars['DateTime']['output']>;
  analyticsCustomPeriodFrom?: Maybe<Scalars['DateTime']['output']>;
  analyticsCustomPeriodTo?: Maybe<Scalars['DateTime']['output']>;
  analyticsHistoricalPeriod: CoreUserLayoutConfigAnalyticsHistoricalPeriodChoices;
  analyticsPreviousPeriod: CoreUserLayoutConfigAnalyticsPreviousPeriodChoices;
  collectionContainersDisabledColumns: Array<Scalars['String']['output']>;
  collectionContainersOrder: Scalars['String']['output'];
  collectionContainersOrderBy: Scalars['String']['output'];
  collectionContainersPageSize: Scalars['Int']['output'];
  commentsDisabledColumns: Array<Scalars['String']['output']>;
  commentsOrder: Scalars['String']['output'];
  commentsOrderBy: Scalars['String']['output'];
  commentsPageSize: Scalars['Int']['output'];
  containersDisabledColumns: Array<Scalars['String']['output']>;
  containersOrder: Scalars['String']['output'];
  containersOrderBy: Scalars['String']['output'];
  containersPageSize: Scalars['Int']['output'];
  driverDisabledColumns: Array<Scalars['String']['output']>;
  driverIconsSize: Scalars['String']['output'];
  driverOrder: Scalars['String']['output'];
  driverOrderBy: Scalars['String']['output'];
  driverPageSize: Scalars['Int']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  inquiryDisabledColumns: Array<Scalars['String']['output']>;
  inquiryOrder: Scalars['String']['output'];
  inquiryOrderBy: Scalars['String']['output'];
  inquiryPageSize: Scalars['Int']['output'];
  locationsDisabledColumns: Array<Scalars['String']['output']>;
  locationsIconsSize: Scalars['String']['output'];
  locationsOrder: Scalars['String']['output'];
  locationsOrderBy: Scalars['String']['output'];
  locationsPageSize: Scalars['Int']['output'];
  notificationsDisabledColumns: Array<Scalars['String']['output']>;
  notificationsOrder: Scalars['String']['output'];
  notificationsOrderBy: Scalars['String']['output'];
  notificationsPageSize: Scalars['Int']['output'];
  pickupOrdersDisabledColumns: Array<Scalars['String']['output']>;
  pickupOrdersOrder: Scalars['String']['output'];
  pickupOrdersOrderBy: Scalars['String']['output'];
  pickupOrdersPageSize: Scalars['Int']['output'];
  routeDisabledColumns: Array<Scalars['String']['output']>;
  routeOrder: Scalars['String']['output'];
  routeOrderBy: Scalars['String']['output'];
  routePageSize: Scalars['Int']['output'];
  user?: Maybe<UserType>;
  vehicleDisabledColumns: Array<Scalars['String']['output']>;
  vehicleIconsSize: Scalars['String']['output'];
  vehicleOrder: Scalars['String']['output'];
  vehicleOrderBy: Scalars['String']['output'];
  vehiclePageSize: Scalars['Int']['output'];
  vehicleTypesDisabledColumns: Array<Scalars['String']['output']>;
  vehicleTypesOrder: Scalars['String']['output'];
  vehicleTypesOrderBy: Scalars['String']['output'];
  vehicleTypesPageSize: Scalars['Int']['output'];
};

export type UserNotificationEventUnion = CompanyEmailAccountMessageEventDef | TicketCommentDef | TicketEventDef | TicketMessageDef;

export type UserNotificationEventsUnionDef = CompanyEmailAccountMessageDef | TicketDef;

export type UserNotificationSubscription = {
  __typename: 'UserNotificationSubscription';
  userNotification?: Maybe<UserNotificationType>;
};

export type UserNotificationType = Node & {
  __typename: 'UserNotificationType';
  companyEmailAccountMessage?: Maybe<CompanyEmailAccountMessageDef>;
  createdAt: Scalars['DateTime']['output'];
  /** The date the notification was read. */
  dateRead?: Maybe<Scalars['DateTime']['output']>;
  /** The date the notification was seen, but not read. */
  dateSeen?: Maybe<Scalars['DateTime']['output']>;
  event?: Maybe<UserNotificationEventUnion>;
  eventObjectId: Scalars['Int']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** The users who have read the notification. */
  readBy: UserTypeConnection;
  /** The users who have seen the notification. */
  seenBy: UserTypeConnection;
  ticket?: Maybe<TicketDef>;
  user: UserType;
};


export type UserNotificationTypeReadByArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserNotificationTypeSeenByArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserNotificationTypeConnection = {
  __typename: 'UserNotificationTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNotificationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `UserNotificationType` and its cursor. */
export type UserNotificationTypeEdge = {
  __typename: 'UserNotificationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserNotificationType>;
};

export type UserNotificationsCounterType = {
  __typename: 'UserNotificationsCounterType';
  directNotificationsCount?: Maybe<Scalars['Int']['output']>;
  followingNotificationsCount?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type UserNotificationsEventsWrapper = {
  __typename: 'UserNotificationsEventsWrapper';
  edges?: Maybe<Array<Maybe<UserNotificationEventsUnionDef>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type UserProjectType = Node & {
  __typename: 'UserProjectType';
  apiKey: Scalars['UUID']['output'];
  deviceContainerCreate: Scalars['Boolean']['output'];
  deviceContainerDelete: Scalars['Boolean']['output'];
  deviceContainerEdit: Scalars['Boolean']['output'];
  fleetManagementDriverCreate: Scalars['Boolean']['output'];
  fleetManagementDriverDelete: Scalars['Boolean']['output'];
  fleetManagementDriverEdit: Scalars['Boolean']['output'];
  fleetManagementLocationCreate: Scalars['Boolean']['output'];
  fleetManagementLocationDelete: Scalars['Boolean']['output'];
  fleetManagementLocationEdit: Scalars['Boolean']['output'];
  fleetManagementVehicleAbsenceCreate: Scalars['Boolean']['output'];
  fleetManagementVehicleAbsenceDelete: Scalars['Boolean']['output'];
  fleetManagementVehicleAbsenceEdit: Scalars['Boolean']['output'];
  fleetManagementVehicleCreate: Scalars['Boolean']['output'];
  fleetManagementVehicleDelete: Scalars['Boolean']['output'];
  fleetManagementVehicleEdit: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** This user has permission to change project settings. */
  isSuper: Scalars['Boolean']['output'];
  project: ProjectType;
  routesInquiryCreate: Scalars['Boolean']['output'];
  routesInquiryDelete: Scalars['Boolean']['output'];
  routesInquiryEdit: Scalars['Boolean']['output'];
  routesPickupSettingCreate: Scalars['Boolean']['output'];
  routesPickupSettingDelete: Scalars['Boolean']['output'];
  routesPickupSettingEdit: Scalars['Boolean']['output'];
  routesRouteCreate: Scalars['Boolean']['output'];
  routesRouteDelete: Scalars['Boolean']['output'];
  routesRouteEdit: Scalars['Boolean']['output'];
  routesRouteSchemeCreate: Scalars['Boolean']['output'];
  routesRouteSchemeDelete: Scalars['Boolean']['output'];
  routesRouteSchemeEdit: Scalars['Boolean']['output'];
  user: UserType;
  userManagementUserAbsenceCreate: Scalars['Boolean']['output'];
  userManagementUserAbsenceDelete: Scalars['Boolean']['output'];
  userManagementUserAbsenceEdit: Scalars['Boolean']['output'];
  userManagementUserCreate: Scalars['Boolean']['output'];
  userManagementUserDelete: Scalars['Boolean']['output'];
  userManagementUserEdit: Scalars['Boolean']['output'];
};

export type UserProjectTypeConnection = {
  __typename: 'UserProjectTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserProjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserProjectType` and its cursor. */
export type UserProjectTypeEdge = {
  __typename: 'UserProjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserProjectType>;
};

export type UserType = Node & {
  __typename: 'UserType';
  activeProjects?: Maybe<ProjectTypeConnection>;
  activeProjectsIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  activeToken: Scalars['String']['output'];
  alertSet: AlertDefConnection;
  alertrecipientSet: AlertRecipientDefConnection;
  assignedTickets: TicketDefConnection;
  assigntouseractionSet: AssignToUserActionDefConnection;
  authorOfComments: CommentTypeConnection;
  billingrunSet: BillingRunDefConnection;
  billingrunexportSet: BillingRunExportDefConnection;
  bulkactionprogressSet: BulkActionProgressDefConnection;
  /** Hex color code */
  color?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyType>;
  companyRole?: Maybe<CompanyRoleDef>;
  companyemailaccountmessageSet: CompanyEmailAccountMessageDefConnection;
  companyemailaccountruleSet: CompanyEmailAccountRuleDefConnection;
  containerbarcodegenerationhistorySet: ContainerBarcodeGenerationHistoryDefConnection;
  costsettingsSet: CostSettingsTypeConnection;
  createdAt: Scalars['DateTime']['output'];
  createdExportTemplates: ExportTemplateDefConnection;
  dashboardConfigs?: Maybe<Array<Maybe<DashboardConfig>>>;
  dateJoined: Scalars['DateTime']['output'];
  datetimeFormatLocale: CoreIoUserDatetimeFormatLocaleChoices;
  daysBeforeDemoExpires?: Maybe<Scalars['Int']['output']>;
  debtorexportSet: DebtorExportDefConnection;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  demoExpireDate?: Maybe<Scalars['Date']['output']>;
  driver?: Maybe<DriverType>;
  driverpushnotificationSet: DriverPushNotificationTypeConnection;
  email: Scalars['String']['output'];
  exportHistory: ExportHistoryDefConnection;
  exportInternalRecipients: ExportHistoryDefConnection;
  exportTemplateInternalRecipients: ExportTemplateDefConnection;
  externalCrmId?: Maybe<Scalars['String']['output']>;
  fcmdeviceSet: FcmDeviceTypeConnection;
  firstName: Scalars['String']['output'];
  followedTickets: TicketDefConnection;
  freshdeskToken?: Maybe<Scalars['String']['output']>;
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups: GroupTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  importHistory: ImportHistoryDefConnection;
  inquiries: CollectionInquiryTypeConnection;
  intercomUserHash?: Maybe<Scalars['String']['output']>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  /** Designates that this user has an access to Beta features. */
  isBeta: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions but can`t edit any data. */
  isDemo: Scalars['Boolean']['output'];
  /** Designates that this user has an access to Developer features. */
  isDeveloper: Scalars['Boolean']['output'];
  isDriver?: Maybe<Scalars['Boolean']['output']>;
  isEmail: Scalars['Boolean']['output'];
  /** Designates that this user has permissions to create projects, and assign users to them. */
  isMaster: Scalars['Boolean']['output'];
  isPush: Scalars['Boolean']['output'];
  /** (Resellers / partners) Designates that this user has all permissions without explicitly assigning them in companies created by him. */
  isReseller: Scalars['Boolean']['output'];
  isSms: Scalars['Boolean']['output'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  jobTitle?: Maybe<JobTitleType>;
  jobtitle?: Maybe<JobTitleType>;
  language: CoreIoUserLanguageChoices;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  /** User who completed the stop manually */
  manuallyCompletedPickupOrders: PickUpOrderTypeConnection;
  metaTypesCanEdit?: Maybe<Array<CompanyMetaTypeDef>>;
  metaTypesCanView?: Maybe<Array<CompanyMetaTypeDef>>;
  mixpanelIdentity?: Maybe<Scalars['JSONString']['output']>;
  notificationSet: NotificationsTypeConnection;
  notificationsettingsSet: NotificationSettingsTypeConnection;
  ownProjects?: Maybe<ProjectTypeConnection>;
  ownProjectsIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  password: Scalars['String']['output'];
  permissions?: Maybe<Array<PermissionChoices>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pickuporderSet: PickUpOrderTypeConnection;
  propertyvieweventSet: PropertyViewEventDefConnection;
  replacementUser: UserAbsenceTypeConnection;
  routeexportSet: RouteExportTypeConnection;
  salesofferSet: SalesOfferTypeConnection;
  teams: TeamTypeConnection;
  ticketSet: TicketDefConnection;
  ticketdraftSet: TicketDraftDefConnection;
  ticketvieweventSet: TicketViewEventDefConnection;
  unconfirmeduser?: Maybe<UnconfirmedUserType>;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  userLayoutConfig?: Maybe<UserLayoutConfigType>;
  /** Specific permissions for this user. */
  userPermissions: PermissionTypeConnection;
  userabsenceSet: UserAbsenceTypeConnection;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']['output'];
  usernotificationSet: UserNotificationTypeConnection;
  userprojectSet: UserProjectTypeConnection;
};


export type UserTypeActiveProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyProjectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  projectsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type UserTypeAlertSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeAlertrecipientSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeAssignedTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeAssigntouseractionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeAuthorOfCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeBillingrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeBillingrunexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeBulkactionprogressSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeCompanyemailaccountmessageSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeCompanyemailaccountruleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeContainerbarcodegenerationhistorySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeCostsettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeCreatedExportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeDebtorexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeDriverpushnotificationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeExportHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeExportInternalRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeExportTemplateInternalRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeFcmdeviceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeFollowedTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeImportHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeInquiriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeManuallyCompletedPickupOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeNotificationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status_Iexact?: InputMaybe<Scalars['String']['input']>;
};


export type UserTypeNotificationsettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeOwnProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypePickuporderSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypePropertyvieweventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeReplacementUserArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeRouteexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeSalesofferSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeTicketSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeTicketdraftSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeTicketvieweventSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeUserPermissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeUserabsenceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeUsernotificationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeUserprojectSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** User config */
export type UserTypeConfigType = {
  __typename: 'UserTypeConfigType';
  config?: Maybe<Scalars['JSONString']['output']>;
};

export type UserTypeConnection = {
  __typename: 'UserTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `UserType` and its cursor. */
export type UserTypeEdge = {
  __typename: 'UserTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserType>;
};

export type VehicleAbsenceType = Node & {
  __typename: 'VehicleAbsenceType';
  absenceType: FleetManagementVehicleAbsenceAbsenceTypeChoices;
  end: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  project: ProjectType;
  replacementVehicle: VehicleType;
  start: Scalars['Date']['output'];
  vehicle: VehicleType;
};

export type VehicleAbsenceTypeConnection = {
  __typename: 'VehicleAbsenceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleAbsenceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `VehicleAbsenceType` and its cursor. */
export type VehicleAbsenceTypeEdge = {
  __typename: 'VehicleAbsenceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VehicleAbsenceType>;
};

export type VehicleAnalyticsType = {
  __typename: 'VehicleAnalyticsType';
  objects?: Maybe<VehicleTypeConnection>;
  objects_Count?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};


export type VehicleAnalyticsTypeObjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleDepotsDef = Node & {
  __typename: 'VehicleDepotsDef';
  depot: DepotType;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  vehicle: VehicleType;
};

export type VehicleDepotsEventDef = Node & {
  __typename: 'VehicleDepotsEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<GenericMtmEventTypesGraphene>>>;
  fromDepot?: Maybe<DepotType>;
  fromVehicle?: Maybe<VehicleType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toDepot?: Maybe<DepotType>;
  toVehicle?: Maybe<VehicleType>;
  user?: Maybe<UserType>;
  vehicleDepots?: Maybe<VehicleDepotsDef>;
};

export type VehicleEventDef = Node & {
  __typename: 'VehicleEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<VehicleEventTypesGraphene>>>;
  fromExternalIdentifier?: Maybe<Scalars['String']['output']>;
  fromLicensePlate?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  fromStatus?: Maybe<Scalars['String']['output']>;
  fromVehicleType?: Maybe<VehicleTypeType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toExternalIdentifier?: Maybe<Scalars['String']['output']>;
  toLicensePlate?: Maybe<Scalars['String']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  toStatus?: Maybe<Scalars['String']['output']>;
  toVehicleType?: Maybe<VehicleTypeType>;
  user?: Maybe<UserType>;
  vehicle?: Maybe<VehicleType>;
};

export type VehicleEventLogEvent = VehicleDepotsEventDef | VehicleEventDef;

export type VehicleEventLogEventWrapper = {
  __typename: 'VehicleEventLogEventWrapper';
  edges?: Maybe<Array<Maybe<VehicleEventLogEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum VehicleEventTypesGraphene {
  EXTERNAL_IDENTIFIER_CHANGE = 'EXTERNAL_IDENTIFIER_CHANGE',
  LICENSE_PLATE_CHANGE = 'LICENSE_PLATE_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE',
  STATUS_CHANGE = 'STATUS_CHANGE',
  VEHICLE_TYPE_CHANGE = 'VEHICLE_TYPE_CHANGE'
}

export type VehicleSizeDef = Node & {
  __typename: 'VehicleSizeDef';
  deleted?: Maybe<Scalars['DateTime']['output']>;
  height: Scalars['Float']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profile: Scalars['String']['output'];
  roadClassesToAvoid?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleSet: VehicleTypeConnection;
  width: Scalars['Float']['output'];
};


export type VehicleSizeDefVehicleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleSizeDefConnection = {
  __typename: 'VehicleSizeDefConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleSizeDefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `VehicleSizeDef` and its cursor. */
export type VehicleSizeDefEdge = {
  __typename: 'VehicleSizeDefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VehicleSizeDef>;
};

export type VehicleSubscription = {
  __typename: 'VehicleSubscription';
  vehicle?: Maybe<VehicleType>;
};

export type VehicleTrailerMultipleChoiceFieldInput = {
  containerTypes: Array<InputMaybe<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  ticketHolds: Array<InputMaybe<Scalars['ID']['input']>>;
  wasteHolds: Array<InputMaybe<VehicleTrailerWasteHoldMultipleChoiceFieldInput>>;
};

export type VehicleTrailerTicketTypeHoldType = Node & {
  __typename: 'VehicleTrailerTicketTypeHoldType';
  capacity: Scalars['Int']['output'];
  company: CompanyType;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  ticketType: TicketTypeDef;
  vehicletrailerSet: VehicleTrailerTypeConnection;
};


export type VehicleTrailerTicketTypeHoldTypeVehicletrailerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleTrailerTicketTypeHoldTypeConnection = {
  __typename: 'VehicleTrailerTicketTypeHoldTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleTrailerTicketTypeHoldTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `VehicleTrailerTicketTypeHoldType` and its cursor. */
export type VehicleTrailerTicketTypeHoldTypeEdge = {
  __typename: 'VehicleTrailerTicketTypeHoldTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VehicleTrailerTicketTypeHoldType>;
};

export type VehicleTrailerType = Node & {
  __typename: 'VehicleTrailerType';
  company: CompanyType;
  containerTypes: ContainerTypeTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  routeVehicles: RouteVehicleTypeConnection;
  routeschemevehicledaySet: RouteSchemeVehicleDayTypeConnection;
  ticketHolds: VehicleTrailerTicketTypeHoldTypeConnection;
  vehicletypeSet: VehicleTypeTypeConnection;
  wasteHolds: VehicleTrailerWasteHoldTypeConnection;
};


export type VehicleTrailerTypeContainerTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type VehicleTrailerTypeRouteVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type VehicleTrailerTypeRouteschemevehicledaySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type VehicleTrailerTypeTicketHoldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type VehicleTrailerTypeVehicletypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type VehicleTrailerTypeWasteHoldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleTrailerTypeConnection = {
  __typename: 'VehicleTrailerTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleTrailerTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `VehicleTrailerType` and its cursor. */
export type VehicleTrailerTypeEdge = {
  __typename: 'VehicleTrailerTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VehicleTrailerType>;
};

export type VehicleTrailerWasteHoldMultipleChoiceFieldInput = {
  capacity: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  industryCapacity: Scalars['Int']['input'];
  switchCapacity: Scalars['Int']['input'];
  wasteFraction: Scalars['ID']['input'];
};

export type VehicleTrailerWasteHoldType = Node & {
  __typename: 'VehicleTrailerWasteHoldType';
  capacity: Scalars['Int']['output'];
  company: CompanyType;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  industryCapacity?: Maybe<Scalars['Int']['output']>;
  switchCapacity?: Maybe<Scalars['Int']['output']>;
  vehicletrailerSet: VehicleTrailerTypeConnection;
  wasteFraction: WasteFractionType;
};


export type VehicleTrailerWasteHoldTypeVehicletrailerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleTrailerWasteHoldTypeConnection = {
  __typename: 'VehicleTrailerWasteHoldTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleTrailerWasteHoldTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `VehicleTrailerWasteHoldType` and its cursor. */
export type VehicleTrailerWasteHoldTypeEdge = {
  __typename: 'VehicleTrailerWasteHoldTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VehicleTrailerWasteHoldType>;
};

/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleType = Node & {
  __typename: 'VehicleType';
  /** Current stopping place */
  activeDepot?: Maybe<DepotType>;
  /** CO2 emissiom per kilometer (less is better) */
  co2EmissionPerKm?: Maybe<Scalars['Decimal']['output']>;
  /** Hex color code */
  color?: Maybe<Scalars['String']['output']>;
  /** Relation to the Comment */
  comments: CommentTypeConnection;
  containerSet: ContainerTypeConnection;
  /** Vehicle can have many days available (add them comma-separated) */
  daysAvailable: Array<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** Stopping places */
  depots: DepotTypeConnection;
  /** Vehicles a driver has access to. For assistant drivers this field can be empty. */
  drivers: DriverTypeConnection;
  eventLog?: Maybe<VehicleEventLogEventWrapper>;
  /** Vehicle identification number (VIN) */
  externalIdentifier: Scalars['String']['output'];
  /** Fixed cost for the Vehicle per day */
  fixedCost?: Maybe<Scalars['Decimal']['output']>;
  /** Fuel cost per kilometer (less is better) */
  fuelCostPerKm?: Maybe<Scalars['Decimal']['output']>;
  /** Fuel cost per litter (less is better) */
  fuelCostPerLtr?: Maybe<Scalars['Decimal']['output']>;
  fuelTankCapacity?: Maybe<Array<Scalars['Decimal']['output']>>;
  fuelType?: Maybe<Scalars['String']['output']>;
  /** Fuel usage per kilometer (less is better) */
  fuelUsagePerKm?: Maybe<Scalars['Decimal']['output']>;
  hasPrimaryDriver?: Maybe<Scalars['Boolean']['output']>;
  hours?: Maybe<Array<Scalars['Int']['output']>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  integrationgpsvehicleSet: IntegrationGpsVehicleTypeConnection;
  isActive: Scalars['Boolean']['output'];
  isOnRoute?: Maybe<Scalars['Boolean']['output']>;
  /** Vehicle's license plate number */
  licensePlateNumber: Scalars['String']['output'];
  logo: Scalars['String']['output'];
  /** Vehicle's manufacturer */
  manufacturer: Scalars['String']['output'];
  /** Vehicle manufacturer's year */
  manufacturerYear: Scalars['String']['output'];
  metaData: Scalars['JSONString']['output'];
  /** Vehicle's model */
  model: Scalars['String']['output'];
  /** Vehicle's naming */
  name: Scalars['String']['output'];
  onRouteId?: Maybe<Scalars['String']['output']>;
  primaryDriver?: Maybe<DriverType>;
  project: ProjectType;
  realtimeLocation?: Maybe<LocationWithContainerType>;
  replacementVehicle: VehicleAbsenceTypeConnection;
  rfidVehicles?: Maybe<RfidVehicleConnectionDef>;
  /** Vehicle driver was in on the route */
  routeLocationHistories: RouteLocationHistoryDefConnection;
  /** Vehicle that is used in the route */
  routeVehicles: RouteVehicleTypeConnection;
  /** Vehicles that can be used in the route and is defined in the route scheme. Used to keep track of the vehicles that can be used in the route. Which in its turn is used to know which drivers can be assigned to the route depending on vehicles they can drive. */
  routesFromRouteScheme: RouteTypeConnection;
  routeschemeSet: RouteSchemeTypeConnection;
  routeschemevehicledaySet: RouteSchemeVehicleDayTypeConnection;
  /**
   *
   *             Indicates if the vehicle is either unavailable, working or not working.
   *             Can hold one of the following string values: 'working', 'off-work', 'unavailable'
   *
   */
  status?: Maybe<Scalars['String']['output']>;
  /** Vehicle's trim */
  trim: Scalars['String']['output'];
  vehicleSize: VehicleSizeDef;
  /** Type of vehicle */
  vehicleType: VehicleTypeType;
  vehicleabsenceSet: VehicleAbsenceTypeConnection;
  /** Vehicle for this waste fraction */
  wasteCapacity: VehicleWasteCapacityTypeConnection;
  wasteFractionCapacity: WasteFractionTypeConnection;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeDepotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeDriversArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeEventLogArgs = {
  addVehicleDepotsEvents?: InputMaybe<Scalars['Boolean']['input']>;
  addVehicleEvents?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  vehicleDepotsEventTypes?: InputMaybe<Array<InputMaybe<GenericMtmEventTypesGraphene>>>;
  vehicleEventTypes?: InputMaybe<Array<InputMaybe<VehicleEventTypesGraphene>>>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeIntegrationgpsvehicleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeReplacementVehicleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeRouteLocationHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeRouteVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeRoutesFromRouteSchemeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeRouteschemeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeRouteschemevehicledaySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeVehicleabsenceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeWasteCapacityArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Describes Vehicle, has relation to :model:`fleet_management.VehicleWasteCapacity` */
export type VehicleTypeWasteFractionCapacityArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleTypeConnection = {
  __typename: 'VehicleTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `VehicleType` and its cursor. */
export type VehicleTypeEdge = {
  __typename: 'VehicleTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VehicleType>;
};

export type VehicleTypeType = Node & {
  __typename: 'VehicleTypeType';
  Type: Scalars['String']['output'];
  /** Type of vehicle's body */
  body: Scalars['String']['output'];
  /** Type of body's mechanization */
  bodyMechanization: Scalars['String']['output'];
  /** CO2 emissiom per kilometer (less is better) */
  co2EmissionPerKm?: Maybe<Scalars['Decimal']['output']>;
  /** With compactor */
  compactor: Scalars['String']['output'];
  company: CompanyType;
  /** Crane variant */
  crane: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** Fixed cost for the Vehicle per day */
  fixedCost?: Maybe<Scalars['Decimal']['output']>;
  /** Fuel cost per kilometer (less is better) */
  fuelCostPerKm?: Maybe<Scalars['Decimal']['output']>;
  /** Fuel cost per litter (less is better) */
  fuelCostPerLtr?: Maybe<Scalars['Decimal']['output']>;
  fuelTankCapacity?: Maybe<Array<Scalars['Decimal']['output']>>;
  /** Vehicle fuel type */
  fuelType?: Maybe<Scalars['String']['output']>;
  /** Fuel usage per kilometer (less is better) */
  fuelUsagePerKm?: Maybe<Scalars['Decimal']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isUsed?: Maybe<Scalars['Boolean']['output']>;
  /** Type of lifting's mechanization */
  liftingMechanization: Scalars['String']['output'];
  /** Type of loading location */
  loadingLocation: Scalars['String']['output'];
  /** Vehicle's manufacturer */
  manufacturer: Scalars['String']['output'];
  /** Vehicle manufacturer's year */
  manufacturerYear: Scalars['String']['output'];
  /** Vehicle's model */
  model: Scalars['String']['output'];
  /** Name of vehicle type */
  name: Scalars['String']['output'];
  routeSettings: RouteSettingsTypeConnection;
  trailers: VehicleTrailerTypeConnection;
  /** Vehicle's trim */
  trim: Scalars['String']['output'];
  /** Type of vehicle */
  vehicles: VehicleTypeConnection;
};


export type VehicleTypeTypeRouteSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type VehicleTypeTypeTrailersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type VehicleTypeTypeVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleTypeTypeConnection = {
  __typename: 'VehicleTypeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleTypeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of queryable objects */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `VehicleTypeType` and its cursor. */
export type VehicleTypeTypeEdge = {
  __typename: 'VehicleTypeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VehicleTypeType>;
};

/** Describes relation between :model:`wastecollector.WasteFraction` and :model:`fleet_management.Vehicle` */
export type VehicleWasteCapacityType = Node & {
  __typename: 'VehicleWasteCapacityType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Volume capacity allowed for each of waste fractions */
  maxAllowedVolume: Scalars['Float']['output'];
  /** Weight capacity allowed for each of waste fractions */
  maxAllowedWeight: Scalars['Float']['output'];
  /** Vehicle for this waste fraction */
  vehicle: VehicleType;
  /** Waste fraction for this vehicle type */
  wasteFraction: WasteFractionType;
};

export type VehicleWasteCapacityTypeConnection = {
  __typename: 'VehicleWasteCapacityTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleWasteCapacityTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VehicleWasteCapacityType` and its cursor. */
export type VehicleWasteCapacityTypeEdge = {
  __typename: 'VehicleWasteCapacityTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VehicleWasteCapacityType>;
};

export type Verify = {
  __typename: 'Verify';
  payload?: Maybe<PayloadType>;
  user?: Maybe<UserType>;
};

/** Store information about Waste Collector */
export type WasteCollectorType = Node & {
  __typename: 'WasteCollectorType';
  /** Naming of company */
  companyName: Scalars['String']['output'];
  containers: ContainerTypeConnection;
  email: Array<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Collector's, phone number */
  phone?: Maybe<Scalars['String']['output']>;
};


/** Store information about Waste Collector */
export type WasteCollectorTypeContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type WasteFractionEventDef = Node & {
  __typename: 'WasteFractionEventDef';
  createdAt: Scalars['DateTime']['output'];
  eventType?: Maybe<Array<Maybe<WasteFractionEventTypesGraphene>>>;
  fromColor?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  fromWasteCategory?: Maybe<Scalars['String']['output']>;
  fromWasteSubstance?: Maybe<Scalars['String']['output']>;
  fromWasteType?: Maybe<Scalars['String']['output']>;
  fromWeightFactor?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  state?: Maybe<HistoryObjectStateGraphene>;
  sysPeriod?: Maybe<Scalars['RangeDateTimeScalar']['output']>;
  toColor?: Maybe<Scalars['String']['output']>;
  toName?: Maybe<Scalars['String']['output']>;
  toWasteCategory?: Maybe<Scalars['String']['output']>;
  toWasteSubstance?: Maybe<Scalars['String']['output']>;
  toWasteType?: Maybe<Scalars['String']['output']>;
  toWeightFactor?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<UserType>;
  wasteFraction?: Maybe<WasteFractionType>;
};

export enum WasteFractionEventTypesGraphene {
  COLOR_CHANGE = 'COLOR_CHANGE',
  NAME_CHANGE = 'NAME_CHANGE',
  WASTE_CATEGORY_CHANGE = 'WASTE_CATEGORY_CHANGE',
  WASTE_SUBSTANCE_CHANGE = 'WASTE_SUBSTANCE_CHANGE',
  WASTE_TYPE_CHANGE = 'WASTE_TYPE_CHANGE',
  WEIGHT_FACTOR_CHANGE = 'WEIGHT_FACTOR_CHANGE'
}

export type WasteFractionType = Node & {
  __typename: 'WasteFractionType';
  alertruleSet: AlertRuleDefConnection;
  binsCount?: Maybe<Scalars['Float']['output']>;
  bulkwastepickupticketitemSet: BulkWastePickupTicketItemDefConnection;
  collectedKg?: Maybe<Scalars['Float']['output']>;
  collectedM3?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  containerSet: ContainerTypeConnection;
  costsettingsSet: CostSettingsTypeConnection;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  /** Relation to the WasteFraction */
  depots: DepotTypeConnection;
  icon?: Maybe<Scalars['String']['output']>;
  iconPreset?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  inquiries: CollectionInquiryTypeConnection;
  isUsed?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  migratedFromId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  project: ProjectType;
  projectsVisible: ProjectTypeConnection;
  routeSettings: RouteSettingsTypeConnection;
  routes: RouteTypeConnection;
  routeschemewastefractionfilllevelsetSet: RouteSchemeWasteFractionFillLevelSetTypeConnection;
  scheduledexportSet: ScheduledExportDefConnection;
  scheduledexportrunSet: ScheduledExportRunDefConnection;
  style?: Maybe<Scalars['String']['output']>;
  vehicleSet: VehicleTypeConnection;
  /** Waste fraction for this vehicle type */
  vehiclesCapacity: VehicleWasteCapacityTypeConnection;
  vehicletrailerwasteholdSet: VehicleTrailerWasteHoldTypeConnection;
  wasteCategory?: Maybe<Scalars['String']['output']>;
  wasteSubstance?: Maybe<Scalars['String']['output']>;
  wasteType?: Maybe<Scalars['String']['output']>;
  wasteTypes: WasteTypeTypeConnection;
  /** kg for m3 */
  weightFactor: Scalars['Float']['output'];
};


export type WasteFractionTypeAlertruleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeBulkwastepickupticketitemSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeContainerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeCostsettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeDepotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeInquiriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeProjectsVisibleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeRouteSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeRoutesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeRouteschemewastefractionfilllevelsetSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeScheduledexportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeScheduledexportrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeVehicleSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeVehiclesCapacityArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeVehicletrailerwasteholdSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteFractionTypeWasteTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type WasteFractionTypeConnection = {
  __typename: 'WasteFractionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WasteFractionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `WasteFractionType` and its cursor. */
export type WasteFractionTypeEdge = {
  __typename: 'WasteFractionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<WasteFractionType>;
};

export type WasteTypeType = Node & {
  __typename: 'WasteTypeType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<WasteTypeType>;
  parentId?: Maybe<Scalars['ID']['output']>;
  project?: Maybe<ProjectType>;
  wastefractionSet: WasteFractionTypeConnection;
  wastetypeSet: WasteTypeTypeConnection;
  /** kg for m^3 */
  weightFactor?: Maybe<Scalars['Float']['output']>;
};


export type WasteTypeTypeWastefractionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type WasteTypeTypeWastetypeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type WasteTypeTypeConnection = {
  __typename: 'WasteTypeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WasteTypeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `WasteTypeType` and its cursor. */
export type WasteTypeTypeEdge = {
  __typename: 'WasteTypeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<WasteTypeType>;
};
