import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TCoreIoUserLanguageChoicesAll =
  | `EN`
  | `DA`
  | 'NO'
  | 'FI'
  | 'SV'
  | `EL`
// we only support 2 for now.
export type TCoreIoUserLanguageChoices = `EN` | `DA` | 'NO' | 'FI' | 'SV'

export const coreIoUserLanguageChoicesLabelMap: Record<
  TCoreIoUserLanguageChoices,
  ReactNode
> = {
  DA: <T _str="Danish" />,
  EN: <T _str="English" />,
  NO: <T _str="Norwegian" />,
  FI: <T _str="Finnish" />,
  SV: <T _str="Swedish" />,
}
